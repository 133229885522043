import React, { useEffect, useState } from 'react';
import { FaUser } from 'react-icons/fa';
import axios from '../axiosConfig';
import { useCommentModal } from '../contexts/CommentModalContext';

const ChatSidebar = ({ userName }) => {
  const [chatRooms, setChatRooms] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedChatRoom, setSelectedChatRoom] = useState(null);
  const { openCommentModal } = useCommentModal();

  useEffect(() => {
    const fetchChatRooms = async () => {
      try {
        const response = await axios.get('/user/chats', { params: { user_name: userName } });
        setChatRooms(response.data);
      } catch (error) {
        console.error('채팅방 목록 불러오기 중 오류 발생:', error);
        setChatRooms([]); // 오류 발생 시 빈 배열로 설정
      }
    };

    if (userName) {
      fetchChatRooms();
    }
  }, [userName]);

  const handleChatRoomClick = (chatRoom) => {
    openCommentModal(chatRoom.id, chatRoom.name);
  };

  const handleUserIconClick = (chatRoom) => {
    setSelectedChatRoom(chatRoom);
  };

  const handleCloseModal = () => {
    setSelectedChatRoom(null);
  };

  return (
    <div className="bg-gray-100 border-r w-64">
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="text-md font-semibold">채팅방 목록</h2>
      </div>
      <ul className="bg-white border-t border-b text-xs">
        {Array.isArray(chatRooms) && chatRooms.length > 0 ? (
          chatRooms.map((chatRoom) => (
            <li
              key={chatRoom.id}
              className="flex justify-between items-center p-4 cursor-pointer hover:bg-gray-200 border-b"
              onClick={() => handleChatRoomClick(chatRoom)}
            >
              <span>{chatRoom.name}</span>
              <FaUser 
                className="text-md" 
                onClick={(e) => { e.stopPropagation(); handleUserIconClick(chatRoom); }} 
              />
            </li>
          ))
        ) : (
          <li className="p-4 text-gray-500">참여 중인 채팅방이 없습니다.</li>
        )}
      </ul>
      {selectedChatRoom && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg">
            <h3 className="text-lg font-semibold mb-4">{selectedChatRoom.name} 참여자</h3>
            <ul>
              {(selectedChatRoom.users || []).map((user) => (
                <li key={user.id} className="p-2 border-b">{user.name}</li>
              ))}
            </ul>
            <button onClick={handleCloseModal} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-lg">닫기</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatSidebar;