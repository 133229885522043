import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDropzone } from 'react-dropzone';
import axios from '../axiosConfig';
import CustomSelect from '../components/commons/modal/CustomSelect';

const TranslateBoard = () => {
  const [files, setFiles] = useState([]);
  const [cloudProvider, setCloudProvider] = useState(null);
  const [sourceLanguage, setSourceLanguage] = useState(null);
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [glossary, setGlossary] = useState(null);
  const [glossaryOptions, setGlossaryOptions] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isTranslating, setIsTranslating] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx',
    onDrop: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
  });

  const cloudOptions = [
    { value: 'naver', label: 'Naver Cloud' },
    { value: 'google', label: 'Google Cloud' },
  ];

  const languageOptions = {
    naver: [
      { value: 'ko', label: '한국어' },
      { value: 'en', label: '영어' },
      { value: 'ja', label: '일본어' },
      { value: 'zh-CN', label: '중국어 간체' },
      { value: 'zh-TW', label: '중국어 번체' },
      { value: 'vi', label: '베트남어' },
      { value: 'th', label: '태국어' },
      { value: 'id', label: '인도네시아어' },
      { value: 'fr', label: '프랑스어' },
      { value: 'es', label: '스페인어' },
      { value: 'ru', label: '러시아어' },
      { value: 'de', label: '독일어' },
      { value: 'it', label: '이탈리아어' },
    ],
    google: [
      { value: 'en', label: 'English' },
      { value: 'es', label: 'Spanish' },
      // 구글 클라우드에서 지원하는 다른 언어 옵션 추가
    ],
  };

  useEffect(() => {
    const loadGlossaryOptions = async () => {
      if (cloudProvider && cloudProvider.value === 'naver') {
        const glossaries = await fetchGlossaryList();
        setGlossaryOptions(glossaries.map(g => ({ value: g.glossaryKey, label: g.glossaryName })));
      }
    };

    loadGlossaryOptions();
  }, [cloudProvider]);

  const handleTranslate = async () => {
    if (!files.length || !sourceLanguage || !targetLanguage || !glossary || !cloudProvider) {
      alert('모든 필드를 선택해주세요.');
      return;
    }

    setIsTranslating(true);
    setProgress(0);

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('sourceLanguage', sourceLanguage.value);
    formData.append('targetLanguage', targetLanguage.value);
    formData.append('glossary', glossary.value);
    formData.append('cloudProvider', cloudProvider.value);

    try {
      const response = await axios.post('/api/translate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        },
      });

      setDownloadLink(response.data.downloadLink);
      setIsTranslating(false);
    } catch (error) {
      console.error('Error translating file:', error);
      setIsTranslating(false);
    }
  };

  return (
    <div className="relative p-4 overflow-x-auto shadow-md sm:rounded-lg">
      <div {...getRootProps({ className: 'dropzone' })} className="p-4 text-center border-2 border-gray-300 border-dashed">
        <input {...getInputProps()} />
        <p>파일을 드래그하거나 클릭하여 업로드하세요.</p>
      </div>
      <div className="mt-4">
        <CustomSelect
          options={cloudOptions}
          placeholder="클라우드 제공자 선택"
          onChange={setCloudProvider}
        />
      </div>
      <div className="mt-4">
        <CustomSelect
          options={cloudProvider ? languageOptions[cloudProvider.value] : []}
          placeholder="출발어 선택"
          onChange={setSourceLanguage}
          isDisabled={!cloudProvider}
        />
      </div>
      <div className="mt-4">
        <CustomSelect
          options={cloudProvider ? languageOptions[cloudProvider.value] : []}
          placeholder="도착어 선택"
          onChange={setTargetLanguage}
          isDisabled={!cloudProvider}
        />
      </div>
      <div className="mt-4">
        <CustomSelect
          options={glossaryOptions}
          placeholder="용어집 선택"
          onChange={setGlossary}
          isDisabled={!cloudProvider || cloudProvider.value !== 'naver'}
        />
      </div>
      <div className="mt-4">
        <button
          onClick={handleTranslate}
          className="inline-flex items-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-800"
          disabled={isTranslating}
        >
          번역 시작
        </button>
      </div>
      {isTranslating && (
        <div className="mt-4">
          <CircularProgressbar value={progress} text={`${progress}%`} />
        </div>
      )}
      {downloadLink && (
        <div className="mt-4">
          <a href={downloadLink} download className="text-blue-600 hover:underline">
            번역된 파일 다운로드
          </a>
        </div>
      )}
    </div>
  );
};

export default TranslateBoard;