import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'; // UUID 라이브러리 추가
import CustomSelect from '../../../components/commons/modal/CustomSelect'; // CustomSelect import 추가

const ProjectFieldsTable = ({ tasks, getOptions, handleSelectChange, handleTaskChange, removeTask, customStyles, setTasks, formData, setFormData }) => {
  const [filteredLanguagePairs, setFilteredLanguagePairs] = useState([]);

  useEffect(() => {
    const languagePairs = getOptions('languagePair');
    if (formData.Currency) {
      const filteredPairs = languagePairs.filter(pair => pair.value.currency === formData.Currency);
      setFilteredLanguagePairs(filteredPairs);
    }
  }, [formData.Currency, getOptions]);

  const initialTask = () => ({
    id: uuidv4(), // 고유한 ID 추가
    ProjectType: '',
    PMName: '',
    SLang: '',
    TLang: '',
    ProjectVolume: '',
    Unit: '',
    Rate: '',
    Total: '',
    Workers: [],
    Status: '',
    DiscountRate: 0, 
    totalInKRW: 0, 
    SDate: formData.SDate,  
    DDate: formData.DDate,  
    Invoice: formData.Invoice,  
    Division: '', 
    Group: '' 
  });

  const calculateRate = (task) => {
    const languagePair = filteredLanguagePairs.find(pair =>
      pair.value.projectType === task.ProjectType &&
      pair.value.SLang === task.SLang &&
      pair.value.TLang === task.TLang &&
      pair.value.unit === task.Unit
    );
    return languagePair ? languagePair.value.rate : '';
  };

  const calculateTotal = (volume, rate, discountRate = 0) => {
    const total = parseFloat(volume) * parseFloat(rate);
    return total * (1 - (parseFloat(discountRate) / 100));
  };

  const calculateTotalWithVAT = (total, currency) => {
    return currency === 'KRW' ? total + Math.floor(total * 0.1) : total;
  };

  const updateProjectDiscount = (tasks) => {
    const totalDiscount = tasks.reduce((acc, task) => acc + (parseFloat(task.DiscountRate) || 0), 0);
    const averageDiscount = tasks.length > 0 ? totalDiscount / tasks.length : 0;

    setFormData((prevData) => ({
      ...prevData,
      Discount: averageDiscount.toFixed(2),  // 소수점 두 자리까지
    }));
  };

  const handleTaskChangeWithRate = (index, e) => {
    const { name, value } = e.target;
    const newTasks = [...tasks];
    newTasks[index][name] = value;

    // 환율이 변경되거나, 관련된 값들이 변경될 때 totalInKRW를 재계산
    if (name === 'ProjectVolume' || name === 'Rate' || name === 'DiscountRate' || name === 'ExchangeRate') {
      const volume = newTasks[index]['ProjectVolume'];
      const rate = newTasks[index]['Rate'];
      const discountRate = newTasks[index]['DiscountRate'];
      const exchangeRate = formData.Currency !== 'KRW' ? parseFloat(newTasks[index]['ExchangeRate'] || 0) : 1;
      if (volume && rate) {
        newTasks[index]['Total'] = calculateTotal(volume, rate, discountRate);
        newTasks[index]['totalInKRW'] = newTasks[index]['Total'] * exchangeRate; // totalInKRW 계산
      }
    }

    if (['ProjectType', 'SLang', 'TLang', 'Unit'].includes(name)) {
      const newRate = calculateRate(newTasks[index]);
      if (newRate) {
        newTasks[index]['Rate'] = newRate;
        // totalInKRW를 다시 계산해야 할 경우
        const volume = newTasks[index]['ProjectVolume'];
        const discountRate = newTasks[index]['DiscountRate'];
        const exchangeRate = formData.Currency !== 'KRW' ? parseFloat(newTasks[index]['ExchangeRate'] || 0) : 1;
        if (volume && newRate) {
          newTasks[index]['Total'] = calculateTotal(volume, newRate, discountRate);
          newTasks[index]['totalInKRW'] = newTasks[index]['Total'] * exchangeRate; // totalInKRW 재계산
        }
      }
    }

    setTasks(newTasks);
    updateProjectTotalInKRW(newTasks); // 프로젝트의 TotalInKRW 업데이트

    // 할인율 변경 시 전체 프로젝트 할인율 업데이트
    if (name === 'DiscountRate') {
      updateProjectDiscount(newTasks);
    }
  };

  const updateProjectTotalInKRW = (tasks) => {
    const totalInKRW = tasks.reduce((acc, task) => acc + (task.totalInKRW || 0), 0);
    setFormData(prev => ({ ...prev, TotalInKRW: totalInKRW.toFixed(2) }));
  };

  const handleSelectChangeWithRate = (index, name, selectedOption) => {
    const newTasks = [...tasks];
    newTasks[index][name] = selectedOption ? selectedOption.value : '';
  
    if (name === 'PMName') {
      const selectedPM = getOptions('employees').find(option => option.value === selectedOption.value);
      if (selectedPM) {
        newTasks[index]['Division'] = selectedPM.division; // 'division'을 'Division'으로 수정
        newTasks[index]['Group'] = selectedPM.group; // 'group'을 'Group'으로 수정
      }
    }
  
    if (['ProjectType', 'SLang', 'TLang', 'Unit'].includes(name)) {
      const newRate = calculateRate(newTasks[index]);
      if (newRate) {
        newTasks[index]['Rate'] = newRate;
      }
    }
  
    setTasks(newTasks);
  };

  const addTask = () => {
    const lastTask = tasks[tasks.length - 1];
    const newTask = {
      ...lastTask,
      id: uuidv4(), 
      SDate: formData.SDate, 
      DDate: formData.DDate, 
      Invoice: formData.Invoice,
      Division: '',
      Group: ''
    };
    setTasks([...tasks, newTask]);
  };

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          {Object.keys(initialTask()).map((key) => (
            key !== 'Workers' && key !== 'id' && key !== 'totalInKRW' && 
            key !== 'SDate' && key !== 'DDate' && key !== 'Invoice' && 
            key !== 'Division' && key !== 'Group' &&
             ( // SDate, DDate, Invoice, Division, Group 컬럼 숨김
              <th key={key} className="px-2 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
                {key === 'ProjectVolume' ? 'Volume' : key === 'DiscountRate' ? 'Discount' : key}
              </th>
            )
          ))}
          <th className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase">
            <button type="button" onClick={addTask} className="text-blue-500">+</button>
          </th>
        </tr>
      </thead>
      <tbody>
        {tasks.map((task, index) => (
          <tr key={task.id}>
            {Object.keys(task).map((key) => (
              key !== 'Workers' && key !== 'id' && key !== 'totalInKRW' &&
              key !== 'SDate' && key !== 'DDate' && key !== 'Invoice' && 
              key !== 'Division' && key !== 'Group' &&
               ( // SDate, DDate, Invoice, Division, Group 필드 숨김
                <td key={key} className="px-2 py-8 whitespace-nowrap">
                  {key === 'SLang' || key === 'TLang' ? (
                    <CustomSelect
                      name={key}
                      value={getOptions('language').find(option => option.value === task[key])}
                      onChange={(selectedOption) => handleSelectChangeWithRate(index, key, selectedOption)}
                      options={getOptions('language')}
                      styles={customStyles}
                      className="block w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  ) : key === 'ProjectType' ? (
                    <CustomSelect
                      name={key}
                      value={getOptions('projectType').find(option => option.value === task[key])}
                      onChange={(selectedOption) => handleSelectChangeWithRate(index, key, selectedOption)}
                      options={getOptions('projectType')}
                      styles={customStyles}
                      className="block w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  ) : key === 'PMName' ? (
                    <CustomSelect
                      name="PMName"
                      value={getOptions('employees').find(option => option.value === task.PMName)}
                      onChange={(selectedOption) => handleSelectChangeWithRate(index, 'PMName', selectedOption)}
                      options={getOptions('employees')}
                      styles={customStyles}
                      className="block w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  ) : key === 'Unit' ? (
                    <CustomSelect
                      name={key}
                      value={getOptions('unit').find(option => option.value === task[key])}
                      onChange={(selectedOption) => handleSelectChangeWithRate(index, key, selectedOption)}
                      options={getOptions('unit')}
                      styles={customStyles}
                      className="block w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  ) : key === 'Rate' || key === 'ProjectVolume' || key === 'DiscountRate' || key === 'ExchangeRate' ? (
                    <input
                      type="text"
                      name={key}
                      value={task[key]}
                      onChange={(e) => handleTaskChangeWithRate(index, e)}
                      placeholder={key === 'ProjectVolume' ? 'Volume' : key === 'DiscountRate' ? 'Discount' : key === 'ExchangeRate' ? 'Exchange Rate' : ' '}
                      required
                      className="block w-full px-0 pt-3 pb-2 mt-0 text-xs bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black"
                    />
                  ) : key === 'Status' ? (
                    <CustomSelect
                      name="Status"
                      value={getOptions('workerStatus').find(option => option.value === task.Status)}
                      onChange={(selectedOption) => handleSelectChangeWithRate(index, 'Status', selectedOption)}
                      options={getOptions('workerStatus')}
                      styles={customStyles}
                      className="block w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  ) : key === 'Division' || key === 'Group' ? (
                    <input
                      type="text"
                      name={key}
                      value={task[key]}
                      onChange={(e) => handleTaskChangeWithRate(index, e)}
                      placeholder=" "
                      required
                      className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black sm:text-sm"
                    />
                  ) : (
                    <input
                      type="text"
                      name={key}
                      value={task[key]}
                      onChange={(e) => handleTaskChangeWithRate(index, e)}
                      placeholder=" "
                      required
                      className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black sm:text-sm"
                    />
                  )}
                </td>
              )
            ))}
            <td className="px-6 py-4 whitespace-nowrap">
              <button type="button" onClick={() => removeTask(index)} className="text-red-500">-</button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ProjectFieldsTable;