import React from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? '0 0 0 2px #2563EB' : 'none', // Focus outline color
    borderColor: state.isFocused ? '#2563EB' : '#d1d5db', // Border color change on focus
    '&:hover': {
      borderColor: '#2563EB', // Hover border color
    },
    minHeight: '20px', // Adjust height to match other inputs
    minWidth: '8rem', // Min width for the dropdown
    outline: 'none',
    fontSize: '12px' // Adjust font size to match other inputs
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#2563EB' : '#d1d5db', // Indicator color change on focus
    '&:hover': {
      color: '#2563EB', // Hover indicator color
    },
  }),
  container: (provided) => ({
    ...provided,
    outline: 'none',
  }),
  input: (provided) => ({
    ...provided,
    boxShadow: 'none',
    outline: 'none',
    '& input': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& input:focus': {
      boxShadow: 'none',
      outline: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // z-index 추가
    borderRadius: '0.375rem', // Rounded corners for menu
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for menu
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // 메뉴를 위한 z-index
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#E0F2FE' : 'white', // Background color on focus
    color: state.isSelected ? '#2563EB' : '#374151', // Text color on selection
    '&:active': {
      backgroundColor: '#2563EB', // Background color on active
      color: 'white', // Text color on active
    },
    fontSize: '0.75rem', // Adjust font size to xs
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#374151', // Text color for selected value
    fontSize: '0.75rem' // Adjust font size to xs
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6B7280', // Placeholder color
    fontSize: '0.75rem' // Adjust font size to xs
  }),
};

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      styles={customStyles}
      menuPortalTarget={document.body} // menuPortalTarget 추가
      menuPosition="fixed" // menuPosition 추가
    />
  );
};

export default CustomSelect;