import { differenceInBusinessDays, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query'; // useQueryClient 추가
import { v4 as uuidv4 } from 'uuid';
import InvoiceFields from '../../../area/project/invoice/InvoiceFields';
import Notes from '../../../area/project/invoice/Notes';
import ProjectFieldsTable from '../../../area/project/invoice/ProjectFieldsTable';
import ReviewComponent from '../../../area/project/invoice/ReviewComponent';
import axios from '../../../axiosConfig';
import { OneButtonModal } from '../Modal';
import CustomSelect from './CustomSelect';

const AddProjectModal = ({ isOpen, onClose, dropdownData, fetchProjects = () => {} }) => {
  const queryClient = useQueryClient(); 
  const initialFormData = {
    Invoice: '',
    ProjectName: '',
    ClientName: '',
    ClientPM: '',
    ClientEmail: '',
    ClientPhone: '',
    EndClient: '',
    Domain: '',
    SDate: '',
    DDate: '',
    Total: '',
    TotalWithVAT: '',
    Status: '',
    Division: '',
    Group: '',
    Currency: '',
    MainPM: '',
    Files: [],
    notes: [
      { text: '▶견적 유효기간 경과 후 재 견적 시 견적금액이 변경될 수 있습니다.' },
      { text: '▶작업에는 영업일 약 0일 소요가 예상되며 정확한 납품일정 및 전체 소요기간은 고객사 사정에 따라 협의가 가능합니다.' },
      { text: '▶번역료는 번역 결과물을 고객에게 인도한 날에 세금계산서의 발행과 함께 청구되며, 청구일로부터 30일 내에 우리은행 1005-000-926061 (예금주 주식회사 엔코라인) 에 입금해 주시기 바랍니다.' }
    ],
  };

  const initialTasks = [{
    id: uuidv4(),
    ProjectType: '',
    PMName: '',
    SLang: '',
    TLang: '',
    ProjectVolume: '',
    Unit: '',
    Rate: '',
    Total: '',
    Workers: [],
    Status: '',
    DiscountRate: 0,
    totalInKRW: 0,
    SDate: initialFormData.SDate,
    DDate: initialFormData.DDate,
  }];

  const [formData, setFormData] = useState(initialFormData);
  const [tasks, setTasks] = useState(initialTasks);
  const [dropdownOptions, setDropdownOptions] = useState({
    division: [],
    group: [],
    languagePair: [],
    taskStatus: [],
    employees: [],
    clients: []
  });
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [endClientOptions, setEndClientOptions] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [projectId, setProjectId] = useState(null);
  const [isNameDuplicateModalOpen, setIsNameDuplicateModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
      setTasks(initialTasks);
      setCurrentStep(1);
      setProjectId(null);
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get(`/getDropdown`);
        const dropdownData = response.data.reduce((acc, item) => {
          if (item.type === 'clients' || item.type === 'employees') {
            acc[item.type] = item.values.map(value => ({
              value: value.clientName || value.name,
              label: value.clientName || value.name,
              division: value.division,
              group: value.group
            }));
          } else {
            acc[item.type] = item.values.map(value => ({
              value: value.name || value,
              label: value.name || value
            }));
          }
          return acc;
        }, {});
        setDropdownOptions((prevOptions) => ({ ...prevOptions, ...dropdownData }));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };
    fetchDropdownOptions();
  }, []);

  useEffect(() => {
    const selectedPair = dropdownOptions.languagePair.find(pair => 
      pair.SLang === formData.SLang && 
      pair.TLang === formData.TLang && 
      pair.currency === formData.Currency && 
      pair.projectType === formData.ProjectType && 
      pair.unit === formData.Unit
    );
    if (selectedPair) {
      setFormData((prevData) => ({
        ...prevData,
        Rate: selectedPair.rate
      }));
      setTasks(prevTasks => prevTasks.map(task => {
        const volume = task.ProjectVolume;
        const rate = selectedPair.rate;
        const discountRate = task.DiscountRate;
        const total = calculateTotal(volume, rate, discountRate);
        return {
          ...task,
          Rate: rate,
          Total: total,
        };
      }));
    }
  }, [formData.SLang, formData.TLang, formData.Currency, formData.ProjectType, formData.Unit, dropdownOptions.languagePair]);
  
  useEffect(() => {
    const selectedClient = dropdownData.find(item => item.type === 'clients')?.values.find(client => client.clientName === formData.ClientName);
    if (selectedClient) {
      if (selectedClient.endClient && selectedClient.endClient.length > 0) {
        setEndClientOptions(selectedClient.endClient.map(ec => ({ value: ec.endClientName, label: ec.endClientName })));
      } else {
        setEndClientOptions([{ value: selectedClient.clientName, label: selectedClient.clientName }]);
      }
    } else {
      setEndClientOptions([]);
    }
  }, [formData.ClientName, dropdownData]);

  useEffect(() => {
    if (tasks.length > 0) {
      const updatedTasks = tasks.map(task => ({
        ...task,
        SDate: formData.SDate,
        DDate: formData.DDate,
        Invoice: formData.Invoice
      }));
      setTasks(updatedTasks);
    }
  }, [formData.SDate, formData.DDate, formData.Invoice]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData((prevData) => ({
      ...prevData,
      [actionMeta.name]: selectedOption.value
    }));
    if (actionMeta.name === 'Division' || actionMeta.name === 'Group') {
      filterEmployees(selectedOption.value, actionMeta.name);
    }
  };

  const filterEmployees = (selectedValue, type) => {
    let filtered = dropdownOptions.employees;
    if (type === 'Division') {
      filtered = filtered.filter(employee => employee.division === selectedValue);
    } else if (type === 'Group') {
      filtered = filtered.filter(employee => employee.group === selectedValue);
    }
    setFilteredEmployees(filtered.map(emp => ({ value: emp.value, label: emp.label })));
  };

  const handleFileUpload = (files) => {
    const filePaths = files.map(file => ({
      path: file.file_path,
      comment: file.comment
    }));
    setFormData((prevData) => ({
      ...prevData,
      Files: filePaths,
    }));
  };

  const saveStepData = async () => {
    try {
      let response;
      const dataToSave = { ...formData, tasks };

      if (!projectId && currentStep === 1) {
        response = await axios.post(`/addProject`, dataToSave);
        if (response.status === 201) {
          setProjectId(response.data.project_id);
        }
      } else if (projectId) {
        response = await axios.put(`/updateProject`, { ...dataToSave, _id: projectId });
      }

      if (response && (response.status === 200 || response.status === 201)) {
        setFormData((prevData) => ({
          ...prevData,
          _id: projectId || response.data.project_id,
        }));
      } else {
        console.error('프로젝트 업데이트 중 오류 발생:', response.data);
      }
    } catch (error) {
      console.error('프로젝트 업데이트 중 오류 발생:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await saveStepData();
    onClose();
    if (typeof fetchProjects === 'function') {
      await fetchProjects(); // 실시간 반영
    }
    queryClient.invalidateQueries(['projects']); // 쿼리 무효화 추가
  };

  const nextStep = async () => {
    if (currentStep === 1) {
      const existingProject = await axios.get(`/checkProjectName?name=${formData.ProjectName}`);
      if (existingProject.data.exists) {
        setIsNameDuplicateModalOpen(true);
        return;
      }
    }
    await saveStepData();
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const addTask = () => {
    const lastTask = tasks[tasks.length - 1];
    const newTask = {
      ...lastTask,
      id: uuidv4(),
    };
    setTasks([...tasks, newTask]);
  };

  const calculateTotalAmounts = (tasks, currency) => {
    const totalAmount = tasks.reduce((sum, task) => sum + (parseFloat(task.Total) || 0), 0);
    const totalWithVAT = currency === 'KRW' ? totalAmount + Math.floor(totalAmount * 0.1) : totalAmount;
    return { totalAmount, totalWithVAT };
  };

  const calculateBusinessDays = (start, end) => {
    if (!start || !end) return 0;
    return differenceInBusinessDays(parseISO(end), parseISO(start));
  };

  useEffect(() => {
    const businessDays = calculateBusinessDays(formData.SDate, formData.DDate);
    setFormData((prevData) => ({
      ...prevData,
      notes: prevData.notes.map(note => ({
        ...note,
        text: note.text.replace(/약 \d+일 소요/, `약 ${businessDays}일 소요`)
      }))
    }));
  }, [formData.SDate, formData.DDate]);

  const handleTaskChange = (index, e) => {
    const { name, value } = e.target;
    const newTasks = [...tasks];
    newTasks[index][name] = value;

    if (name === 'ProjectVolume' || name === 'Rate' || name === 'DiscountRate') {
      const volume = newTasks[index]['ProjectVolume'];
      const rate = newTasks[index]['Rate'];
      const discountRate = newTasks[index]['DiscountRate'];
      if (volume && rate) {
        newTasks[index]['Total'] = calculateTotal(volume, rate, discountRate);
      }
    }

    if (['ProjectType', 'SLang', 'TLang', 'Unit'].includes(name)) {
      const newRate = calculateRate(newTasks[index], formData.Currency);
      if (newRate) {
        newTasks[index]['Rate'] = newRate;
      }
    }

    setTasks(newTasks);
  };

  const handleSelectChangeWithRate = (index, name, selectedOption) => {
    const newTasks = [...tasks];
    newTasks[index][name] = selectedOption ? selectedOption.value : '';

    if (name === 'PMName') {
      const selectedPM = getOptions('employees').find(option => option.value === selectedOption.value);
      if (selectedPM) {
        newTasks[index]['Division'] = selectedPM.division;
        newTasks[index]['Group'] = selectedPM.group;
      }
    }

    if (['ProjectType', 'SLang', 'TLang', 'Unit'].includes(name)) {
      const newRate = calculateRate(newTasks[index]);
      if (newRate) {
        newTasks[index]['Rate'] = newRate;
      }
    }

    setTasks(newTasks);
  };

  const removeTask = (index) => {
    const newTasks = tasks.filter((_, i) => i !== index);
    setTasks(newTasks);
  };

  const getOptions = (type) => {
    if (type === 'clients') {
      return dropdownData.find(item => item.type === type)?.values.map(value => ({
        value: value.clientName,
        label: value.clientName
      })) || [];
    }
    if (type === 'domains') {
      return dropdownData.find(item => item.type === type)?.values.map(value => ({
        value: value.name || value,
        label: value.name || value
      })) || [];
    }
    return dropdownData.find(item => item.type === type)?.values.map(value => ({
      value: value.name || value,
      label: value.name || value
    })) || [];
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const { totalAmount, totalWithVAT } = calculateTotalAmounts(tasks, formData.Currency);

  const addNote = () => {
    setFormData(prevData => ({ ...prevData, notes: [...prevData.notes, initialNote()] }));
  };

  const initialNote = () => ({
    text: '▶새로운 내용을 입력하세요.'
  });

  const handleNoteChange = (index, e) => {
    const { value } = e.target;
    const newNotes = [...formData.notes];
    newNotes[index].text = value;
    setFormData(prevData => ({ ...prevData, notes: newNotes }));
  };

  const removeNote = (index) => {
    const newNotes = formData.notes.filter((_, i) => i !== index);
    setFormData(prevData => ({ ...prevData, notes: newNotes }));
  };

  const businessDays = calculateBusinessDays(formData.SDate, formData.DDate);

  if (!isOpen) return null;

  return (
    <>
      {isNameDuplicateModalOpen && (
        <OneButtonModal
          title="중복된 프로젝트 이름"
          body="동일한 프로젝트 이름이 존재합니다. 다른 이름을 사용해 주세요."
          onSave={() => setIsNameDuplicateModalOpen(false)}
          onClose={() => setIsNameDuplicateModalOpen(false)}
          zIndex={60}
        />
      )}
      <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50">
        <div className="relative w-full px-6 py-12 mx-auto bg-white border-0 shadow-lg max-w-7xl sm:rounded-3xl" style={{ width: '80%', height: '80%', overflowY: 'auto' }}>
          <button onClick={onClose} className="absolute text-gray-500 top-4 right-4 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="mb-8 text-2xl font-bold">Add Project</h2>
          <div className="flex justify-center mb-4 space-x-4">
            {['담당 본부', '견적서', '최종검토'].map((step, index) => (
              <div key={index} className={`px-2 py-1 ${currentStep === index + 1 ? 'text-blue-600' : 'text-gray-500'}`}>
                {step}
                {currentStep === index + 1 && <div className="h-1 mt-1 bg-blue-600"></div>}
              </div>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="flex flex-wrap py-12">
            {currentStep === 1 && (
              <>
                <div className="relative z-0 w-1/2 pr-2 mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900 p-0 pl-2">프로젝트</label>
                  <input
                    type="text"
                    name="ProjectName"
                    value={formData.ProjectName}
                    onChange={handleChange}
                    required
                    className="block w-full m-2.5 px-2 py-2.5 mt-1 bg-white border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
                </div>
                <div className="relative z-0 w-1/4 pl-2 mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900">본부</label>
                  <CustomSelect
                    name="Division"
                    value={dropdownOptions.division.find(option => option.value === formData.Division)}
                    onChange={handleSelectChange}
                    options={dropdownOptions.division}
                  />
                </div>
                <div className="relative z-0 w-1/4 pl-2 mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900">그룹</label>
                  <CustomSelect
                    name="Group"
                    value={dropdownOptions.group.find(option => option.value === formData.Group)}
                    onChange={handleSelectChange}
                    options={dropdownOptions.group}
                  />
                </div>
                <div className="relative z-0 w-1/2 pl-2 mb-5">
                  <label className="block mb-2 text-sm font-medium text-gray-900">담당자</label>
                  <CustomSelect
                    name="MainPM"
                    value={filteredEmployees.find(option => option.value === formData.MainPM)}
                    onChange={(selectedOption) => setFormData((prevData) => ({ ...prevData, MainPM: selectedOption.value }))}
                    options={filteredEmployees}
                  />
                </div>
                <div className="relative z-0 w-1/2 pl-2 mb-5">
                  <label className="block mb-2 text-sm font-medium                   text-gray-900">상태</label>
                  <CustomSelect
                    name="Status"
                    value={dropdownOptions.taskStatus.find(option => option.value === formData.Status)}
                    onChange={(selectedOption) => setFormData((prevData) => ({ ...prevData, Status: selectedOption.value }))}
                    options={dropdownOptions.taskStatus}
                  />
                </div>
                <div className="flex justify-end w-full p-2 pt-40">
                  <button type="button" onClick={nextStep} className="px-4 py-2 text-white bg-blue-500 rounded">Next</button>
                </div>
              </>
            )}
            {currentStep === 2 && (
              <>
                <h3 className="mb-4 text-xl font-semibold"></h3>
                <div className="p-4 mx-auto max-w-7xl invoice-container">
                  <InvoiceFields
                    fields={[
                      { name: 'Currency', type: 'dropdown', label: '통화' },
                      { name: 'Invoice', type: 'text', label: '견적서 번호' },
                      { name: 'Discount', type: 'text', label: '할인율' },
                      { name: 'ClientName', type: 'dropdown', label: '클라이언트' },
                      { name: 'SDate', type: 'date', label: '시작일' },
                      { name: 'DDate', type: 'date', label: '마감일' },
                    ]}
                    formData={formData}
                    handleChange={handleChange}
                    getOptions={getOptions}
                    endClientOptions={endClientOptions}
                  />
                  <ProjectFieldsTable
                    tasks={tasks}
                    getOptions={getOptions}
                    handleSelectChange={handleSelectChangeWithRate}
                    handleTaskChange={handleTaskChange}
                    removeTask={removeTask}
                    setTasks={setTasks}
                    formData={formData}
                    setFormData={setFormData}
                  />
                  <div className="flex justify-end mt-4">
                    <div className="text-right">
                      <p className="font-bold text-blue-500">합계 금액: {formData.Currency === 'KRW' ? formatNumber(Math.floor(totalAmount)) : formatNumber(totalAmount.toFixed(2))} {formData.Currency}</p>
                      {formData.Currency === 'KRW' && (
                        <p className="font-bold text-red-500">합계 (VAT 10% 포함): {formatNumber(Math.floor(totalWithVAT))} 원</p>
                      )}
                    </div>
                  </div>
                  <Notes
                    notes={formData.notes}
                    handleNoteChange={handleNoteChange}
                    removeNote={removeNote}
                    addNote={addNote}
                  />
                </div>
                <div className="flex justify-between w-full p-2">
                  <button type="button" onClick={prevStep} className="px-4 py-2 bg-gray-300 rounded">Previous</button>
                  <button type="button" onClick={nextStep} className="px-4 py-2 text-white bg-blue-500 rounded">Next</button>
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <h3 className="mb-4 text-xl font-semibold">최종 검토</h3>
                <ReviewComponent formData={formData} tasks={tasks} />
                <div className="flex justify-between w-full p-2">
                  <button type="button" onClick={prevStep} className="px-4 py-2 bg-gray-300 rounded">Previous</button>
                  <button type="submit" className="px-4 py-2 text-white bg-blue-500 rounded">Confirm</button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default AddProjectModal;