import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from '../axiosConfig';
import { LargeModal } from '../components/commons/Modal';
import { formatNumber } from '../components/commons/Utils';

const SendPOButton = ({ pmDivision, pmGroup, projectId, jobId, projectName, taskId, workerId, workerEmail, volume, rate, currency, total, workerName, workerMobile, workerSlang, workerTlang, workerTasktype, workStartedAt, workEndedAt, paymentRequestedAt, paymentReceivedAt, paymentAccount, paymentBank, paymentCountry, paymentMethod, comments, unit, costInKRW, pmName, onPOSuccess, sdate, ddate, workerDomain, workerType, files, apiUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [poComment, setPoComment] = useState('<p><br></p><p><br></p><p><br></p><p><br></p><p><br></p>');
  const [selectedFiles, setSelectedFiles] = useState(files.map(file => ({ ...file, selected: false })));
  const [startDate, setStartDate] = useState(sdate ? new Date(sdate) : null);
  const [endDate, setEndDate] = useState(ddate ? new Date(ddate) : null);
  const queryClient = useQueryClient();

  const calculateTotal = (volume, rate) => {
    const total = parseFloat(volume) * parseFloat(rate);
    return parseFloat(total.toFixed(3)); // 소수점 세 자리까지 유지
  };

  const handleFileSelection = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles[index].selected = !updatedFiles[index].selected;
    setSelectedFiles(updatedFiles);
  };

  const handleSendPO = async () => {
    const missingFields = [];
    if (!projectName) missingFields.push('프로젝트 이름');
    if (!pmName) missingFields.push('PM 이름');
    if (!pmDivision) missingFields.push('PM 본부');
    if (!pmGroup) missingFields.push('PM 그룹');
    if (!workerName) missingFields.push('작업자 이름');
    if (!workerType) missingFields.push('작업자 유형');
    if (!workerTasktype) missingFields.push('작업 유형');
    if (!workerDomain) missingFields.push('분야');
    if (!workerSlang) missingFields.push('출발어');
    if (!workerTlang) missingFields.push('도착어');
    if (!startDate) missingFields.push('작업 시작일');
    if (!endDate) missingFields.push('작업 종료일');
    if (!volume) missingFields.push('분량');
    if (!unit) missingFields.push('단위');
    if (!rate) missingFields.push('단가');
    if (!total) missingFields.push('작업비');
    if (!costInKRW) missingFields.push('작업비(원화)');

    if (missingFields.length > 0) {
      alert(`다음 항목이 누락되었습니다: ${missingFields.join(', ')}`);
      setIsErrorModalOpen(true);
      return;
    }

    try {
      const calculatedTotal = calculateTotal(volume, rate);
      const response = await axios.post('/sendPO', {
        project_id: projectId,
        project_name: projectName,
        worker_id: workerId,
        email: workerEmail,
        task_id: taskId,
        volume: volume,
        rate: rate,
        currency: currency,
        total: calculatedTotal, // 계산된 total 사용
        worker_name: workerName,
        worker_mobile: workerMobile,
        worker_slang: workerSlang,
        worker_tlang: workerTlang,
        worker_tasktype: workerTasktype,
        unit: unit,
        work_started_at: startDate,
        work_ended_at: endDate,
        payment_requested_at: paymentRequestedAt,
        payment_received_at: paymentReceivedAt,
        payment_account: paymentAccount,
        payment_bank: paymentBank,
        payment_country: paymentCountry,
        payment_method: paymentMethod,
        comments: comments,
        costinKRW: costInKRW,
        pm_name: pmName,
        pm_division: pmDivision,
        pm_group: pmGroup,
        job_id: jobId,
        po_comment: poComment,
        sdate: sdate,
        ddate: ddate,
        worker_domains: workerDomain,
        worker_workerType: workerType,
        files: selectedFiles.filter(file => file.selected).map(file => ({
          name: file.name,
          url: `${apiUrl}/upload/project/${projectId}/${file.path}`
        }))
      });

      if (response.status === 200) {
        const { invoice, po_number } = response.data;
        alert('PO sent successfully!');
        onPOSuccess({
          invoice: invoice,
          po_number: po_number,
          project_name: projectName,
          volume: volume,
          rate: rate,
          currency: currency,
          total: calculatedTotal, // 계산된 total 사용
          costinKRW: costInKRW
        });
        queryClient.invalidateQueries(['projectDetails', projectId]);
        queryClient.invalidateQueries(['poList', projectId]);
      } else {
        alert('Failed to send PO.');
      }
    } catch (error) {
      console.error('Error sending PO:', error);
      alert('An error occurred while sending PO.');
    }
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === 'send-po-modal') {
      handleCloseModal();
    }
  };

  const handleCopyUrl = (url) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(url).then(() => {
        alert('URL이 복사되었습니다.');
      }).catch(err => {
        console.error('URL 복사 중 오류 발생:', err);
      });
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = url;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        alert('URL이 복사되었습니다.');
      } catch (err) {
        console.error('URL 복사 중 오류 발생:', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const truncateProjectName = (name) => {
    return name.length > 15 ? `${name.slice(0, 15)}...` : name;
  };

  const formatRate = (rate) => {
    if (rate == null) return 'TBD';
    const parsedRate = parseFloat(rate);
    if (isNaN(parsedRate)) return 'TBD';
    return parsedRate.toFixed(3); // 소수점 세 자리까지 유지
  };

  return (
    <>
      <button
        className="px-2 py-1 text-xs text-white bg-blue-500 rounded"
        onClick={handleOpenModal}
      >
        Send
      </button>
      {isModalOpen && (
        <LargeModal
          id="send-po-modal"
          title="PO 전송"
          className="w-4/5"
          body={
            <>
              <table className="w-full mt-2 text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">프로젝트 이름</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">PM 이름</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">PM 본부</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">PM 그룹</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">작업자</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">작업자유형</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">작업유형</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">분야</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">출발어</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">도착어</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">작업 시작일</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">작업 종료일</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">분량</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">단위</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">단가</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">작업비</th>
                    <th className="px-2 py-3 min-w-[4em] whitespace-nowrap">작업비(원화)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{truncateProjectName(projectName)}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{pmName}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{pmDivision}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{pmGroup}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{workerName}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{workerType}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{workerTasktype}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{workerDomain}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{workerSlang}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{workerTlang}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">
                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat="YYYY-MM-dd"
                        className="w-full px-2 py-1 text-xs text-gray-900 bg-white border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                        placeholderText="날짜를 선택하세요"
                        isClearable
                      />
                    </td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">
                      <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="YYYY-MM-dd"
                        className="w-full px-2 py-1 text-xs text-gray-900 bg-white border border-gray-300 rounded focus:ring-blue-500 focus:border-blue-500"
                        placeholderText="날짜를 선택하세요"
                        isClearable
                      />
                    </td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{formatNumber(volume)}</td>
                    <td className="px-2 py-3 min-w-[4em] whitespace-nowrap">{unit}</td>
                    <td className="px-2 py-3">{formatRate(rate)}</td>
                    <td className="px-2 py-3 font-bold text-blue-600 min-w-[4em] whitespace-nowrap">{formatNumber(calculateTotal(volume, rate), currency)}</td>
                    <td className="px-2 py-3 font-bold text-red-600 min-w-[4em] whitespace-nowrap">{formatNumber(costInKRW, 'KRW')}</td>
                  </tr>
                </tbody>
              </table>
              <div className="mt-4">
                <ReactQuill 
                  theme="snow" 
                  value={poComment} 
                  onChange={setPoComment}
                  style={{ minHeight: '150px', maxHeight: '400px', overflowY: 'auto' }}
                  placeholder="이메일 본문을 입력하세요..."
                />
              </div>
              <div className="mt-4">
                <h3 className="text-sm font-medium text-gray-900 dark:text-white">첨부 파일</h3>
                <ul className="mt-2">
                  {selectedFiles.map((file, index) => (
                    <li key={file.name} className="flex items-center justify-between p-2 border-b border-gray-300 dark:border-gray-700">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={file.selected}
                          onChange={() => handleFileSelection(index)}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <span className="ml-2 text-gray-900 dark:text-gray-300">{file.name}</span>
                      </div>
                      <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => handleCopyUrl(`${apiUrl}/upload/project/${projectId}/${file.path}`)}
                      >
                        링크 복사
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          }
          onSave={handleSendPO}
          onClose={handleCloseModal}
          onBackgroundClick={handleBackgroundClick}
          saveButtonText="Send"
        />
      )}
    </>
  );
};

export default SendPOButton;