import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import { LoginButton } from '../components/commons/Buttons';
import { OneButtonModal } from '../components/commons/Modal';
import { useNotifications } from '../contexts/NotificationContext';
import { AuthContext } from '../session/AuthContext';

axios.defaults.withCredentials = true;

function Guest() {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [modalInfo, setModalInfo] = useState({ show: false, title: '', body: '', onSave: null });
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { setNotifications } = useNotifications();  

  const mutation = useMutation(login, {
    onSuccess: (response) => {
      if (response.success) {
        console.log('로그인 성공:', response);
        if (response.user && response.user.notifications) {
          console.log('받은 알림:', response.user.notifications);
          setNotifications(response.user.notifications);  
        } else {
          console.log('알림 없음');
          setNotifications([]);  
        }
        setModalInfo({
          show: true,
          title: '로그인 성공',
          body: '로그인에 성공하였습니다.',
          onSave: () => {
            setModalInfo({ ...modalInfo, show: false });
            navigate('/home'); // 로그인 후 홈으로 리디렉션
          },
          onClose: () => setModalInfo({ ...modalInfo, show: false })
        });
      } else {
        console.error('로그인 실패:', response.error);
        setModalInfo({
          show: true,
          title: '로그인 실패',
          body: response.error || '아이디 또는 비밀번호가 잘못되었습니다.',
          onSave: () => setModalInfo({ ...modalInfo, show: false }),
          onClose: () => setModalInfo({ ...modalInfo, show: false })
        });
      }
    },
    onError: (error) => {
      console.error('로그인 실패:', error);
      setModalInfo({
        show: true,
        title: '로그인 실패',
        body: error.response?.data?.error || '아이디 또는 비밀번호가 잘못되었습니다.',
        onSave: () => setModalInfo({ ...modalInfo, show: false }),
        onClose: () => setModalInfo({ ...modalInfo, show: false })
      });
    }
  });

  const handleLogin = (e) => {
    e.preventDefault();
    mutation.mutate({ name, password });
  };

  // 비밀번호 변경 페이지로 이동
  const handlePasswordChange = () => {
    navigate('/change-credentials');
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="mb-2 text-2xl font-bold text-center">로그인</h2>
        <p className="mb-6 text-center text-gray-600"></p>
        
        <div className="mb-4 text-center text-gray-500">Welcome to ETS.Ai</div>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label htmlFor="name" className="block text-gray-700">이름</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 mt-1 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4">
            <label htmlFor="password" className="block text-gray-700">비밀번호</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 mt-1 border border-gray-300 rounded"
              required
            />
          </div>
          <div className="mb-4 text-right">
            <a href="#" className="text-blue-500" onClick={handlePasswordChange}>
              아이디/비밀번호 찾기
            </a>
          </div>
          <LoginButton type="submit" />
        </form>
        <div className="mt-4 text-center">
          <p className="text-gray-600">처음오셨나요? <a href="/signup" className="text-blue-500">가입하기</a></p>
        </div>
        <div className="flex justify-between mt-6 text-gray-500">
          <a href="#" className="hover:underline">Terms</a>
          <a href="#" className="hover:underline">Privacy Policy</a>
        </div>
      </div>
      {modalInfo.show && (
        <OneButtonModal
          id="loginModal"
          title={modalInfo.title}
          body={modalInfo.body}
          onSave={modalInfo.onSave}
          onClose={modalInfo.onClose}
        />
      )}
    </div>
  );
}

export default Guest;
