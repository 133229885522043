import React from 'react';

const EmployeeInfoCard = ({ profile }) => {
  const apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
  const imagePath = profile.image ? `${apiUrl}/upload/profile_pics/${profile.image}` : 'https://via.placeholder.com/80';

  return (
    <div className="flex flex-col w-full p-6 bg-gray-100 rounded-lg shadow-md">
      <div className="flex items-center mb-8">
        <img
          src={imagePath}
          alt="User Avatar"
          className="w-20 h-20 mr-4 rounded-full"
        />
        <div>
          <h3 className="text-xl font-semibold">{profile.name}</h3>
          <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">{profile.status}</span>
          <p className="text-sm text-gray-500">{profile.email}</p>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 pt-4 pb-4 mb-4 border-b border-gray-300">
        <div className="text-center">
          <p className="text-lg font-semibold">{profile.points}</p>
          <p className="text-sm text-gray-500">보유 포인트</p>
        </div>
        <div className="text-center">
          <p className="text-lg font-semibold">{profile.position}</p>
          <p className="text-sm text-gray-500">직급</p>
        </div>
        <div className="text-center">
          <p className="text-lg font-semibold">{profile.division}</p>
          <p className="text-sm text-gray-500">본부</p>
        </div>
      </div>
      <div className="pb-4 mb-4 border-b border-gray-300">
        <h4 className="pt-4 pb-4 mb-3 font-semibold text-md">일반 정보</h4>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <p className="text-sm font-semibold">연락처</p>
            <p className="text-sm text-gray-500">{profile.phone}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">Skype ID</p>
            <p className="text-sm text-gray-500">{profile.skypeId}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">그룹</p>
            <p className="text-sm text-gray-500">{profile.group}</p>
          </div>
          <div>
            <p className="text-sm font-semibold">재직상태</p>
            <p className="text-sm text-gray-500">{profile.status}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <a href="#" className="text-blue-500">명함 공유</a>
      </div>
    </div>
  );
};

export default EmployeeInfoCard;