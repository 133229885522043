import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../../axiosConfig';
import { OneButtonModal, TwoButtonModal } from '../Modal';
import CustomSelect from './CustomSelect';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? '0 0 0 2px #2563EB' : 'none',
    borderColor: state.isFocused ? '#2563EB' : '#d1d5db',
    '&:hover': {
      borderColor: '#2563EB',
    },
    minHeight: '40px',
    minWidth: '7rem',
    outline: 'none',
    fontSize: '14px'
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#2563EB' : '#d1d5db',
    '&:hover': {
      color: '#2563EB',
    },
  }),
  container: (provided) => ({
    ...provided,
    outline: 'none',
  }),
  input: (provided) => ({
    ...provided,
    boxShadow: 'none',
    outline: 'none',
    '& input': {
      boxShadow: 'none',
      outline: 'none',
    },
    '& input:focus': {
      boxShadow: 'none',
      outline: 'none',
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    borderRadius: '0.375rem',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#E0F2FE' : 'white',
    color: state.isSelected ? '#2563EB' : '#374151',
    '&:active': {
      backgroundColor: '#2563EB',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#374151',
    fontSize: '14px'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#6B7280',
    fontSize: '14px'
  }),
};

const EditProjectModal = ({ isOpen, onClose, project, fetchProjects }) => {
  const queryClient = useQueryClient();

  const initialFormData = {
    ProjectName: '',
    ClientName: '',
    ClientPM: '',
    ClientEmail: '',
    ClientPhone: '',
    SDate: '',
    DDate: '',
    Total: '',
    TotalWithVAT: '',
    Status: '',
    Division: '',
    Group: '',
    Currency: '',
    MainPM: '',
    Discount: '',
    Invoice: '',
    ExchangeRate: '',
    TotalInKRW: '',
    TotalDiscount: '',
    tasks: [],
    notes: [],
    Files: [],
    Domain: '',
    EndClient: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [dropdownData, setDropdownData] = useState({
    taskStatus: [],
    employees: [],
    division: [],
    group: [],
    currency: [],
    clients: [],
    projectTypes: [],
    languages: [],
    units: [],
    workerStatus: [],
    domain: [], // Domain 추가
    endClients: [] // EndClient 추가
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isNameDuplicateModalOpen, setIsNameDuplicateModalOpen] = useState(false);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(`/getDropdown`);
        const dropdowns = response.data;

        const taskStatus = dropdowns.find(item => item.type === 'taskStatus')?.values || [];
        const employees = dropdowns.find(item => item.type === 'employees')?.values || [];
        const division = dropdowns.find(item => item.type === 'division')?.values || [];
        const group = dropdowns.find(item => item.type === 'group')?.values || [];
        const currency = dropdowns.find(item => item.type === 'currency')?.values || [];
        const clients = dropdowns.find(item => item.type === 'clients')?.values || [];
        const projectTypes = dropdowns.find(item => item.type === 'projectType')?.values || [];
        const languages = dropdowns.find(item => item.type === 'language')?.values || [];
        const units = dropdowns.find(item => item.type === 'unit')?.values || [];
        const workerStatus = dropdowns.find(item => item.type === 'workerStatus')?.values || [];
        const domain = dropdowns.find(item => item.type === 'domain')?.values || []; // Domain 추가
        const endClients = dropdowns.find(item => item.type === 'endClients')?.values || []; // EndClient 추가

        setDropdownData({
          taskStatus,
          employees,
          division,
          group,
          currency,
          clients,
          projectTypes,
          languages,
          units,
          workerStatus,
          domain, 
          endClients 
        });
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  useEffect(() => {
    if (project) {
      setFormData({
        ...initialFormData,
        ...project,
        tasks: project.tasks.map(task => ({
          ...task,
          SDate: task.SDate || project.SDate,
          DDate: task.DDate || project.DDate
        }))
      });
    } else {
      setFormData(initialFormData);
    }
  }, [project]);

  useEffect(() => {
    if (formData.ClientPM && dropdownData.clients) {
      const selectedClient = dropdownData.clients.find(client => client.clientName === formData.ClientName);
      const selectedPM = selectedClient?.pm?.find(pm => pm.pmName === formData.ClientPM);
      if (selectedPM) {
        setFormData(prevData => ({
          ...prevData,
          ClientEmail: selectedPM.pmEmail,
          ClientPhone: selectedPM.pmMobile
        }));
      }
    }
  }, [formData.ClientPM, dropdownData.clients, formData.ClientName]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleTaskChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTasks = [...formData.tasks];
    updatedTasks[index] = {
      ...updatedTasks[index],
      [name]: value
    };
  
    // Task 총액 재계산
    if (name === 'ProjectVolume' || name === 'Rate' || name === 'DiscountRate' || name === 'DiscountAmount') {
      const volume = parseFloat(updatedTasks[index].ProjectVolume) || 0;
      const rate = parseFloat(updatedTasks[index].Rate) || 0;
      const discountRate = parseFloat(updatedTasks[index].DiscountRate) || 0;
      const discountAmount = parseFloat(updatedTasks[index].DiscountAmount) || 0;
      
      const total = volume * rate;
      const totalAfterDiscount = total * (1 - discountRate / 100) - discountAmount;
      updatedTasks[index].Total = totalAfterDiscount.toFixed(2);
    }
  
    setFormData((prevData) => ({
      ...prevData,
      tasks: updatedTasks
    }));
  
    updateProjectTotal(updatedTasks);
  };
  
  const updateProjectTotal = (tasks) => {
    const total = tasks.reduce((acc, task) => {
      const taskTotal = parseFloat(task.Total) || 0;
      return acc + taskTotal;
    }, 0);
  
    // 개별 Task의 총 할인액 합산
    const totalDiscountAmount = tasks.reduce((acc, task) => {
      return acc + (parseFloat(task.DiscountAmount) || 0);
    }, 0);
  
    // 평균 할인율 계산
    const discountRates = tasks.map(task => parseFloat(task.DiscountRate) || 0);
    const averageDiscountRate = discountRates.length ? (discountRates.reduce((a, b) => a + b) / discountRates.length) : 0;
  
    // 프로젝트 할인율 적용
    const discountRate = parseFloat(formData.Discount) || 0;
    const totalAfterDiscountRate = total * (1 - discountRate / 100);
  
    // 총액 할인은 이미 task에 반영되었으므로 다시 빼지 않음
    const finalTotal = totalAfterDiscountRate;
  
    const finalTotalWithVAT = finalTotal * 1.1; // VAT 적용
  
    let finalTotalInKRW = finalTotalWithVAT;
    if (formData.Currency !== 'KRW' && formData.ExchangeRate) {
      finalTotalInKRW = finalTotalWithVAT * parseFloat(formData.ExchangeRate);
    }
  
    setFormData((prevData) => ({
      ...prevData,
      Total: total.toFixed(2),
      TotalWithVAT: finalTotalWithVAT.toFixed(2),
      TotalInKRW: finalTotalInKRW.toFixed(2),
      FinalTotal: finalTotal.toFixed(2),
      FinalTotalWithVAT: finalTotalWithVAT.toFixed(2),
      FinalTotalInKRW: finalTotalInKRW.toFixed(2),
      TotalDiscount: totalDiscountAmount.toFixed(2),
      Discount: averageDiscountRate.toFixed(2)
    }));
  };
  

  const handleAddTask = () => {
    const lastTask = formData.tasks[formData.tasks.length - 1] || {};
    const newTask = {
      id: uuidv4(),
      ProjectType: lastTask.ProjectType || '',
      PMName: lastTask.PMName || '',
      SLang: lastTask.SLang || '',
      TLang: lastTask.TLang || '',
      ProjectVolume: lastTask.ProjectVolume || '',
      Unit: lastTask.Unit || '',
      Rate: lastTask.Rate || '',
      Total: lastTask.Total || '',
      Status: lastTask.Status || '',
      DiscountRate: lastTask.DiscountRate || '',
      SDate: lastTask.SDate || formData.SDate || '',
      DDate: lastTask.DDate || formData.DDate || '',
      Invoice: lastTask.Invoice || formData.Invoice || '',
      created_at: new Date().toISOString()
    };
    setFormData((prevData) => ({
      ...prevData,
      tasks: [...prevData.tasks, newTask]
    }));
  };
  

  const handleRemoveTask = (index) => {
    const updatedTasks = formData.tasks.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      tasks: updatedTasks
    }));

    updateProjectTotal(updatedTasks);
  };

  const handleSave = async () => {
    const existingProject = await axios.get(`/checkProjectName?name=${formData.ProjectName}&excludeId=${project?._id}`);
    if (existingProject.data.exists) {
      setIsNameDuplicateModalOpen(true);
      return;
    }
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      // 할인액과 할인율이 반영된 task 총액을 업데이트
      const updatedTasks = formData.tasks.map(task => {
        const volume = parseFloat(task.ProjectVolume) || 0;
        const rate = parseFloat(task.Rate) || 0;
        const discountRate = parseFloat(task.DiscountRate) || 0;
        const discountAmount = parseFloat(task.DiscountAmount) || 0;
  
        const total = volume * rate;
        const totalAfterDiscount = total * (1 - discountRate / 100) - discountAmount;
  
        return {
          ...task,
          Total: totalAfterDiscount.toFixed(2)
        };
      });
  
      // 업데이트된 tasks를 formData에 반영
      setFormData(prevData => ({
        ...prevData,
        tasks: updatedTasks
      }));
  
      let response;
      if (project) {
        response = await axios.put(`/updateProject`, { ...formData, tasks: updatedTasks, _id: project._id });
      } else {
        response = await axios.post(`/addProject`, { ...formData, tasks: updatedTasks });
      }
  
      if (response.status === 200 || response.status === 201) {
        queryClient.invalidateQueries(['projectDetails', project._id]);
        onClose();
        await fetchProjects();
      } else {
        console.error('Error updating project:', response.data);
      }
    } catch (error) {
      console.error('Error updating project:', error);
    } finally {
      setIsConfirmModalOpen(false);
    }
  };
  

  return (
    <>
      <div className={`fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50 ${isOpen ? '' : 'hidden'}`}>
        <div className="relative w-full max-h-screen p-4 mx-auto bg-white rounded-lg shadow-lg max-w-8.5xl" style={{ width: '90%', height: '90%' }}>
          <form className="overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
            <div className="grid grid-cols-2 gap-2 text-sm">
              <div className="col-span-2">
                <label className="block mb-1 font-medium text-gray-900">프로젝트 이름</label>
                <input type="text" name="ProjectName" value={formData.ProjectName} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
              </div>
              <div className="col-span-2">
                <label className="block mb-1 font-medium text-gray-900">견적서 번호</label>
                <input type="text" name="Invoice" value={formData.Invoice} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">클라이언트</label>
                <CustomSelect
                  name="ClientName"
                  value={{ label: formData.ClientName, value: formData.ClientName }}
                  onChange={(option) => handleChange({ target: { name: 'ClientName', value: option.value } })}
                  options={dropdownData.clients?.map((option) => ({ label: option.clientName, value: option.clientName }))}
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">클라이언트 담당자</label>
                <CustomSelect
                  name="ClientPM"
                  value={{ label: formData.ClientPM, value: formData.ClientPM }}
                  onChange={(option) => handleChange({ target: { name: 'ClientPM', value: option.value } })}
                  options={dropdownData.clients?.find(client => client.clientName === formData.ClientName)?.pm?.map((option) => ({ label: option.pmName, value: option.pmName }))}
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">클라이언트 이메일</label>
                <input type="email" name="ClientEmail" value={formData.ClientEmail} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">클라이언트 연락처</label>
                <input type="text" name="ClientPhone" value={formData.ClientPhone} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">최종 클라이언트</label>
                <CustomSelect
                      name="EndClient"
                      value={{ label: formData.EndClient, value: formData.EndClient }}
                      onChange={(option) => handleChange({ target: { name: 'EndClient', value: option.value } })}
                      options={dropdownData.endClients?.filter(ec => ec.clientName === formData.ClientName).map((option) => ({ label: option.endClientName, value: option.endClientName }))}
                    />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">분야</label>
                <CustomSelect
                  name="Domain"
                      value={{ label: formData.Domain, value: formData.Domain }}
                      onChange={(option) => handleChange({ target: { name: 'Domain', value: option.value } })}
                      options={dropdownData.domain?.map((option) => ({ label: option, value: option }))}
                    />
              </div>
              
              <div>
                <label className="block mb-1 font-medium text-gray-900">시작일</label>
                <input type="date" name="SDate" value={formData.SDate} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">종료일</label>
                <input type="date" name="DDate" value={formData.DDate} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">총액</label>
                <input 
                  type="text" 
                  name="Total" 
                  value={formatNumber(formData.Total, formData.Currency)} 
                  readOnly 
                  className="w-full p-1 border border-gray-300 rounded" 
                  style={{ minHeight: '40px', fontSize: '14px' }} 
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">상태</label>
                <CustomSelect
                  name="Status"
                  value={{ label: formData.Status, value: formData.Status }}
                  onChange={(option) => handleChange({ target: { name: 'Status', value: option.value } })}
                  options={dropdownData.taskStatus?.map((option) => ({ label: option, value: option }))}
                />
              </div>
              <div>
                <label className="block mb-1 font-medium text-gray-900">담당자</label>
                <CustomSelect
                  name="MainPM"
                  value={{ label: formData.MainPM, value: formData.MainPM }}
                  onChange={(option) => handleChange({ target: { name: 'MainPM', value: option.value } })}
                  options={dropdownData.employees?.map((option) => ({ label: option.name, value: option.name }))}
                  />
                  </div>
                  <div className="flex">
                    <div className="w-1/2 pr-2">
                      <label className="block mb-1 font-medium text-gray-900">본부</label>
                      <CustomSelect
                        name="Division"
                        value={{ label: formData.Division, value: formData.Division }}
                        onChange={(option) => handleChange({ target: { name: 'Division', value: option.value } })}
                        options={dropdownData.division?.map((option) => ({ label: option, value: option }))}
                      />
                    </div>
                    <div className="w-1/2 pl-2">
                      <label className="block mb-1 font-medium text-gray-900">그룹</label>
                      <CustomSelect
                        name="Group"
                        value={{ label: formData.Group, value: formData.Group }}
                        onChange={(option) => handleChange({ target: { name: 'Group', value: option.value } })}
                        options={dropdownData.group?.map((option) => ({ label: option, value: option }))}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block mb-1 font-medium text-gray-900">통화</label>
                    <CustomSelect
                      name="Currency"
                      value={{ label: formData.Currency, value: formData.Currency }}
                      onChange={(option) => handleChange({ target: { name: 'Currency', value: option.value } })}
                      options={dropdownData.currency?.map((option) => ({ label: option, value: option }))}
                    />
                  </div>
                  {formData.Currency !== 'KRW' && (
                    <div>
                      <label className="block mb-1 font-medium text-gray-900">환율</label>
                      <input type="text" name="ExchangeRate" value={formData.ExchangeRate} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
                    </div>
                  )}
                  <div>
                    <label className="block mb-1 font-medium text-gray-900">할인율</label>
                    <input type="text" name="Discount" value={formData.Discount} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
                  </div>
                                <div>
                <label className="block mb-1 font-medium text-gray-900">총액 할인</label>
                <input type="text" name="TotalDiscount" value={formData.TotalDiscount} onChange={handleChange} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
              </div>
                
                </div>
                <div className="w-full mt-2"> 
                  <h3 className="text-sm font-bold">세부 작업</h3>
                  <div className="relative overflow-x-auto">
                    <table className="w-full text-xs text-left text-gray-500 rtl:text-right dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">추가 견적번호</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">시작일</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">종료일</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">프로젝트 유형</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">PM</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">출발어</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">도착어</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">분량</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">단위</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">단가</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">총액</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">할인율</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">할인액</th>
                          <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">상태</th>
                          <th scope="col" className="px-6 py-2">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.tasks.map((task, index) => (
                          <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                             <td className="p-1 text-xs rounded-lg">
                                  <input 
                                    type="text" 
                                    name="Invoice" 
                                    value={task.Invoice || formData.Invoice} 
                                    onChange={(e) => handleTaskChange(index, e)} 
                                    className="w-full p-1 border border-gray-300 rounded" 
                                    style={{ minHeight: '40px', fontSize: '14px' }} 
                                  />
                                </td>
                                <td>
                                  <input 
                                    type="date" 
                                    name="SDate" 
                                    value={task.SDate || formData.SDate} 
                                    onChange={(e) => handleTaskChange(index, e)} 
                                    className="w-full p-1 border border-gray-300 rounded" 
                                    style={{ minHeight: '40px', fontSize: '14px' }} 
                                  />
                                </td>
                                <td>
                                  <input 
                                    type="date" 
                                    name="DDate" 
                                    value={task.DDate || formData.DDate} 
                                    onChange={(e) => handleTaskChange(index, e)} 
                                    className="w-full p-1 border border-gray-300 rounded" 
                                    style={{ minHeight: '40px', fontSize: '14px' }} 
                                  />
                                </td>
                                <td className="p-1 text-xs rounded-lg">
                              <CustomSelect
                                name="ProjectType"
                                value={{ label: task.ProjectType, value: task.ProjectType }}
                                onChange={(option) => handleTaskChange(index, { target: { name: 'ProjectType', value: option.value } })}
                                options={dropdownData.projectTypes?.map((option) => ({ label: option, value: option }))}
                              />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                              <CustomSelect
                                name="PMName"
                                value={{ label: task.PMName, value: task.PMName }}
                                onChange={(option) => handleTaskChange(index, { target: { name: 'PMName', value: option.value } })}
                                options={dropdownData.employees?.map((option) => ({ label: option.name, value: option.name }))}
                              />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                              <CustomSelect
                                name="SLang"
                                value={{ label: task.SLang, value: task.SLang }}
                                onChange={(option) => handleTaskChange(index, { target: { name: 'SLang', value: option.value } })}
                                options={dropdownData.languages?.map((option) => ({ label: option, value: option }))}
                              />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                              <CustomSelect
                                name="TLang"
                                value={{ label: task.TLang, value: task.TLang }}
                                onChange={(option) => handleTaskChange(index, { target: { name: 'TLang', value: option.value } })}
                                options={dropdownData.languages?.map((option) => ({ label: option, value: option }))}
                              />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                              <input type="text" name="ProjectVolume" value={task.ProjectVolume} onChange={(e) => handleTaskChange(index, e)} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                              <CustomSelect
                                name="Unit"
                                value={{ label: task.Unit, value: task.Unit }}
                                onChange={(option) => handleTaskChange(index, { target: { name: 'Unit', value: option.value } })}
                                options={dropdownData.units?.map((option) => ({ label: option, value: option }))}
                              />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                              <input type="text" name="Rate" value={task.Rate} onChange={(e) => handleTaskChange(index, e)} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                              <input 
                                type="text" 
                                name="Total" 
                                value={formatNumber(task.Total, formData.Currency)} 
                                readOnly
                                className="w-full p-1 border border-gray-300 rounded" 
                                style={{ minHeight: '40px', fontSize: '14px' }} 
                              />
                            </td>
                            <td className="w-2 p-1 text-xs rounded-lg">
                              <input type="text" name="DiscountRate" value={task.DiscountRate} onChange={(e) => handleTaskChange(index, e)} className="w-full p-1 border border-gray-300 rounded" style={{ minHeight: '40px', fontSize: '14px' }} />
                            </td>
                            <td className="p-1 text-xs rounded-lg">
                            <input 
                              type="text" 
                              name="DiscountAmount" 
                              value={task.DiscountAmount || ''} 
                              onChange={(e) => handleTaskChange(index, e)} 
                              className="w-full p-1 border border-gray-300 rounded" 
                              style={{ minHeight: '40px', fontSize: '14px' }} 
                            />
                          </td>
                            <td className="p-1 text-xs rounded-lg w-1/8">
                              <CustomSelect
                                name="Status"
                                value={{ label: task.Status, value: task.Status }}
                                onChange={(option) => handleTaskChange(index, { target: { name: 'Status', value: option.value } })}
                                options={dropdownData.workerStatus?.map((option) => ({ label: option, value: option }))}
                              />
                            </td>
                            <td className="px-6 py-2">
                              <button type="button" onClick={() => handleRemoveTask(index)} className="px-2 py-1 text-white bg-red-500 rounded">-</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <button type="button" onClick={handleAddTask} className="p-1 mt-2 text-xs text-white bg-green-500 rounded rounded-lg">+작업 추가</button>
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button type="button" onClick={onClose} className="px-4 py-2 mr-2 bg-gray-300 rounded">Cancel</button>
                  <button type="button" onClick={handleSave} className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>
                </div>
              </form>
            </div>
          </div>
          {isConfirmModalOpen && (
            <TwoButtonModal
              title="Confirm Save"
              body="Are you sure you want to save the changes?"
              onSave={handleConfirmSave}
              onClose={() => setIsConfirmModalOpen(false)}
            />
          )}
          {isNameDuplicateModalOpen && (
            <OneButtonModal
              title="중복된 프로젝트 이름"
              body="동일한 프로젝트 이름이 존재합니다. 다른 이름을 사용해 주세요."
              onSave={() => setIsNameDuplicateModalOpen(false)}
              onClose={() => setIsNameDuplicateModalOpen(false)}
            />
          )}
        </>
      );
    };
    
    export default EditProjectModal;