import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import axios from '../../axiosConfig';

const UserEvaluationModal = ({ onClose, workerId }) => {
  const [evaluation, setEvaluation] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    try {
      await axios.post('/user_evaluation', { workerId, evaluation });
      queryClient.invalidateQueries('evaluationRecords');
      onClose();
    } catch (err) {
      setError('평가를 저장하는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-1/3 p-4 bg-white rounded shadow-lg">
        <h2 className="mb-4 text-xl font-semibold">유저 평가 입력</h2>
        <textarea
          className="w-full p-2 border border-gray-300 rounded"
          rows="5"
          value={evaluation}
          onChange={(e) => setEvaluation(e.target.value)}
        />
        {error && <p className="mt-2 text-red-500">{error}</p>}
        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
          >
            취소
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
            disabled={loading}
          >
            {loading ? '저장 중...' : '저장'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserEvaluationModal;