import React, { useContext } from 'react';
import '../../App.css';
import BoardTitle from '../../area/BoardTitle'; // BoardTitle import 추가
import ProjectSettingsBoard from '../../boards/settings/ProjectSettingsBoard'; // 게시판 컴포넌트 추가
import AsideContainer from '../../components/containers/AsideContainer';
import { SidebarContext } from '../../contexts/SidebarContext'; // Import SidebarContext

const ProjectSettings = () => {
  const { isCollapsed } = useContext(SidebarContext); // Use SidebarContext

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-white custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title="프로젝트 설정" /> {/* title prop 추가 */}
        <ProjectSettingsBoard /> {/* 게시판 컴포넌트 추가 */}
      </main>
      <AsideContainer />
    </div>
  );
};

export default ProjectSettings;
