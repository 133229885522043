import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { formatNumber } from '../components/commons/Utils';
import CustomSelect from '../components/commons/modal/CustomSelect';

const POContent = ({ projects = [] }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // SDate 기준으로 프로젝트 정렬
  const sortedProjects = projects.slice().sort((a, b) => new Date(a.SDate) - new Date(b.SDate));

  // 선택된 년도의 프로젝트 필터링 (해당 년도에 진행 중인 프로젝트 포함)
  const filteredProjects = sortedProjects.filter(project => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);
    return startDate.getFullYear() <= selectedYear && endDate.getFullYear() >= selectedYear;
  });

  // 월별 정산 상태 데이터 생성
  const monthlyStatusData = Array.from({ length: 12 }, () => ({
    정산요청: 0,
    정산대기: 0,
    정산완료: 0
  }));

  filteredProjects.forEach(project => {
    const paymentDate = new Date(project.SDate);
    const month = paymentDate.getMonth();
    const status = project.Status;

    if (paymentDate.getFullYear() === selectedYear) {
      if (status === '정산요청') {
        monthlyStatusData[month].정산요청 += project.Total;
      } else if (status === '정산대기') {
        monthlyStatusData[month].정산대기 += project.Total;
      } else if (status === '정산완료') {
        monthlyStatusData[month].정산완료 += project.Total;
      }
    }
  });

  const statusSeries = ['정산요청', '정산대기', '정산완료'].map(status => ({
    name: status,
    data: monthlyStatusData.map(monthData => Math.round(monthData[status]))
  }));

  // 사용 가능한 년도 목록 생성
  const years = Array.from(new Set(projects.map(project => new Date(project.SDate).getFullYear())));

  return (
    <div className="w-full p-2 mb-2 text-center bg-gray-150" style={{ height: 'auto' }}>
      <div className="grid grid-cols-1 gap-4 mt-0 md:grid-cols-2 lg:grid-cols-3">
        {projects.length > 0 ? (
          <>
            <div className="col-span-1 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">월간 비용 상태</h2>
              <div className="mb-2">
                <CustomSelect
                  value={{ value: selectedYear, label: selectedYear }}
                  onChange={(option) => setSelectedYear(option.value)}
                  options={years.map(year => ({ value: year, label: year }))}
                />
              </div>
              <Chart
                options={{
                  chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                  },
                  xaxis: {
                    categories: Array.from({ length: 12 }, (_, i) => new Date(selectedYear, i, 1).toLocaleString('default', { month: 'short' })),
                  },
                  yaxis: {
                    title: {
                      text: '전체 작업 금액'
                    },
                    labels: {
                      formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                    }
                  },
                  tooltip: {
                    shared: true,
                    intersect: false,
                    x: {
                      formatter: (val) => `Month: ${val}`
                    },
                    y: {
                      formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                    }
                  },
                  legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                  },
                  colors: ['#008FFB', '#00E396', '#FEB019'] // 정산요청, 정산대기, 정산완료 순서대로 색상 지정
                }}
                series={statusSeries}
                type="bar"
                height={250}
              />
            </div>
            <div className="col-span-2 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">월간 비용 지급</h2>
              <div className="mb-2">
                <CustomSelect
                  value={{ value: selectedYear, label: selectedYear }}
                  onChange={(option) => setSelectedYear(option.value)}
                  options={years.map(year => ({ value: year, label: year }))}
                />
              </div>
              <Chart
                options={{
                  chart: {
                    height: 350,
                    type: 'line',
                    stacked: false
                  },
                  stroke: {
                    width: [0, 4]
                  },
                  dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                    formatter: function (val) {
                      return formatNumber(val, 'KRW');
                    },
                    offsetY: -10,
                    style: {
                      fontSize: '10px',
                      colors: ['#008FFB']
                    }
                  },
                  xaxis: {
                    categories: Array.from({ length: 12 }, (_, i) => new Date(selectedYear, i, 1).toLocaleString('default', { month: 'short' })),
                    labels: {
                      rotate: -45 // 날짜를 대각선으로 출력
                    }
                  },
                  yaxis: [
                    {
                      title: {
                        text: '전체 작업 금액',
                        style: {
                          color: '#008FFB',
                        }
                      },
                      labels: {
                        style: {
                          colors: '#008FFB',
                        },
                        formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                      }
                    },
                  ],
                  tooltip: {
                    shared: true,
                    intersect: false,
                    x: {
                      formatter: (val) => `Month: ${val}`
                    },
                    y: {
                      formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                    }
                  },
                  legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                  }
                }}
                series={[
                  {
                    name: '전체 정산 금액',
                    type: 'column',
                    data: monthlyStatusData.map(item => Math.round(item.정산요청 + item.정산대기 + item.정산완료)) // 소숫점 제거
                  },
                  {
                    name: '정산 요청 금액',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.정산요청)) // 소숫점 제거
                  },
                  {
                    name: '정산 대기 금액',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.정산대기)) // 소숫점 제거
                  },
                  {
                    name: '정산 완료 금액',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.정산완료)) // 소숫점 제거
                  }
                ]}
                type="line"
                height={250}
              />
            </div>
          </>
        ) : (
          <p>No projects available.</p>
        )}
      </div>
    </div>
  );
};

export default POContent;