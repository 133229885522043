import React from 'react';
import { useParams } from 'react-router-dom';
import '../../App.css';
import BoardTitle from '../../area/BoardTitle';
import Board from '../../boards/Board';
import BreadScrum from '../../components/bars/BreadScrum';
import { LivePosts } from '../../data/Posts';
import AsideContent from './AsideContainer';
import BoardDetail from './BoardDetail';

function BoardContainer() {
  const { postId } = useParams(); // URL에서 postId를 가져옴
  const post = LivePosts.find(p => p.id === parseInt(postId, 10)); // postId에 해당하는 포스트를 찾음

  if (!post) {
    return <div>게시글을 찾을 수 없습니다.</div>;
  }

  return (
    <div className="container flex flex-row p-0 mx-auto min-h-[80vh]">
      <main className="flex-grow p-5 mt-0 mb-0 mr-4 bg-white border border-boderGray">
        <BoardTitle />
        <BreadScrum />
        <BoardDetail postId={post.id} posts={LivePosts} />
        <Board/>
      </main>
      <AsideContent />
    </div>
  );
}

export default BoardContainer;

