import React from 'react';
import adsBanner from '../../assets/ads.png';

function Sidebar3() {
  return (
    <img
      src={adsBanner}
      alt="Ads Banner"
      className="w-[300px] h-[600px] object-cover rounded-lg"
    />
  );
}

export default Sidebar3;
