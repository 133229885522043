import React, { useEffect, useState } from 'react';
import axios from '../../../axiosConfig';
import { TwoButtonModal } from '../Modal';
import CustomSelect from './CustomSelect';

const AddEndClientModal = ({ isOpen, onClose, fetchClients }) => {
  const initialFormData = {
    clientId: '',
    endClientName: '',
    endClientEmail: '',
    endClientMobile: '',
    endClientMemo: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [clients, setClients] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData(initialFormData);
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/clients');
        setClients(response.data.map(client => ({ value: client._id, label: client.clientName })));
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleSave = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      await axios.post(`/addEndClient`, formData);
      console.log('End Client가 성공적으로 추가되었습니다.');
      onClose();
      fetchClients(); // fetch the updated list of clients
    } catch (error) {
      console.error('End Client 정보 처리 중 오류 발생:', error);
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleClose}>
        <div className="relative w-full max-w-2xl p-4 bg-white rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
          <button onClick={handleClose} className="absolute text-gray-500 top-4 right-4 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="mb-4 text-xl font-bold">최종 클라이언트 추가</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="mb-4">
                <label className="block text-gray-700">클라이언트</label>
                <CustomSelect
                  name="clientId"
                  value={clients.find(option => option.value === formData.clientId)}
                  onChange={(option) => handleSelectChange('clientId', option)}
                  options={clients}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">최종 클라이언트 이름</label>
                <input type="text" name="endClientName" value={formData.endClientName} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">이메일</label>
                <input type="email" name="endClientEmail" value={formData.endClientEmail} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">연락처</label>
                <input type="text" name="endClientMobile" value={formData.endClientMobile} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Memo</label>
                <input type="text" name="endClientMemo" value={formData.endClientMemo} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={handleClose} className="px-4 py-2 mr-2 bg-gray-300 rounded">Cancel</button>
              <button type="button" onClick={handleSave} className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>
            </div>
          </form>
        </div>
      </div>
      {isConfirmModalOpen && (
        <TwoButtonModal
          title="Confirm Save"
          body="Are you sure you want to save the changes?"
          onSave={handleConfirmSave}
          onClose={() => setIsConfirmModalOpen(false)}
        />
      )}
    </>
  );
};

export default AddEndClientModal;