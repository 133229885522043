import React from 'react';
import CustomSelect from '../../../components/commons/modal/CustomSelect';

const InvoiceFields = ({ fields, formData, handleChange, getOptions, endClientOptions }) => {
  const handleClientChange = (selectedOption) => {
    handleChange({ target: { name: 'ClientName', value: selectedOption.value } });
    handleChange({ target: { name: 'EndClient', value: '' } }); // EndClient 초기화
  };

  return (
    <div className="grid grid-cols-2 gap-4 mb-4">
      {fields.map(({ name, type, label }) => (
        <div key={name} className="flex flex-col">
          <label className="text-sm font-medium text-gray-900">{label}</label>
          {name === 'Currency' ? (
            <div className="flex space-x-2">
              <CustomSelect
                name={name}
                value={getOptions('currency').find(option => option.value === formData[name])}
                onChange={(selectedOption) => handleChange({ target: { name, value: selectedOption.value } })}
                options={getOptions('currency')}
                className={`block mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm ${formData.Currency !== 'KRW' ? 'w-1/2' : 'w-full'}`}
              />
              {formData.Currency !== 'KRW' && (
                <input
                  type="number"
                  name="ExchangeRate"
                  value={formData.ExchangeRate || ''}
                  onChange={handleChange}
                  placeholder="Exchange Rate"
                  required
                  className="block w-1/2 px-2 py-1 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              )}
            </div>
          ) : name === 'ClientName' ? (
            <div className="flex space-x-2">
              <CustomSelect
                name={name}
                value={getOptions('clients').find(option => option.value === formData[name])}
                onChange={handleClientChange}
                options={getOptions('clients')}
                className="block w-1/2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
              <CustomSelect
                name="EndClient"
                value={endClientOptions.find(option => option.value === formData.EndClient)}
                onChange={(selectedOption) => handleChange({ target: { name: 'EndClient', value: selectedOption.value } })}
                options={endClientOptions}
                className="block w-1/2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          ) : name === 'Invoice' ? (
            <div className="flex space-x-2">
              <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                placeholder=" "
                required
                className="block w-1/2 px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:border-black"
              />
              <CustomSelect
                name="Domain"
                value={getOptions('domain').find(option => option.value === formData.Domain)}
                onChange={(selectedOption) => handleChange({ target: { name: 'Domain', value: selectedOption.value } })}
                options={getOptions('domain')}
                className="block w-1/2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
          ) : (
            <input
              type={type}
              name={name}
              value={formData[name]}
              onChange={handleChange}
              placeholder=" "
              required
              className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:border-black"
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default InvoiceFields;