import React from 'react';
import RecolorLogo from '../assets/Recolor.svg';

const FooterLinkSection = ({ title, links }) => (
  <div>
    <h2 className="mb-6 text-lg font-semibold text-gray-900 uppercase dark:text-white">{title}</h2>
    <ul className="font-medium text-gray-500 dark:text-gray-400">
      {links.map((link, index) => (
        <li key={index} className="mb-4">
          <a href={link.href} className="hover:underline">{link.text}</a>
        </li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const resourcesLinks = [
    { href: "https://recolor.co.kr", text: "About US" },
    { href: "https://tailwindcss.com/", text: "Tailwind CSS" },
  ];

  const followUsLinks = [
    { href: "https://github.comthemesberg/recolor", text: "E-mail" },
    { href: "https://discord.gg/4eeurUVvTy", text: "Discord" },
  ];

  const legalLinks = [
    { href: "#", text: "Privacy Policy" },
    { href: "#", text: "Terms & Conditions" },
  ];

  const socialLinks = [
    { href: "#", icon: "facebook", srText: "Facebook page" },
    { href: "#", icon: "discord", srText: "Discord community" },
    { href: "#", icon: "twitter", srText: "Twitter page" },
    { href: "#", icon: "instagram", srText: "Instagram" },
  ];

  const renderSocialIcon = (icon) => {
    switch (icon) {
      case "facebook":
        return (
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
            <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd"/>
          </svg>
        );
      case "discord":
        return (
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
            <path d="M16.942 1.556a16.3 16.3 0 0 1-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z"/>
          </svg>
        );
      case "twitter":
        return (
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        );
      case "instagram":
        return (
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 18.182c-4.518 0-8.182-3.664-8.182-8.182S5.482 1.818 10 1.818 18.182 5.482 18.182 10 14.518 18.182 10 18.182zm0-13.636a5.455 5.455 0 1 0 0 10.91 5.455 5.455 0 0 0 0-10.91zm0 9.09a3.636 3.636 0 1 1 0-7.273 3.636 3.636 0 0 1 0 7.273z"/>
          </svg>
        );
    }
  };

  return (
    <footer className="mt-0 mb-0 ml-0 mr-0 bg-white border-t-2 border-gray-200 dark:bg-gray-900 dark:border-gray-700">
      <div className="w-full max-w-screen-xl p-8 mx-auto md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a href="/home" className="flex items-center mb-4 space-x-3 sm:mb-0 rtl:space-x-reverse">
            <img src={RecolorLogo} className="h-8" alt="Recolor Logo" />
            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">ETS.Ai</span>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
            {resourcesLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href} className="hover:underline me-4 md:me-6">{link.text}</a>
              </li>
            ))}
            {legalLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href} className="hover:underline me-4 md:me-6">{link.text}</a>
              </li>
            ))}
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="/" className="hover:underline">CLI.Ai</a>. All Rights Reserved.</span>
        
      </div>
    </footer>
  );
};

export default Footer;

