import React, { useState } from 'react';
import ReactStars from 'react-rating-stars-component';

const RatingModal = ({ isOpen, onRequestClose, po, onSave }) => {
  const initialRatings = {
    communication: po.ratings?.communication || 0,
    professionalism: po.ratings?.professionalism || 0,
    consistency: po.ratings?.consistency || 0,
    compliance: po.ratings?.compliance || 0,
    expertise: po.ratings?.expertise || 0,
    flow: po.ratings?.flow || 0,
    comment: po.ratings?.comment || '',
    created_at: new Date().toISOString() // 현재 시간 추가
  };

  const [ratings, setRatings] = useState(initialRatings);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const skillDescriptions = {
    communication: "의사소통 능력은 PM, 클라이언트, 팀원, 그리고 다른 관계자들과 얼마나 효과적으로 소통할 수 있는지를 나타냅니다.",
    professionalism: "프로의식은 마감일 준수, 피드백 수용, 그리고 전반적인 책임감을 포함합니다.",
    consistency: "일관성은 번역물 전체에 걸쳐 용어, 스타일, 톤을 일관되게 유지하는 능력을 나타냅니다.",
    compliance: "규칙준수는 프로젝트 지침, 스타일 가이드 그리고 PM의 요청사항을 얼마나 잘 따르는지를 나타냅니다.",
    expertise: "전문성은 특정 분야에 대한 지식과 경험의 깊이를 의미합니다.",
    flow: "문장력은 번역된 텍스트가 얼마나 자연스럽고 읽기 쉬운지를 평가합니다."
  };

  const handleRatingChange = (key, value) => {
    setRatings({ ...ratings, [key]: value * 20 }); // 별점 변환
  };

  const handleSave = () => {
    onSave(ratings); // 모든 별점 정보 포함
    onRequestClose();
  };

  const handleQuestionClick = (key) => {
    setModalContent(skillDescriptions[key]);
    setModalOpen(true);
  };

  return isOpen ? (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative w-full max-w-3xl px-6 py-8 mx-4 bg-white rounded-lg shadow-lg md:mx-auto">
        <div className="flex justify-between mb-4">
          <h2 className="text-xl font-semibold">Rate PO</h2>
          <button onClick={onRequestClose} className="text-gray-500 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="mb-4">
          {[
            { label: '의사소통', key: 'communication' },
            { label: '프로의식', key: 'professionalism' },
            { label: '일관성', key: 'consistency' },
            { label: '규칙준수', key: 'compliance' },
            { label: '전문성', key: 'expertise' },
            { label: '문장력', key: 'flow' }
          ].map(({ label, key }) => (
            <div key={key} className="mb-4">
              <label className="flex items-center block mb-2 text-sm font-medium text-gray-700">
                {label.toUpperCase()} 별점
                <button 
                  className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                  onClick={() => handleQuestionClick(key)}
                >
                  ?
                </button>
              </label>
              <ReactStars
                count={5}
                value={ratings[key] / 20} // 0~5 범위로 변환
                onChange={(value) => handleRatingChange(key, value)}
                size={24}
                isHalf={true}
                activeColor="#ffd700"
                className="flex"
              />
            </div>
          ))}
          <div className="mb-4">
            <label className="block mb-2 text-sm font-medium text-gray-700">Comment</label>
            <textarea
              value={ratings.comment}
              onChange={(e) => setRatings({ ...ratings, comment: e.target.value })}
              className="block w-full px-3 py-2 border rounded-md"
              rows="4"
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button onClick={onRequestClose} className="px-4 py-2 mr-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300">
            Cancel
          </button>
          <button onClick={handleSave} className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">
            Save
          </button>
        </div>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="max-w-sm p-6 bg-white rounded-lg">
            <p>{modalContent}</p>
            <button 
              className="px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-700"
              onClick={() => setModalOpen(false)}
            >
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default RatingModal;