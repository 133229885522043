export const getWeeksInMonth = (year, month) => {
    const weeks = [];
    let startDate = new Date(year, month, 1);
    let endDate = new Date(year, month + 1, 0);
  
    while (startDate <= endDate) {
      const weekStart = new Date(startDate);
      const weekEnd = new Date(startDate);
      weekEnd.setDate(weekEnd.getDate() + 6);
      if (weekEnd > endDate) {
        weekEnd.setDate(endDate.getDate());
      }
      weeks.push({ startDate: weekStart, endDate: weekEnd });
      startDate.setDate(startDate.getDate() + 7);
    }
  
    return weeks;
  };