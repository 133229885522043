import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const ClientContent = ({ clients = [] }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // clientType별 클라이언트 카운트 데이터 생성
  const clientTypeData = clients.reduce((acc, client) => {
    const type = client.clientType || 'Unknown';
    acc[type] = (acc[type] || 0) + 1;
    return acc;
  }, {});

  const clientTypeSeries = Object.keys(clientTypeData).map(type => ({
    x: type,
    y: clientTypeData[type]
  }));

  // 분야별 클라이언트 카운트 데이터 생성
  const domainData = clients.reduce((acc, client) => {
    const domains = Array.isArray(client.clientDomains) ? client.clientDomains : [];
    domains.forEach(domain => {
      acc[domain] = (acc[domain] || 0) + 1;
    });
    return acc;
  }, {});

  const domainSeries = Object.keys(domainData).map(domain => ({
    x: domain,
    y: domainData[domain]
  }));

  return (
    <div className="w-full p-4 mb-2 text-center bg-gray-150" style={{ height: 'auto' }}>
      <div className="grid grid-cols-1 gap-4 mt-0 md:grid-cols-2 lg:grid-cols-3">
        {clients.length > 0 ? (
          <>
            <div className="col-span-1 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">클라이언트 유형별</h2>
              <Chart
                options={{
                  chart: {
                    type: 'bar',
                    height: 350,
                    stacked: false
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  xaxis: {
                    categories: clientTypeSeries.map(item => item.x),
                  },
                  yaxis: {
                    title: {
                      text: 'Clients'
                    }
                  },
                  fill: {
                    opacity: 1
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return val + " clients"
                      }
                    }
                  },
                  legend: {
                    position: 'top'
                  },
                  colors: ['#008FFB']
                }}
                series={[{
                  name: 'Clients',
                  data: clientTypeSeries.map(item => item.y)
                }]}
                type="bar"
                height={250}
              />
            </div>
            <div className="col-span-2 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">분야별 클라이언트</h2>
              <Chart
                options={{
                  chart: {
                    type: 'bar',
                    height: 350,
                    stacked: false
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  xaxis: {
                    categories: domainSeries.map(item => item.x),
                  },
                  yaxis: {
                    title: {
                      text: 'Clients'
                    }
                  },
                  fill: {
                    opacity: 1
                  },
                  tooltip: {
                    y: {
                      formatter: function (val) {
                        return val + " clients"
                      }
                    }
                  },
                  legend: {
                    position: 'top'
                  },
                  colors: ['#00E396']
                }}
                series={[{
                  name: 'Clients',
                  data: domainSeries.map(item => item.y)
                }]}
                type="bar"
                height={250}
              />
            </div>
          </>
        ) : (
          <p>No clients available.</p>
        )}
      </div>
    </div>
  );
};

export default ClientContent;