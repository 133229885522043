import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import { TwoButtonModal } from '../components/commons/Modal';
import { formatDate, formatNumber } from '../components/commons/Utils';
import WorkerAssignModal from '../components/commons/WorkerAssignModal';
import POTable from './POTable';
import SendPOButton from './SendPOButton';

const TaskTable = ({
  projectName,
  tasks,
  workers,
  selectedWorker,
  setSelectedWorker,
  expandedTaskIds,
  toggleTaskExpand,
  handleAssignWorker,
  handleRemoveWorker,
  workerVolumes,
  setWorkerVolumes, 
  handleVolumeChange,
  saveVolume,
  workerRates,
  handleRateChange,
  saveRate,
  workerContents,
  handleContentChange,
  saveContent,
  projectId,
  onPOSuccess,
  taskStatus,
  projectCurrency,
  files,
  apiUrl,
}) => {
  const [localTasks, setLocalTasks] = useState(tasks || []); // 초기화 시 빈 배열로 설정
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTwoButtonModalOpen, setIsTwoButtonModalOpen] = useState(false);
  const [assignTaskIndex, setAssignTaskIndex] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const [isProjectCompleteModalOpen, setIsProjectCompleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [clientsMap, setClientsMap] = useState({});
  const [workersMap, setWorkersMap] = useState({});
  const [dropdownData, setDropdownData] = useState({
    workerStatus: [],
    taskStatus: [],
    employees: [],
    division: [],
    group: [],
    currency: [],
    language: [],
    domain: [],
  });
  const [editableContent, setEditableContent] = useState({});
  const [editableRate, setEditableRate] = useState({});
  const [editableVolume, setEditableVolume] = useState({});
  const [editableExchangeRate, setEditableExchangeRate] = useState({});
  const [isVolumeExceedModalOpen, setIsVolumeExceedModalOpen] = useState(false);
  const [exceedTaskIndex, setExceedTaskIndex] = useState(null);
  const [isVolumeChangeModalOpen, setIsVolumeChangeModalOpen] = useState(false);
  const [volumeChangeData, setVolumeChangeData] = useState(null);
  const [initialVolumeValues, setInitialVolumeValues] = useState({});
  const navigate = useNavigate();
  

  const queryClient = useQueryClient();

  useEffect(() => {
    setLocalTasks(tasks || []); // tasks가 undefined일 경우 빈 배열로 설정
    setIsLoading(false);
  }, [tasks]);

  const saveProjectDetails = useMutation(
    async ({ projectId, totalWorkerCost }) => {
      await axios.put(`/saveProjectDetails/${projectId}`, {
        totalWorkerCost,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const fetchDropdownData = async () => {
    try {
      const response = await axios.get(`/getDropdown`);
      const dropdowns = response.data;

      const taskStatus = dropdowns.find((item) => item.type === 'taskStatus')?.values || [];
      const employees = dropdowns.find((item) => item.type === 'employees')?.values || [];
      const division = dropdowns.find((item) => item.type === 'division')?.values || [];
      const group = dropdowns.find((item) => item.type === 'group')?.values || [];
      const currency = dropdowns.find((item) => item.type === 'currency')?.values || [];
      const workerStatus = dropdowns.find((item) => item.type === 'workerStatus')?.values || [];
      const language = dropdowns.find((item) => item.type === 'language')?.values || [];
      const domain = dropdowns.find((item) => item.type === 'domain')?.values || [];

      setDropdownData({
        taskStatus,
        employees,
        division,
        group,
        currency,
        workerStatus,
        language,
        domain,
      });
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching dropdown data:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/clients');
        const clientsData = response.data;
        const map = {};
        clientsData.forEach(client => {
          map[client.clientName] = client._id;
        });
        setClientsMap(map);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const fetchWorkers = async () => {
      try {
        const response = await axios.get('/workers');
        const workersData = response.data;
        const map = {};
        workersData.forEach(worker => {
          map[worker.name] = worker._id;
        });
        setWorkersMap(map);
      } catch (error) {
        console.error('Error fetching workers:', error);
      }
    };

    fetchClients();
    fetchWorkers();
  }, []);

  const handleWorkerClick = (workerName) => {
    const workerId = workersMap[workerName];
    if (workerId) {
      navigate(`/resource/workers/${workerId}`);
    } else {
      console.error(`Worker ID not found for ${workerName}`);
    }
  };

  const assignWorkerMutation = useMutation(
    async ({ taskIndex, worker }) => {
      await axios.put(`/assignWorker`, {
        project_id: projectId,
        task_index: taskIndex,
        worker: worker,
      });
    },
    {
      onSuccess: (data, { taskIndex, worker }) => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
        setLocalTasks((prevTasks) => {
          const updatedTasks = [...prevTasks];
          updatedTasks[taskIndex].worker = worker;
          return updatedTasks;
        });
        setIsModalOpen(false);
      },
    }
  );

  const handleWorkerAssign = (worker) => {
    if (assignTaskIndex !== null) {
      assignWorkerMutation.mutate({ taskIndex: assignTaskIndex, worker });
    }
  };

  const openModal = (index) => {
    setAssignTaskIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAssignTaskIndex(null);
  };

  const handleContentBlur = (index) => {
    saveContent(index, workerContents[index]);
    setEditableContent({ ...editableContent, [index]: false });
  };

  const handleContentDoubleClick = (index) => {
    setEditableContent({ ...editableContent, [index]: true });
    setTimeout(() => {
      const element = document.getElementById(`content-${index}`);
      if (element) element.select();
    }, 0);
  };

  const handleContentKeyPress = (index, e) => {
    if (e.key === 'Enter') {
      handleContentBlur(index);
    }
  };

  const handleRateBlur = (index, workerId) => {
    saveRate(index, workerId);
    setEditableRate({ ...editableRate, [`${index}-${workerId}`]: false });
  };

  const handleRateDoubleClick = (index, workerId) => {
    setEditableRate({ ...editableRate, [`${index}-${workerId}`] : true });
    setTimeout(() => {
      const element = document.getElementById(`rate-${index}-${workerId}`);
      if (element) element.select();
    }, 0);
  };

  const handleRateKeyPress = (index, workerId, e) => {
    if (e.key === 'Enter') {
      handleRateBlur(index, workerId);
    }
  };

  const handleVolumeBlur = (index, workerId) => {
    setIsVolumeChangeModalOpen(true);
    setVolumeChangeData({ index, workerId });
  };

  const handleVolumeDoubleClick = (index, workerId) => {
    setEditableVolume({ ...editableVolume, [`${index}-${workerId}`]: true });
    setTimeout(() => {
      const element = document.getElementById(`volume-${index}-${workerId}`);
      if (element) element.select();
    }, 0);
  };

  const handleVolumeKeyPress = (index, workerId, e) => {
    if (e.key === 'Enter') {
      handleVolumeBlur(index, workerId);
    }
  };

  const handleExchangeRateBlur = (index, workerId) => {
    saveExchangeRate(index, workerId);
    setEditableExchangeRate({ ...editableExchangeRate, [`${index}-${workerId}`]: false });
  };

  const handleExchangeRateDoubleClick = (index, workerId) => {
    setEditableExchangeRate({ ...editableExchangeRate, [`${index}-${workerId}`]: true });
    setTimeout(() => {
      const element = document.getElementById(`exchangeRate-${index}-${workerId}`);
      if (element) element.select();
    }, 0);
  };

  const handleExchangeRateKeyPress = (index, workerId, e) => {
    if (e.key === 'Enter') {
      handleExchangeRateBlur(index, workerId);
    }
  };

  const handleExchangeRateChange = (index, workerId, value) => {
    const updatedTasks = [...localTasks];
    const task = updatedTasks[index];
    if (task && task.Workers) {
      const worker = task.Workers.find((w) => w._id === workerId);
      if (worker) {
        worker.exchangeRate = parseFloat(value) || '';
        setLocalTasks(updatedTasks);
      }
    }
  };

  const saveExchangeRate = async (index, workerId) => {
    const worker = localTasks[index].Workers.find((worker) => worker._id === workerId);
    await axios.put('/updateWorkerExchangeRate', {
      project_id: projectId,
      task_index: index,
      worker_id: workerId,
      exchange_rate: worker.exchangeRate,
    });
    queryClient.invalidateQueries(['projectDetails', projectId]);
  };

  const handleVolumeChangeWithCheck = (taskIndex, workerId, volume) => {
    const parsedVolume = volume === '' ? '' : parseFloat(volume) || 0;
    setWorkerVolumes((prev) => ({
      ...prev,
      [`${taskIndex}-${workerId}`]: parsedVolume,
    }));

    const taskVolume = localTasks[taskIndex].ProjectVolume;
    const totalWorkerVolume = Object.values(workerVolumes).reduce((acc, vol) => acc + vol, 0);

    if (totalWorkerVolume > taskVolume) {
      setExceedTaskIndex(taskIndex);
      setIsVolumeExceedModalOpen(true);
    } else {
      saveVolume(taskIndex, workerId, parsedVolume);
    }
  };

  const handleConfirmVolumeExceed = () => {
    const taskIndex = exceedTaskIndex;
    const task = localTasks[taskIndex];
    const totalWorkerVolume = Object.values(workerVolumes).reduce((acc, vol) => acc + vol, 0);

    setLocalTasks((prevTasks) =>
      prevTasks.map((t, index) =>
        index === taskIndex ? { ...t, ProjectVolume: totalWorkerVolume } : t
      )
    );

    setIsVolumeExceedModalOpen(false);
  };

  const calculateTotalWorkerCost = () => {
    const totalWorkerCost = localTasks.reduce((acc, task) => {
      const taskWorkerCost = task.Workers?.reduce((taskAcc, worker) => {
        const volume = workerVolumes[`${task.id}-${worker._id}`] || worker.volume || 0;
        const rate = workerRates[`${task.id}-${worker._id}`] || worker.rate || 0;
        const cost = volume * rate;
        const costInKRW = worker.currency !== 'KRW' ? cost * worker.exchangeRate : cost;
        return taskAcc + costInKRW;
      }, 0) || 0;
      return acc + taskWorkerCost;
    }, 0) || 0;

    saveProjectDetails.mutate({ projectId, totalWorkerCost });
  };

  const updateTaskStatusMutation = useMutation(
    async ({ taskId, status }) => {
      await axios.put(`/updateTaskStatus`, {
        task_id: taskId,
        status,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const updateWorkerStatusMutation = useMutation(
    async ({ taskId, workerId, status }) => {
      await axios.put(`/updateWorkerStatus`, {
        task_id: taskId,
        worker_id: workerId,
        status,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const updateProjectStatusMutation = useMutation(
    async ({ projectId, status }) => {
      await axios.put(`/updateProjectStatus`, {
        project_id: projectId,
        status,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const handleTaskStatusChange = (taskId, status) => {
    setLocalTasks((prevTasks) =>
      prevTasks.map((task) => (task.id === taskId ? { ...task, Status: status } : task))
    );
    updateTaskStatusMutation.mutate({ taskId, status });
  };

  const handleWorkerStatusChange = (taskId, workerId, status) => {
    setLocalTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId
          ? {
              ...task,
              Workers: task.Workers.map((worker) =>
                worker._id === workerId ? { ...worker, Status: status } : worker
              ),
            }
          : task
      )
    );

    updateWorkerStatusMutation.mutate({ taskId, workerId, status }, {
      onSuccess: () => {
        const task = localTasks.find((task) => task.id === taskId);

        if (task) {
          const allWorkersCompleted = task.Workers?.every((worker) => worker.Status === '작업완료');
          if (allWorkersCompleted) {
            setCurrentTaskId(taskId);
            setIsTwoButtonModalOpen(true);
          }
        }

        const allTasksCompleted = localTasks?.every((task) => task.Status === '작업완료');
        if (allTasksCompleted && taskStatus !== '완료') {
          setIsProjectCompleteModalOpen(true);
        }
      }
    });
  };

  useEffect(() => {
    const allTasksCompleted = localTasks?.every((task) => task.Status === '작업완료');
    if (allTasksCompleted && taskStatus !== '완료') {
      setIsProjectCompleteModalOpen(true);
    }
  }, [localTasks, taskStatus]);

  const handleSaveModal = () => {
    handleTaskStatusChange(currentTaskId, '작업진행');
    setIsTwoButtonModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsTwoButtonModalOpen(false);
  };

  const handleSaveProjectCompleteModal = () => {
    updateProjectStatusMutation.mutate({ projectId, status: '완료' });
    setIsProjectCompleteModalOpen(false);
  };

  const handleCloseProjectCompleteModal = () => {
    setIsProjectCompleteModalOpen(false);
  };

  const handleConfirmVolumeChange = () => {
    if (volumeChangeData) {
      const { index, workerId } = volumeChangeData;
      saveVolume(index, workerId);
      setEditableVolume({ ...editableVolume, [`${index}-${workerId}`]: false }); // Close the input field
      calculateTaskTotals(index); // Recalculate task totals
      setIsVolumeChangeModalOpen(false);
      setVolumeChangeData(null);
    }
  };

  const handleCancelVolumeChange = () => {
    if (volumeChangeData) {
      const { index, workerId } = volumeChangeData;
      setEditableVolume({ ...editableVolume, [`${index}-${workerId}`]: false }); // Close the input field
      setIsVolumeChangeModalOpen(false);
      setVolumeChangeData(null);
    }
  };

  const fetchUpdatedProject = async () => {
    try {
      const response = await axios.get(`projects/${projectId}`);
      const updatedProject = response.data;
      setLocalTasks(updatedProject.tasks);
    } catch (error) {
      console.error('Error fetching updated project:', error);
    }
  };

  const calculateTaskTotals = (taskIndex) => {
    fetchUpdatedProject();
  };

  useEffect(() => {
    setLocalTasks(tasks);
  }, [tasks]);
  

  const handleExport = async () => {
    try {
      const response = await axios.get(`/exportProject/${projectId}`, { responseType: 'json' });
      const fileUrl = response.data.file_url;
      const invoice = response.data.invoice;
      const clientName = response.data.client_name;
      const todayDate = response.data.today_date;
      const fileName = `견적서_${invoice}_${clientName}_${todayDate}.xlsx`;
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting project:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">추가 견적번호</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">시작일</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">마감일</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">유형</th>
          
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">그룹</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">PM 이름</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">출발어</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">도착어</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">분량</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">단위</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">단가</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">총액</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">총액(원화환산)</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">상태</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">할인율</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">할인액</th>
            <th className="px-4 py-3 min-w-[4em] whitespace-nowrap">작업자</th>
            <th className="px-8 py-3 min-w-[4em] whitespace-nowrap">내용</th>
            <th className="px-4 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(localTasks) && localTasks.map((task, index) => (
            <React.Fragment key={task.id}>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.Invoice}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{formatDate(task.SDate)}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{formatDate(task.DDate)}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.ProjectType}</td>
                
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.Group}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.PMName}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.SLang}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.TLang}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{Number(task.ProjectVolume).toLocaleString()}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.Unit}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{formatNumber(task.Rate, projectCurrency)}</td>
                <td className="px-4 py-2 font-bold min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(task.Total, projectCurrency)}</td>
                <td className="px-4 py-2 font-bold min-w-[4em] whitespace-nowrap text-red-500">{formatNumber(task.totalInKRW || 0, 'KRW')}</td>
                <td className="px-4 py-4">
                  <div className="flex items-center space-x-2">
                    <select
                      className="p-1 text-xs border border-gray-300 rounded-lg"
                      value={task.Status || dropdownData.workerStatus[0]}
                      onChange={(e) => handleTaskStatusChange(task.id, e.target.value)}
                    >
                      <option value="">Select Status</option>
                      {dropdownData.workerStatus.map((status, idx) => (
                        <option key={idx} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{task.DiscountRate}%</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">{formatNumber(task.DiscountAmount || 0, projectCurrency)}</td>
                <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">
                  <div className="flex items-center space-x-2">
                    <button
                      className="px-1 py-1 text-xs text-white bg-blue-500 rounded"
                      onClick={() => openModal(index)}
                    >
                      Assign
                    </button>
                  </div>
                </td>
                <td className="px-8 py-4 text-xs min-w-[4em] whitespace-nowrap">
                  {editableContent[index] ? (
                    <input
                      id={`content-${index}`}
                      type="text"
                      className="w-32 p-1 border border-gray-300 rounded-lg"
                      value={workerContents[index] || ''}
                      onChange={(e) => handleContentChange(index, e.target.value)}
                      onBlur={() => handleContentBlur(index)}
                      onKeyPress={(e) => handleContentKeyPress(index, e)}
                    />
                  ) : (
                    <span onDoubleClick={() => handleContentDoubleClick(index)}>
                      {workerContents[index] || 'No details'}
                    </span>
                  )}
                </td>
                <td className="px-4 py-4">
                  <button
                    className={`font-medium hover:underline ${expandedTaskIds.includes(task.id) ? 'text-red-600' : 'text-blue-600'}`}
                    onClick={() => toggleTaskExpand(task.id)}
                  >
                    {expandedTaskIds.includes(task.id) ? '접기' : '펼치기'}
                  </button>
                </td>
              </tr>
              {expandedTaskIds.includes(task.id) && (
                <tr className="bg-gray-50 dark:bg-gray-700">
                  <td colSpan="18">
                    <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                      <h4 className="mb-2 text-lg font-semibold">
                        <span className="pb-1 border-b-2 border-blue-500">작업자</span>
                      </h4>
                      {task.Workers && task.Workers.length > 0 ? (
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th className="px-4 py-3">이름</th>
                              <th className="px-4 py-3">작업유형</th>
                              
                              <th className="px-4 py-3">작업자 유형</th>
                              <th className="px-4 py-3">출발어</th>
                              <th className="px-4 py-3">도착어</th>
                              <th className="px-4 py-3">단가</th>
                              <th className="px-4 py-3">분량</th>
                              <th className="px-6 py-3">작업비</th>
                              <th className="px-4 py-3">환율</th>
                              <th className="px-6 py-3">작업비(원화환산)</th>
                              <th className="px-4 py-3">상태</th>
                              <th className="px-4 py-3">PO</th>
                              <th className="px-4 py-3">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {task.Workers.map((worker, idx) => {
                              const volume =
                                workerVolumes[`${index}-${worker._id}`] === ''
                                  ? ''
                                  : workerVolumes[`${index}-${worker._id}`] || worker.volume || 0;
                              const rate =
                                workerRates[`${index}-${worker._id}`] === ''
                                  ? ''
                                  : workerRates[`${index}-${worker._id}`] || worker.rate || 0;
                              const exchangeRate = worker.exchangeRate || 1;
                              const cost = volume && rate ? volume * rate : 0;
                              const costInKRW = worker.costInKRW ? worker.costInKRW : 0;
                              return (
                                <tr
                                  key={idx}
                                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                                >
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap cursor-pointer hover:underline" onClick={() => handleWorkerClick(worker.name)}>{worker.name}</td>
                                  
                                  <td className="px-4 py-4">{worker.domains}</td>
                                  <td className="px-4 py-4">{worker.workerType}</td>
                                  <td className="px-4 py-4">{worker.sLang}</td>
                                  <td className="px-4 py-4">{worker.tLang}</td>
                                  <td className="px-4 py-4">
                                    {editableRate[`${index}-${worker._id}`] ? (
                                      <input
                                        id={`rate-${index}-${worker._id}`}
                                        type="text"
                                        className="w-20 p-1 border border-gray-300 rounded-lg"
                                        value={rate}
                                        onChange={(e) => handleRateChange(index, worker._id, e.target.value)}
                                        onBlur={() => handleRateBlur(index, worker._id)}
                                        onKeyPress={(e) => handleRateKeyPress(index, worker._id, e)}
                                      />
                                    ) : (
                                      <span onDoubleClick={() => handleRateDoubleClick(index, worker._id)}>
                                        {rate}
                                      </span>
                                    )}
                                  </td>
                                  <td className="px-4 py-4">
                                    {editableVolume[`${index}-${worker._id}`] ? (
                                      <input
                                        id={`volume-${index}-${worker._id}`}
                                        type="text"
                                        className="w-20 p-1 border border-gray-300 rounded-lg"
                                        value={volume}
                                        onChange={(e) => handleVolumeChange(index, worker._id, e.target.value)}
                                        onBlur={() => handleVolumeBlur(index, worker._id)}
                                        onKeyPress={(e) => handleVolumeKeyPress(index, worker._id, e)}
                                      />
                                    ) : (
                                      <span onDoubleClick={() => handleVolumeDoubleClick(index, worker._id)}>
                                        {volume}
                                      </span>
                                    )}
                                  </td>
                                  <td className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap text-blue-600">
                                    {formatNumber(cost, worker.currency)}
                                  </td>
                                  <td className="px-4 py-4">
                                    {editableExchangeRate[`${index}-${worker._id}`] ? (
                                      <input
                                        id={`exchangeRate-${index}-${worker._id}`}
                                        type="text"
                                        className="w-20 p-1 border border-gray-300 rounded-lg"
                                        value={exchangeRate}
                                        onChange={(e) => handleExchangeRateChange(index, worker._id, e.target.value)}
                                        onBlur={() => handleExchangeRateBlur(index, worker._id)}
                                        onKeyPress={(e) => handleExchangeRateKeyPress(index, worker._id, e)}
                                      />
                                    ) : (
                                      <span onDoubleClick={() => handleExchangeRateDoubleClick(index, worker._id)}>
                                        {exchangeRate}
                                      </span>
                                    )}
                                  </td>
                                  <td className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap text-red-600">
                                    {formatNumber(costInKRW, 'KRW')}
                                  </td>
                                  <td className="px-4 py-4">
                                    <div className="flex items-center space-x-2">
                                      <select
                                        className="p-1 text-xs border border-gray-300 rounded-lg"
                                        value={worker.Status || ''}
                                        onChange={(e) =>
                                          handleWorkerStatusChange(task.id, worker._id, e.target.value)
                                        }
                                      >
                                        <option value="">Select Status</option>
                                        {dropdownData.workerStatus.map((status, idx) => (
                                          <option key={idx} value={status}>
                                            {status}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </td>
                                  <td className="px-4 py-4">
                                  <SendPOButton
                                    key={worker._id}
                                    projectId={projectId}
                                    projectName={projectName}
                                    taskId={task.id}
                                    workerId={worker.workerId}
                                    workerEmail={worker.email}
                                    volume={worker.volume}
                                    rate={worker.rate}
                                    currency={worker.currency}
                                    total={worker.total}
                                    workerName={worker.name}
                                    workerMobile={worker.mobile}
                                    workerSlang={worker.sLang}
                                    workerTlang={worker.tLang}
                                    workerTasktype={worker.taskType}
                                    workStartedAt={worker.workStartedAt}
                                    workEndedAt={worker.workEndedAt}
                                    paymentRequestedAt={worker.paymentRequestedAt}
                                    paymentReceivedAt={worker.paymentReceivedAt}
                                    paymentAccount={worker.paymentAccount}
                                    paymentBank={worker.paymentBank}
                                    paymentCountry={worker.paymentCountry}
                                    paymentMethod={worker.paymentMethod}
                                    comments={worker.memo}
                                    onPOSuccess={onPOSuccess}
                                    unit={task.Unit}
                                    costInKRW={worker.costInKRW}
                                    pmName={task.PMName}
                                    jobId={worker._id}
                                    sdate={task.SDate}
                                    ddate={task.DDate}
                                    workerDomain={worker.domains}  // workerDomain 추가
                                    workerType={worker.workerType}  // workerType 추가
                                    pmDivision={task.Division}
                                    pmGroup={task.Group}
                                    files={files}
                                    apiUrl={apiUrl}
                                  />
                                  </td>
                                  <td className="px-4 py-4">
                                    <button
                                      className="text-red-500 hover:underline"
                                      onClick={() => handleRemoveWorker(index, worker._id)}
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      ) : (
                        <p>No workers available.</p>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <POTable projectId={projectId} projectName={projectName} />
      {isModalOpen && (
        <WorkerAssignModal
          isOpen={isModalOpen}
          onClose={closeModal}
          workers={workers}
          onAssign={handleWorkerAssign}
          dropdownmenus={{
            language: dropdownData.language,
            domain: dropdownData.domain,
          }}
        />
      )}
      {isTwoButtonModalOpen && (
        <TwoButtonModal
          id="statusChangeModal"
          title="상태 변경 확인"
          body="작업자 상태가 '작업진행'으로 변경되었습니다. 작업 상태를 '작업진행' 상태로 변경하시겠습니까?"
          onSave={handleSaveModal}
          onClose={handleCloseModal}
        />
      )}
      {isProjectCompleteModalOpen && (
        <TwoButtonModal
          id="projectStatusChangeModal"
          title="프로젝트 상태 변경 확인"
          body="모든 작업이 완료되었습니다. 프로젝트 상태를 '완료' 상태로 변경하시겠습니까?"
          onSave={handleSaveProjectCompleteModal}
          onClose={handleCloseProjectCompleteModal}
        />
      )}
      {isVolumeChangeModalOpen && (
        <TwoButtonModal
          id="volumeChangeModal"
          title="분량 변경 확인"
          body="작업자 분량이 변경되었습니다. 작업 분량에 반영하시겠습니까?"
          onSave={handleConfirmVolumeChange}
          onClose={handleCancelVolumeChange}
          saveButtonText="반영"
          closeButtonText="반영안함"
        />
      )}
    </div>
  );
};

export default TaskTable;
