import React from 'react';

const ReviewComponent = ({ formData, tasks }) => {
  const renderGeneralInfo = () => {
    const keys = ['Invoice', 'ProjectName', 'MainPM', 'Division', 'Group', 'Status'];
    const values = keys.map(key => formData[key]);

    return (
      <table className="w-full text-sm text-left text-gray-500 border-b border-gray-200 rtl:text-right dark:text-gray-400">
        <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
          <tr>
            {keys.map((key, index) => (
              <th key={index} scope="col" className="w-1/6 px-3 py-1">{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white dark:bg-gray-800">
            {values.map((value, index) => (
              <td key={index} className="w-1/6 px-3 py-1">{value}</td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const renderTaskInfo = () => {
    const keys = Object.keys(formData).filter(key => !['Invoice', 'ProjectName', 'MainPM', 'Division', 'Group', 'Status', 'Files', 'notes', '_id'].includes(key));
    const values = keys.map(key => {
      if (['Total', 'TotalWithVAT', 'ProjectVolume', 'TotalInKRW'].includes(key)) {
        return formatNumber(formData[key]);
      }
      return formData[key];
    });

    return (
      <table className="w-full mt-2 text-sm text-left text-gray-500 border-b border-gray-200 rtl:text-right dark:text-gray-400">
        <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
          <tr>
            {keys.map((key, index) => (
              
              <th key={index} scope="col" className="w-1/6 px-3 py-1">{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white dark:bg-gray-800">
            {values.map((value, index) => (
              <td key={index} className={`w-1/6 px-3 py-1 ${keys[index] === 'Total' ? 'text-blue-500 font-bold' : keys[index] === 'TotalWithVAT' ? 'text-red-500 font-bold' : ''}`}>{value}</td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  const renderTasks = () => {
    if (tasks.length === 0) return null;
  
    const taskKeys = Object.keys(tasks[0]).filter(key => key !== '_id' && key !== 'Workers' && key !== 'id');
    
    return (
      <table className="w-full text-sm text-left text-gray-500 border-b border-gray-200 rtl:text-right dark:text-gray-400">
        <thead className="text-xs text-gray-900 uppercase dark:text-gray-400">
          <tr>
            
            {taskKeys.map((key) => (
              key !== 'Division' && key !== 'Group' &&
              <th key={key} scope="col" className="w-1/12 px-3 py-1">{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tasks.map((task, index) => (
            <tr key={index} className="bg-white dark:bg-gray-800">
              {taskKeys.map((key, idx) => (
                key !== 'Division' && key !== 'Group' &&
                <td key={idx} className={`w-1/12 px-3 py-1 ${key === 'Total' ? 'text-blue-500 font-bold' : key === 'totalInKRW' ? 'text-green-500 font-bold' : ''}`}>
                  {typeof task[key] === 'number' ? formatNumber(task[key]) : (typeof task[key] === 'object' ? JSON.stringify(task[key]) : task[key])}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderFiles = () => {
    if (!formData.Files || formData.Files.length === 0) return null;

    return (
      <div className="pb-2 mt-2 border-b border-gray-200">
        <h4 className="mb-2 text-lg font-semibold">Files</h4>
        <ul className="text-sm text-gray-500 list-none list-inside">
          {formData.Files.map((file, index) => (
            <li key={index}>
              {file.path ? file.path.split('/').pop() : 'No path'} <span className="font-bold text-gray-800">| {file.comment}</span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderNotes = () => {
    if (!formData.notes || formData.notes.length === 0) return null;

    return (
      <div className="mt-2">
        <h4 className="mb-2 text-lg font-semibold">Notes</h4>
        <ul className="text-sm text-gray-500 list-none list-inside">
          {formData.notes.map((note, index) => (
            <li key={index}>{note.text}</li>
          ))}
        </ul>
      </div>
    );
  };

  const formatNumber = (num) => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
  };

  return (
    <div className="relative max-h-full overflow-x-auto">
      <h4 className="mb-2 text-lg font-semibold">일반 정보</h4>
      {renderGeneralInfo()}

      <h4 className="mt-2 mb-2 text-lg font-semibold">작업 정보</h4>
      {renderTaskInfo()}

      <h4 className="mt-2 mb-2 text-lg font-semibold">세부 작업</h4>
      {renderTasks()}

      {renderFiles()}
      {renderNotes()}
    </div>
  );
};

export default ReviewComponent;