import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import AddClientModal from '../components/commons/modal/AddClientModal';
import AddClientPMModal from '../components/commons/modal/AddClientPMModal';
import AddEndClientModal from '../components/commons/modal/AddEndClientModal';
import EditClientModal from '../components/commons/modal/EditClientModal';
import EditClientPMModal from '../components/commons/modal/EditClientPMModal';
import EditEndClientModal from '../components/commons/modal/EditEndClientModal';

const fetchClients = async () => {
  const { data } = await axios.get('/clients');
  // 클라이언트 데이터를 updatedAt 또는 createdAt 필드를 기준으로 정렬
  return data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
};

const ClientBoard = () => {
  const queryClient = useQueryClient();
  const { data: clients, isLoading, error } = useQuery('clients', fetchClients);
  const [currentPageClients, setCurrentPageClients] = useState(1);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientPM, setSelectedClientPM] = useState(null);
  const [selectedEndClient, setSelectedEndClient] = useState(null);
  const [selectedClientName, setSelectedClientName] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [oldClientId, setOldClientId] = useState(null);  // oldClientId 상태 추가
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
  const [isAddClientPMModalOpen, setIsAddClientPMModalOpen] = useState(false);
  const [isEditClientPMModalOpen, setIsEditClientPMModalOpen] = useState(false);
  const [isAddEndClientModalOpen, setIsAddEndClientModalOpen] = useState(false);
  const [isEditEndClientModalOpen, setIsEditEndClientModalOpen] = useState(false);
  const [searchTermClients, setSearchTermClients] = useState('');
  const [selectedClients, setSelectedClients] = useState([]);
  const [isEndClientVisible, setIsEndClientVisible] = useState({});
  const [isClientPMVisible, setIsClientPMVisible] = useState({});
  const [isDomainVisible, setIsDomainVisible] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const clientsPerPage = 10;
  const navigate = useNavigate();

  const addClientMutation = useMutation(
    async (newClient) => {
      await axios.post('/addClient', newClient);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        setIsAddClientModalOpen(false);
      },
    }
  );

  const editClientMutation = useMutation(
    async (updatedClient) => {
      await axios.put(`/clients/${updatedClient._id}`, updatedClient);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        setIsClientModalOpen(false);
      },
    }
  );

  const addClientPMMutation = useMutation(
    async (newClientPM) => {
      await axios.post('/addClientPM', newClientPM);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        setIsAddClientPMModalOpen(false);
      },
    }
  );

  const editClientPMMutation = useMutation(
    async (updatedClientPM) => {
      await axios.put(`/clientPMs/${updatedClientPM._id}`, updatedClientPM);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        setIsEditClientPMModalOpen(false);
      },
    }
  );

  const addEndClientMutation = useMutation(
    async (newEndClient) => {
      await axios.post('/addEndClient', newEndClient);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        setIsAddEndClientModalOpen(false);
      },
    }
  );

  const editEndClientMutation = useMutation(
    async (updatedEndClient) => {
      await axios.put(`/endClients/${updatedEndClient._id}`, updatedEndClient);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('clients');
        setIsEditEndClientModalOpen(false);
      },
    }
  );

  const handleAddClient = () => {
    setSelectedClient(null);
    setIsAddClientModalOpen(true);
  };

  const handleAddClientPM = () => {
    setIsAddClientPMModalOpen(true);
  };

  const handleEditClientPM = (clientPM, clientId, clientName) => {
    setSelectedClientPM(clientPM);
    setSelectedClientId(clientId);
    setOldClientId(clientId);
    setSelectedClientName(clientName);
    setIsEditClientPMModalOpen(true);
  };

  const handleEditEndClient = (endClient, clientName, clientId) => {
    setSelectedEndClient(endClient);
    setSelectedClientName(clientName);  // 클라이언트 이름 저장
    setSelectedClientId(clientId);  // 클라이언트 ID 저장
    setOldClientId(clientId);  // 기존 클라이언트 ID 저장
    setIsEditEndClientModalOpen(true);
  };

  const handleAddEndClient = () => {
    setSelectedEndClient(null);
    setIsAddEndClientModalOpen(true);
  };

  const handleClientClick = (clientId) => {
    navigate(`/resource/clients/${clientId}`);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedClients(currentClients.map((client) => client._id));
    } else {
      setSelectedClients([]);
    }
  };

  const handleSelectClient = (e, clientId) => {
    if (e.target.checked) {
      setSelectedClients([...selectedClients, clientId]);
    } else {
      setSelectedClients(selectedClients.filter((id) => id !== clientId));
    }
  };

  const handleExport = async () => {
    try {
      const clientNames = clients
        .filter((client) => selectedClients.includes(client._id))
        .map((client) => client.clientName);

      const response = await axios.post(
        '/exportProjectsByClient',
        {
          clientNames,
        },
        { responseType: 'blob' }
      );

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(
        blob,
        `Projects_${clientNames.join('_')}_${new Date().toISOString().split('T')[0]}.xlsx`
      );
    } catch (error) {
      console.error('Error exporting projects:', error);
    }
  };

  const toggleEndClientVisibility = (clientId) => {
    setIsEndClientVisible((prevState) => ({
      ...prevState,
      [clientId]: !prevState[clientId],
    }));
  };

  const toggleClientPMVisibility = (clientId) => {
    setIsClientPMVisible((prevState) => ({
      ...prevState,
      [clientId]: !prevState[clientId],
    }));
  };

  const toggleDomainVisibility = (clientId) => {
    setIsDomainVisible((prevState) => ({
      ...prevState,
      [clientId]: !prevState[clientId],
    }));
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching clients</div>;

  const indexOfLastClient = currentPageClients * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;

  const currentClients = clients
    .slice(indexOfFirstClient, indexOfLastClient)
    .filter(
      (client) =>
        (client.clientName &&
          client.clientName.toLowerCase().includes(searchTermClients.toLowerCase())) ||
        (client.clientType &&
          client.clientType.toLowerCase().includes(searchTermClients.toLowerCase())) ||
        (client.clientEmail &&
          client.clientEmail.toLowerCase().includes(searchTermClients.toLowerCase())) ||
        (client.clientRegistrationNumber &&
          client.clientRegistrationNumber.toLowerCase().includes(searchTermClients.toLowerCase())) ||
        (client.memo && client.memo.toLowerCase().includes(searchTermClients.toLowerCase()))
    );

  const pageNumbersClients = [];
  for (let i = 1; i <= Math.ceil(clients.length / clientsPerPage); i++) {
    pageNumbersClients.push(i);
  }

  const handleEditClient = (client) => {
    setSelectedClient(client);
    setIsClientModalOpen(true);
  };

  return (
    <div className="m-4 space-y-8">
      <div className="relative p-6 overflow-x-auto bg-white shadow-md sm:rounded-lg dark:bg-gray-900">
      <h3 className="mb-4 text-lg font-semibold">
        <span className="pb-1 border-b-2 border-blue-500">클라이언트 리스트</span>
      </h3>
        <div className="flex flex-wrap items-center justify-between py-4">
          <div className="relative">
            <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="text"
              id="table-search-clients"
              className="block pt-2 text-sm text-gray-900 border border-gray-300 rounded-lg ps-10 w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for clients"
              value={searchTermClients}
              onChange={(e) => setSearchTermClients(e.target.value)}
            />
          </div>
          <div className="relative inline-block text-left">
            <button
              id="dropdownHoverButton"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button"
            >
              클라이언트 등록
              <svg
                className="w-2.5 h-2.5 ms-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
            {isDropdownOpen && (
              <div
                id="dropdownHover"
                className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul
                  className="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownHoverButton"
                >
                  <li>
                    <button
                      onClick={handleAddClient}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      클라이언트
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleAddClientPM}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      클라이언트 PM
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={handleAddEndClient}
                      className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      최종 클라이언트
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
        <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    onChange={handleSelectAll}
                    checked={
                      selectedClients.length === currentClients.length && currentClients.length > 0
                    }
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">
                    checkbox
                  </label>
                </div>
              </th>
              <th scope="col" className="px-6 py-3">
                클라이언트
              </th>
              <th scope="col" className="px-6 py-3">
                클라이언트 PM
              </th>
              <th scope="col" className="px-6 py-3">
                최종 클라이언트
              </th>
              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">
                분야
              </th>
              <th scope="col" className="px-6 py-3">
                Memo
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentClients.map((client) => (
              <tr
                key={client._id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="w-4 p-4">
                  <div className="flex items-center">
                    <input
                      id={`checkbox-table-search-${client._id}`}
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={(e) => handleSelectClient(e, client._id)}
                      checked={selectedClients.includes(client._id)}
                    />
                    <label htmlFor={`checkbox-table-search-${client._id}`} className="sr-only">
                      checkbox
                    </label>
                  </div>
                </td>
                <th
                  scope="row"
                  className="flex items-center px-6 py-4 text-gray-9000 whitespace-nowrap dark:text-white"
                >
                  <span onClick={() => handleClientClick(client._id)} className="cursor-pointer ps-3">
                    <div className="text-base font-semibold">{client.clientName}</div>
                    <div className="font-normal text-gray-500">{client.clientType}</div>
                  </span>
                </th>
                <td className="px-4 py-4">
                  {client.pm && client.pm.length > 0 && (
                    <>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() => toggleClientPMVisibility(client._id)}
                      >
                        {client.pm.length > 1 && (
                          <span className="mr-2">{isClientPMVisible[client._id] ? '▲' : '▼'}</span>
                        )}
                        <span>
                          {client.pm[0].pmName} ({client.pm[0].pmMobile}, {client.pm[0].pmEmail})
                        </span>
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditClientPM(client.pm[0], client._id, client.clientName); // clientId와 clientName 전달
                          }}
                          className="ml-2 text-gray-500 cursor-pointer hover:text-blue-700"
                        />
                      </div>
                      {isClientPMVisible[client._id] &&
                        client.pm.slice(1).map((pm, index) => (
                          <div key={index} className="pl-6">
                            <span>
                              {pm.pmName} ({pm.pmMobile}, {pm.pmEmail})
                            </span>
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditClientPM(pm, client._id, client.clientName); // clientId와 clientName 전달
                              }}
                              className="ml-2 text-gray-500 cursor-pointer hover:text-blue-700"
                            />
                          </div>
                        ))}
                    </>
                  )}
                </td>
                <td className="px-4 py-4">
                  {client.endClient && client.endClient.length > 0 && (
                    <>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() => toggleEndClientVisibility(client._id)}
                      >
                        {client.endClient.length > 1 && (
                          <span className="mr-2">
                            {isEndClientVisible[client._id] ? '▲' : '▼'}
                          </span>
                        )}
                        <span className="mr-2">
                          {client.endClient[0].endClientName}
                          {client.endClient[0].endClientMobile && ` (${client.endClient[0].endClientMobile}`}
                          {client.endClient[0].endClientEmail && `, (${client.endClient[0].endClientEmail})`}
                        </span>
                        <FontAwesomeIcon
                          icon={faEdit}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditEndClient(client.endClient[0], client.clientName, client._id); // 클라이언트 ID 전달
                          }}
                          className="ml-2 text-gray-500 cursor-pointer hover:text-blue-700"
                        />
                      </div>
                      {isEndClientVisible[client._id] &&
                        client.endClient.slice(1).map((ec, index) => (
                          <div key={index} className="pl-6">
                            <span>
                              {ec.endClientName}
                              {ec.endClientMobile && ` (${ec.endClientMobile}`}
                              {ec.endClientEmail && `, ${ec.endClientEmail})`}
                            </span>
                            <FontAwesomeIcon
                              icon={faEdit}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEditEndClient(ec, client.clientName, client._id); // 클라이언트 ID 전달
                              }}
                              className="ml-2 text-gray-500 cursor-pointer hover:text-blue-700"
                            />
                          </div>
                        ))}
                    </>
                  )}
                </td>
    
                <td className="px-6 py-4">
                  {client.clientDomains && client.clientDomains.length > 0 && (
                    <>
                      <div
                        className="flex items-center cursor-pointer"
                        onClick={() => toggleDomainVisibility(client._id)}
                      >
                        {client.clientDomains.length > 1 && (
                          <span className="mr-2">{isDomainVisible[client._id] ? '▲' : '▼'}</span>
                        )}
                        <span className="min-w-[4em] whitespace-nowrap px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                          {client.clientDomains[0]}
                        </span>
                      </div>
                      {isDomainVisible[client._id] &&
                        client.clientDomains.slice(1).map((domain, index) => (
                          <span
                            key={index}
                            className="block px-2 py-1 mt-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full"
                          >
                            {domain}
                          </span>
                        ))}
                    </>
                  )}
                </td>
                <td className="px-6 py-4">{client.memo}</td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => handleEditClient(client)}
                    className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                  >
                    Edit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPageClients}
          setCurrentPage={setCurrentPageClients}
          pageNumbers={pageNumbersClients}
        />
      </div>
    
      {/* Modals */}
      <AddClientPMModal
        isOpen={isAddClientPMModalOpen}
        onClose={() => {
          setIsAddClientPMModalOpen(false);
          queryClient.invalidateQueries('clients');
        }}
        clients={clients}
        fetchClients={() => queryClient.invalidateQueries('clients')}
      />
      <EditClientPMModal
        isOpen={isEditClientPMModalOpen}
        onClose={() => setIsEditClientPMModalOpen(false)}  // onClose 핸들러
        clientPM={selectedClientPM}  // clientPM 전달
        clientId={selectedClientId}
        oldClientId={oldClientId}
        clientName={selectedClientName}
      />
      <EditClientModal
        isOpen={isClientModalOpen}
        onClose={() => {
          setIsClientModalOpen(false);
          queryClient.invalidateQueries('clients');
        }}
        client={selectedClient}
      />
      <AddClientModal
        isOpen={isAddClientModalOpen}
        onClose={() => {
          setIsAddClientModalOpen(false);
          queryClient.invalidateQueries('clients');
        }}
      />
      <AddEndClientModal
        isOpen={isAddEndClientModalOpen}
        onClose={() => {
          setIsAddEndClientModalOpen(false);
          queryClient.invalidateQueries('clients');
        }}
      />
      <EditEndClientModal
        isOpen={isEditEndClientModalOpen}
        onClose={() => setIsEditEndClientModalOpen(false)}  // onClose 핸들러
        endClient={selectedEndClient}
        clientId={selectedClientId}
        oldClientId={oldClientId}
        clientName={selectedClientName}
      />
    </div>
    );
    };
    
    export default ClientBoard;