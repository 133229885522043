import React from 'react';

const Notes = ({ notes, handleNoteChange, removeNote, addNote }) => {
  return (
    <div className="mt-4">
      {notes.map((note, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="text"
            value={note.text}
            onChange={(e) => handleNoteChange(index, e)}
            className="flex-1 px-2 py-1 border border-gray-300 rounded-md"
          />
          <button type="button" onClick={() => removeNote(index)} className="ml-2 text-red-500">-</button>
        </div>
      ))}
      <button type="button" onClick={addNote} className="text-blue-500">+</button>
    </div>
  );
};

export default Notes;