import { faExpand, faPaw, faTrash } from '@fortawesome/free-solid-svg-icons'; // faExpand 추가
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Rnd } from 'react-rnd';
import { useNavigate } from 'react-router-dom';
import botProfile from '../../assets/moongchi_face.jpg'; // 봇 프로필 이미지 경로
import userProfile from '../../assets/pepe_normal.jpg'; // 사용자 프로필 이미지 경로
import axios from '../../axiosConfig';

function Sidebar3() {
  const [prompt, setPrompt] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [userCount, setUserCount] = useState(0); // 초기값을 0으로 설정
  const [isExpanded, setIsExpanded] = useState(false); // 확대 상태 추가
  const [isDragging, setIsDragging] = useState(false); // 드래그 상태 추가
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const fetchUserCount = async () => {
    const result = await axios.get('/userCount');
    return result.data.count;
  };

  const fetchChatLogs = async () => {
    const result = await axios.get('/chatLogs');
    return result.data;
  };

  const { data: messages = [], refetch } = useQuery('chatLogs', fetchChatLogs);
  const { data: userCountData } = useQuery('userCount', fetchUserCount, {
    refetchInterval: 300000, // 5분마다 유저 수 갱신
  });

  useEffect(() => {
    setUserCount(userCountData || 0);
  }, [userCountData]);

  const clearChatMutation = useMutation(
    async () => {
      await axios.delete('/clearChatLogs');
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('chatLogs');
      },
    }
  );

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handlePromptSubmit = async (e) => {
    e.preventDefault();
    const userMessage = { role: 'user', content: prompt };
    queryClient.setQueryData('chatLogs', (old) => [...old, userMessage]);
    setPrompt('');
    setIsTyping(true);

    try {
      console.log(`Sending prompt to /chat:`, prompt);
      const result = await axios.post('/chat', { prompt });
      console.log('Bot response:', result.data);

      if (result.data.projects) {
        const projectMessages = result.data.projects.map((project, index) => ({
          role: 'bot',
          content: `${index + 1}. <a href="/projects/${project.id}">${project.name}</a>`
        }));
        queryClient.setQueryData('chatLogs', (old) => [
          ...old,
          { role: 'bot', content: result.data.content },
          ...projectMessages,
        ]);
      } else {
        queryClient.setQueryData('chatLogs', (old) => [...old, { role: 'bot', content: result.data.content }]);
      }
    } catch (error) {
      console.error('Error fetching response:', error);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handlePromptSubmit(e);
    }
  };

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleClearChat = async () => {
    try {
      clearChatMutation.mutate();
    } catch (error) {
      console.error('Error clearing chat logs:', error);
    }
  };

  const renderMessage = (message) => {
    if (message.projects) {
      return (
        <div>
          <p className="text-sm">{message.content}</p>
          {message.projects.map((project, index) => (
            <div key={project.id} style={{ textDecoration: 'underline' }}>
              {index + 1}. <a href="#" onClick={() => handleProjectClick(project.id)}>{project.name}</a>
            </div>
          ))}
        </div>
      );
    }
    return <p className="text-sm" dangerouslySetInnerHTML={{ __html: message.content }}></p>;
  };

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleCloseExpand = () => {
    setIsExpanded(false);
  };

  return (
    <>
      <div className={`w-[300px] h-[600px] bg-white rounded-lg shadow-md flex flex-col ${isDragging ? '' : 'sticky bottom-4 right-4'}`}>
        <div className="flex items-center p-4 text-white bg-blue-500 rounded-t-lg shadow-md handle">
          <img src={botProfile} alt="Bot" className="mr-3 rounded-full w-14 h-14" />
          <div className="flex-grow">
            <h2 className="text-lg font-semibold">뭉치와 채팅</h2>
            <p className="text-sm">{userCount}명의 참가자</p>
          </div>
          <div className="flex space-x-3">
            <button onClick={handleClearChat} className="bg-transparent border-none cursor-pointer">
              <FontAwesomeIcon icon={faTrash} className="w-5 h-5 text-white" />
            </button>
            <button onClick={handleExpand} className="bg-transparent border-none cursor-pointer">
              <FontAwesomeIcon icon={faExpand} className="w-5 h-5 text-white" />
            </button>
          </div>
        </div>
        <div className="flex-1 p-4 mb-4 overflow-y-auto">
          {messages.map((message, index) => (
            <div key={index} className={`flex my-2 ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
              {message.role === 'user' ? (
                <>
                  <div className="p-2 rounded-lg max-w-[80%] bg-blue-100">
                    <p className="text-sm">{message.content}</p>
                  </div>
                  <img
                    src={userProfile}
                    alt="User"
                    className="ml-2 rounded-full w-14 h-14"
                  />
                </>
              ) : (
                <>
                  <img
                    src={botProfile}
                    alt="Bot"
                    className="mr-2 rounded-full w-14 h-14"
                  />
                  <div className="p-2 rounded-lg max-w-[80%] bg-gray-100">
                    {renderMessage(message)}
                  </div>
                </>
              )}
            </div>
          ))}
          {isTyping && (
            <div className="flex items-start my-2">
              <img
                src={botProfile}
                alt="Bot"
                className="mr-2 rounded-full w-14 h-14"
              />
              <div className="p-2 rounded-lg bg-gray-100 max-w-[80%]">
                <p className="text-sm typing">...</p>
              </div>
            </div>
          )}
        </div>
        <div className="p-4 bg-blue-300 rounded-b-lg shadow-md">
          <form onSubmit={handlePromptSubmit} className="flex items-center">
            <textarea
              value={prompt}
              onChange={handlePromptChange}
              onKeyPress={handleKeyPress}
              className="flex-grow p-2 border border-gray-100 rounded-full resize-none h-11 focus:outline-none"
              placeholder="뭉치에게 물어보세요"
            />
            <button type="submit" className="h-10 p-2 ml-2 bg-blue-300 rounded-full shadow-md hover:bg-gray-300 focus:outline-none">
              <FontAwesomeIcon icon={faPaw} />
            </button>
          </form>
        </div>
      </div>

      {isExpanded && (
        <Rnd
          default={{
            x: window.innerWidth / 2 - 150,
            y: window.innerHeight / 2 - 300,
            width: 300,
            height: 600,
          }}
          bounds="window"
          dragHandleClassName="handle"
          onDragStart={() => setIsDragging(true)}
          onDragStop={() => setIsDragging(false)}
        >
          <div className="w-[300px] h-[600px] bg-white rounded-lg shadow-md flex flex-col handle">
            <div className="flex items-center p-4 text-white bg-blue-500 rounded-t-lg shadow-md">
              <img src={botProfile} alt="Bot" className="mr-3 rounded-full w-14 h-14" />
              <div className="flex-grow">
                <h2 className="text-lg font-semibold">뭉치와 채팅</h2>
                <p className="text-sm">{userCount}명의 참가자</p>
              </div>
              <div className="flex space-x-3">
                <button onClick={handleClearChat} className="bg-transparent border-none cursor-pointer">
                  <FontAwesomeIcon icon={faTrash} className="w-5 h-5 text-white" />
                </button>
                <button onClick={handleCloseExpand} className="bg-transparent border-none cursor-pointer">
                  <FontAwesomeIcon icon={faExpand} className="w-5 h-5 text-white" />
                </button>
              </div>
            </div>
            <div className="flex-1 p-4 mb-4 overflow-y-auto">
              {messages.map((message, index) => (
                <div key={index} className={`flex my-2 ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}>
                  {message.role === 'user' ? (
                    <>
                      <div className="p-2 rounded-lg max-w-[80%] bg-blue-100">
                        <p className="text-sm">{message.content}</p>
                      </div>
                      <img
                        src={userProfile}
                        alt="User"
                        className="ml-2 rounded-full w-14 h-14"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={botProfile}
                        alt="Bot"
                        className="mr-2 rounded-full w-14 h-14"
                      />
                      <div className="p-2 rounded-lg max-w-[80%] bg-gray-100">
                        {renderMessage(message)}
                      </div>
                    </>
                  )}
                </div>
              ))}
              {isTyping && (
                <div className="flex items-start my-2">
                  <img
                    src={botProfile}
                    alt="Bot"
                    className="mr-2 rounded-full w-14 h-14"
                  />
                  <div className="p-2 rounded-lg bg-gray-100 max-w-[80%]">
                    <p className="text-sm typing">...</p>
                  </div>
                </div>
              )}
            </div>
            <div className="p-4 bg-blue-300 rounded-b-lg shadow-md">
              <form onSubmit={handlePromptSubmit} className="flex items-center">
                <textarea
                  value={prompt}
                  onChange={handlePromptChange}
                  onKeyPress={handleKeyPress}
                  className="flex-grow p-2 border border-gray-100 rounded-full resize-none h-11 focus:outline-none"
                  placeholder="뭉치에게 물어보세요"
                />
                <button type="submit" className="h-10 p-2 ml-2 bg-blue-300 rounded-full shadow-md hover:bg-gray-300 focus:outline-none">
                  <FontAwesomeIcon icon={faPaw} />
                </button>
              </form>
            </div>
          </div>
        </Rnd>
      )}
    </>
  );
}

export default Sidebar3;