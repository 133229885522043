import React, { useEffect } from 'react';
import io from 'socket.io-client';
import Chatbotbar from '../../components/bars/Chatbotbar';
import LoginSidebar from '../../components/bars/LoginSidebar';
import Sidebar2 from '../../components/bars/Sidebar2';
import { useNotifications } from '../../contexts/NotificationContext';

const AsideContainer = () => {
  const { notifications, setNotifications } = useNotifications();

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const socket = io(socketUrl, {
      withCredentials: true,
      transports: ['websocket'],
    });
  
    socket.on('notification_update', (updatedNotifications) => {
      setNotifications(updatedNotifications);
    });
  
    return () => socket.disconnect();
  }, [setNotifications]);

  return (
    <aside className="flex flex-col w-[300px] p-0 m-0 hidden sm:block shadow-sm mt-4">
      <div className="p-4 mb-4 bg-white border border-gray-300 rounded-lg">
        <LoginSidebar />
      </div>
      <div className="p-4 mb-4 bg-white border border-gray-300 rounded-lg">
        <Sidebar2 notifications={notifications} />
      </div>
      <div className="sticky top-0 rounded-lg">
        <Chatbotbar />
      </div>
    </aside>
  );
};

export default AsideContainer;