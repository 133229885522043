import { faMinus, faPlus, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';
import POContent from '../area/POContent';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import { OneButtonModal } from '../components/commons/Modal';
import { formatDate, formatNumber } from '../components/commons/Utils';

const POBoard = () => {
  const [POs, setPOs] = useState([]);
  const [selectedPOs, setSelectedPOs] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('');
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [modalDate, setModalDate] = useState(null);
  const [modalPOId, setModalPOId] = useState('');
  const [modalField, setModalField] = useState('');
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isPaymentRequestedDateModalOpen, setIsPaymentRequestedDateModalOpen] = useState(false);
  const [paymentRequestedDate, setPaymentRequestedDate] = useState(null);
  const [dropdownData, setDropdownData] = useState({
    paymentStatus: [],
    divisions: [],
    groups: []
  });
  const [username, setUsername] = useState('');
  const [authLevel, setAuthLevel] = useState(1);
  const location = useLocation();
  const itemsPerPage = 20;
  const [isRollback, setIsRollback] = useState(false);
  const [collapsedGroups, setCollapsedGroups] = useState({});
  const [employees, setEmployees] = useState([]);
  const [userGroup, setUserGroup] = useState(null);
  const [userDivision, setUserDivision] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [exportOption, setExportOption] = useState('self'); // Default export option
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const [sidebarContent, setSidebarContent] = useState({});

  useEffect(() => {
    const fetchSessionUser = async () => {
      try {
        const response = await axios.get('/session-user');
        const userData = response.data.user;
        setUserGroup(userData.group);
        setUserDivision(userData.division);
        setAuthLevel(userData.authlevel);
      } catch (error) {
        console.error('세션 정보를 가져오는 중 오류 발생:', error);
      }
    };

    fetchSessionUser();
  }, []);

  useEffect(() => {
    const fetchPOs = async () => {
      try {
        const response = await axios.get(`/purchase-orders`);
        setPOs(response.data);
      } catch (error) {
        console.error('Error fetching purchase orders:', error);
      }
    };

    fetchPOs();
  }, []);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(`/getDropdown`);
        const dropdowns = response.data;

        const paymentStatus = dropdowns.find((item) => item.type === 'paymentStatus')?.values || [];
        const divisions = dropdowns.find((item) => item.type === 'division')?.values || [];
        const groups = dropdowns.find((item) => item.type === 'group')?.values || [];

        setDropdownData({
          paymentStatus,
          divisions,
          groups
        });
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('/employees');
        setEmployees(response.data);
      } catch (error) {
        console.error('Error fetching employees:', error);
      }
    };

    fetchEmployees();
  }, []);

  useEffect(() => {
    const fetchSessionUser = async () => {
      try {
        const response = await axios.get('/session-user');
        if (response.data && response.data.user) {
          setUsername(response.data.user.name);
        }
      } catch (error) {
        console.error('Error fetching session user:', error);
      }
    };

    fetchSessionUser();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchParam = params.get('search');
    if (searchParam) {
      setSearchTerm(searchParam);
    }
  }, [location.search]);

  const handleToggleGroupWithCheck = (groupKey) => {
    if (userGroup === groupKey.split('-')[1]) {
      handleToggleGroup(groupKey);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleToggleDivisionWithCheck = (divisionKey) => {
    if (userDivision === divisionKey.split('-')[1]) {
      handleToggleDivision(divisionKey);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedPOs(POs.map(po => po._id));
    } else {
      setSelectedPOs([]);
    }
  };

  const handleSelectPO = (e, id) => {
    if (e.target.checked) {
      setSelectedPOs([...selectedPOs, id]);
    } else {
      setSelectedPOs(selectedPOs.filter(poId => poId !== id));
    }
  };
  
  const handleExport = async () => {
    console.log('Exporting selected POs:', selectedPOs);
    try {
      const response = await axios.post('/exportPOs', {
        po_ids: selectedPOs,
        username: username,
        export_option: exportOption, // Add the selected export option
      }, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `PO_${username}_${new Date().toISOString().split('T')[0]}.xlsx`);
      setIsExportModalOpen(false);
    } catch (error) {
      console.error('Error exporting POs:', error);
    }
  };

  const handlePaymentStatusChange = async (id, value) => {
    try {
      await axios.put('/updatePOPaymentStatus', {
        po_id: id,
        payment_status: value,
      });
      setPOs((prevPOs) =>
        prevPOs.map((po) =>
          po._id === id ? { ...po, payment_status: value } : po
        )
      );
    } catch (error) {
      console.error('Error updating payment status:', error);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchTerm('');
    setPaymentStatusFilter('');
    setPaymentRequestedDate(null);
  };

  const handleOpenModal = (date, id, field, isRollback = false) => {
    setModalDate(date ? new Date(date) : new Date());
    setModalPOId(id);
    setModalField(field);
    setIsDateModalOpen(true);
    setIsRollback(isRollback);
  };

  const handleOpenPaymentRequestedDateModal = (date, id) => {
    setModalDate(date ? new Date(date) : new Date());
    setModalPOId(id);
    setIsPaymentRequestedDateModalOpen(true);
  };

  const handleSaveDate = async () => {
    try {
      let endpoint;
      if (modalField === 'work_started_at') {
        endpoint = '/updatePOWorkStartedDate';
      } else if (modalField === 'work_ended_at') {
        endpoint = '/updatePOWorkEndedDate';
      } else if (modalField === 'payment_received_at') {
        endpoint = '/updatePOPaymentReceivedDate';
      }
      await axios.put(endpoint, {
        po_id: modalPOId,
        date: modalDate,
      });
      setPOs((prevPOs) =>
        prevPOs.map((po) =>
          po._id === modalPOId ? { ...po, [modalField]: modalDate.toISOString() } : po
        )
      );
      setIsDateModalOpen(false);
    } catch (error) {
      console.error('Error updating date:', error);
    }
  };

  const handleSavePaymentRequestedDate = async () => {
    try {
      const po = POs.find(po => po._id === modalPOId);
      const pm_name = po.pm_name;
  
      const requestData = {
        po_id: modalPOId,
        date: modalDate,
        pm_name: pm_name
      };
      const response = await axios.put('/updatePOPaymentRequestedDate', requestData);
      console.log('Response:', response.data);
      setPOs((prevPOs) =>
        prevPOs.map((po) =>
          po._id === modalPOId
            ? { ...po, payment_requested_at: modalDate }
            : po
        )
      );
      setIsPaymentRequestedDateModalOpen(false);
    } catch (error) {
      console.error('Error updating payment requested date:', error.response ? error.response.data : error.message);
    }
  };

  const handleRollbackDate = async () => {
    try {
      const requestData = {
        po_id: modalPOId,
      };
      console.log('Sending rollback request with:', requestData);
      const response = await axios.put('/rollbackPOPaymentRequestedDate', requestData);
      console.log('Response:', response.data);
      setPOs((prevPOs) =>
        prevPOs.map((po) =>
          po._id === modalPOId
            ? { ...po, payment_requested_at: null }
            : po
        )
      );
      setIsDateModalOpen(false);
    } catch (error) {
      console.error('Error rolling back payment requested date:', error.response ? error.response.data : error.message);
    }
  };

  const handleToggleGroup = (groupKey) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [groupKey]: !prev[groupKey],
    }));
  };

  const getEmployeeInfo = (pmName) => {
    const employee = employees.find(emp => emp.name === pmName);
    return employee ? { division: employee.division, group: employee.group } : { division: 'Unknown', group: 'Unknown' };
  };

  const getStatusCountsAndAmounts = (pmPOs) => {
    const statusCounts = pmPOs.reduce((acc, po) => {
      acc[po.payment_status] = (acc[po.payment_status] || 0) + 1;
      return acc;
    }, {});

    const monthlyAmounts = Array.from({ length: 12 }, () => ({}));
    pmPOs.forEach(po => {
      const paymentDate = new Date(po.payment_requested_at);
      const month = paymentDate.getMonth();
      const currency = po.currency || 'KRW';
      if (!monthlyAmounts[month][currency]) {
        monthlyAmounts[month][currency] = 0;
      }
      monthlyAmounts[month][currency] += po.total;
    });

    return { statusCounts, monthlyAmounts };
  };

  const currentMonth = new Date().getMonth();

  const openSidebar = (monthlyAmounts) => {
    setSidebarContent(monthlyAmounts);
    setSidebarIsOpen(true);
  };

  const closeSidebar = () => {
    setSidebarIsOpen(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatRate = (rate) => {
    if (rate == null) return 'TBD';
    const parsedRate = parseFloat(rate);
    if (isNaN(parsedRate)) return 'TBD';
    return parsedRate.toFixed(3);
  };

  const filteredPOs = POs.filter((po) => {
    if (startDate && new Date(po.work_started_at) < startDate) return false;
    if (endDate && new Date(po.work_ended_at) > endDate) return false;
    if (paymentRequestedDate && new Date(po.payment_requested_at) > paymentRequestedDate) return false;
    if (paymentStatusFilter && po.payment_status !== paymentStatusFilter) return false;
    if (searchTerm && !(
      (po.po_number && po.po_number.includes(searchTerm)) ||
      (po.project_name && po.project_name.includes(searchTerm)) ||
      (po.pm_name && po.pm_name.includes(searchTerm)) ||
      (po.worker_name && po.worker_name.includes(searchTerm))
    )) return false;
    return true;
  });

  const groupedPOs = filteredPOs.reduce((groups, po) => {
    const { division, group } = getEmployeeInfo(po.pm_name);
    if (!groups[division]) {
      groups[division] = {};
    }
    if (!groups[division][group]) {
      groups[division][group] = {};
    }
    if (!groups[division][group][po.pm_name]) {
      groups[division][group][po.pm_name] = [];
    }
    groups[division][group][po.pm_name].push(po);
    return groups;
  }, {});

  const totalPages = Math.ceil(filteredPOs.length / itemsPerPage);
  const paginatedPOs = filteredPOs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="relative m-4 overflow-x-auto sm:rounded-lg">
      <POContent projects={Object.values(groupedPOs).flat()} />
      <div className="flex flex-wrap items-center justify-between p-2 py-2 space-y-2 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="table-search-pos"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for POs"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
        {!selectedPOs.length > 0 && (
          <div className="flex space-x-2">
            <select
              className="p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              value={paymentStatusFilter}
              onChange={(e) => setPaymentStatusFilter(e.target.value)}
            >
              <option value="">결제상태</option>
              {dropdownData.paymentStatus.map((status, idx) => (
                <option key={idx} value={status}>
                  {status}
                </option>
              ))}
            </select>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholderText="시작일 (~부터)"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              placeholderText="마감일 (~까지)"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            />
            <DatePicker
              selected={paymentRequestedDate}
              onChange={(date) => setPaymentRequestedDate(date)}
              placeholderText="결제요청일 (~까지)"
              className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            />
            <button
              onClick={handleResetFilters}
              className="px-2 py-1 text-sm font-medium text-white bg-gray-500 rounded-lg hover:bg-gray-600"
            >
              Reset
            </button>
          </div>
        )}
        {selectedPOs.length > 0 && (
          <button onClick={() => setIsExportModalOpen(true)} className="inline-flex items-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-800">
            Export
          </button>
        )}
      </div>
      <table className="w-full text-sm text-left text-gray-500 rounded-lg shadow-md dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={handleSelectAll}
                  checked={selectedPOs.length === POs.length}
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">PM 이름</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">PO No.</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">프로젝트 이름</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">작업자 이름</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">이메일</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">분량</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">단가</th>
              <th scope="col" className="px-6 py-2 min-w-[4em] whitespace-nowrap">작업비</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">결제 상태</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">작업 시작</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">작업 종료</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">결제 요청</th>
              <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">결제 완료</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedPOs).map((division) => (
              <React.Fragment key={division}>
                <tr className="bg-gray-300 dark:bg-gray-800">
                  <td colSpan="16" className="px-4 py-2 cursor-pointer" onClick={() => handleToggleGroup(division)}>
                    <FontAwesomeIcon icon={collapsedGroups[division] ? faPlus : faMinus} className="mr-2" />
                    {division}
                  </td>
                </tr>
                {!collapsedGroups[division] && Object.keys(groupedPOs[division]).map((group) => (
                  <React.Fragment key={group}>
                    <tr className="bg-gray-200 dark:bg-gray-700">
                      <td colSpan="16" className="px-4 py-2 cursor-pointer" onClick={() => handleToggleGroup(`${division}-${group}`)}>
                        <FontAwesomeIcon icon={collapsedGroups[`${division}-${group}`] ? faPlus : faMinus} className="mr-2" />
                        {group}
                      </td>
                    </tr>
                    {!collapsedGroups[`${division}-${group}`] && Object.keys(groupedPOs[division][group]).map((pmName) => {
                      const { statusCounts, monthlyAmounts } = getStatusCountsAndAmounts(groupedPOs[division][group][pmName]);
                      return (
                        <React.Fragment key={pmName}>
                          <tr className="bg-gray-100 dark:bg-gray-600">
                            <td colSpan="16" className="px-4 py-2 cursor-pointer" onClick={() => handleToggleGroupWithCheck(`${division}-${group}-${pmName}`)}>
                              <FontAwesomeIcon icon={!collapsedGroups[`${division}-${group}-${pmName}`] ? faPlus : faMinus} className="mr-2" />
                              {pmName} ({groupedPOs[division][group][pmName].length}) - 
                              {userGroup === group && ( // 같은 그룹 내의 유저에게만 표시
                                <>
                                  {Object.entries(statusCounts).map(([status, count]) => (
                                    <span key={status} className="ml-2">{status}: {count}</span>
                                  ))}
                                  <span className="ml-2">
                                    당월 정산: 
                                    {Object.entries(monthlyAmounts[currentMonth]).map(([currency, amount]) => (
                                      <span key={currency} className="ml-1">{formatNumber(amount, currency)}</span>
                                    ))}
                                  </span>
                                  <button className="ml-2 text-white bg-gray-500 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 font-xs rounded-lg text-xs px-2 py-1.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button" onClick={() => openSidebar(monthlyAmounts)}>
                                    전체
                                  </button>
                                </>
                              )}
                            </td>
                          </tr>
                          {collapsedGroups[`${division}-${group}-${pmName}`] && groupedPOs[division][group][pmName].map((po) => (
                            <tr key={po._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                              <td className="p-4">
                                <div className="flex items-center">
                                  <input
                                    id={`checkbox-${po._id}`}
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    checked={selectedPOs.includes(po._id)}
                                    onChange={(e) => handleSelectPO(e, po._id)}
                                  />
                                  <label htmlFor={`checkbox-${po._id}`} className="sr-only">checkbox</label>
                                </div>
                              </td>
                              <td className="px-4 py-2">{po.pm_name}</td>
                              <td className="px-4 py-2" style={{ width: '120px' }}>{po.po_number}</td>
                              <td className="px-4 py-2 min-w-[4em] whitespace-nowrap" style={{ width: '120px' }}>{po.project_name}</td>
                              <td className="px-4 py-2 min-w-[4em] whitespace-nowrap" style={{ width: '120px' }}>{po.worker_name}</td>
                              <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{po.worker_email}</td>
                              <td className="px-4 py-2 font-bold">{formatNumber(po.volume)}</td>
                              <td className="px-4 py-2">{formatRate(po.rate)}</td>
                              <td className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(po.total, po.currency)}</td>
                              <td className="px-4 py-2">
                                <select
                                  className="p-1 text-xs border border-gray-300 rounded-lg"
                                  value={po.payment_status || dropdownData.paymentStatus[0]}
                                  onChange={(e) => handlePaymentStatusChange(po._id, e.target.value)}
                                >
                                  <option value="">Select Status</option>
                                  {dropdownData.paymentStatus.map((status, idx) => (
                                    <option key={idx} value={status}>
                                      {status}
                                    </option>
                                  ))}
                                </select>
                              </td>
                              <td className="px-4 py-2">
                                {po.work_started_at ? (
                                  formatDate(po.work_started_at)
                                ) : (
                                  <button
                                    className="px-2 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-700"
                                    onClick={() => handleOpenModal(po.work_started_at, po._id, 'work_started_at')}
                                  >
                                    Edit
                                  </button>
                                )}
                              </td>
                              <td className="px-4 py-2">
                                {po.work_ended_at ? (
                                  formatDate(po.work_ended_at)
                                ) : (
                                  <button
                                    className="px-2 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-700"
                                    onClick={() => handleOpenModal(po.work_ended_at, po._id, 'work_ended_at')}
                                  >
                                    Edit
                                  </button>
                                )}
                              </td>
                              <td className="px-4 py-2">
                                {po.payment_requested_at ? (
                                  <div className="flex items-center">
                                    {formatDate(po.payment_requested_at)}
                                    <button
                                      className="ml-2 text-red-500 hover:text-red-700"
                                      onClick={() => handleOpenModal(po.payment_requested_at, po._id, 'payment_requested_at', true)}
                                    >
                                      <FontAwesomeIcon icon={faUndoAlt} />
                                    </button>
                                  </div>
                                ) : (
                                  <button
                                    className={`px-2 py-1 text-xs text-white ${po.work_ended_at ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'} rounded`}
                                    onClick={() => po.work_ended_at && handleOpenPaymentRequestedDateModal(po.payment_requested_at, po._id)}
                                    disabled={!po.work_ended_at}
                                  >
                                    Edit
                                  </button>
                                )}
                              </td>
                              <td className="px-4 py-2">
                                {po.payment_received_at ? (
                                  formatDate(po.payment_received_at)
                                ) : (
                                  <button
                                    className={`px-2 py-1 text-xs text-white ${po.payment_requested_at ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'} rounded`}
                                    onClick={() => po.payment_requested_at && handleOpenModal(po.payment_received_at, po._id, 'payment_received_at')}
                                    disabled={!po.payment_requested_at}
                                  >
                                    Edit
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
            pageNumbers={[...Array(totalPages).keys()].map(i => i + 1)}
          />
          {isExportModalOpen && (
            <OneButtonModal
              id="export-modal"
              title="Export Confirmation"
              body={
                <div>
                  <p>Do you want to export the selected purchase orders?</p>
                  <select
                    value={exportOption}
                    onChange={(e) => setExportOption(e.target.value)}
                    className="block w-full p-2 mt-2 border border-gray-300 rounded"
                  >
                    {authLevel >= 3 && <option value="all">전체</option>}
                    {authLevel >= 2 && <option value="division">본부</option>}
                    {authLevel >= 2 && <option value="group">그룹</option>}
                    <option value="self">본인</option>
                  </select>
                </div>
              }
              onSave={() => {
                handleExport();
                setIsExportModalOpen(false);
              }}
              onClose={() => setIsExportModalOpen(false)}
              isOpen={isExportModalOpen}
            />
          )}
          {isDateModalOpen && (
            <OneButtonModal
              id="date-modal"
              title={isRollback ? "비용지급일 입력 취소" : "날짜 입력"}
              body={
                isRollback ? (
                  <p>정말로 비용지급일 입력을 취소하시겠습니까?</p>
                ) : (
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-400">날짜 선택</label>
                    <input
                      type="date"
                      value={modalDate ? modalDate.toISOString().split('T')[0] : ''}
                      onChange={(e) => setModalDate(new Date(e.target.value))}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  </div>
                )
              }
              onSave={() => {
                if (isRollback) {
                  handleRollbackDate();
                } else {
                  handleSaveDate();
                }
                setIsDateModalOpen(false);
              }}
              onClose={() => setIsDateModalOpen(false)}
              isOpen={isDateModalOpen}
            />
          )}
          {isPaymentRequestedDateModalOpen && (
            <OneButtonModal
              id="payment-requested-date-modal"
              title="날짜 입력"
              body={
                <div>
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-400">날짜 선택</label>
                  <input
                    type="date"
                    value={modalDate ? modalDate.toISOString().split('T')[0] : ''}
                    onChange={(e) => setModalDate(new Date(e.target.value))}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
              }
              onSave={() => {
                handleSavePaymentRequestedDate();
                setIsPaymentRequestedDateModalOpen(false);
              }}
              onClose={() => setIsPaymentRequestedDateModalOpen(false)}
              isOpen={isPaymentRequestedDateModalOpen}
            />
          )}
          {isModalOpen && (
            <OneButtonModal
              id="access-denied-modal"
              title="권한이 없습니다."
              body="다른 그룹원의 PO 항목을 조회 할 수 없습니다."
          onSave={() => {
            closeModal();
            setIsModalOpen(false);
              }}
              onClose={() => setIsModalOpen(false)}
              isOpen={isModalOpen}
            />
          )}
          {sidebarIsOpen && (
            <div id="drawer-navigation" className="fixed top-0 right-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-96 dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-navigation-label">
              <h5 id="drawer-navigation-label" className="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">월별 정산 금액</h5>
              <button type="button" onClick={closeSidebar} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span className="sr-only">Close menu</span>
              </button>
              <div className="py-4 overflow-y-auto">
                <ul className="space-y-2 font-medium">
                  {sidebarContent.map((amounts, month) => (
                    <li key={month}>
                      <strong>{month + 1}월:</strong>
                      {Object.entries(amounts).map(([currency, amount]) => (
                        <span key={currency} className="ml-1">{formatNumber(amount, currency)}</span>
                      ))}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          </div>
          );
          };
          
          export default POBoard;