import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import Pagination from '../../components/bars/Pagination';
import { formatNumber } from '../../components/commons/Utils';

const MyProjectsCard = ({ projects = [], pmName }) => {
  const [expandedProjectIds, setExpandedProjectIds] = useState([]);
  const [expandedWorkerIds, setExpandedWorkerIds] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 5;
  const navigate = useNavigate();
  const [clientsMap, setClientsMap] = useState({});
  const [workersMap, setWorkersMap] = useState({});

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/clients');
        const clientsData = response.data;
        const map = {};
        clientsData.forEach(client => {
          map[client.clientName] = client._id;
        });
        setClientsMap(map);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        const response = await axios.get('/workers'); // 워커 API 엔드포인트에 맞게 수정
        const workersData = response.data;
        const map = {};
        workersData.forEach(worker => {
          map[worker.name] = worker._id;
        });
        setWorkersMap(map);
      } catch (error) {
        console.error('Error fetching workers:', error);
      }
    };

    fetchWorkers();
  }, []);

  const handleWorkerClick = (workerName) => {
    const workerId = workersMap[workerName];
    if (workerId) {
      navigate(`/resource/workers/${workerId}`);
    } else {
      console.error(`Worker ID not found for ${workerName}`);
    }
  };

  const myProjects = useMemo(() => 
    projects.filter(project => 
      project.MainPM === pmName || project.tasks.some(task => task.PMName === pmName)
    ), 
    [projects, pmName]
  );

  const sortedProjects = useMemo(() => {
    let sortableProjects = [...myProjects];
    if (sortConfig.key !== null) {
      sortableProjects.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProjects;
  }, [myProjects, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const toggleExpand = (projectId) => {
    setExpandedProjectIds((prevExpandedProjectIds) =>
      prevExpandedProjectIds.includes(projectId)
        ? prevExpandedProjectIds.filter(id => id !== projectId)
        : [...prevExpandedProjectIds, projectId]
    );
  };

  const toggleWorkerExpand = (taskId) => {
    setExpandedWorkerIds((prevExpandedWorkerIds) =>
      prevExpandedWorkerIds.includes(taskId)
        ? prevExpandedWorkerIds.filter(id => id !== taskId)
        : [...prevExpandedWorkerIds, taskId]
    );
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case '협상':
        return <span className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">협상</span>;
      case '진행':
        return <span className="bg-green-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">진행</span>;
      case '완료':
        return <span className="bg-purple-200 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">완료</span>;
      case '실주':
        return <span className="bg-orange-200 text-orange-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">실주</span>;
      case '중단':
        return <span className="bg-red-200 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">중단</span>;
      default:
        return <span className="bg-blue-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{status}</span>;
    }
  };

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleClientClick = (clientName) => {
    const clientId = clientsMap[clientName];
    if (clientId) {
      navigate(`/resource/clients/${clientId}`);
    } else {
      console.error(`Client ID not found for ${clientName}`);
    }
  };

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = sortedProjects.slice(indexOfFirstProject, indexOfLastProject);
  const totalPages = Math.ceil(sortedProjects.length / projectsPerPage);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mr-2">
      <div className="flex flex-wrap items-center justify-between py-4 space-y-4 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
        <h3 className="pb-1 ml-2 text-lg font-semibold border-b-2 border-blue-500">내 프로젝트</h3>
      </div>
      {sortedProjects.length > 0 ? (
        <>
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('ProjectName')}>
                  프로젝트
                  {sortConfig.key === 'ProjectName' && (
                    <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
                <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('ClientName')}>
                  클라이언트
                  {sortConfig.key === 'ClientName' && (
                    <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
                <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('MainPM')}>
                  담당자
                  {sortConfig.key === 'MainPM' && (
                    <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
                <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('SDate')}>
                  시작일
                  {sortConfig.key === 'SDate' && (
                    <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
                <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('DDate')}>
                  마감일
                  {sortConfig.key === 'DDate' && (
                    <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
                <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('Total')}>
                  총액
                  {sortConfig.key === 'Total' && (
                    <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
                <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('Status')}>
                  상태
                  {sortConfig.key === 'Status' && (
                    <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                  )}
                </th>
                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {currentProjects.map((project) => (
                <React.Fragment key={project._id}>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4 font-bold hover:underline cursor-pointer text-md min-w-[4em] whitespace-nowrap" onClick={() => handleProjectClick(project._id)}>{project.ProjectName}</td>
                    <td className="px-6 py-4 min-w-[4em] whitespace-nowrap cursor-pointer hover:underline" onClick={() => handleClientClick(project.ClientName)}>{project.ClientName}</td>
                    <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.MainPM}</td>
                    <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.SDate}</td>
                    <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.DDate}</td>
                    <td className="px-6 py-4 font-bold min-w-[4em] whitespace-nowrap text-blue-600">
                      {formatNumber(project.Total, project.Currency)}
                    </td>
                    <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{getStatusBadge(project.Status)}</td>
                    <td className="px-6 py-4">
                      <button onClick={() => toggleExpand(project._id)} className={`font-medium hover:underline ${expandedProjectIds.includes(project._id) ? 'text-red-600' : 'text-blue-600'}`}>
                        {expandedProjectIds.includes(project._id) ? '접기' : '펼치기'}
                      </button>
                    </td>
                  </tr>
                  {expandedProjectIds.includes(project._id) && (
                    <tr className="bg-gray-550 dark:bg-gray-700">
                      <td colSpan="8" className="px-6 py-4">
                        <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                          <h4 className="mb-2 text-lg font-semibold">세부 작업</h4>
                          {project.tasks.length > 0 ? (
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">프로젝트 유형</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">PM</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">출발어</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">도착어</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">분량</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단위</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단가</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">총액</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">상태</th>
                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {project.tasks.map((task, index) => (
                                  <React.Fragment key={index}>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                      <td className="px-6 py-4 font-bold text-md min-w-[4em] whitespace-nowrap">{task.ProjectType}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.PMName}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.SLang}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.TLang}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{Number(task.ProjectVolume).toLocaleString()}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.Unit}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{formatNumber(task.Rate, project.Currency)}</td>
                                      <td className="px-6 py-4 font-bold text-blue-600 min-w-[4em] whitespace-nowrap">{formatNumber(task.Total, project.Currency)}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{getStatusBadge(task.Status)}</td>
                                      <td className="px-6 py-4">
                                        <button onClick={() => toggleWorkerExpand(task.id)} className={`font-medium hover:underline ${expandedWorkerIds.includes(task.id) ? 'text-red-600' : 'text-blue-600'}`}>
                                          {expandedWorkerIds.includes(task.id) ? '접기' : '펼치기'}
                                        </button>
                                      </td>
                                    </tr>
                                    {expandedWorkerIds.includes(task.id) && (
                                      <tr className="bg-gray-50 dark:bg-gray-700">
                                        <td colSpan="10">
                                          <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                                            <h4 className="mb-2 text-lg font-semibold">작업자</h4>
                                            {task.Workers && task.Workers.length > 0 ? (
                                              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                                <tr>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">이름</th>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">이메일</th>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">작업 유형</th>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">출발 언어</th>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">도착 언어</th>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단가</th>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">총액</th>
                                                  <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">상태</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {task.Workers.map((worker, idx) => (
                                                  <tr key={idx} className="bg-white border-b hover:bg-gray-50">
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap cursor-pointer hover:underline" onClick={() => handleWorkerClick(worker.name)}>{worker.name}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.email}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.taskType}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.sLang}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.tLang}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{formatNumber(worker.rate, worker.currency)}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(worker.total, worker.currency)}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{getStatusBadge(worker.Status)}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            ) : (
                                              <p>No workers available.</p>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <p>No tasks available.</p>
                          )}
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={Array.from({ length: totalPages }, (_, i) => i + 1)} />
        </>
      ) : (
        <p className="p-4">No projects assigned to you.</p>
      )}
    </div>
  );
};

export default MyProjectsCard;
