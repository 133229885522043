import React, { useMemo, useState } from 'react';
import axios from '../axiosConfig';

const ActivityLog = ({ logs, poId }) => {
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  const handleFileDownload = (filePath, poId) => {
    const link = document.createElement('a');
    link.href = `${axios.defaults.baseURL}/upload/po/${poId}/${filePath.split('/').pop()}`;
    link.download = filePath.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const filteredLogs = useMemo(() => logs.filter(log =>
    (log.worker_name && log.worker_name.toLowerCase().includes(filter.toLowerCase())) ||
    (log.action && log.action.toLowerCase().includes(filter.toLowerCase())) ||
    (log.title && log.title.toLowerCase().includes(filter.toLowerCase())) ||
    (log.project_name && log.project_name.toLowerCase().includes(filter.toLowerCase())) ||
    (log.description && log.description.toLowerCase().includes(filter.toLowerCase()))
  ), [logs, filter]);

  const groupedLogs = useMemo(() => {
    const groups = filteredLogs.reduce((acc, log) => {
      const projectName = log.project_name || 'Unknown Project';
      if (!acc[projectName]) {
        acc[projectName] = [];
      }
      acc[projectName].push(log);
      return acc;
    }, {});

    // Flatten the grouped logs into a single array with project headers
    const flattenedLogs = [];
    Object.keys(groups).forEach((projectName, index) => {
      flattenedLogs.push({ type: 'header', projectName, colorIndex: index });
      flattenedLogs.push(...groups[projectName]);
    });

    return flattenedLogs;
  }, [filteredLogs]);

  const totalPages = Math.ceil(groupedLogs.length / itemsPerPage);
  const currentLogs = groupedLogs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#33FFF2'];

  return (
    <div className="mt-8">
      <div className="bg-white border border-gray-200 rounded-lg shadow-lg p-9 dark:bg-gray-800 dark:border-gray-600">
        <div className="flex items-center justify-between mb-4">
          <h2 className="pb-2 text-2xl font-semibold border-b-2 border-blue-500">작업 정보</h2>
          <input
            type="text"
            placeholder="Search..."
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="p-2 border border-gray-300 rounded-lg"
          />
        </div>
        <ol className="relative mt-4 border-gray-200 border-s dark:border-gray-700">
          {currentLogs.map((log, index) => log.type === 'header' ? (
            <li key={index} className="flex items-center mb-4">
              <div style={{ backgroundColor: colors[log.colorIndex % colors.length] }} className="w-1 h-6 ml-2 mr-2"></div>
              <h3 className="text-xl font-semibold">{log.projectName}</h3>
            </li>
          ) : (
            <li key={index} className="mb-10 ms-6">
              <div className="items-center justify-between p-4 bg-white border border-gray-200 rounded-lg shadow-sm sm:flex dark:bg-gray-700 dark:border-gray-600">
                <time className="mb-1 text-xs font-bold text-gray-400 sm:order-last sm:mb-0">{formatDate(log.date)}</time>
                <div className="text-sm font-normal text-gray-500 dark:text-gray-300">
                  <span className="font-bold">{log.worker_name}</span> has {log.action || 'performed an action on'} <a href="#" className="font-semibold text-blue-600 dark:text-blue-500 hover:underline">{log.title}</a>
                  {log.project_name && (
                    <span className="bg-gray-100 text-gray-800 text-xs font-normal me-2 px-2.5 py-0.5 rounded dark:bg-gray-600 dark:text-gray-300">in {log.project_name}</span>
                  )}
                </div>
              </div>
              {log.description && (
                <div className="p-3 mt-2 text-xs italic font-normal text-gray-500 border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-600 dark:border-gray-500 dark:text-gray-300">
                  {log.description}
                </div>
              )}
              {log.files && log.files.length > 0 && (
                <div className="mt-2">
                  <strong className="text-sm font-normal text-gray-500 dark:text-gray-400">Files:</strong>
                  <ul className="list-disc list-inside">
                    {log.files.map((file, fileIndex) => (
                      <li key={fileIndex} className="text-sm font-normal text-gray-500 dark:text-gray-400">
                        <button
                          onClick={() => handleFileDownload(file.path, log.po_id)}
                          className="text-blue-600 dark:text-blue-400 hover:underline"
                        >
                          {file.path.split('/').pop()}
                        </button>
                        {file.comment && (
                          <span className="ml-2 font-bold">| {file.comment}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ol>
        <div className="flex items-center justify-between mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg font-sm hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700"
          >
            Previous
          </button>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;