import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import * as XLSX from 'xlsx';
import { formatNumber } from '../components/commons/Utils';
import CustomSelect from '../components/commons/modal/CustomSelect';
import ClientRevenueTreemap from './ClientRevenueTreemap';

const ReportBoard = ({ projects = [] }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // SDate 기준으로 프로젝트 정렬
  const sortedProjects = projects.slice().sort((a, b) => new Date(a.SDate) - new Date(b.SDate));

  const filteredProjects = sortedProjects.filter(project => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);
    return startDate.getFullYear() <= selectedYear && endDate.getFullYear() >= selectedYear;
  });

  // 중복되는 계산 로직을 함수로 분리
  const calculateMonthlyData = (projects, key) => {
    return projects.reduce((acc, project) => {
      if (project.Status === '진행' || project.Status === '완료') {
        const startDate = new Date(project.SDate);
        const endDate = new Date(project.DDate);
        const workDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
        const dailyValue = project[key] / workDays;

        for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
          const month = d.getMonth();
          const year = d.getFullYear();
          const key = `${year}-${month}`;
          if (!acc[key]) {
            acc[key] = 0;
          }
          acc[key] += dailyValue;
        }
      }
      return acc;
    }, {});
  };

  const generateSeries = (data) => {
    return Object.keys(data).map(key => {
      const [year, month] = key.split('-').map(Number);
      return {
        x: new Date(year, month, 1).toLocaleString('default', { month: 'short', year: 'numeric' }),
        y: data[key]
      };
    }).sort((a, b) => new Date(a.x) - new Date(b.x));
  };

  const totalInKRWData = calculateMonthlyData(sortedProjects, 'TotalInKRW');
  const totalWorkerCostData = calculateMonthlyData(sortedProjects, 'totalWorkerCost');
  const revenueData = calculateMonthlyData(sortedProjects, 'expectedRevenue');

  const totalInKRWSeries = generateSeries(totalInKRWData);
  const totalWorkerCostSeriesMonthly = generateSeries(totalWorkerCostData);
  const totalRevenueSeries = { name: 'All', data: generateSeries(revenueData) };

  const monthlyData = Array.from({ length: 12 }, (_, i) => ({
    total: 0,
    진행: 0
  }));

  filteredProjects.forEach(project => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);
    const status = project.Status;

    for (let month = 0; month < 12; month++) {
      const monthStart = new Date(selectedYear, month, 1);
      const monthEnd = new Date(selectedYear, month + 1, 0);

      if (startDate <= monthEnd && endDate >= monthStart) {
        monthlyData[month].total += 1;
        if (status === '진행') {
          monthlyData[month].진행 += 1;
        }
      }
    }
  });

  const monthlySeries = monthlyData.map((data, month) => ({
    x: new Date(selectedYear, month, 1).toLocaleString('default', { month: 'short' }),
    y: data.total,
    goals: [
      {
        name: '진행',
        value: data.진행,
        strokeColor: '#00E396'
      }
    ]
  }));

  // 본부별 프로젝트 수 데이터 생성
  const divisionData = filteredProjects.reduce((acc, project) => {
    acc[project.Division] = (acc[project.Division] || 0) + 1;
    return acc;
  }, {});

  const divisionSeries = Object.keys(divisionData).map(division => ({
    name: division,
    data: divisionData[division]
  }));

  // 본부별 revenue 데이터 생성
  const divisionRevenueData = sortedProjects.reduce((acc, project) => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);
    const status = project.Status;
    const division = project.Division;
    if (status === '진행' || status === '완료') {
      const workDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      const dailyRevenue = project.expectedRevenue / workDays;
      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const month = d.getMonth();
        const year = d.getFullYear();
        const key = `${year}-${month}`;
        if (!acc[division]) {
          acc[division] = {};
        }
        if (!acc[division][key]) {
          acc[division][key] = 0;
        }
        acc[division][key] += dailyRevenue;
      }
    }
    return acc;
  }, {});

  const divisionRevenueSeries = Object.keys(divisionRevenueData).map(division => ({
    name: division,
    data: Object.keys(divisionRevenueData[division]).map(key => {
      const [year, month] = key.split('-').map(Number);
      return {
        x: new Date(year, month, 1).toLocaleString('default', { month: 'short', year: 'numeric' }),
        y: divisionRevenueData[division][key]
      };
    }).sort((a, b) => new Date(a.x) - new Date(b.x)) // 월별로 정렬
  }));

  // 그룹별 데이터 생성
  const groupData = sortedProjects.reduce((acc, project) => {
    const group = project.Group;
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(project);
    return acc;
  }, {});

  // PM별 데이터 생성 (tasks 배열의 PMName 기준)
  const pmData = sortedProjects.reduce((acc, project) => {
    project.tasks.forEach(task => {
      const pm = task.PMName;
      if (!acc[pm]) {
        acc[pm] = [];
      }
      acc[pm].push(project);
    });
    return acc;
  }, {});

  // 라인 색상 설정
  const colors = ['#00E396', '#FF4560', '#775DD0', '#FEB019', '#00E396', '#775DD0', '#FF4560'];

  // xaxis의 categories 설정
  const categories = Array.from({ length: 12 }, (_, i) => new Date(selectedYear, i, 1).toLocaleString('default', { month: 'short' }));

  // 사용 가능한 년도 목록 생성
  const years = Array.from(new Set(projects.map(project => new Date(project.SDate).getFullYear())));

  // 엑셀 파일로 내보내기
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // 월별 프로젝트 수 시트 추가
    const monthlySheetData = [['Month', 'Total Projects', 'In Progress']];
    monthlySeries.forEach(data => {
      monthlySheetData.push([data.x, data.y, data.goals[0].value]);
    });
    const monthlySheet = XLSX.utils.aoa_to_sheet(monthlySheetData);
    XLSX.utils.book_append_sheet(wb, monthlySheet, 'Monthly Projects');

    // 그룹별 데이터 시트 추가
    Object.keys(groupData).forEach(group => {
      const groupSheetData = [['Project Name', 'Start Date', 'End Date', 'Total Cost', 'Expected Revenue']];
      groupData[group].forEach(project => {
        groupSheetData.push([project.ProjectName, project.SDate, project.DDate, project.Total, project.expectedRevenue]);
      });
      const groupSheet = XLSX.utils.aoa_to_sheet(groupSheetData);
      XLSX.utils.book_append_sheet(wb, groupSheet, `Group - ${group}`);
    });

    // PM별 데이터 시트 추가
    Object.keys(pmData).forEach(pm => {
      const pmSheetData = [['Project Name', 'Start Date', 'End Date', 'Total Cost', 'Expected Revenue']];
      pmData[pm].forEach(project => {
        pmSheetData.push([project.ProjectName, project.SDate, project.DDate, project.Total, project.expectedRevenue]);
      });
      const pmSheet = XLSX.utils.aoa_to_sheet(pmSheetData);
      XLSX.utils.book_append_sheet(wb, pmSheet, `PM - ${pm}`);
    });

    // 엑셀 파일 저장
    XLSX.writeFile(wb, `Projects_${selectedYear}.xlsx`);
  };

  // clientData 정의
  const clientData = filteredProjects.reduce((acc, project) => {
    const client = project.ClientName;
    if (!acc[client]) {
      acc[client] = 0;
    }
    acc[client] += 1;
    return acc;
  }, {});

  // 클라이언트별 수익 데이터 계산 (연도별로)
  const calculateClientRevenueData = (projects) => {
    const clientRevenueData = {};

    projects.forEach(project => {
      const startDate = new Date(project.SDate);
      const endDate = new Date(project.DDate);
      const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
      const dailyRevenue = project.expectedRevenue / totalDays;

      for (let year = startDate.getFullYear(); year <= endDate.getFullYear(); year++) {
        const yearStart = new Date(Math.max(startDate, new Date(year, 0, 1)));
        const yearEnd = new Date(Math.min(endDate, new Date(year, 11, 31)));
        const daysInYear = (yearEnd - yearStart) / (1000 * 60 * 60 * 24) + 1;
        const revenueInYear = dailyRevenue * daysInYear;

        if (!clientRevenueData[year]) {
          clientRevenueData[year] = {};
        }
        if (!clientRevenueData[year][project.ClientName]) {
          clientRevenueData[year][project.ClientName] = 0;
        }
        clientRevenueData[year][project.ClientName] += revenueInYear;
      }
    });

    return clientRevenueData;
  };

  const clientRevenueData = calculateClientRevenueData(projects);

  return (
    <div className="w-full p-4 mb-2 text-center bg-white" style={{ height: 'auto' }}>
      <div className="grid grid-cols-1 gap-4 mt-0 md:grid-cols-2 lg:grid-cols-3">
        {projects.length > 0 ? (
          <>
            <div className="col-span-3 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
                <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">전체 프로젝트</h2>
                <div className="grid grid-cols-3 gap-4">
                  <Chart
                    options={{
                      chart: { type: 'area', sparkline: { enabled: true }, group: 'sparklines' },
                      stroke: { curve: 'straight' },
                      fill: { opacity: 1 },
                      colors: ['#DCE6EC'],
                      tooltip: {
                        shared: true,
                        x: {
                          formatter: (val, { dataPointIndex }) => totalInKRWSeries[dataPointIndex].x // 툴팁에 년월 표시
                        },
                        y: {
                          formatter: (val) => formatNumber(val, 'KRW')
                        }
                      },
                      title: {
                        text: formatNumber(totalInKRWSeries.reduce((acc, item) => acc + item.y, 0), 'KRW'),
                        offsetX: 30,
                        style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title' }
                      },
                      subtitle: {
                        text: '매출',
                        offsetX: 30,
                        style: { fontSize: '14px', cssClass: 'apexcharts-yaxis-title' }
                      },
                      xaxis: {
                        categories: totalInKRWSeries.map(item => item.x), // 년월 표시를 위한 categories 설정
                        labels: {
                          rotate: -45, // 날짜를 대각선으로 출력
                        }
                      }
                    }}
                    series={[{ name: 'TotalInKRW', data: totalInKRWSeries.map(item => item.y) }]}
                    type="area"
                    height={160}
                  />
                  <Chart
                    options={{
                      chart: { type: 'area', sparkline: { enabled: true }, group: 'sparklines' },
                      stroke: { curve: 'straight' },
                      fill: { opacity: 1 },
                      colors: ['#DCE6EC'],
                      tooltip: {
                        shared: true,
                        x: {
                          formatter: (val, { dataPointIndex }) => totalWorkerCostSeriesMonthly[dataPointIndex].x // 툴팁에 년월 표시
                        },
                        y: {
                          formatter: (val) => formatNumber(val, 'KRW')
                        }
                      },
                      title: {
                        text: formatNumber(totalWorkerCostSeriesMonthly.reduce((acc, item) => acc + item.y, 0), 'KRW'),
                        offsetX: 30,
                        style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title' }
                      },
                      subtitle: {
                        text: '작업비용',
                        offsetX: 30,
                        style: { fontSize: '14px', cssClass: 'apexcharts-yaxis-title' }
                      },
                      xaxis: {
                        categories: totalWorkerCostSeriesMonthly.map(item => item.x), // 년월 표시를 위한 categories 설정
                        labels: {
                          rotate: -45, // 날짜를 대각선으로 출력
                        }
                      }
                    }}
                    series={[{ name: 'TotalWorkerCost', data: totalWorkerCostSeriesMonthly.map(item => item.y) }]}
                    type="area"
                    height={160}
                  />
                  <Chart
                    options={{
                      chart: { type: 'area', sparkline: { enabled: true }, group: 'sparklines' },
                      stroke: { curve: 'straight' },
                      fill: { opacity: 1 },
                      colors: ['#008FFB'],
                      tooltip: {
                        shared: true,
                        x: {
                          formatter: (val, { dataPointIndex }) => totalRevenueSeries.data[dataPointIndex].x // 툴팁에 년월 표시
                        },
                        y: {
                          formatter: (val) => formatNumber(val, 'KRW')
                        }
                      },
                      title: {
                        text: formatNumber(totalRevenueSeries.data.reduce((acc, item) => acc + item.y, 0), 'KRW'),
                        offsetX: 30,
                        style: { fontSize: '24px', cssClass: 'apexcharts-yaxis-title' }
                      },
                      subtitle: {
                        text: '이익',
                        offsetX: 30,
                        style: { fontSize: '14px', cssClass: 'apexcharts-yaxis-title' }
                      },
                      xaxis: {
                        categories: totalRevenueSeries.data.map(item => item.x), // 년월 표시를 위한 categories 설정
                        labels: {
                          rotate: -45, // 날짜를 대각선으로 출력
                        }
                      }
                    }}
                    series={[{ name: 'Revenue', data: totalRevenueSeries.data.map(item => item.y) }]}
                    type="area"
                    height={160}
                  />
                </div>
              </div>
              <div className="col-span-1 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
                <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">월간 프로젝트</h2>
                <div className="mb-2">
                  <CustomSelect
                    value={{ value: selectedYear, label: selectedYear }}
                    onChange={(option) => setSelectedYear(option.value)}
                    options={years.map(year => ({ value: year, label: year }))}
                  />
                </div>
                <Chart
                  options={{
                    chart: {
                      height: 350,
                      type: 'line',
                      stacked: false
                    },
                    stroke: {
                      width: [0, 4]
                    },
                    dataLabels: {
                      enabled: true,
                      enabledOnSeries: [1]
                    },
                    xaxis: {
                      categories: categories,
                      labels: {
                        rotate: -45 // 날짜를 대각선으로 출력
                      }
                    },
                    yaxis: [
                      {
                        title: {
                          text: 'Projects',
                          style: {
                            color: '#008FFB',
                          }
                        },
                        labels: {
                          style: {
                            colors: '#008FFB',
                          }
                        }
                      },
                      
                    ],
                    tooltip: {
                      shared: true,
                      intersect: false,
                      x: {
                        formatter: (val) => `Month: ${val}`
                      },
                    },
                    legend: {
                      horizontalAlign: 'left',
                      offsetX: 40
                    }
                  }}
                  
                  series={[
                    {
                      name: '전체 프로젝트',
                      type: 'column',
                      data: monthlySeries.map(item => item.y)
                    },
                    {
                      name: '진행 중인 프로젝트',
                      type: 'line',
                      data: monthlySeries.map(item => item.goals[0].value)
                    }
                  ]}
                  type="line"
                  height={300}
                />
              </div>
            <div className="col-span-2 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="flex items-center justify-between mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">
                월간 이익
                <button onClick={exportToExcel} className="px-2 py-1 mb-1 text-sm text-white bg-blue-500 rounded">Export</button>
              </h2>
              <Chart
                options={{
                  chart: { type: 'line' },
                  xaxis: { 
                    type: 'category', 
                    categories: Array.from({ length: 12 }, (_, i) => new Date(new Date().getFullYear(), i, 1).toLocaleString('default', { year: '2-digit', month: '2-digit' })),
                    labels: {
                      rotate: -45 // 날짜를 대각선으로 출력
                    }
                  },
                  yaxis: {
                    labels: {
                      formatter: (value) => formatNumber(value, 'KRW')
                    }
                  },
                  stroke: {
                    curve: 'smooth',
                    width: 2.5,
                    lineCap: 'round' // 라인 그래프 끝을 둥글게 설정
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function (val, { seriesIndex }) {
                      return formatNumber(val, 'KRW');
                    },
                    offsetY: -10,
                    style: {
                      fontSize: '10px',
                      colors: colors // 데이터 레이블 색상 설정
                    }
                  },
                  colors: colors, // 라인 색상 설정
                  markers: {
                    size: 3 // 마커 크기 설정
                  }
                }}
                series={[totalRevenueSeries, ...divisionRevenueSeries]}
                type="line"
                height={350}
              />
            </div>
            <div className="col-span-1 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">클라이언트</h2>
              <div className="mb-2">
                <CustomSelect
                  value={{ value: selectedYear, label: selectedYear }}
                  onChange={(option) => setSelectedYear(option.value)}
                  options={years.map(year => ({ value: year, label: year }))}
                />
              </div>
              <Chart
                options={{
                  chart: { type: 'pie', width: '100%', height: 400 },
                  dataLabels: { enabled: false },
                  plotOptions: {
                    pie: {
                      customScale: 0.8,
                      donut: { size: '75%' },
                      offsetY: 20,
                    },
                    stroke: { colors: undefined }
                  },
                  colors: ['#00E396', '#FF4560', '#775DD0', '#FEB019', '#00E396'],
                  series: Object.values(clientData),
                  labels: Object.keys(clientData),
                  legend: {
                    position: 'left',
                    offsetY: 80
                  }
                }}
                series={Object.values(clientData)}
                type="pie"
                height={400}
              />
            </div>
            
            <div className="col-span-2 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">클라이언트 매출 트리맵</h2>
                <div className="mb-2">
                <CustomSelect
                  value={{ value: selectedYear, label: selectedYear }}
                  onChange={(option) => setSelectedYear(option.value)}
                  options={years.map(year => ({ value: year, label: year }))}
                />
              </div>
              <ClientRevenueTreemap clientRevenueData={clientRevenueData[selectedYear] || {}} />
            </div>
          
          </>
        ) : (
          <p>No projects available.</p>
        )}
      </div>
    </div>
  );
};

export default ReportBoard;
