import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import axios from '../../../axiosConfig';
import { TwoButtonModal } from '../Modal';
import CustomSelect from './CustomSelect';

const EditEndClientModal = ({ isOpen, onClose, endClient, clientId, oldClientId }) => {
  const queryClient = useQueryClient();
  const initialFormData = {
    _id: '', // _id 추가
    clientId: clientId || '',  // 클라이언트 ID 설정
    endClientName: '',
    endClientEmail: '',
    endClientMobile: '',
    endClientMemo: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [clients, setClients] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [newClientId, setNewClientId] = useState(clientId); // newClientId 상태 추가

  const fetchClients = async () => {
    try {
      const response = await axios.get('/clients');
      setClients(response.data.map(client => ({ value: client._id, label: client.clientName })));
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setFormData(endClient ? { ...endClient, clientId: clientId || endClient.clientId || '' } : initialFormData);
      setNewClientId(clientId); // 모달이 열릴 때 newClientId를 초기화
    }
  }, [isOpen, endClient, clientId]);

  useEffect(() => {
    fetchClients(); // Call the fetchClients function here
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOption) => {
    if (name === 'clientId') {
      setNewClientId(selectedOption ? selectedOption.value : ''); // 클라이언트 변경 시 newClientId 업데이트
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleSave = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      const dataToSend = {
        ...formData,
        oldClientId: oldClientId,
        newClientId: newClientId || oldClientId, // 클라이언트 변경이 없으면 oldClientId 사용
      };

      await axios.put(`/endClients/${formData._id}`, dataToSend);
      queryClient.invalidateQueries('clients'); // 쿼리 무효화
      onClose();
    } catch (error) {
      console.error('Error updating end client:', error);
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  const handleClose = () => {
    setFormData(initialFormData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleClose}>
        <div className="relative w-full max-w-2xl p-4 bg-white rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
          <button onClick={handleClose} className="absolute text-gray-500 top-4 right-4 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="mb-4 text-xl font-bold">클라이언트 수정</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="mb-4">
                <label className="block text-gray-700">클라이언트</label>
                <CustomSelect
                  name="clientId"
                  value={clients.find(option => option.value === formData.clientId)}
                  onChange={(option) => handleSelectChange('clientId', option)}
                  options={clients}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">엔드 클라이언트 이름</label>
                <input type="text" name="endClientName" value={formData.endClientName} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">이메일</label>
                <input type="email" name="endClientEmail" value={formData.endClientEmail} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">연락처</label>
                <input type="text" name="endClientMobile" value={formData.endClientMobile} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Memo</label>
                <input type="text" name="endClientMemo" value={formData.endClientMemo} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={handleClose} className="px-4 py-2 mr-2 bg-gray-300 rounded">Cancel</button>
              <button type="button" onClick={handleSave} className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>
            </div>
          </form>
        </div>
      </div>
      {isConfirmModalOpen && (
        <TwoButtonModal
          title="Confirm Save"
          body="Are you sure you want to save the changes?"
          onSave={handleConfirmSave}
          onClose={() => setIsConfirmModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditEndClientModal;