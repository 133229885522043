import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import Pagination from '../../components/bars/Pagination';
import EditUserModal from '../../components/commons/modal/EditUserModal';

const EmployeeBoard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true); // 로딩 상태 추가
  const [error, setError] = useState(null); // 에러 상태 추가
  const employeesPerPage = 10;
  const navigate = useNavigate();

  const fetchEmployees = async (retry = 3) => {
      try {
        setLoading(true); // 로딩 시작
        const response = await axios.get('/employees');
        setEmployees(response.data);
      setError(null); // 에러 초기화
      } catch (error) {
        console.error('Error fetching employees:', error);
      setError(error);
      if (retry > 0) {
        setTimeout(() => fetchEmployees(retry - 1), 1000); // 1초 후 재시도
      }
      } finally {
        setLoading(false); // 로딩 종료
      }
    };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredEmployees = employees.filter((employee) =>
    employee.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.phone.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.division.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.group.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
    employee.status.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastEmployee = currentPage * employeesPerPage;
  const indexOfFirstEmployee = indexOfLastEmployee - employeesPerPage;
  const currentEmployees = filteredEmployees.slice(indexOfFirstEmployee, indexOfLastEmployee);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredEmployees.length / employeesPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleEditUser = (user) => {
    setSelectedUser(user);
    setIsModalOpen(true);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const handleNameClick = (employeeName) => {
    // 직원의 이름을 URL로 전달
    navigate(`/employee/${employeeName}`);
  };

  const handleDivisionClick = (divisionName) => {
    // 본부 이름을 URL로 전달
    navigate(`/division/${divisionName}`);
  };

  const handleGroupClick = (groupName) => {
    // 그룹 이름을 URL로 전달
    if (groupName) {
      navigate(`/group/${groupName}`);
    } else {
      console.error('Group name is missing');
    }
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex flex-wrap items-center justify-between py-4 space-y-4 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
        <div className="relative ml-4">
          <div className="absolute inset-y-0 flex items-center pointer-events-none rtl:inset-r-0 start-0 ps-3">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
          <input
            type="text"
            id="table-search-users"
            className="block pt-2 text-sm text-gray-900 border border-gray-300 rounded-lg ps-10 w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for users"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center py-10">
          <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-all-search"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                </div>
              </th>
              <th scope="col" className="px-4 py-3">
                이름
              </th>
              <th scope="col" className="px-4 py-3">
                Email
              </th>
              <th scope="col" className="px-4 py-3">
                연락처
              </th>
              <th scope="col" className="px-4 py-3">
                본부
              </th>
              <th scope="col" className="px-4 py-3">
                그룹
              </th>
              <th scope="col" className="px-4 py-3">
                직책
              </th>
              <th scope="col" className="px-4 py-3">
                근무상태
              </th>
              <th scope="col" className="px-4 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {currentEmployees.map((employee) => {
              const apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
              const imagePath = employee.image ? `${apiUrl}/upload/profile_pics/${employee.image}` : "https://via.placeholder.com/40";
              return (
                <tr key={employee._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="w-4 p-4">
                    <div className="flex items-center">
                      <input
                        id={`checkbox-table-search-${employee._id}`}
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        checked={selectAll}
                        onChange={handleSelectAll} // 추가된 onChange 핸들러
                      />
                      <label htmlFor={`checkbox-table-search-${employee._id}`} className="sr-only">checkbox</label>
                    </div>
                  </td>
                  <th scope="row" className="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white">
                    <img className="w-10 h-10 rounded-full" src={imagePath} alt={`${employee.name} image`} />
                    <div className="ps-3">
                      <div className="text-base font-semibold cursor-pointer" onClick={() => handleNameClick(employee.name)}>{employee.name}</div>
                      <div className="font-normal text-gray-500">{employee.email}</div>
                    </div>
                  </th>
                  <td className="px-4 py-4">
                    {employee.email}
                  </td>
                  <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">
                    {employee.phone}
                  </td>
                  <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">
                    <div className="text-blue-600 cursor-pointer" onClick={() => handleDivisionClick(employee.division)}>{employee.division}</div>
                  </td>
                  <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">
                    <div className="text-blue-600 cursor-pointer" onClick={() => handleGroupClick(employee.group)}>{employee.group}</div>
                  </td>
                  <td className="px-4 py-4">
                    {employee.position}
                  </td>
                  <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">
                    <div className="flex items-center">
                      <div className={`h-2.5 w-2.5 rounded-full ${employee.status === '재직중' ? 'bg-green-500' : 'bg-red-500'} me-2`}></div> {employee.status}
                    </div>
                  </td>
                  <td className="px-4 py-4 min-w-[4em] whitespace-nowrap">
                    <button onClick={() => handleEditUser(employee)} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">수정</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageNumbers={pageNumbers}
      />
      <EditUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} user={selectedUser} />
    </div>
  );
};

export default EmployeeBoard;