import React, { useContext, useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useDropzone } from 'react-dropzone';
import { FaComments, FaDownload, FaEdit, FaFileAlt, FaFileExcel, FaFileExport, FaFileImage, FaFilePdf, FaFileWord, FaTasks, FaTimesCircle, FaVideo } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { io } from 'socket.io-client';
import BoardTitle from '../area/BoardTitle';
import axios from '../axiosConfig';
import CustomScrollbars from '../components/commons/CustomScrollbars';
import { OneButtonModal } from '../components/commons/Modal';
import { formatNumber } from '../components/commons/Utils';
import CustomSelect from '../components/commons/modal/CustomSelect';
import EditProjectModal from '../components/commons/modal/EditProjectModal';
import AsideContainer from '../components/containers/AsideContainer';
import { useCommentModal } from '../contexts/CommentModalContext';
import { SidebarContext } from '../contexts/SidebarContext';
import HashTagModal from './HashTagModal';
import TaskTable from './TaskTable';


const fetchTags = async () => {
  const response = await axios.get('/getDropdownByType?type=tags');
  return response.data.data[0]?.values || [];
};

const fetchProjectDetails = async (projectId) => {
  const response = await axios.get(`/projects/${projectId}`);
  return response.data;
};



const ProjectDetails = () => {
  const { isCollapsed } = useContext(SidebarContext);
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [data, setData] = useState(null);
  const { data: queryData, isLoading, error } = useQuery(['projectDetails', projectId], () => fetchProjectDetails(projectId), {
    refetchOnWindowFocus: false,
  });
  const [tags, setTags] = useState([]);
  const { refetch: refetchTags } = useQuery('tags', fetchTags, {
    onSuccess: (data) => setTags(data),
  });
  const [workers, setWorkers] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedWorker, setSelectedWorker] = useState({});
  const [expandedTaskIds, setExpandedTaskIds] = useState([]);
  const [workerVolumes, setWorkerVolumes] = useState({});
  const [workerRates, setWorkerRates] = useState({});
  const [workerContents, setWorkerContents] = useState({});
  const [poList, setPoList] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [previousSelectedWorker, setPreviousSelectedWorker] = useState({});
  const [clientEmail, setClientEmail] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [sessionUser, setSessionUser] = useState(null);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [showRedDot, setShowRedDot] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
  const { openCommentModal, closeCommentModal, isCommentModalOpen } = useCommentModal();
  const [isHashTagModalOpen, setIsHashTagModalOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [loading, setLoading] = useState(false);




const getStatusBadge = (status) => {
  switch (status) {
    case '협상':
      return <span className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">협상</span>;
    case '진행':
      return <span className="bg-green-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">진행</span>;
    case '완료':
      return <span className="bg-purple-200 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">완료</span>;
    case '실주':
      return <span className="bg-orange-200 text-orange-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">실주</span>;
    case '중단':
      return <span className="bg-red-200 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">중단</span>;
    default:
      return <span className="bg-blue-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{status}</span>;
  }
};

useEffect(() => {
  if (queryData) {
    setData(queryData);
    setSelectedTags(queryData.tags || []);
  }
}, [queryData]);

useEffect(() => {
  const fetchTags = async () => {
    try {
      const response = await axios.get('/getDropdownByType?type=tags');
      setTags(response.data.data[0]?.values || []);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  fetchTags();
}, []);

const handleAddTag = async () => {
  try {
    const response = await axios.post('/saveDropdown', { type: 'tags', value: newTag });
    setTags([...tags, newTag]);
    setNewTag('');
    setIsHashTagModalOpen(false);
    refetchTags();
  } catch (error) {
    console.error('Error adding tag:', error);
  }
};

const handleApplyTag = async (tag) => {
  if (!selectedTags.includes(tag)) {
    setSelectedTags([...selectedTags, tag]);
    setLoading(true);
    try {
      await retryRequest(() => axios.post(`/projects/${projectId}/addTag`, { tag }));
    } catch (error) {
      console.error('Error applying tag:', error);
    } finally {
      setLoading(false);
    }
  }
};

const handleRemoveTag = async (tagToRemove) => {
  setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
  setLoading(true);
  try {
    await retryRequest(() => axios.post(`/projects/${projectId}/removeTag`, { tag: tagToRemove }));
  } catch (error) {
    console.error('Error removing tag:', error);
  } finally {
    setLoading(false);
  }
};

const retryRequest = async (requestFunc, retries = 3, delay = 1000) => {
  for (let i = 0; i < retries; i++) {
    try {
      return await requestFunc();
    } catch (error) {
      if (i < retries - 1) {
        await new Promise(resolve => setTimeout(resolve, delay));
      } else {
        throw error;
      }
    }
  }
};

useEffect(() => {
  const fetchProjectDetails = async () => {
    try {
      const response = await axios.get(`/projects/${projectId}`);
      setData(response.data);
    } catch (error) {
      console.error('프로젝트 정보를 불러오는 중 오류 발생:', error);
    }
  };

  fetchProjectDetails();
}, [projectId]);


const fetchWorkers = async () => {
  const response = await axios.get(`/workers`);
  return response.data;
};

const fetchPOList = async (projectId) => {
  const response = await axios.get(`/getPOList`, {
    params: { project_id: projectId }
  });
  return response.data.po_list;
};

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

  const debouncedWorkerVolumes = useDebounce(workerVolumes, 2000);
  const debouncedWorkerRates = useDebounce(workerRates, 2000);

  const saveProjectDetails = useMutation(
    async ({ projectId, totalWorkerCost, expectedRevenue, exchangeRate }) => {
      await axios.put(`/saveProjectDetails/${projectId}`, {
        totalWorkerCost,
        expectedRevenue,
        exchangeRate,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  useEffect(() => {
    if (data) {
      const { tasks } = data;

      const totalWorkerCost = tasks?.reduce((acc, task) => {
        const taskWorkerCost = task.Workers?.reduce((taskAcc, worker) => {
          const volume = debouncedWorkerVolumes[`${task.id}-${worker._id}`] || worker.volume || 0;
          const rate = debouncedWorkerRates[`${task.id}-${worker._id}`] || worker.rate || 0;
          const cost = volume * rate;
          const costInKRW = worker.currency !== 'KRW' ? cost * worker.exchangeRate : cost;
          return taskAcc + costInKRW;
        }, 0) || 0;
        return acc + taskWorkerCost;
      }, 0) || 0;

      let total = data.Total;
      if (data.Currency !== 'KRW') {
        total = data.TotalInKRW;
      }

      const exchangeRate = tasks?.[0]?.Workers?.[0]?.exchangeRate || 1;

      const expectedRevenue = total - totalWorkerCost;

      if (
        Math.abs(totalWorkerCost - data.totalWorkerCost) > 0.01 ||
        Math.abs(expectedRevenue - data.expectedRevenue) > 0.01 ||
        Math.abs(exchangeRate - data.exchangeRate) > 0.01
      ) {
        saveProjectDetails.mutate({ projectId, totalWorkerCost, expectedRevenue, exchangeRate });
      }
    }
  }, [debouncedWorkerVolumes, debouncedWorkerRates, data]);

  useEffect(() => {
    const getWorkers = async () => {
      const workerData = await fetchWorkers();
      setWorkers(workerData);
    };
    getWorkers();
  }, []);

  useEffect(() => {
    if (data && data.ClientPM && data.pm) {
      const selectedPM = data.pm.find(pm => pm.pmName === data.ClientPM);
      if (selectedPM) {
        setClientEmail(selectedPM.pmEmail);
        setClientPhone(selectedPM.pmMobile);
      }
    }
  }, [data]);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`/getContent`, {
          params: { project_id: projectId }
        });
        const contents = response.data.contents;
        const contentMapping = contents.reduce((acc, content, index) => ({
          ...acc,
          [index]: content
        }), {});
        setWorkerContents(contentMapping);
      } catch (error) {
        console.error('Error fetching content:', error);
      }
    };

    if (projectId) {
      fetchContent();
    }
  }, [projectId]);

  useEffect(() => {
    const getPOList = async () => {
      try {
        const poData = await fetchPOList(projectId);
        setPoList(poData);
      } catch (error) {
        console.error('Error fetching PO list:', error);
      }
    };

    if (projectId) {
      getPOList();
    }
  }, [projectId]);

  useEffect(() => {
    const fetchSessionUser = async () => {
      try {
        const response = await axios.get('/session-user');
        if (response.data && response.data.user) {
          setSessionUser(response.data.user);
        }
      } catch (error) {
        console.error('Error fetching session user:', error);
      }
    };

    fetchSessionUser();
  }, []);

  

  useEffect(() => {
    if (data && sessionUser) {
      const isAuthorized = sessionUser.authLevel >= 2 || data.MainPM === sessionUser.name || (data.tasks && data.tasks.some(task => task.PMName === sessionUser.name));
      if (!isAuthorized) {
        setIsPermissionModalOpen(true);
      }
    }
  }, [data, sessionUser]);

  const assignWorkerMutation = useMutation(
    async ({ taskIndex, worker }) => {
      await axios.put(`/assignWorker`, {
        project_id: projectId,
        task_index: taskIndex,
        worker: worker
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
        queryClient.invalidateQueries(['poList', projectId]);
      },
    }
  );

  const removeWorkerMutation = useMutation(
    async ({ taskIndex, workerId }) => {
      await axios.put(`/removeWorker`, {
        project_id: projectId,
        task_index: taskIndex,
        worker_id: workerId
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const saveVolumeMutation = useMutation(
    async ({ taskIndex, workerId, volume }) => {
      await axios.put(`/saveVolumeWithCheck`, {
        project_id: projectId,
        task_index: taskIndex,
        worker_id: workerId,
        volume: volume
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const saveRateMutation = useMutation(
    async ({ taskIndex, workerId, rate }) => {
      await axios.put(`/saveRate`, {
        project_id: projectId,
        task_index: taskIndex,
        worker_id: workerId,
        rate: rate
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const saveContentMutation = useMutation(
    async ({ taskIndex, content }) => {
      await axios.put(`/saveContent`, {
        project_id: projectId,
        task_index: taskIndex,
        content: content
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const updateTaskStatusMutation = useMutation(
    async ({ taskId, status }) => {
      await axios.put(`/updateTaskStatus`, {
        task_id: taskId,
        status: status
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const updateWorkerStatusMutation = useMutation(
    async ({ projectId, taskIndex, workerId, status }) => {
      await axios.put(`/updateWorkerStatus`, {
        project_id: projectId,
        task_index: taskIndex,
        worker_id: workerId,
        status: status
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['projectDetails', projectId]);
      },
    }
  );

  const handleTaskStatusChange = (taskId, status) => {
    updateTaskStatusMutation.mutate({ taskId, status });
  };

  const handleWorkerStatusChange = (taskIndex, workerId, status) => {
    updateWorkerStatusMutation.mutate({ projectId, taskIndex, workerId, status });
  };

  const handleAssignWorker = (taskIndex, worker) => {
    if (previousSelectedWorker[taskIndex] !== worker) {
      assignWorkerMutation.mutate({ taskIndex, worker });
      setPreviousSelectedWorker({ ...previousSelectedWorker, [taskIndex]: worker });
    }
  };

  const handleRemoveWorker = (taskIndex, workerId) => {
    removeWorkerMutation.mutate({ taskIndex, workerId });
  };

  const saveVolume = (taskIndex, workerId) => {
    let volume = workerVolumes[`${taskIndex}-${workerId}`] === '' ? 0 : workerVolumes[`${taskIndex}-${workerId}`];
    const worker = workers.find(worker => worker._id === workerId);
    let rate = workerRates[`${taskIndex}-${workerId}`] || (worker ? worker.rate : 0);

    if (rate === undefined || rate === null) {
      console.error('Rate not found for worker');
      return;
    }

    volume = parseFloat(volume) || 0;
    rate = parseFloat(rate) || 0;

    const total = volume * rate;
    console.log(`Calculated total: ${total} from volume: ${volume} and rate: ${rate}`);
    saveVolumeMutation.mutate({ taskIndex, workerId, volume });
  };

  const saveRate = (taskIndex, workerId) => {
    let rate = workerRates[`${taskIndex}-${workerId}`] === '' ? 0 : workerRates[`${taskIndex}-${workerId}`];
    const worker = workers.find(worker => worker._id === workerId);
    let volume = workerVolumes[`${taskIndex}-${workerId}`] || (worker ? worker.volume : 0);

    if (volume === undefined || volume === null) {
      console.error('Volume not found for worker');
      return;
    }

    volume = parseFloat(volume) || 0;
    rate = parseFloat(rate) || 0;

    const total = volume * rate;
    console.log(`Calculated total: ${total} from volume: ${volume} and rate: ${rate}`);
    saveRateMutation.mutate({ taskIndex, workerId, rate });
  };

  const saveContent = (taskIndex, content) => {
    saveContentMutation.mutate({ taskIndex, content });
    setWorkerContents({ ...workerContents, [taskIndex]: content });
  };


  const getFileIcon = (type) => {
    switch (type) {
      case 'application/pdf':
        return <FaFilePdf className="w-10 h-10 text-red-500" />;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <FaFileWord className="w-10 h-10 text-blue-500" />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <FaFileExcel className="w-10 h-10 text-green-500" />;
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return <FaFileImage className="w-10 h-10 text-green-500" />;
      case 'video/mp4':
      case 'video/avi':
      case 'video/quicktime':
        return <FaVideo className="w-10 h-10 text-purple-500" />;
      case 'application/zip':
        return <FaFileArchive className="w-10 h-10 text-yellow-500" />;
      case 'text/srt':
      case 'application/x-subrip':
      case 'application/smil':
        return <FaFileAlt className="w-10 h-10 text-gray-500" />;
      default:
        return <FaFileAlt className="w-10 h-10 text-gray-500" />;
    }
  };
  
  const handleFileDownload = (fileName) => {
    const link = document.createElement('a');
    link.href = `${apiUrl}/upload/project/${projectId}/${fileName}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleDelete = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
  };
  
  const fetchFiles = async () => {
    try {
      const response = await axios.get(`/projectFiles/${projectId}`);
      const filesWithPreview = response.data.files.map(file => ({
        ...file,
        preview: file.path.match(/\.(jpeg|jpg|gif|png|mp4|avi|mov)$/) ? `${apiUrl}/upload/project/${projectId}/${file.path}` : null,
        type: file.path.match(/\.(jpeg|jpg|gif|png)$/) ? 'image' :
              file.path.match(/\.pdf$/) ? 'application/pdf' :
              file.path.match(/\.xlsx$/) ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' :
              file.path.match(/\.(mp4|avi|mov)$/) ? 'video' :
              file.path.match(/\.zip$/) ? 'application/zip' :
              file.path.match(/\.srt$/) ? 'text/srt' :
              file.path.match(/\.smi$/) ? 'application/smil' :
              'application/octet-stream'
      }));
      setFiles(filesWithPreview);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };
  
  useEffect(() => {
    fetchFiles();
  }, [projectId, apiUrl]);
  
  const onDrop = async (acceptedFiles) => {
    const formData = new FormData();
    formData.append('project_id', projectId);
    acceptedFiles.forEach(file => {
      formData.append('file', file);
    });
  
    try {
      const response = await axios.post('/uploadProjectFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      queryClient.invalidateQueries(['projectDetails', projectId]); 
      fetchFiles(); 
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'text/plain': ['.txt'],
      'video/mp4': ['.mp4'],
      'video/avi': ['.avi'],
      'video/quicktime': ['.mov'],
      'application/zip': ['.zip'],
      'text/srt': ['.srt'],
      'application/x-subrip': ['.srt'],
      'application/smil': ['.smi'],
    },
    onDrop,
  });

  const confirmDelete = () => {
    axios.delete(`/deleteProjectFile/${projectId}/${fileToDelete.name}`)
      .then(() => {
        setFiles(files.filter(f => f.name !== fileToDelete.name));
        setIsDeleteModalOpen(false);
        setFileToDelete(null);
        queryClient.invalidateQueries(['projectDetails', projectId]); // 쿼리 무효화
      })
      .catch(error => {
        console.error('Error deleting file:', error);
      });
  };

  const toggleTaskExpand = (taskId) => {
    setExpandedTaskIds((prevExpandedTaskIds) =>
      prevExpandedTaskIds.includes(taskId)
        ? prevExpandedTaskIds.filter(id => id !== taskId)
        : [...prevExpandedTaskIds, taskId]
    );
  };


  const handleVolumeChange = (taskIndex, workerId, volume) => {
    const parsedVolume = volume === '' ? '' : parseFloat(volume) || 0;
    setWorkerVolumes({
      ...workerVolumes,
      [`${taskIndex}-${workerId}`]: parsedVolume
    });
  };

  const handleRateChange = (taskIndex, workerId, rate) => {
    const parsedRate = rate === '' ? '' : parseFloat(rate) || 0;
    setWorkerRates({
      ...workerRates,
      [`${taskIndex}-${workerId}`]: parsedRate
    });
  };

  const handleContentChange = (taskIndex, content) => {
    setWorkerContents({
      ...workerContents,
      [taskIndex]: content
    });
  };

  const handleExport = async () => {
    try {
      const response = await axios.get(`/exportProject/${projectId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      
      const today = new Date().toISOString().split('T')[0];
      const invoiceNumber = data.Invoice;
      const clientName = data.ClientName;
      const fileName = `견적서_${invoiceNumber}_${clientName}_${today}.xlsx`;
  
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting project:", error);
    }
  };

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const socket = io(socketUrl, {
      withCredentials: true,
      transports: ['websocket'], 
    });
    socket.emit('join', projectId);
  
    socket.on('new_message', (message) => {
      if (sessionUser && message.user !== sessionUser.name) {
        setShowRedDot(true);
      }
    });
  
    return () => {
      socket.emit('leave', projectId);
      socket.disconnect();
    };
  }, [projectId, sessionUser]);

  useEffect(() => {
    const checkUnreadMessages = async () => {
      if (!sessionUser) return;

      try {
        const response = await axios.get(`/projects/${projectId}/chats/unread`, {
          params: { user: sessionUser.name }
        });
        if (response.data.unread) {
          setShowRedDot(true);
        }
      } catch (error) {
        console.error('읽지 않은 메시지 확인 중 오류 발생:', error);
      }
    };

    checkUnreadMessages();
  }, [projectId, sessionUser]);


  const handleOpenCommentModal = async () => {
    if (!sessionUser) return; 

    openCommentModal(projectId, data.ProjectName);
    setShowRedDot(false); // 모달을 열 때 레드닷 제거

    try {
      await axios.post(`/projects/${projectId}/chats/markAsRead`, { user: sessionUser.name });
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  };

  const handleInput = () => {
    setShowRedDot(false);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching project details: {error.message}</div>;
  if (!data) return <div>No project data available</div>; 

  const { ProjectName, ClientName, ClientPM, ClientEmail, ClientPhone, MainPM, SDate, DDate, Total, Status, Discount, Currency, tasks, Invoice, TotalWithVAT, TotalInKRW, Division, Group, Files, ExchangeRate, EndClient, Domain, TotalDiscount } = data;

  const totalWorkerCost = tasks?.reduce((acc, task) => {
    const taskWorkerCost = task.Workers?.reduce((taskAcc, worker) => {
      const volume = workerVolumes[`${task.id}-${worker._id}`] || worker.volume || 0;
      const rate = workerRates[`${task.id}-${worker._id}`] || worker.rate || 0;
      const cost = volume * rate;
      const costInKRW = worker.currency !== 'KRW' ? cost * worker.exchangeRate : cost;
      return taskAcc + costInKRW;
    }, 0) || 0;
    return acc + taskWorkerCost;
  }, 0) || 0;

  let total = Total;
  if (Currency !== 'KRW') {
    total = TotalInKRW;
  }
  
  const expectedRevenue = total - totalWorkerCost;
  const percentage = (expectedRevenue / total) * 100;

  const handleAddComment = (newComment) => {
    setComments([...comments, newComment]);
  };

  
  const tagOptions = [{ label: '태그 추가', value: 'addTag' }, ...tags.map(tag => ({ label: tag, value: tag }))];

  const handleTagSelect = (selectedOption) => {
    if (selectedOption.value === 'addTag') {
      setIsHashTagModalOpen(true);
    } else {
      handleApplyTag(selectedOption.value);
    }
  };


  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-gra custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <BoardTitle title={`"${data.ProjectName}" 프로젝트 진행 정보`} hashTags={selectedTags} onRemoveTag={handleRemoveTag} />
        <div className="flex justify-between mb-2">
          <div className="flex">
            <h2 className="flex items-center mb-2 text-lg font-semibold">
              <span className="pl-2 mr-3 border-l-4 border-blue-500">채팅방 입장</span>
            </h2>
            <button
              className="relative flex items-center justify-center w-10 h-10 mr-2 text-white transition duration-200 bg-blue-500 rounded-full hover:bg-blue-600"
              onClick={handleOpenCommentModal}
            >
              <FaComments className="w-5 h-5" />
              {showRedDot && (
                <div className="absolute top-0 right-0 flex items-center justify-center w-5 h-5 bg-red-500 rounded-full animate-pulse" style={{ transform: 'translate(25%, -25%)', fontSize: '0.5rem', color: 'white' }}>
                  N
                </div>
              )}
            </button>
          </div>
          <div className="flex">
          <CustomSelect
            className="mr-2 text-xs"
            name="tags"
            options={tagOptions}
            onChange={(option) => {
              if (option.value === 'addTag') {
                setIsHashTagModalOpen(true);
              } else {
                handleApplyTag(option.value);
              }
            }}
            placeholder="태그 선택"
          />
            <button className="flex items-center px-3 py-1.5 mr-2 text-sm text-white bg-green-500 rounded hover:bg-green-600 transition duration-200" onClick={() => setIsEditModalOpen(true)}>
              <FaEdit className="mr-1" /> 프로젝트 수정
            </button>
            <button className="flex items-center px-3 py-1.5 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-200" onClick={handleExport}>
              <FaFileExport className="mr-1" /> 내보내기
            </button>
          </div>
        </div>
        <div className="p-5 bg-white rounded-lg shadow-lg min-h-[100vh]">
          <div className="grid grid-cols-1 gap-2 pb-4 mb-4 md:grid-cols-3">
            <div className="p-4 bg-gray-100 border-gray-300 rounded-lg shadow">
              <h2 className="flex items-center mb-2 text-lg font-semibold">
                <span className="pl-2 border-l-4 border-blue-500">프로젝트 예산</span>
              </h2>
              <div className="flex items-center mb-2">
                <div className="w-1/2">
                  <CircularProgressbar
                    value={percentage}
                    text={`${Math.round(percentage)}%`}
                    styles={buildStyles({
                      textColor: "#3b82f6",
                      pathColor: "#3b82f6",
                      trailColor: "#d1d5db",
                    })}
                  />
                </div>
                <div className="w-1/2 pl-4 text-right">
                  <p className="text-blue-600">합산 총액: <span className="text-sm text-gray-500">{formatNumber(total, 'KRW')}</span></p>
                  <p className="text-red-600">작업비용: <span className="text-sm text-gray-500">{formatNumber(totalWorkerCost, 'KRW')}</span></p>
                  <p className="text-yellow-600">이익: <span className="text-sm text-gray-500">{formatNumber(expectedRevenue, 'KRW')}</span></p>
                </div>
              </div>
            </div>
            <div className="p-4 bg-gray-100 border-gray-300 rounded-lg shadow">
              <h2 className="flex items-center mb-2 text-lg font-semibold">
                <span className="pl-2 border-l-4 border-blue-500">작업 정보</span>
              </h2>
              <div className="py-1 mb-2 border-b border-gray-300">
                <p>견적서 번호: <span className="float-right text-sm text-gray-500">{Invoice}</span></p>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <p>시작일: <span className="float-right text-sm text-gray-500">{SDate}</span></p>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <p>마감일: <span className="float-right text-sm text-gray-500">{DDate}</span></p>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <p>상태: <span className="float-right text-sm">{getStatusBadge(Status)}</span></p>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <p>분야: <span className="float-right text-sm text-gray-500">{Domain}</span></p>
              </div>
              <div className="flex items-center justify-between py-1 mb-2 border-b border-gray-300">
                <p>태그:</p>
                <div className="flex flex-wrap justify-end">
            {selectedTags.map((tag, index) => (
              <div key={index} className="relative flex items-center m-1">
            <span className="bg-gray-200 text-gray-800 text-sm font-semibold mr-1 px-2.5 py-0.5 rounded">
              #{tag}
            </span>
            <button
              className="text-gray-500 text-sm"
              onClick={() => handleRemoveTag(tag)}
            >
              x
            </button>
          </div>
            ))}
          </div>
              </div>
            </div>
            <div className="p-4 bg-gray-100 border-gray-300 rounded-lg shadow">
              <h2 className="flex items-center mb-2 text-lg font-semibold">
                <span className="pl-2 border-l-4 border-blue-500">클라이언트 정보</span>
              </h2>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>클라이언트 이름:</span>
                  <span className="text-sm text-gray-500">{ClientName}</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>담당자:</span>
                  <span className="text-sm text-gray-500">{ClientPM}</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>이메일:</span>
                  <span className="text-sm text-gray-500">{ClientEmail}</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>연락처:</span>
                  <span className="text-sm text-gray-500">{ClientPhone}</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>최종 클라이언트:</span>
                  <span className="text-sm text-gray-500">{EndClient}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2 pb-4 mb-4 md:grid-cols-3">  
          <div className="p-4 bg-gray-100 border-gray-300 rounded-lg shadow">
            <h2 className="flex items-center mb-4 text-lg font-semibold">
              <span className="pl-2 border-l-4 border-blue-500">파일 다운로드</span>
            </h2>
            <div {...getRootProps({ className: 'dropzone' })} className="flex flex-col items-center justify-center w-full h-16 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
              <input {...getInputProps()} className="hidden" />
              <div className="flex flex-col items-center justify-center pt-2 pb-2">
                <svg className="w-8 h-8 mb-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
                </svg>
                <p className="mb-1 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
              </div>
            </div>
            <CustomScrollbars style={{ height: 200 }}>
            <div className="mt-2">
              {files.map(file => (
                <div key={file.name} className="relative flex items-center justify-between p-1 mb-1 border rounded-lg">
                  <button className="absolute top-0 left-0 mt-1 ml-1 text-gray-500 hover:text-gray-700" onClick={() => handleDelete(file)}>
                    <FaTimesCircle className="w-4 h-4" />
                  </button>
                  <div className="flex items-center">
                    {file.preview ? (
                      <img src={file.preview} alt={file.name} className="w-8 h-8 mr-2" />
                    ) : (
                      getFileIcon(file.type)
                    )}
                    <div>
                      <p className="text-sm font-medium text-gray-900">{file.name}</p>
                      <p className="text-xs text-gray-500">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                    </div>
                  </div>
                  <button className="text-blue-500 hover:text-blue-700" onClick={() => handleFileDownload(file.path)}>
                    <FaDownload className="w-4 h-4" />
                  </button>
                </div>
              ))}
            </div>
          </CustomScrollbars>
          </div>
            <div className="p-4 bg-gray-100 border-gray-300 rounded-lg shadow">
              <h2 className="flex items-center mb-2 text-lg font-semibold">
                <span className="pl-2 border-l-4 border-blue-500">결제 정보</span>
              </h2>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>총액:</span>
                  <span className="text-sm font-bold text-blue-500">{formatNumber(Total, Currency)}</span>
                </div>
              </div>
              {Currency === 'KRW' ? (
                <div className="py-1 mb-2 border-b border-gray-300">
                  <div className="flex justify-between">
                    <span>총액(VAT):</span>
                    <span className="text-sm font-bold text-red-500">{formatNumber(TotalWithVAT, Currency)}</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="py-1 mb-2 border-b border-gray-300">
                    <div className="flex justify-between">
                      <span>총액(원화환산):</span>
                      <span className="text-sm font-bold text-yellow-500">{formatNumber(TotalInKRW, 'KRW')}</span>
                    </div>
                  </div>
                  <div className="py-1 mb-2 border-b border-gray-300">
                    <div className="flex justify-between">
                      <span>환율:</span>
                      <span className="text-sm text-gray-500">{ExchangeRate}</span>
                    </div>
                  </div>
                </>
              )}
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>할인율:</span>
                  <span className="text-sm text-gray-500">{Discount}%</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>총액할인:</span>
                  <span className="text-sm text-gray-500">{formatNumber(TotalDiscount, Currency)}</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>통화:</span>
                  <span className="text-sm text-gray-500">{Currency}</span>
                </div>
              </div>
            </div>
            <div className="p-4 bg-gray-100 border-gray-300 rounded-lg shadow">
              <h2 className="flex items-center mb-2 text-lg font-semibold">
                <span className="pl-2 border-l-4 border-blue-500">PM & 그룹 정보</span>
              </h2>
              <div className="py-1 mb-2">
                <div className="flex justify-between border-b border-gray-300">
                  <span>담당자:</span>
                  <span className="text-sm text-gray-500">{MainPM}</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>본부:</span>
                  <span className="text-sm text-gray-500">{Division}</span>
                </div>
              </div>
              <div className="py-1 mb-2 border-b border-gray-300">
                <div className="flex justify-between">
                  <span>그룹:</span>
                  <span className="text-sm text-gray-500">{Group}</span>
                </div>
              </div>
            </div>
          </div>
          <h2 className="flex items-center mt-4 text-xl font-semibold"><FaTasks className="p-1 mr-2 text-white bg-gray-800 rounded-full" />세부 작업</h2>
          <TaskTable
            projectName={ProjectName}
            tasks={tasks}
            workers={workers}
            selectedWorker={selectedWorker}
            setSelectedWorker={setSelectedWorker}
            expandedTaskIds={expandedTaskIds}
            toggleTaskExpand={toggleTaskExpand}
            handleAssignWorker={handleAssignWorker}
            handleRemoveWorker={handleRemoveWorker}
            workerVolumes={workerVolumes}
            setWorkerVolumes={setWorkerVolumes}
            handleVolumeChange={handleVolumeChange}
            saveVolume={saveVolume}
            workerRates={workerRates}
            handleRateChange={handleRateChange}
            saveRate={saveRate}
            workerContents={workerContents}
            handleContentChange={handleContentChange}
            saveContent={saveContent}
            projectId={projectId}
            onPOSuccess={(po) => setPoList((prev) => [...prev, po])}
            taskStatus={Status}
            exchangeRate={ExchangeRate}
            projectCurrency={Currency}
            files={files}
            apiUrl={apiUrl}
          />
        </div>
        <EditProjectModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          project={data}
          refetchProject={() => queryClient.invalidateQueries(['projectDetails', projectId])}
        />
      </main>
      <AsideContainer />
      {isPermissionModalOpen && sessionUser.authLevel < 2 && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <OneButtonModal
            id="permission-modal"
            title="권한 없음"
            body="이 프로젝트에 대한 권한이 없습니다."
            onSave={() => {
              setIsPermissionModalOpen(false);
              window.location.href = '/project';
            }}
            onClose={() => {
              setIsPermissionModalOpen(false);
              window.location.href = '/project';
            }}
          />
        </div>
      )}
      {isHashTagModalOpen && (
      <HashTagModal
      isOpen={isHashTagModalOpen}
      onClose={() => setIsHashTagModalOpen(false)}
      onSave={handleAddTag}
    />
      )}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-11/12 bg-white rounded-lg shadow-lg md:w-1/2 lg:w-1/3">
            <div className="flex items-center justify-between p-4 border-b">
              <h2 className="text-xl font-semibold">파일 삭제</h2>
              <button onClick={() => setIsDeleteModalOpen(false)} className="text-gray-500 hover:text-gray-700">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            <div className="p-4">
              <p>정말로 {fileToDelete?.name} 파일을 삭제하시겠습니까?</p>
              <div className="flex justify-end mt-4">
                <button onClick={confirmDelete} className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600">삭제</button>
                <button onClick={() => setIsDeleteModalOpen(false)} className="px-4 py-2 ml-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300">취소</button>
              </div>
            </div>
          </div>
        </div>
      )}
    {loading && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div role="status">
                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}  
    </div>
  );
};

export default ProjectDetails;