import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import '../App.css';
import BoardTitle from '../area/BoardTitle.js';
import PepeContent from '../area/PepeContent.js';
import axios from '../axiosConfig';
import ProjectBoard from '../boards/ProjectBoard.js';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';

const fetchProjects = async (page, projectsPerPage) => {
  const response = await axios.get(`/projectboard?page=${page}&limit=${projectsPerPage}`);
  return response.data;
};

const Project = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;
  const queryClient = useQueryClient();
  const [allProjects, setAllProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { isCollapsed } = useContext(SidebarContext);

  // Fetch initial project data based on the current page
  const { data, isLoading, error } = useQuery(['projects', currentPage], () => fetchProjects(currentPage, projectsPerPage), {
    keepPreviousData: true,
  });

  useEffect(() => {
    const fetchAllProjects = async () => {
      try {
        let allProjects = [];
        let page = 1;
        let totalPages = 1;

        while (page <= totalPages) {
          const response = await axios.get(`/projectboard?page=${page}&limit=${projectsPerPage}`);
          if (response.status === 200) {
            allProjects = [...allProjects, ...response.data.projects];
            totalPages = Math.ceil(response.data.total / projectsPerPage);
            page++;
          } else {
            break;
          }
        }

        setAllProjects(allProjects);
      } catch (error) {
        console.error('Error fetching all projects:', error);
      }
    };

    fetchAllProjects();
  }, [projectsPerPage]);

  useEffect(() => {
    // 검색어가 변경될 때 페이지를 1로 설정하고 필터링된 프로젝트 업데이트
    setCurrentPage(1);
    const filtered = allProjects.filter(project =>
      (project.ProjectName && project.ProjectName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (project.ClientName && project.ClientName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (project.EndClient && project.EndClient.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (project.MainPM && project.MainPM.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    setFilteredProjects(filtered);
  }, [searchTerm, allProjects]);

  const handleAddProjectSuccess = () => {
    queryClient.invalidateQueries(['projects']);
  };

  const handleEditProjectSuccess = () => {
    queryClient.invalidateQueries(['projects']);
  };

  const handleDeleteProjectSuccess = () => {
    queryClient.invalidateQueries(['projects']);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching projects: {error.message}</div>;

  // 필터링된 프로젝트 수를 기반으로 페이지네이션 계산
  const totalFilteredPages = Math.ceil(filteredProjects.length / projectsPerPage);
  const paginatedProjects = filteredProjects.slice((currentPage - 1) * projectsPerPage, currentPage * projectsPerPage);
  const pageNumbers = Array.from({ length: totalFilteredPages }, (_, i) => i + 1);

  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-gray-150 custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <BoardTitle title="프로젝트 목록" />
        <PepeContent projects={filteredProjects} />
        <ProjectBoard 
          projects={paginatedProjects} 
          fetchProjects={handleAddProjectSuccess}
          onAddProjectSuccess={handleAddProjectSuccess}
          onEditProjectSuccess={handleEditProjectSuccess}
          onDeleteProjectSuccess={handleDeleteProjectSuccess}
          currentPage={currentPage} 
          setCurrentPage={setCurrentPage} 
          pageNumbers={pageNumbers} 
          projectsPerPage={projectsPerPage} 
          setProjects={setAllProjects} 
          searchTerm={searchTerm} 
          setSearchTerm={setSearchTerm}
        />
      </main>
      <AsideContainer />
    </div>
  );
};

export default Project;
