import React from 'react';

const BoardTitle = ({ title }) => (
  <div className="flex items-center justify-between pb-4 mb-4 border-b border-gray-300 header">
    <h2 className="text-xl font-semibold">{title}</h2>
    <div className="flex space-x-2 actions">

    </div>
  </div>
);

export default BoardTitle;