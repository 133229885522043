import React, { useState } from 'react';
import axios from '../../../axiosConfig';
import { TwoButtonModal } from '../Modal';
import CustomSelect from './CustomSelect'; // CustomSelect import 추가

const AddClientPMModal = ({ isOpen, onClose, clients, fetchClients }) => {
  const [formData, setFormData] = useState({
    clientId: '',
    pmName: '',
    pmEmail: '',
    pmMobile: '',
    pmMemo: ''
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSelectChange = (selectedOption) => {
    setFormData(prevData => ({
      ...prevData,
      clientId: selectedOption ? selectedOption.value : ''
    }));
  };

  const handleSave = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      await axios.post('/clientPMs', formData);
      fetchClients();
      setIsConfirmModalOpen(false);
      onClose(); // onClose를 여기로 이동
    } catch (error) {
      console.error('Error adding client PM:', error);
    }
  };

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  const clientOptions = clients.map(client => ({
    value: client._id,
    label: client.clientName
  }));

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={handleBackgroundClick}>
        <div className="relative w-full max-w-2xl p-4 bg-white rounded-lg shadow-lg">
          <button onClick={onClose} className="absolute text-gray-500 top-4 right-4 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="mb-4 text-xl font-bold">클라이언트 PM 추가</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="mb-4">
              <label className="block text-gray-700">클라이언트</label>
              <CustomSelect
                name="clientId"
                value={clientOptions.find(option => option.value === formData.clientId)}
                onChange={handleSelectChange}
                options={clientOptions}
                placeholder="Select a client"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">PM 이름</label>
              <input
                type="text"
                name="pmName"
                value={formData.pmName}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">PM 이메일</label>
              <input
                type="email"
                name="pmEmail"
                value={formData.pmEmail}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">PM 연락처</label>
              <input
                type="text"
                name="pmMobile"
                value={formData.pmMobile}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Memo</label>
              <textarea
                name="pmMemo"
                value={formData.pmMemo}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              ></textarea>
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={onClose} className="px-4 py-2 mr-2 bg-gray-300 rounded">Cancel</button>
              <button type="button" onClick={handleSave} className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>
            </div>
          </form>
        </div>
      </div>
      {isConfirmModalOpen && (
        <TwoButtonModal
          title="Confirm Save"
          body="Are you sure you want to add this Client PM?"
          onSave={handleConfirmSave}
          onClose={() => setIsConfirmModalOpen(false)}
        />
      )}
    </>
  );
};

export default AddClientPMModal;