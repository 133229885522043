import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import axios from '../../axiosConfig';
import { LargeModal } from './Modal';

const RecentTasksModal = ({ isOpen, onClose, worker }) => {
  const [recentTasks, setRecentTasks] = useState([]);

  useEffect(() => {
    if (worker) {
      const fetchRecentTasks = async () => {
        try {
          const response = await axios.get(`/recent-purchase-orders?worker_name=${worker.name}`);
          const data = Array.isArray(response.data) ? response.data : [];
          setRecentTasks(data);
        } catch (error) {
          console.error("Error fetching recent tasks:", error);
        }
      };

      fetchRecentTasks();
    }
  }, [worker]);

  if (!worker) return null;

  const handleClose = () => {
    onClose(recentTasks.length);
  };

  return (
    <LargeModal
      id="recent-tasks-modal"
      title={`${worker.name}의 최근 작업 (${new Date(recentTasks[0]?.work_started_at).toLocaleDateString('ko-KR').replace(/\./g, '.')} ~ ${new Date(recentTasks[recentTasks.length - 1]?.work_started_at).toLocaleDateString('ko-KR').replace(/\./g, '.')})`}
      body={
        <div>
          <table className="min-w-full text-sm bg-white border border-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">프로젝트 이름</th>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">PO 번호</th>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">작업자 이름</th>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">PM 이름</th>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">작업 유형</th>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">볼륨</th>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">평점</th>
                <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">코멘트</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {recentTasks.map((po) => {
                const ratings = po.ratings || {};
                const averageRating = (ratings.communication + ratings.expertise + ratings.consistency + ratings.professionalism + ratings.compliance + ratings.flow) / 6 || 0;
                return (
                  <tr key={po.po_number}>
                    <td className="px-4 py-2 text-sm text-gray-900 whitespace-nowrap">{po.project_name}</td>
                    <td className="px-4 py-2 text-sm text-gray-900 whitespace-nowrap">{po.po_number}</td>
                    <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">{po.worker_name}</td>
                    <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">{po.pm_name}</td>
                    <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">{po.ProjectType}</td>
                    <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">{po.volume}</td>
                    <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <ReactStars
                        count={5}
                        value={Math.round(averageRating / 20 * 2) / 2}
                        edit={false}
                        size={12}
                        isHalf={true}
                        activeColor="#ffd700"
                      />
                    </td>
                    <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                      <div className="w-48 overflow-hidden whitespace-nowrap">
                        <div className="inline-block animate-marquee">
                          {ratings.comment || 'No comment'}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      }
      onClose={handleClose}
    />
  );
};

export default RecentTasksModal;