import React, { useEffect, useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa'; // Font Awesome 아이콘 임포트
import { useQueryClient } from 'react-query';
import axios from '../../axiosConfig';
import { TwoButtonModal } from './Modal';
import UserEvaluationModal from './UserEvaluationModal';

const AISummaryCard = ({ workerId }) => {
  const [summary, setSummary] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [evaluationRecords, setEvaluationRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const queryClient = useQueryClient();

  const fetchSummary = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('/ai_summary', { workerId });
      setSummary(response.data.summary);
      fetchEvaluationRecords(); // 평가 기록 갱신
    } catch (err) {
      setError('요약을 가져오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const fetchEvaluationRecords = async () => {
    try {
      const response = await axios.get(`/worker_evaluation_records/${workerId}`);
      setEvaluationRecords(response.data.evaluation_records || []);
    } catch (err) {
      console.error('평가 기록을 가져오는 중 오류가 발생했습니다.', err);
    }
  };

  const handleDeleteRecord = async () => {
    try {
      await axios.delete(`/user_evaluation/${recordToDelete._id}`, {
        params: { workerId }
      });
      queryClient.invalidateQueries('evaluationRecords');
      setIsDeleteModalOpen(false);
    } catch (err) {
      console.error('평가 기록을 삭제하는 중 오류가 발생했습니다.', err);
    }
  };

  useEffect(() => {
    fetchEvaluationRecords();
  }, [workerId]);

  return (
    <>
      <div>
        <h2 className="mt-4 mb-8 text-xl font-semibold">
          <span className="pb-1 border-b-2 border-blue-500">AI 평가</span>
        </h2>
      </div>
      <div className="relative p-4 bg-white border border-gray-200 rounded-lg shadow-md min-h-[408px] max-h-[408px] flex">
        <div className="w-1/3 pr-2 border-r border-gray-200">
          <h3 className="text-lg font-semibold">평가 기록</h3>
          <ul className="mt-2 space-y-2">
            {evaluationRecords.length > 0 ? (
              evaluationRecords.map((record, index) => (
                <li key={index} className="flex items-center justify-between p-2 bg-gray-100 rounded">
                  <span className="cursor-pointer hover:bg-gray-200" onClick={() => setSelectedRecord(record)}>
                    {new Date(record.created_at).toLocaleString()}
                  </span>
                  <button
                    className="text-red-500 hover:text-red-600"
                    onClick={() => {
                      setRecordToDelete(record);
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <FaTimes />
                  </button>
                </li>
              ))
            ) : (
              <li className="p-2 bg-gray-100 rounded">평가 기록이 없습니다.</li>
            )}
          </ul>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => setIsModalOpen(true)}
              className="flex items-center justify-center w-8 h-8 text-xl text-gray-500 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              <FaPlus />
            </button>
          </div>
        </div>
        <div className="w-2/3 pl-2">
          <div className="flex flex-col h-full">
            <div className="flex-grow overflow-hidden">
              <div className="mt-4 text-gray-700 dark:text-gray-300" style={{ whiteSpace: 'pre-line' }}>
                {selectedRecord ? selectedRecord.summary : (summary || '평가 내용이 없습니다.')}
              </div>
            </div>
            <div className="flex justify-start mt-4 text-xs">
              <button
                onClick={fetchSummary}
                className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-700"
              >
                평가 하기
              </button>
            </div>
          </div>
        </div>
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-opacity-75 bg-gray-50 dark:bg-gray-800 dark:bg-opacity-75">
            <div role="status">
              <svg
                aria-hidden="true"
                className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && <UserEvaluationModal onClose={() => setIsModalOpen(false)} workerId={workerId} />}
      {isDeleteModalOpen && (
        <TwoButtonModal
          id="delete-modal"
          title="평가 기록 삭제"
          body="이 평가 기록을 삭제하시겠습니까?"
          onSave={handleDeleteRecord}
          onClose={() => setIsDeleteModalOpen(false)}
          saveButtonText="삭제"
          closeButtonText="취소"
        />
      )}
    </>
  );
};

export default AISummaryCard;