import React, { useState } from 'react';
import axios from '../../axiosConfig';
import POFileDropzone from './POFileDropzone';

const FileUploadModal = ({ isOpen, onClose, onSave, poId, taskId, projectId, poNumber, workerId, projectName, workerName, pmName }) => {
  const [filePreviews, setFilePreviews] = useState([]);

  const handleFileUpload = (files) => {
    setFilePreviews(files);
  };

  const handleSave = async () => {
    if (filePreviews.length === 0) {
      console.error('No file selected');
      return;
    }

    const formData = new FormData();
    filePreviews.forEach((file, index) => {
      formData.append('file', file.file);
      formData.append('comment', file.comment);
    });
    formData.append('po_id', poId); 
    formData.append('task_id', taskId);
    formData.append('project_id', projectId);
    formData.append('worker_id', workerId);
    formData.append('project_name', projectName);
    formData.append('po_number', poNumber);
    formData.append('worker_name', workerName);
    formData.append('pm_name', pmName);

    try {
      const response = await axios.post('/uploadPOFile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data.message);
      onSave(response.data.file_paths, poNumber);
      onClose();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
     <div className="z-50 p-4 bg-white rounded shadow-lg">
        <h2 className="mb-4 text-xl font-semibold">Upload File for PO Number: {poNumber}</h2>
        <POFileDropzone onFileUpload={handleFileUpload} initialFiles={[]} poId={poId} />
        <div className="flex justify-end mt-4">
          <button className="px-4 py-2 mr-2 bg-gray-300 rounded" onClick={onClose}>Cancel</button>
          <button className="px-4 py-2 text-white bg-blue-600 rounded" onClick={handleSave}>Save</button>
        </div>
      </div>
    </div>
  );
};

export default FileUploadModal;