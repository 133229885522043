import React, { useContext, useEffect, useState } from 'react';
import '../App.css';
import BoardTitle from '../area/BoardTitle';
import InvoiceContent from '../area/InvoiceContent';
import axios from '../axiosConfig';
import InvoiceBoard from '../boards/InvoiceBoard';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';

const Invoice = () => {
  const { isCollapsed } = useContext(SidebarContext); 
  const [invoices, setInvoices] = useState([]); 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get('/invoices');
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices();
  }, []);

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-gray-150 custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title="견적서" />
        <InvoiceContent projects={invoices} startDate={startDate} endDate={endDate} />
        <InvoiceBoard setStartDate={setStartDate} setEndDate={setEndDate} />
      </main>
      <AsideContainer />
    </div>
  );
};

export default Invoice;