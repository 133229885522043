import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import '../App.css';
import BoardTitle from '../area/BoardTitle';
import ClientContent from '../area/ClientContent';
import axios from '../axiosConfig';
import ClientBoard from '../boards/ClientBoard';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';

const fetchClients = async () => {
  const { data } = await axios.get('/clients');
  return data;
};

const Client = () => {
  const { isCollapsed } = useContext(SidebarContext); 
  const { data: clients, isLoading, error } = useQuery('clients', fetchClients);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching clients</div>;

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-gray-150 custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title="클라이언트" />
        <ClientContent clients={clients} />
        <ClientBoard />
      </main>
      <AsideContainer />
    </div>
  );
};

export default Client;