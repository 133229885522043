import React, { createContext, useContext, useState } from 'react';

const CommentModalContext = createContext();

export const CommentModalProvider = ({ children }) => {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [comments, setComments] = useState([]);

  const openCommentModal = (id, name) => {
    setProjectId(id);
    setProjectName(name);
    setIsCommentModalOpen(true);
  };

  const closeCommentModal = () => {
    setIsCommentModalOpen(false);
  };

  const handleAddComment = (newComment) => {
    setComments([...comments, newComment]);
  };

  const handleInput = () => {
  };

  return (
    <CommentModalContext.Provider value={{ 
      isCommentModalOpen, 
      projectId, 
      projectName, 
      comments, 
      openCommentModal, 
      closeCommentModal, 
      handleAddComment, 
      handleInput 
    }}>
      {children}
    </CommentModalContext.Provider>
  );
};

export const useCommentModal = () => useContext(CommentModalContext);