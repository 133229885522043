import { Chart, RadialLinearScale, registerables } from 'chart.js';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import BoardTitle from '../area/BoardTitle';
import axios from '../axiosConfig';
import AISummaryCard from '../components/commons/AISummaryCard';
import FileUploadModal from '../components/commons/FileUploadModal';
import { OneButtonModal } from '../components/commons/Modal';
import TranslatorCard from '../components/commons/TranslatorCard';
import WorkerFiles from '../components/commons/WorkerFiles';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';
import WorkerDetailsTable from './WorkerDetailsTable';

// 필요한 구성 요소를 명시적으로 등록합니다.
Chart.register(...registerables, RadialLinearScale);

const WorkerDetails = () => {
  const { isCollapsed } = useContext(SidebarContext);
  const { workerId } = useParams();
  const [worker, setWorker] = useState(null);
  const [workerPOs, setWorkerPOs] = useState([]);
  const [selectedPOs, setSelectedPOs] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [modalDate, setModalDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [uploadingPoId, setUploadingPoId] = useState(null);
  const [uploadingProjectId, setUploadingProjectId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadingPoNumber, setUploadingPoNumber] = useState(null);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [currentPo, setCurrentPo] = useState(null);
  const [filter, setFilter] = useState('');
  const [filterField, setFilterField] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorModal, setErrorModal] = useState({ show: false, message: '' });
  const itemsPerPage = 10;
  const queryClient = useQueryClient();

  useEffect(() => {
    if (workerId) {
      fetchWorkerDetails();
      fetchWorkerPOs();
      fetchActivityLogs();
    }
  }, [workerId]);

  const fetchWorkerDetails = async () => {
    try {
      const response = await axios.get(`/workers/details/${workerId}`);
      setWorker(response.data);
    } catch (error) {
      console.error('Error fetching worker details:', error);
    }
  };

  const fetchWorkerPOs = async () => {
    try {
      const response = await axios.get(`/worker_po/${workerId}`);
      setWorkerPOs(response.data);
    } catch (error) {
      console.error('Error fetching worker POs:', error);
    }
  };

  const fetchActivityLogs = async () => {
    try {
      const response = await axios.get(`/getActivityLogs?worker_id=${workerId}`);
      setActivityLogs(response.data.logs);
    } catch (error) {
      console.error('Error fetching activity logs:', error);
    }
  };

  const handleSelectPO = (poId) => {
    setSelectedPOs((prevSelected) =>
      prevSelected.includes(poId)
        ? prevSelected.filter((id) => id !== poId)
        : [...prevSelected, poId]
    );
  };

  const handleSelectAll = () => {
    if (selectedPOs.length === workerPOs.length) {
      setSelectedPOs([]);
    } else {
      setSelectedPOs(workerPOs.map((po) => po._id));
    }
  };

  const handleOpenModal = (field, date, poId) => {
    setSelectedField(field);
    setModalDate(date);
    setSelectedPOs([poId]);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedPOs([]);
    setSelectedField(null);
  };

  const handleSaveDate = async () => {
    if (
      selectedField === 'work_ended_at' &&
      new Date(modalDate) <
        new Date(workerPOs.find((po) => po._id === selectedPOs[0]).work_started_at)
    ) {
      setErrorModal({ show: true, message: 'End date는 Start date보다 빠를 수 없습니다.' });
      return;
    }
    setSaving(true);
    try {
      for (let poId of selectedPOs) {
        const po = workerPOs.find((po) => po._id === poId);
        if (po) {
          const response = await axios.put('/updatePOEndDate', {
            po_id: poId,
            field: selectedField,
            date: modalDate,
          });

          if (response.status !== 200) {
            console.error(`Error updating PO date: ${response.data.error}`);
            continue;
          }
        }
      }
      const updatedPOs = workerPOs.map((po) =>
        selectedPOs.includes(po._id) ? { ...po, [selectedField]: modalDate } : po
      );
      setWorkerPOs(updatedPOs);
    } catch (error) {
      console.error('Error saving date:', error);
    }
    setSaving(false);
    handleCloseModal();
  };

  const handleFileUploadModalOpen = (poId, poNumber) => {
    setUploadingPoId(poId);
    setUploadingPoNumber(poNumber);
    setIsFileUploadModalOpen(true);
  };

  const handleFileUploadModalClose = () => {
    setIsFileUploadModalOpen(false);
    setUploadingPoId(null);
    setUploadingPoNumber(null);
  };

  const handleFileUploadSave = async (filePaths, poNumber) => {
    setUploadedFiles((prevFiles) => ({
      ...prevFiles,
      [uploadingPoId]: filePaths,
    }));

    const response = await axios.get(`/getActivityLogs?worker_id=${workerId}`);
    setActivityLogs(response.data.logs);
    console.log('Updated activity logs: ', response.data.logs);
  };

  const handleResetFilters = () => {
    setFilter('');
    setFilterField('');
    setFilterDate(null);
  };

  const filteredPOs = useMemo(() => {
    return workerPOs
      .filter(
        (po) =>
          (po.worker_name && po.worker_name.toLowerCase().includes(filter.toLowerCase())) ||
          (po.po_number && po.po_number.toLowerCase().includes(filter.toLowerCase())) ||
          (po.worker_email && po.worker_email.toLowerCase().includes(filter.toLowerCase()))
      )
      .filter((po) => {
        if (!filterDate) return true;
        const date = new Date(filterDate).toISOString().substr(0, 10);
        return (
          po[filterField] && new Date(po[filterField]).toISOString().substr(0, 10) === date
        );
      });
  }, [workerPOs, filter, filterField, filterDate]);

  const currentPOs = filteredPOs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredPOs.length / itemsPerPage);

  const recentTasks = useMemo(() => {
    return workerPOs.map((po) => {
      const ratings = po.ratings || {};
      const averageRating =
        (ratings.communication +
          ratings.expertise +
          ratings.consistency +
          ratings.professionalism +
          ratings.compliance +
          ratings.flow) /
        6 || 0;
      return {
        project_name: po.project_name,
        averageRating: averageRating / 20, // 100점 만점을 5점 만점으로 환산
        comment: ratings.comment || 'No comment',
      };
    });
  }, [workerPOs]);

  const formatDateCustom = (dateString) => {
    const date = new Date(dateString);
    const year = String(date.getFullYear()).slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-gray-150 custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <BoardTitle title={`작업자 상세 정보`} />
        <div className="flex flex-row space-x-4">
          <div>
            {worker && <TranslatorCard worker={worker} />}
            {worker && <WorkerFiles workerId={workerId} />}
          </div>
          <div className="flex-grow">
            {worker && (
              <WorkerDetailsTable
                currentPOs={currentPOs}
                selectedPOs={selectedPOs}
                handleSelectPO={handleSelectPO}
                handleSelectAll={handleSelectAll}
                formatDateCustom={formatDateCustom}
                handleOpenModal={handleOpenModal}
              />
            )}
               {worker && <AISummaryCard workerId={workerId} />}
          </div>
        </div>


        {isModalOpen && (
          <OneButtonModal
            id="date-modal"
            title="날짜 입력"
            body={
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-400">
                  날짜 선택
                </label>
                <input
                  type="date"
                  value={modalDate}
                  onChange={(e) => setModalDate(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded"
                />
              </div>
            }
            onSave={handleSaveDate}
            onClose={handleCloseModal}
          />
        )}

        {errorModal.show && (
          <OneButtonModal
            id="error-modal"
            title="Error"
            body={errorModal.message}
            onSave={() => setErrorModal({ show: false, message: '' })}
            onClose={() => setErrorModal({ show: false, message: '' })}
          />
        )}
        <div className="flex flex-row space-x-4">
          <div className="flex-grow w-1/2">
            <FileUploadModal
              isOpen={isFileUploadModalOpen}
              onClose={handleFileUploadModalClose}
              onSave={handleFileUploadSave}
              poId={uploadingPoId}
              projectId={uploadingProjectId}
              poNumber={uploadingPoNumber}
            />
          </div>
        </div>
      </main>
      <AsideContainer />
    </div>
  );
};

export default WorkerDetails;