import React, { forwardRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

const CustomScrollbars = forwardRef((props, ref) => {
  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      left: '2px'
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars
      ref={ref}
      renderThumbVertical={renderThumb}
      {...props}
    />
  );
});

export default CustomScrollbars;