import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaBell, FaBuilding, FaCalendarAlt, FaChartPie, FaChevronLeft, FaChevronRight, FaClipboardList, FaComment, FaHome, FaLanguage, FaProjectDiagram, FaUserFriends, FaVideo } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import RecolorLogo from '../../assets/Recolor.svg';
import axios from '../../axiosConfig';
import { useNotifications } from '../../contexts/NotificationContext';
import NotificationDisplay from '../../contexts/NotificationDisplay';
import { SidebarContext } from '../../contexts/SidebarContext';

const Navbar = () => {
  const [isOpenWorker, setIsOpenWorker] = useState(false);
  const [isOpenFinancial, setIsOpenFinancial] = useState(false);
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const { isCollapsed, setIsCollapsed, toggleCollapse } = useContext(SidebarContext);
  const { notifications, setNotifications } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const socket = io(socketUrl, {
      withCredentials: true,
      transports: ['websocket'],
    });
  
    socket.on('notification_update', (updatedNotifications) => {
      setNotifications(updatedNotifications);
    });
  
    return () => socket.disconnect();
  }, [setNotifications]);

  useEffect(() => {
    const savedIsCollapsed = localStorage.getItem('isCollapsed');
    if (savedIsCollapsed !== null) {
      setIsCollapsed(JSON.parse(savedIsCollapsed));
    } else {
      setIsCollapsed(true);
    }
  }, [setIsCollapsed]);

  const handleScheduleClick = useCallback(async () => {
    try {
      const [projectsResponse, userResponse] = await Promise.all([
        axios.get('/projects'),
        axios.get('/session-user')
      ]);
      navigate('/schedule', { state: { projects: projectsResponse.data.projects, user: userResponse.data.user } });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [navigate]);

  const handleStudioClick = () => {
    navigate('/studio');
  };

  const unreadNotifications = notifications.filter(notification => !notification.is_read);

  return (
    <aside className={`fixed top-0 left-0 z-40 h-screen text-white transition-all duration-300 bg-gray-800 ${isCollapsed ? 'w-[80px]' : 'w-[250px]'}`} aria-label="Sidebar">
      <div className="px-3 py-4 overflow-y-auto">
        <div className="flex items-center justify-between p-2">
          <div className="flex items-center">
            <img src={RecolorLogo} className={`h-8 transition-opacity duration-300 ${isCollapsed ? 'opacity-0' : 'opacity-100'}`} alt="Recolor Logo" />
            {!isCollapsed && <span className="ml-2 text-2xl font-semibold whitespace-nowrap">ETS.Ai</span>}
          </div>
          <button onClick={toggleCollapse} className="p-2 text-white rounded hover:bg-gray-700">
            {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
          </button>
        </div>
        <ul className="space-y-2">
          <li>
            <button className="relative flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaBell className="w-5 h-5" />
              {unreadNotifications.length > 0 && (
                <span className="absolute top-0 right-0 flex items-center justify-center w-4 h-4 text-xs text-white bg-red-500 rounded-full">
                  {unreadNotifications.length}
                </span>
              )}
              {!isCollapsed && <span className="ml-3">알림</span>}
            </button>
          </li>
          <li>
            <Link to="/home" className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaHome className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">대시보드</span>}
            </Link>
          </li>
          <li>
            <Link to="/project" className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaProjectDiagram className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">프로젝트</span>}
            </Link>
          </li>
          <li>
            <button onClick={() => setIsOpenFinancial(!isOpenFinancial)} className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaClipboardList className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">정산 관리</span>}
              <svg className={`w-4 h-4 ml-auto transition-transform duration-300 ${isOpenFinancial ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {isOpenFinancial && (
              <ul className={`py-2 ${isCollapsed ? 'absolute left-[80px] top-[140px] bg-gray-800 w-[170px] z-50' : ''}`}>
                <li><Link to="/financial/invoice" className="block px-4 py-2 text-sm hover:bg-gray-700">견적서</Link></li>
                <li><Link to="/financial/po" className="block px-4 py-2 text-sm hover:bg-gray-700">PO</Link></li>
              </ul>
            )}
          </li>
          <li>
            <button onClick={() => setIsOpenWorker(!isOpenWorker)} className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaUserFriends className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">리소스 관리</span>}
              <svg className={`w-4 h-4 ml-auto transition-transform duration-300 ${isOpenWorker ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {isOpenWorker && (
              <ul className={`py-2 ${isCollapsed ? 'absolute left-[80px] top-[220px] bg-gray-800 w-[170px] z-50' : ''}`}>
                <li><Link to="/resource/client" className="block px-4 py-2 text-sm hover:bg-gray-700">클라이언트</Link></li>
                <li><Link to="/resource/worker" className="block px-4 py-2 text-sm hover:bg-gray-700">번역사</Link></li>
              </ul>
            )}
          </li>
          <li>
            <button onClick={() => setIsOpenInternal(!isOpenInternal)} className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaBuilding className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">사내 관리</span>}
              <svg className={`w-4 h-4 ml-auto transition-transform duration-300 ${isOpenInternal ? 'rotate-180' : ''}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
            {isOpenInternal && (
              <ul className={`py-2 ${isCollapsed ? 'absolute left-[80px] bg-gray-800 w-[170px] z-50' : ''}`}>
                <li><Link to="/resource/employee" className="block px-4 py-2 text-sm hover:bg-gray-700">임직원 관리</Link></li>
                <li><Link to="/resource/company" className="block px-4 py-2 text-sm hover:bg-gray-700">회사 설정</Link></li>
                <li><Link to="/resource/project" className="block px-4 py-2 text-sm hover:bg-gray-700">프로젝트 설정</Link></li>
              </ul>
            )}
          </li>
          <li>
            <Link to="/report" className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaChartPie className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">종합 리포트</span>}
            </Link>
          </li>
          <li>
            <Link to="/translate" className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaLanguage className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">MTPE / CLI.Ai</span>}
            </Link>
          </li>
          <li>
            <Link to="/freeboard" className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaComment className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">자유게시판</span>}
            </Link>
          </li>
          <li>
            <button onClick={handleStudioClick} className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaVideo className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">E.Studio</span>}
            </button>
          </li>
          <li>
            <button onClick={handleScheduleClick} className="flex items-center p-2 text-base rounded-lg hover:bg-gray-700">
              <FaCalendarAlt className="w-5 h-5" />
              {!isCollapsed && <span className="ml-3">스케줄</span>}
            </button>
          </li>
        </ul>
        {!isCollapsed && <NotificationDisplay />} {/* Add NotificationDisplay here */}
      </div>
    </aside>
  );
};

export default Navbar;