import React, { useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { formatNumber } from '../components/commons/Utils';
import { useNotifications } from './NotificationContext';

const NotificationDisplay = () => {
  const { notifications, handleNotification, readNotification, isDisplayExpanded, toggleDisplay, setNotifications, refetchNotifications } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const socket = io(socketUrl, {
      withCredentials: true,
      transports: ['websocket'],
    });

    socket.on('notification_update', (updatedNotifications) => {
      setNotifications(updatedNotifications);
    });

    return () => {
      socket.disconnect();
    };
  }, [setNotifications]);

  const handleAction = async (notificationId, action, additionalData) => {
    try {
      await handleNotification(notificationId, action, additionalData);
      await refetchNotifications();
    } catch (error) {
      console.error('알림 처리 중 오류 발생:', error);
      alert('알림 처리 중 오류가 발생했습니다. 다시 시도해 주세요.');
    }
  };

  const handleNotificationClick = async (notification) => {
    await readNotification(notification._id);
    await refetchNotifications();
    navigate(`/projects/${notification.project_id}`);
  };

  const unreadNotifications = notifications.filter(notification => !notification.is_read);

  return (
    <div className="fixed bottom-4 left-4 z-50 max-w-[calc(100vw-2rem)] overflow-x-auto text-gray-700">
      <div className="p-4 mb-4 bg-white rounded-lg shadow-l">
        <div className="flex items-center justify-between mb-2">
          <h3 className="font-bold text-md">
            알림
            {unreadNotifications.length > 0 && (
              <span className="px-2 py-1 ml-1 text-xs text-white bg-red-500 rounded-full">
                {unreadNotifications.length}
              </span>
            )}
          </h3>
          <button
            onClick={toggleDisplay}
            className="p-1 text-gray-500 hover:text-gray-700"
          >
            {isDisplayExpanded ? (
              <FaChevronUp className="w-5 h-5" />
            ) : (
              <FaChevronDown className="w-5 h-5" />
            )}
          </button>
        </div>
        {isDisplayExpanded && unreadNotifications.length === 0 && (
          <div className="flex items-center justify-center mr-12">
          <p className="text-sm text-gray-500">새로운 알림이 없습니다.</p>
        </div>
      )}
      </div>
      {isDisplayExpanded && unreadNotifications.map((notification) => (
        <div key={notification._id} className="flex items-center justify-between p-4 mb-4 bg-white rounded-lg shadow-lg">
          <div className="flex-grow mr-4">
            {notification.type === 'signup' ? (
              <div className="grid grid-cols-2 gap-2 text-xs">
                <p><span className="font-semibold">이름:</span> {notification.name}</p>
                <p><span className="font-semibold">이메일:</span> {notification.email}</p>
                <p><span className="font-semibold">신청일:</span> {new Date(notification.created_at).toLocaleString()}</p>
              </div>
            ) : notification.type === 'mention' ? (
              <div className="grid grid-cols-2 gap-2 text-xs">
                <p><span className="font-semibold">멘션:</span> {notification.mentioned_by}님이 당신을 언급했습니다.</p>
                <p><span className="font-semibold">프로젝트:</span> {notification.project_name}</p>
                <p><span className="font-semibold">메시지:</span> {notification.message_content}</p>
                <p><span className="font-semibold">시간:</span> {new Date(notification.created_at).toLocaleString()}</p>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-2 text-xs">
                <p><span className="font-semibold">프로젝트:</span> {notification.project_name}</p>
                <p><span className="font-semibold">PO 번호:</span> {notification.po_number}</p>
                <p><span className="font-semibold">정산 날짜:</span> {new Date(notification.requested_date).toLocaleString()}</p>
                <p><span className="font-semibold">작업자:</span> {notification.requester_name}</p>
                <p><span className="font-semibold">분량:</span> {formatNumber(notification.volume)}</p>
                <p><span className="font-semibold">작업비:</span> {formatNumber(notification.total, notification.currency)}</p>
              </div>
            )}
          </div>
          <div className="flex space-x-2">
            {notification.type === 'signup' ? (
              <>
                <button
                  onClick={async () => {
                    await handleAction(notification._id, 'reject', { type: 'signup', name: notification.name });
                    await refetchNotifications();
                  }}
                  className="px-2 py-1 text-xs text-white transition-colors bg-red-500 rounded hover:bg-red-600"
                >
                  거부
                </button>
                <button
                  onClick={async () => {
                    await handleAction(notification._id, 'approve', { type: 'signup', name: notification.name });
                    await refetchNotifications();
                  }}
                  className="px-2 py-1 text-xs text-white transition-colors bg-green-500 rounded hover:bg-green-600"
                >
                  승인
                </button>
              </>
            ) : notification.type === 'mention' ? (
              <button
                onClick={() => handleNotificationClick(notification)}
                className="px-2 py-1 text-xs text-white transition-colors bg-blue-500 rounded hover:bg-blue-600"
              >
                이동
              </button>
            ) : (
              <>
                <button
                  onClick={async () => {
                    await handleAction(notification._id, 'reject', { type: 'po', po_id: notification.po_id, requested_date: notification.requested_date });
                    await refetchNotifications();
                  }}
                  className="px-2 py-1 text-xs text-white transition-colors bg-red-500 rounded hover:bg-red-600"
                >
                  거부
                </button>
                <button
                  onClick={async () => {
                    await handleAction(notification._id, 'approve', { type: 'po', po_id: notification.po_id, requested_date: notification.requested_date });
                    await refetchNotifications();
                  }}
                  className="px-2 py-1 text-xs text-white transition-colors bg-green-500 rounded hover:bg-green-600"
                >
                  승인
                </button>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationDisplay;