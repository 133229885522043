import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import { OneButtonModal } from '../../components/commons/Modal'; // 모달 컴포넌트 경로에 맞게 수정
import Dropzone from '../../components/commons/ProfileDropzone'; // Dropzone 컴포넌트 경로에 맞게 수정

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function SignupForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    division: '',
    group: '',
    position: '',
    skypeId: '',
    status: '',
    image: '',
    points: 0, 
    password: '',
    confirmPassword: ''
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    division: [],
    group: [],
    position: [],
    status: []
  });

  const [modalInfo, setModalInfo] = useState({ show: false, title: '', body: '', onSave: null });

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get('/getDropdown');
        const dropdownData = response.data.reduce((acc, item) => {
          acc[item.type] = item.values;
          return acc;
        }, {});
        setDropdownOptions((prevOptions) => ({ ...prevOptions, ...dropdownData }));

        // Set default values for dropdowns
        setFormData((prevFormData) => ({
          ...prevFormData,
          division: dropdownData.division?.[0] || '',
          group: dropdownData.group?.[0] || '',
          position: dropdownData.position?.[0] || '',
          status: dropdownData.status?.[0] || ''
        }));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageUpload = (imageData) => {
    setFormData({ ...formData, image: imageData });
  };

  const checkNameExists = async (name) => {
    try {
      const response = await axios.post(`/checkName`, { name });
      return response.data.exists;
    } catch (error) {
      console.error('Error checking name:', error);
      return false;
    }
  };

  const handleCloseModal = () => {
    setModalInfo({ ...modalInfo, show: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setModalInfo({
        show: true,
        title: '오류',
        body: '비밀번호가 일치하지 않습니다.',
        onSave: handleCloseModal
      });
      return;
    }
    const nameExists = await checkNameExists(formData.name);
    if (nameExists) {
      setModalInfo({
        show: true,
        title: '오류',
        body: '이미 같은 이름을 가진 사용자가 존재합니다.',
        onSave: handleCloseModal
      });
      return;
    }
    try {
      await axios.post(`/signup`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setModalInfo({
        show: true,
        title: '요청완료',
        body: '회원가입을 요청하였습니다.',
        onSave: () => {
          handleCloseModal();
          navigate('/home');
        }
      });
    } catch (error) {
      console.error('Error during signup:', error);
      setModalInfo({
        show: true,
        title: '오류',
        body: error.response && error.response.data.error ? error.response.data.error : '서버 오류가 발생했습니다.',
        onSave: handleCloseModal
      });
    }
  };

  return (
    <div className="min-h-screen p-0 bg-gray-150 sm:p-12">
      <div className="max-w-md px-6 py-12 mx-auto bg-white border-0 shadow-lg sm:rounded-3xl">
        <h1 className="mb-8 text-2xl font-bold">회원가입</h1>
        <form onSubmit={handleSubmit} className="flex flex-wrap">
          <div className="relative z-0 flex items-center w-full mb-5">
            <Dropzone onImageUpload={handleImageUpload} />
          </div>
          {[
            { name: 'name', type: 'text', label: 'Name' },
            { name: 'email', type: 'email', label: 'Email' },
            { name: 'password', type: 'password', label: 'Password' },
            { name: 'confirmPassword', type: 'password', label: 'Confirm Password' },
            { name: 'phone', type: 'text', label: 'Phone' },
            { name: 'skypeId', type: 'text', label: 'Skype ID' }
          ].map(({ name, type, label }) => (
            <div key={name} className="relative z-0 w-full mb-5">
              <input
                type={type}
                name={name}
                value={formData[name]}
                onChange={handleChange}
                placeholder=" "
                required
                className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black"
              />
              <label
                htmlFor={name}
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 uppercase"
              >
                {label}
              </label>
            </div>
          ))}
          {[
            { name: 'division', label: 'Division' },
            { name: 'group', label: 'Group' },
            { name: 'position', label: 'Position' },
            { name: 'status', label: 'Status' }
          ].map(({ name, label }) => (
            <div key={name} className="relative z-0 w-full mb-5">
              <select
                name={name}
                value={formData[name]}
                onChange={handleChange}
                required
                className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black"
              >
                {dropdownOptions[name].map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
              <label
                htmlFor={name}
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 uppercase"
              >
                {label}
              </label>
            </div>
          ))}
          <div className="flex justify-end w-full p-2">
            <button type="submit" className="p-2 text-white bg-blue-500 rounded hover:bg-blue-700">회원가입</button>
          </div>
        </form>
      </div>
      {modalInfo.show && (
        <OneButtonModal
          id="signupModal"
          title={modalInfo.title}
          body={modalInfo.body}
          onSave={modalInfo.onSave}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
}

export default SignupForm;