import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import CustomSelect from '../components/commons/modal/CustomSelect';
import { formatNumber } from '../components/commons/Utils';

const InvoiceContent = ({ projects = [], startDate, endDate }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    if (startDate && endDate) {
      setSelectedYear(startDate.getFullYear());
    }
  }, [startDate, endDate]);

  // SDate 기준으로 프로젝트 정렬
  const sortedProjects = projects.slice().sort((a, b) => new Date(a.SDate) - new Date(b.SDate));

  // 선택된 년도의 프로젝트 필터링 (해당 년도에 진행 중인 프로젝트 포함)
  const filteredProjects = sortedProjects.filter(project => {
    const projectStartDate = new Date(project.SDate);
    const projectEndDate = new Date(project.DDate);
    return (!startDate || projectEndDate >= startDate) && (!endDate || projectStartDate <= endDate);
  });

  // 월별 정산 상태 데이터 생성
  const monthlyStatusData = Array.from({ length: 12 }, () => ({
    작업대기: 0,
    작업진행: 0,
    작업완료: 0,
    작업중단: 0,
    작업취소: 0
  }));

  filteredProjects.forEach(project => {
    project.tasks.forEach(task => {
      const taskStatus = task.Status;
      const taskTotalInKRW = parseFloat(task.totalInKRW) || 0; // 문자열을 숫자로 변환, 변환 실패 시 0으로 설정
      const taskStartDate = new Date(task.SDate);
      const taskEndDate = new Date(task.DDate);
      const taskDays = (taskEndDate - taskStartDate) / (1000 * 60 * 60 * 24) + 1; // 작업일 계산
      const dailyValue = taskTotalInKRW / taskDays; // 일일 값 계산

      // SDate와 DDate 사이의 모든 월에 대해 합산
      for (let date = new Date(taskStartDate); date <= taskEndDate; date.setDate(date.getDate() + 1)) {
        const month = date.getMonth();
        const year = date.getFullYear();

        if (year === selectedYear) {
          if (taskStatus === '작업대기') {
            monthlyStatusData[month].작업대기 += dailyValue;
          } else if (taskStatus === '작업진행') {
            monthlyStatusData[month].작업진행 += dailyValue;
          } else if (taskStatus === '작업완료') {
            monthlyStatusData[month].작업완료 += dailyValue;
          } else if (taskStatus === '작업중단') {
            monthlyStatusData[month].작업중단 += dailyValue;
          } else if (taskStatus === '작업취소') {
            monthlyStatusData[month].작업취소 += dailyValue;
          }
        }
      }
    });
  });

  const statusSeries = ['작업대기', '작업진행', '작업완료', '작업중단', '작업취소'].map(status => ({
    name: status,
    data: monthlyStatusData.map(monthData => Math.round(monthData[status]))
  }));

  // 사용 가능한 년도 목록 생성
  const years = Array.from(new Set(projects.map(project => new Date(project.SDate).getFullYear())));

  return (
    <div className="w-full p-2 mb-2 text-center bg-gray-150" style={{ height: 'auto' }}>
      <div className="grid grid-cols-1 gap-4 mt-0 md:grid-cols-2 lg:grid-cols-3">
        {projects.length > 0 ? (
          <>
            <div className="col-span-1 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">월간 정산</h2>
              <div className="mb-2">
                <CustomSelect
                  value={{ value: selectedYear, label: selectedYear }}
                  onChange={(option) => setSelectedYear(option.value)}
                  options={years.map(year => ({ value: year, label: year }))}
                />
              </div>
              <Chart
                options={{
                  chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true
                  },
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: '55%',
                      endingShape: 'rounded'
                    },
                  },
                  dataLabels: {
                    enabled: false
                  },
                  stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                  },
                  xaxis: {
                    categories: Array.from({ length: 12 }, (_, i) => new Date(selectedYear, i, 1).toLocaleString('default', { month: 'short' })),
                  },
                  yaxis: {
                    title: {
                      text: '전체 작업 금액'
                    },
                    labels: {
                      formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                    }
                  },
                  tooltip: {
                    shared: true,
                    intersect: false,
                    x: {
                      formatter: (val) => `Month: ${val}`
                    },
                    y: {
                      formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                    }
                  },
                  legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                  }
                }}
                series={statusSeries}
                type="bar"
                height={250}
              />
            </div>
            <div className="col-span-2 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
              <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">상태별 정산</h2>
              <div className="mb-2">
                <CustomSelect
                  value={{ value: selectedYear, label: selectedYear }}
                  onChange={(option) => setSelectedYear(option.value)}
                  options={years.map(year => ({ value: year, label: year }))}
                />
              </div>
              <Chart
                options={{
                  chart: {
                    height: 350,
                    type: 'line',
                    stacked: false
                  },
                  stroke: {
                    width: [0, 4]
                  },
                  dataLabels: {
                    enabled: true,
                    enabledOnSeries: [1],
                    formatter: function (val) {
                      return formatNumber(val, 'KRW');
                    },
                    offsetY: -10,
                    style: {
                      fontSize: '10px',
                      colors: ['#008FFB']
                    }
                  },
                  xaxis: {
                    categories: Array.from({ length: 12 }, (_, i) => new Date(selectedYear, i, 1).toLocaleString('default', { month: 'short' })),
                    labels: {
                      rotate: -45 // 날짜를 대각선으로 출력
                    }
                  },
                  yaxis: [
                    {
                      title: {
                        text: '전체 작업 금액',
                        style: {
                          color: '#008FFB',
                        }
                      },
                      labels: {
                        style: {
                          colors: '#008FFB',
                        },
                        formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                      }
                    },
                  ],
                  tooltip: {
                    shared: true,
                    intersect: false,
                    x: {
                      formatter: (val) => `Month: ${val}`
                    },
                    y: {
                      formatter: (val) => formatNumber(val, 'KRW') // 숫자 포맷팅
                    }
                  },
                  legend: {
                    horizontalAlign: 'left',
                    offsetX: 40
                  }
                }}
                series={[
                  {
                    name: '전체 금액',
                    type: 'column',
                    data: monthlyStatusData.map(item => Math.round(item.작업대기 + item.작업진행 + item.작업완료 + item.작업중단 + item.작업취소)) // 소숫점 제거
                  },
                  {
                    name: '작업대기',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.작업대기)) // 소숫점 제거
                  },
                  {
                    name: '작업진행',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.작업진행)) // 소숫점 제거
                  },
                  {
                    name: '작업완료',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.작업완료)) // 소숫점 제거
                  },
                  {
                    name: '작업중단',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.작업중단)) // 소숫점 제거
                  },
                  {
                    name: '작업취소',
                    type: 'line',
                    data: monthlyStatusData.map(item => Math.round(item.작업취소)) // 소숫점 제거
                  }
                ]}
                type="line"
                height={250}
              />
            </div>
          </>
        ) : (
          <p>No projects available.</p>
        )}
      </div>
    </div>
  );
};

export default InvoiceContent;