import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import { SaveConfirmationModal } from '../components/commons/Modal';

axios.defaults.withCredentials = true;

function ChangeCredentials() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [modalInfo, setModalInfo] = useState({ show: false, title: '', body: '' });
  const navigate = useNavigate();

  const sendCodeMutation = useMutation(
    (emailData) => axios.post('/sendVerificationCode', emailData),
    {
      onSuccess: () => {
        setCodeSent(true); 
        setModalInfo({
          show: true,
          title: '성공',
          body: '인증 코드가 이메일로 전송되었습니다.',
          onSave: () => setModalInfo({ show: false }),
        });
      },
      onError: (error) => {
        setModalInfo({
          show: true,
          title: '오류',
          body: error.response?.data?.error || '코드 전송 중 오류가 발생했습니다.',
          onSave: () => setModalInfo({ show: false }),
        });
      },
    }
  );

  const changePasswordMutation = useMutation(
    (newCredentials) => axios.post('/changeCredentials', newCredentials),
    {
      onSuccess: () => {
        setModalInfo({
          show: true,
          title: '성공',
          body: '비밀번호가 성공적으로 변경되었습니다.',
          onSave: () => {
            setModalInfo({ show: false });
            navigate('/home');
          },
        });
      },
      onError: (error) => {
        setModalInfo({
          show: true,
          title: '오류',
          body: error.response?.data?.error || '비밀번호 변경 중 오류가 발생했습니다.',
          onSave: () => setModalInfo({ show: false }),
        });
      },
    }
  );

  // 인증 코드 전송 처리
  const handleSendCode = (e) => {
    e.preventDefault();
    sendCodeMutation.mutate({ name, email });
  };

  // 비밀번호 변경 처리
  const handleSubmit = (e) => {
    e.preventDefault();

    // 새 비밀번호와 확인 비밀번호가 일치하는지 확인
    if (newPassword !== confirmPassword) {
      setModalInfo({
        show: true,
        title: '오류',
        body: '새 비밀번호와 비밀번호 확인이 일치하지 않습니다.',
        onSave: () => setModalInfo({ show: false }),
      });
      return;
    }

    // 비밀번호가 일치하면 변경 요청 전송
    changePasswordMutation.mutate({ 
      name, 
      email, 
      new_password: newPassword, 
      verification_code: verificationCode 
    });
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
        <h2 className="mb-2 text-2xl font-bold text-center">비밀번호 변경</h2>

        {!codeSent ? (
          <form onSubmit={handleSendCode}>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700">이름</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 mt-1 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700">이메일</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-2 mt-1 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4 text-center">
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">인증 코드 전송</button>
            </div>
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="verificationCode" className="block text-gray-700">인증 코드</label>
              <input
                type="text"
                id="verificationCode"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                className="w-full p-2 mt-1 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="newPassword" className="block text-gray-700">새 비밀번호</label>
              <input
                type="password"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 mt-1 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label htmlFor="confirmPassword" className="block text-gray-700">새 비밀번호 확인</label>
              <input
                type="password"
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 mt-1 border border-gray-300 rounded"
                required
              />
            </div>
            <div className="mb-4 text-center">
              <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">비밀번호 변경</button>
            </div>
          </form>
        )}

        {modalInfo.show && (
          <SaveConfirmationModal
            id="changeCredentialsModal"
            title={modalInfo.title}
            body={modalInfo.body}
            isOpen={modalInfo.show}
            onSave={modalInfo.onSave}
            onClose={() => setModalInfo({ show: false })}
          />
        )}
      </div>
    </div>
  );
}

export default ChangeCredentials;
