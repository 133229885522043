import React, { useState } from 'react';
import PepeContent from '../../area/PepeContent';
import ReplyContent from '../../area/ReplyContent';
import VoteContent from '../../area/VoteContent';
import ReplyWindow from '../../components/commons/ReplyWindow';
import { ListButton, WriteButton } from '../commons/Buttons';

const BoardDetail = ({ postId, posts }) => {
  const [isWriting, setIsWriting] = useState(false);
  const post = posts.find(p => p.id === postId);

  if (!post) {
    return <div>게시글을 찾을 수 없습니다.</div>;
  }

  const content = post.content.length > 2500 ? post.content.slice(0, 2500) + '...' : post.content;

  return (
    <div className="board-detail">
      <hr className="border-t border-gray-100" />
      <hr className="border-t border-gray-300" />
      <h1 className="flex items-center h-10 pl-2 bg-gray-100 board-title">{post.title}</h1>
      <hr className="border-t border-gray-300" />
      <table className="w-full h-10 text-sm board-meta">
        <tbody>
          <tr className="h-10 border-b">
            <td className="pl-2 text-left align-middle">{post.author}</td>
            <td className="text-right align-middle">추천 {post.likes} | 조회수 {post.views} | 작성일 {post.date}</td>
          </tr>
        </tbody>
      </table>
      <div className="box-border p-4 border-gray-300 board-content" style={{ maxWidth: '1440px', minHeight: '917px', margin: '0 auto' }}>
        {content}
      </div>
      <div className="flex justify-between py-4 mt-4 border-b border-gray-300" style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <VoteContent />
      </div>
      <div className="flex justify-between py-4 mt-4 border-b border-gray-300" style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <PepeContent />
      </div>
      <div className="flex justify-between py-4 mt-4 border-gray-300" style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <ReplyContent />
      </div>
      <div className="p-4 mt-4 rounded" style={{ maxWidth: '1440px', margin: '0 auto'}}>
        <ReplyWindow /> 
      </div>
      
      <div className="flex justify-between py-4 mt-4 border-t border-b border-gray-300" style={{ maxWidth: '1440px', margin: '0 auto' }}>
        <ListButton />
        <WriteButton />
      </div>
    </div>
  );
};

export default BoardDetail;

