import { addDays, differenceInDays, format, isWithinInterval, startOfWeek } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomScrollbars from '../components/commons/CustomScrollbars';

const ScheduleCard = ({ projects, user }) => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [filterOption, setFilterOption] = useState('name');

  const filteredSchedule = projects.filter(project => {
    if (filterOption === 'name') {
      return project.MainPM === user?.name || 
             project.tasks.some(task => task.PMName === user?.name);
    } else if (filterOption === 'division') {
      return project.Division === user?.division;
    } else if (filterOption === 'group') {
      return project.Group === user?.group;
    }
    return false;
  }).filter(project => {
    const projectStartDate = new Date(project.SDate);
    const projectEndDate = new Date(project.DDate);
    return isWithinInterval(currentDate, { start: projectStartDate, end: projectEndDate });
  }).sort((a, b) => new Date(a.DDate) - new Date(b.DDate));

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleDateChange = (date) => {
    setCurrentDate(date);
  };

  const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 0 });
  const endOfCurrentWeek = addDays(startOfCurrentWeek, 6);

  return (
    <div className="w-full p-8 bg-white rounded-lg shadow-md md:w-auto mt-2 min-h-full">
      <div className="flex items-center justify-between mb-4">
        <div>
          <h3 className="text-lg font-semibold ">팀 캘린더</h3>
          <p className="text-sm text-gray-500">프로젝트 {filteredSchedule.length}건 진행 중</p>
        </div>
        <div className="flex items-center space-x-2">
          <select
            className="px-3 py-1 text-gray-700 bg-gray-100 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={filterOption}
            onChange={(e) => setFilterOption(e.target.value)}
          >
            <option value="name">이름</option>
            <option value="division">본부</option>
            <option value="group">그룹</option>
          </select>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex space-x-2">
          {daysOfWeek.map((day, index) => {
            const dayDate = addDays(startOfCurrentWeek, index);
            return (
              <div key={index} className={`text-center ${dayDate.getDate() === currentDate.getDate() ? 'text-red-500' : 'text-gray-500'}`}>
                <div className={`w-8 h-8 rounded-full ${dayDate.getDate() === currentDate.getDate() ? 'bg-red-500 text-white' : ''} flex items-center justify-center`}>
                  {day}
                </div>
                <div className="text-xs">{format(dayDate, 'd')}</div>
              </div>
            );
          })}
        </div>
      </div>
      <CustomScrollbars style={{ minHeight: '178px' }}>
        <div className="space-y-4">
          {filteredSchedule.map((project, index) => {
            const daysUntilDue = differenceInDays(new Date(project.DDate), currentDate);
            const color = daysUntilDue <= 3 ? 'text-red-500' : daysUntilDue <= 7 ? 'text-yellow-500' : 'text-blue-500';
            return (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <div className={`w-1 h-12 bg-blue-500 rounded`}></div>
                  <div>
                    <div className={`text-sm font-semibold ${color}`}>{format(new Date(project.SDate), 'MM/dd')} - {format(new Date(project.DDate), 'MM/dd')}</div>
                    <div className="text-sm">{project.ProjectName}</div>
                    <div className="text-xs text-gray-500">Lead by <span className="text-blue-500">{project.MainPM}</span></div>
                  </div>
                </div>
                <button className="px-3 py-1 text-gray-700 bg-gray-100 rounded" onClick={() => navigate(`/projects/${project._id}`)}>View</button>
              </div>
            );
          })}
        </div>
      </CustomScrollbars>
    </div>
  );
};

export default ScheduleCard;