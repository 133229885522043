import React, { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import '../App.css';
import BoardTitle from '../area/BoardTitle';
import WorkerContent from '../area/WorkerContent';
import axios from '../axiosConfig';
import WorkerBoard from '../boards/WorkerBoard';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';

const fetchWorkers = async () => {
  const response = await axios.get('/workers');
  return response.data;
};

const Worker = () => {
  const { isCollapsed } = useContext(SidebarContext);
  const queryClient = useQueryClient();
  const { data: workers, error, isLoading } = useQuery('workers', fetchWorkers);

  const handleAddWorkerSuccess = () => {
    queryClient.invalidateQueries('workers');
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching workers</div>;

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] ${isCollapsed ? 'pl-20' : 'pl-64'} sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-gray-150 custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title="작업자" />
        <WorkerContent workers={workers} />
        <WorkerBoard workers={workers} onAddWorkerSuccess={handleAddWorkerSuccess} />
      </main>
      <AsideContainer />
    </div>
  );
};

export default Worker;
