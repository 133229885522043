import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';
import Pagination from '../../components/bars/Pagination';
import { RightAlignedButton } from '../../components/commons/Buttons';
import { OneButtonModal } from '../../components/commons/Modal';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function CompanySettings({ itemsPerPage }) {
    const [newType, setNewType] = useState('division');
    const [newValue, setNewValue] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalValue, setModalValue] = useState('');
    const [activeTab, setActiveTab] = useState('division');
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => {
        fetchDropdownData();
    }, [currentPage, activeTab]);

    const fetchDropdownData = async () => {
        try {
            const response = await axios.get(`/getDropdownByType`, {
                params: {
                    type: activeTab,
                    page: currentPage,
                    limit: itemsPerPage
                }
            });
            setDropdownData(response.data.data);
            setTotalItems(response.data.total);
        } catch (error) {
            console.error('드랍다운 데이터 가져오기 실패:', error);
        }
    };

    const handleAddItem = async () => {
        try {
            await axios.post(`/saveDropdown`, { type: newType, value: newValue });
            alert(`${newType}가 추가되었습니다.`);
            setNewValue('');
            fetchDropdownData();
        } catch (error) {
            console.error(`${newType} 추가 실패:`, error);
            alert(`${newType} 추가에 실패했습니다.`);
        }
    };

    const handleDeleteItem = async (type, value) => {
        try {
            await axios.post(`/deleteDropdown`, { type, value });
            alert('항목이 삭제되었습니다.');
            fetchDropdownData();
        } catch (error) {
            console.error('항목 삭제 실패:', error);
            alert('항목 삭제에 실패했습니다.');
        }
    };

    const handleOpenModal = (type, value) => {
        setModalType(type);
        setModalValue(value);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveModal = () => {
        if (modalType === 'add') {
            handleAddItem();
        }
        setIsModalOpen(false);
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setNewValue(value);
        if (value) {
            const filtered = dropdownData.flatMap(item => item.values)
                .filter(item => item.toLowerCase().includes(value.toLowerCase()));
            setFilteredItems(filtered);
        } else {
            setFilteredItems([]);
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Array.isArray(dropdownData) ? dropdownData.flatMap(item => item.values.map(value => ({ type: item.type, value }))).slice(indexOfFirstItem, indexOfLastItem) : [];
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    return (
        <div className="relative overflow-x-auto bg-white shadow-lg sm:rounded-lg dark:bg-gray-800">
            <div className="flex justify-between p-4 pb-4 bg-white dark:bg-gray-900">
                <div className="flex space-x-4">
                    <button className={`px-4 py-2 font-semibold ${activeTab === 'division' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg transition-all`} onClick={() => { setActiveTab('division'); setCurrentPage(1); }}>본부</button>
                    <button className={`px-4 py-2 font-semibold ${activeTab === 'group' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg transition-all`} onClick={() => { setActiveTab('group'); setCurrentPage(1); }}>그룹</button>
                    <button className={`px-4 py-2 font-semibold ${activeTab === 'position' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg transition-all`} onClick={() => { setActiveTab('position'); setCurrentPage(1); }}>직위</button>
                    <button className={`px-4 py-2 font-semibold ${activeTab === 'status' ? 'bg-blue-600 text-white' : 'bg-gray-300 text-gray-700'} rounded-lg transition-all`} onClick={() => { setActiveTab('status'); setCurrentPage(1); }}>근무상태</button>
                </div>
                <div className="relative flex items-center">
                    <select
                        value={newType}
                        onChange={(e) => setNewType(e.target.value)}
                        className="block pt-2 text-sm bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    >
                        <option value="division">본부</option>
                        <option value="group">그룹</option>
                        <option value="position">직위</option>
                        <option value="status">근무상태</option>
                    </select>
                    <div className="relative w-full">
                        <input
                            type="text"
                            value={newValue}
                            onChange={handleInputChange}
                            className="block w-full p-2 ml-2 text-sm bg-white border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            placeholder="값을 입력하세요"
                        />
                        {filteredItems.length > 0 && (
                            <div className="absolute z-10 w-full mt-2 bg-white border border-gray-300 rounded-lg shadow-lg left-2 dark:bg-gray-700 dark:border-gray-600">
                                <ul className="overflow-auto max-h-48">
                                    {filteredItems.map((item, index) => (
                                        <li key={index} className="p-2 border-b border-gray-300 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:border-gray-600">{item}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <RightAlignedButton onClick={() => handleOpenModal('add', newValue)} className="px-4 py-2 ml-4 text-xs text-white bg-blue-500 rounded-md">Add</RightAlignedButton>
                </div>
            </div>

            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="p-4">
                            <div className="flex items-center">
                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                            </div>
                        </th>
                        <th scope="col" className="px-4 py-3">항목</th>
                        <th scope="col" className="px-4 py-3">값</th>
                        <th scope="col" className="px-4 py-3">Action</th>
                    </tr>
                </thead>
                <tbody>
                {currentItems.map((item, index) => (
                    <tr key={`${index}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="w-4 p-4">
                            <div className="flex items-center">
                                <input id={`checkbox-table-search-${index}`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor={`checkbox-table-search-${index}`} className="sr-only">checkbox</label>
                            </div>
                        </td>
                        <td className="px-6 py-4">{item.type}</td>
                        <td className="px-6 py-4">{typeof item.value === 'object' ? `${item.value.SLang} - ${item.value.TLang} : ${item.value.rate}` : item.value}</td>
                        <td className="px-6 py-4">
                            <button onClick={() => handleDeleteItem(item.type, item.value)} className="px-2 py-1 text-white bg-red-500 rounded hover:bg-red-600">삭제</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="">
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
            </div>
            {isModalOpen && (
                <OneButtonModal
                    id="modal"
                    title={modalType === 'add' ? '항목 추가' : '항목 삭제'}
                    body={modalType === 'add' ? '이 항목을 추가하시겠습니까?' : '이 항목을 삭제하시겠습니까?'}
                    onSave={handleSaveModal}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
}

export default CompanySettings;
