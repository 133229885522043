import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import ActiveProjectsCard from '../area/ActiveProjectsCard';
import BoardTitle from '../area/BoardTitle';
import EmployeeInfoCard from '../area/EmployeeInfoCard';
import QuicklinkCard from '../area/QuicklinkCard';
import ScheduleCard from '../area/ScheduleCard';
import MyProjectsCard from '../area/project/MyProjectsCard';
import axios from '../axiosConfig';
import FavoriteBar from '../components/bars/FavoriteBar';

const fetchProjects = async () => {
  const response = await axios.get('/projects');
  return response.data.projects || [];
};

const Dashboard = ({ user }) => {
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState(['']); 

  useEffect(() => {
    if (!user) {
      navigate('/guest');
    }
  }, [user, navigate]);

  const { data: projects, error, isLoading } = useQuery('projects', fetchProjects, {
    enabled: !!user,
  });

  if (!user) {
    return null; 
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching projects: {error.message}</div>;
  }

  return (
    <div>
      <BoardTitle title="대시보드" />
      <div className="flex flex-wrap w-full p-0 ml-0 rounded-lg">
        <div className="w-full p-2 md:w-1/3">
          <EmployeeInfoCard profile={user} />
          <FavoriteBar favorites={favorites} /> {/* FavoriteBar added */}
        </div>
        <div className="w-full p-2 md:w-2/3">
          <div className="flex flex-wrap">
            <div className="w-full p-2 md:w-1/2 mt-2">
              <ActiveProjectsCard projects={projects} />
            </div>
            <div className="w-full p-2 md:w-1/2 mt-2">
              <QuicklinkCard />
            </div>
            <div className="w-full p-2">
              <ScheduleCard projects={projects} user={user} />
            </div>
          </div>
        </div>
        <div className="w-full p-2 mt-4">
          <MyProjectsCard projects={projects} pmName={user.name} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
