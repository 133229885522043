import { faFileAlt, faFileExcel, faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const POFileDropzone = ({ onFileUpload, initialFiles }) => {
  const [filePreviews, setFilePreviews] = useState(initialFiles || []);

  const onDrop = useCallback((acceptedFiles) => {
    console.log('Dropped files:', acceptedFiles);  // 디버깅 로그 추가
    const newFiles = acceptedFiles.map(file => ({
      name: file.name,
      type: file.type,
      preview: URL.createObjectURL(file),
      comment: '',
      file,
      file_path: `${file.name}` // 파일 경로 추가
    }));

    setFilePreviews(prevFiles => [...prevFiles, ...newFiles]);
    onFileUpload(newFiles);
  }, [onFileUpload]);

  const handleCommentChange = (index, comment) => {
    const updatedFiles = [...filePreviews];
    updatedFiles[index].comment = comment;
    setFilePreviews(updatedFiles);
    onFileUpload(updatedFiles);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = filePreviews.filter((_, i) => i !== index);
    setFilePreviews(updatedFiles);
    onFileUpload(updatedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*,application/pdf' });

  const getFileIcon = (fileName) => {
    if (!fileName) return <FontAwesomeIcon icon={faFileAlt} className="w-16 h-16 text-gray-500" />;
    const extension = fileName.split('.').pop().toLowerCase();
    switch (extension) {
      case 'pdf':
        return <FontAwesomeIcon icon={faFilePdf} className="w-16 h-16 text-red-500" />;
      case 'doc':
      case 'docx':
        return <FontAwesomeIcon icon={faFileWord} className="w-16 h-16 text-blue-500" />;
      case 'xls':
      case 'xlsx':
        return <FontAwesomeIcon icon={faFileExcel} className="w-16 h-16 text-green-500" />;
      default:
        return <FontAwesomeIcon icon={faFileAlt} className="w-16 h-16 text-gray-500" />;
    }
  };

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })} className="flex items-center justify-center w-full h-32 p-4 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
        <input {...getInputProps()} />
        <p className="text-gray-500">파일을 여기에 드롭하거나 클릭하여 업로드하세요</p>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        {filePreviews.map((file, index) => (
          <div key={index} className="relative flex items-center mb-2">
            <div className="relative">
              {file.type && file.type.startsWith('image/') ? (
                <img src={file.preview} alt="Preview" className="object-cover w-16 h-16 mr-4" />
              ) : (
                getFileIcon(file.name)
              )}
              <button
                type="button"
                onClick={() => handleRemoveFile(index)}
                className="absolute top-0 right-0 p-1 text-white bg-red-500 rounded-full hover:bg-red-700"
                style={{ width: '24px', height: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                &times;
              </button>
            </div>
            <input
              type="text"
              placeholder="파일 코멘트 입력"
              value={file.comment}
              onChange={(e) => handleCommentChange(index, e.target.value)}
              className="flex-1 px-2 py-1 border border-gray-300 rounded-md"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default POFileDropzone;