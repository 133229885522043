import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import { DeleteModal } from '../components/commons/Modal';
import { formatNumber } from '../components/commons/Utils';
import AddProjectModal from '../components/commons/modal/AddProjectModal';
import EditProjectModal from '../components/commons/modal/EditProjectModal';

const fetchProjects = async ({ queryKey }) => {
  const [_, page, projectsPerPage, searchTerm] = queryKey;
  const response = await axios.get(`/projectboard?page=${page}&limit=${projectsPerPage}&search=${searchTerm}`);
  return response.data;
};

const ProjectBoard = ({ searchTerm, setSearchTerm }) => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [expandedProjectIds, setExpandedProjectIds] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [expandedWorkerIds, setExpandedWorkerIds] = useState([]);
  const [clientsMap, setClientsMap] = useState({});
  const [workersMap, setWorkersMap] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading, error } = useQuery(
    ['projects', currentPage, projectsPerPage, searchTerm],
    fetchProjects,
    {
      keepPreviousData: true,
      staleTime: 5000,
      onSettled: () => setLoading(false), // Set loading to false when data is fetched
    }
  );

  const { projects = [], total = 0 } = data || {};
  const pageNumbers = Array.from({ length: Math.ceil(total / projectsPerPage) }, (_, i) => i + 1);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/clients');
        const clientsData = response.data;
        const map = {};
        clientsData.forEach(client => {
          map[client.clientName] = client._id;
        });
        setClientsMap(map);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    const fetchWorkers = async () => {
      try {
        const response = await axios.get('/workers');
        const workersData = response.data;
        const map = {};
        workersData.forEach(worker => {
          map[worker.name] = worker._id;
        });
        setWorkersMap(map);
      } catch (error) {
        console.error('Error fetching workers:', error);
      }
    };

    fetchClients();
    fetchWorkers();
  }, []);

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(`/getDropdown`);
        setDropdownData(response.data);
      } catch (error) {
        console.error('Error fetching dropdown data:', error);
      }
    };

    fetchDropdownData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const search = params.get('search');
    if (search) {
      setSearchTerm(search);
    }
  }, [location.search]);

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleViewProject = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleClientClick = (clientName) => {
    const clientId = clientsMap[clientName];
    if (clientId) {
      navigate(`/resource/clients/${clientId}`);
    } else {
      console.error(`Client ID not found for ${clientName}`);
    }
  };

  const handleWorkerClick = (workerName) => {
    const workerId = workersMap[workerName];
    if (workerId) {
      navigate(`/resource/workers/${workerId}`);
    } else {
      console.error(`Worker ID not found for ${workerName}`);
    }
  };

  const toggleWorkerExpand = (workerId) => {
    setExpandedWorkerIds(prevExpandedWorkerIds => {
    if (prevExpandedWorkerIds.includes(workerId)) {
        return prevExpandedWorkerIds.filter(id => id !== workerId);
      } else {
        return [...prevExpandedWorkerIds, workerId];
      }
    });
  };

  const sortedProjects = React.useMemo(() => {
    let sortableProjects = [...projects];
    if (sortConfig.key !== null) {
      sortableProjects.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProjects;
  }, [projects, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleEditProject = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
    setDropdownOpen(null);
  };

  const handleAddProject = () => {
    setSelectedProject(null);
    setIsAddModalOpen(true);
    setDropdownOpen(null);
  };

  const handleDeleteProject = (project) => {
    setSelectedProject(project);
    setIsDeleteModalOpen(true);
    setDropdownOpen(null);
  };

  const confirmDeleteProject = async () => {
    try {
      await axios.delete(`/deleteProject/${selectedProject._id}`);
      setIsDeleteModalOpen(false);
      queryClient.invalidateQueries(['projects']);
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
    queryClient.invalidateQueries(['projects']);
  };

  const handleEditModalClose = () => {
    setIsModalOpen(false);
    queryClient.invalidateQueries(['projects']);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    queryClient.invalidateQueries(['projects']);
  };

  const toggleExpand = (projectId) => {
    setExpandedProjectIds((prevExpandedProjectIds) =>
      prevExpandedProjectIds.includes(projectId)
        ? prevExpandedProjectIds.filter(id => id !== projectId)
        : [...prevExpandedProjectIds, projectId]
    );
  };

  const toggleDropdown = (projectId) => {
    setDropdownOpen(dropdownOpen === projectId ? null : projectId);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === 'Enter') {
      setLoading(true); // Set loading state to true when Enter is pressed
      setCurrentPage(1); // Reset to the first page on search
      queryClient.invalidateQueries(['projects', currentPage, projectsPerPage, searchTerm]);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const getStatusBadge = (status) => {
    switch (status) {
      case '협상':
        return <span className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">협상</span>;
      case '진행':
        return <span className="bg-green-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">진행</span>;
      case '완료':
        return <span className="bg-purple-200 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">완료</span>;
      case '실주':
        return <span className="bg-orange-200 text-orange-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">실주</span>;
      case '중단':
        return <span className="bg-red-200 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">중단</span>;
      default:
        return <span className="bg-blue-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{status}</span>;
    }
  };

  const getWorkerStatusBadge = (status) => {
    switch (status) {
      case '작업대기':
        return <span className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">작업대기</span>;
      case '작업진행':
        return <span className="bg-green-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">작업진행</span>;
      case '작업완료':
        return <span className="bg-purple-200 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">작업완료</span>;
      case '작업중단':
        return <span className="bg-orange-200 text-orange-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">작업중단</span>;
      case '작업취소':
        return <span className="bg-red-200 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">작업취소</span>;
      default:
        return <span className="bg-blue-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{status}</span>;
    }
  };

  return (
    <div className="relative m-2 overflow-x-auto shadow-md sm:rounded-lg">
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div role="status">
            <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
            </svg>
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <div className="flex flex-wrap items-center justify-between px-4 py-4 space-y-4 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleSearchKeyDown}
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
        <button
          onClick={handleAddProject}
          className="inline-flex items-center px-4 py-2 mr-4 text-sm font-medium text-white bg-blue-600 rounded-lg shadow-md hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-800"
        >
          프로젝트 등록
        </button>
      </div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-800 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('ProjectName')}>
              프로젝트
              {sortConfig.key === 'ProjectName' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('ClientName')}>
              클라이언트
              {sortConfig.key === 'ClientName' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('EndClient')}>
              최종 클라이언트
              {sortConfig.key === 'EndClient' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('Division')}>
              본부
              {sortConfig.key === 'Division' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('Group')}>
              그룹
              {sortConfig.key === 'Group' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('MainPM')}>
              담당자
              {sortConfig.key === 'MainPM' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('SDate')}>
              시작일
              {sortConfig.key === 'SDate' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('DDate')}>
              마감일
              {sortConfig.key === 'DDate' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('Total')}>
              총액
              {sortConfig.key === 'Total' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer min-w-[4em] whitespace-nowrap" onClick={() => requestSort('Status')}>
              상태
              {sortConfig.key === 'Status' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">Action</th>
            <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">Action2</th>
          </tr>
        </thead>
        <tbody>
          {sortedProjects.map((project) => (
            <React.Fragment key={project._id}>
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-6 py-4 font-bold first-line:hover:underline cursor-pointer text-md min-w-[4em] whitespace-nowrap" onClick={() => handleProjectClick(project._id)}>
                {truncateText(project.ProjectName, 30)}
                <div className="flex flex-wrap mt-1">
                  {project.tags && project.tags.map((tag, index) => (
                    <span key={index} className="bg-gray-200 text-gray-500 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">
                      #{tag}
                    </span>
                  ))}
                </div>
              </td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap cursor-pointer first-line:hover:underline" onClick={() => handleClientClick(project.ClientName)}>{project.ClientName}</td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.EndClient}</td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.Division}</td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.Group}</td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.MainPM}</td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.SDate}</td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{project.DDate}</td>
                <td className="px-6 py-4 font-bold text-blue-600 min-w-[4em] whitespace-nowrap">
                {formatNumber(project.Total, project.Currency)}
                </td>
                <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{getStatusBadge(project.Status)}</td>
                <td className="px-6 py-4">
                  <button onClick={() => toggleExpand(project._id)} className={`font-medium hover:underline ${expandedProjectIds.includes(project._id) ? 'text-red-600' : 'text-blue-600'}`}>
                    {expandedProjectIds.includes(project._id) ? '접기' : '펼치기'}
                  </button>
                </td>
                <td className="relative px-6 py-4">
                  <button onClick={() => toggleDropdown(project._id)} id="dropdownMenuIconHorizontalButton" className="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-white rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none dark:text-white focus:ring-gray-50 dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-600" type="button">
                    <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                      <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"/>
                    </svg>
                  </button>
                  {dropdownOpen === project._id && (
                    <div id="dropdownDotsHorizontal" className="absolute right-0 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
                      <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconHorizontalButton">
                        <li>
                          <a href="#" onClick={() => handleEditProject(project)} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Edit</a>
                        </li>
                        <li>
                          <a href="#" onClick={() => handleDeleteProject(project)} className="block px-4 py-2 text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Delete</a>
                        </li>
                      </ul>
                    </div>
                  )}
                </td>
              </tr>
              {expandedProjectIds.includes(project._id) && (
                <tr className="bg-gray-50 dark:bg-gray-700">
                  <td colSpan="12" className="px-6 py-4">
                    <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                      <h4 className="mb-2 text-lg font-semibold">세부 작업</h4>
                      {project.tasks.length > 0 ? (
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                              <th scope="col" className="px-6 py-3 font-bold min-w-[4em] whitespace-nowrap">프로젝트 유형</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">PM</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">출발어</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">도착어</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">분량</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단위</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단가</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">총액</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">상태</th>
                              <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.tasks.map((task, index) => (
                              <React.Fragment key={index}>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                  <td className="px-6 py-4 font-bold min-w-[4em] whitespace-nowrap">{task.ProjectType}</td>
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.PMName}</td>
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.SLang}</td>
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.TLang}</td>
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{Number(task.ProjectVolume).toLocaleString()}</td>
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.Unit}</td>
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{formatNumber(task.Rate, project.Currency)}</td>
                                  <td className="px-6 py-4 font-bold text-blue-600 min-w-[4em] whitespace-nowrap">{formatNumber(task.Total, project.Currency)}</td>
                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{getStatusBadge(task.Status)}</td>
                                  <td className="px-6 py-4">
                                    <button onClick={() => toggleWorkerExpand(task.id)} className={`font-medium hover:underline ${expandedWorkerIds.includes(task.id) ? 'text-red-600' : 'text-blue-600'}`}>
                                      {expandedWorkerIds.includes(task.id) ? '접기' : '펼치기'}
                                    </button>
                                  </td>
                                </tr>
                                {expandedWorkerIds.includes(task.id) && (
                                  <tr className="bg-gray-50 dark:bg-gray-700">
                                    <td colSpan="12">
                                      <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                                        <h4 className="mb-2 text-lg font-semibold">작업자</h4>
                                        {task.Workers && task.Workers.length > 0 ? (
                                          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                              <tr>
                                                <th scope="col" className="px-6 py-3 font-bold min-w-[4em] whitespace-nowrap">이름</th>
                                                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">이메일</th>
                                                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">작업 유형</th>
                                                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">출발어</th>
                                                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">도착어</th>
                                                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단가</th>
                                                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">작업비</th>
                                                <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">상태</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {task.Workers.map((worker, idx) => (
                                                <tr key={idx} className="bg-white border-b hover:bg-gray-50">
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap cursor-pointer hover:underline" onClick={() => handleWorkerClick(worker.name)}>{worker.name}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.email}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.taskType}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.sLang}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.tLang}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{formatNumber(worker.rate, worker.currency)}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(worker.total, worker.currency)}</td>
                                                  <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{getWorkerStatusBadge(worker.Status)}</td>
                                                </tr>
                                              ))}
                                            </tbody>
                                          </table>
                                        ) : (
                                          <p>No workers available.</p>
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <p>No tasks available.</p>
                      )}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <AddProjectModal isOpen={isAddModalOpen} onClose={handleAddModalClose} dropdownData={dropdownData} fetchProjects={handleAddModalClose} />
      <EditProjectModal isOpen={isModalOpen} onClose={handleEditModalClose} project={selectedProject} dropdownData={dropdownData} fetchProjects={handleEditModalClose} />
      <DeleteModal isOpen={isDeleteModalOpen} onClose={handleDeleteModalClose} onSave={confirmDeleteProject} />
      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
    </div>
  );
};

export default ProjectBoard;
