import React, { useState } from 'react';
import { FaClipboardList, FaFileSignature, FaUserClock } from 'react-icons/fa';

const FavoriteBar = () => {
  const [newPosts, setNewPosts] = useState({ board: 1, approval: 1, work: 0 });

  const favorites = [
    { name: '게시판', icon: <FaClipboardList />, link: 'https://boards.office.hiworks.com/board/postlist/recent?page=1&searchValue=&searchType=word&filterType=all', key: 'board' },
    { name: '전자결재', icon: <FaFileSignature />, link: 'https://approval.office.hiworks.com/enkoline.com/approval/document/lists/W', key: 'approval' },
    { name: '근무', icon: <FaUserClock />, link: 'https://hr-work.office.hiworks.com/personal/index', key: 'work' }
  ];

  // useEffect(() => {
  //   const fetchNewPosts = async () => {
  //     try {
  //       const boardResponse = await axios.get('#', {
  //         headers: {
  //           'Authorization': `Bearer ${process.env.REACT_APP_HIWORKS_API_KEY}`
  //         }
  //       });
  //       const approvalResponse = await axios.get('#', {
  //         headers: {
  //           'Authorization': `Bearer ${process.env.REACT_APP_HIWORKS_API_KEY}`
  //         }
  //       });
  //       const workResponse = await axios.get('#', {
  //         headers: {
  //           'Authorization': `Bearer ${process.env.REACT_APP_HIWORKS_API_KEY}`
  //         }
  //       });

  //       setNewPosts({
  //         board: boardResponse.data.newPosts,
  //         approval: approvalResponse.data.newDocuments,
  //         work: workResponse.data.newEntries
  //       });
  //     } catch (error) {
  //       console.error('Error fetching new posts:', error);
  //     }
  //   };

  //   fetchNewPosts();
  // }, []);

  return (
    <div className="p-2 mt-5 bg-white rounded-lg shadow-md">
      <h3 className="mb-4 text-lg font-semibold">
        <span className="pb-1 border-b-2 border-blue-500">하이웍스 오피스</span>
      </h3>
      <ul className="flex items-center justify-around pt-1 mt-2 border-gray-200">
        {favorites.map((favorite, index) => (
          <li key={index} className="flex flex-col items-center py-1 border-gray-200 last:border-r-0">
            <a href={favorite.link} target="_blank" rel="noopener noreferrer" className="relative text-center text-gray-700 hover:text-blue-500">
              <div className="relative flex flex-col items-center">
                <div className="mb-1 text-xl">{favorite.icon}</div>
                {newPosts[favorite.key] > 0 && (
                  <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
                    {newPosts[favorite.key]}
                  </span>
                )}
              </div>
              <div className="mt-1 text-xs font-medium">{favorite.name}</div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FavoriteBar;