import React, { useEffect } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { formatDate, formatNumber } from '../../components/commons/Utils';
import { useNotifications } from '../../contexts/NotificationContext';

function Sidebar2() {
  const { notifications, handleNotification, readNotification, isSidebarExpanded, toggleSidebar, setNotifications, refetchNotifications } = useNotifications();
  const navigate = useNavigate();

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const socket = io(socketUrl, {
      withCredentials: true,
      transports: ['websocket'],
    });
  
    socket.on('notification_update', (updatedNotifications) => {
      setNotifications(updatedNotifications);
    });
  
    return () => {
      socket.disconnect();
    };
  }, [setNotifications]);

  const unreadNotifications = notifications.filter(notification => !notification.is_read);

  const handleNotificationClick = async (notification) => {
    await readNotification(notification._id);
    await refetchNotifications();
    navigate(`/projects/${notification.project_id}`);
  };

  return (
    <div className="sidebar2">
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-semibold">
          알림
          {unreadNotifications.length > 0 && (
            <span className="px-2 py-1 ml-1 text-xs text-white bg-red-500 rounded-full">
              {unreadNotifications.length}
            </span>
          )}
        </h3>
        <button
          onClick={toggleSidebar}
          className="p-1 text-gray-500 hover:text-gray-700"
        >
          {isSidebarExpanded ? (
            <FaChevronUp className="w-5 h-5" />
          ) : (
            <FaChevronDown className="w-5 h-5" />
          )}
        </button>
      </div>
      {isSidebarExpanded && (
        unreadNotifications.length > 0 ? (
          <div className="overflow-y-auto max-h-60">
            {unreadNotifications.map((notification) => (
              <div key={notification._id} className="p-2 mb-2 bg-gray-100 rounded">
                {notification.type === 'signup' ? (
                  <div>
                    <p className="text-sm">
                      {notification.name}님이 회원가입을 신청했습니다.
                    </p>
                    <div className="mt-1 text-xs text-gray-500">
                      <p>이메일: {notification.email}</p>
                      <p>신청일: {new Date(notification.created_at).toLocaleString()}</p>
                    </div>
                    <div className="flex justify-end mt-2 space-x-2">
                      <button
                        onClick={async () => {
                          await handleNotification(notification._id, 'reject', { type: 'signup', name: notification.name });
                          await refetchNotifications();
                        }}
                        className="px-2 py-1 text-xs text-white transition-colors bg-red-500 rounded hover:bg-red-600"
                      >
                        거부
                      </button>
                      <button
                        onClick={async () => {
                          await handleNotification(notification._id, 'approve', { type: 'signup', name: notification.name });
                          await refetchNotifications();
                        }}
                        className="px-2 py-1 text-xs text-white transition-colors bg-green-500 rounded hover:bg-green-600"
                      >
                        승인
                      </button>
                    </div>
                  </div>
                ) : notification.type === 'mention' ? (
                  <div>
                    <p className="text-sm">
                      {notification.mentioned_by}님이 당신을 언급했습니다.
                    </p>
                    <div className="mt-1 text-xs text-gray-500">
                      <p>프로젝트: {notification.project_name}</p>
                      <p>메시지: {notification.message_content}</p>
                      <p>시간: {new Date(notification.created_at).toLocaleString()}</p>
                    </div>
                    <div className="flex justify-end mt-2 space-x-2">
                      <button
                        onClick={() => handleNotificationClick(notification)}
                        className="px-2 py-1 text-xs text-white transition-colors bg-blue-500 rounded hover:bg-blue-600"
                      >
                        이동
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <p className="text-sm">
                      {notification.project_name} 프로젝트의 PO {notification.po_number}의 작업비 정산일이 {formatDate(notification.requested_date)}로 요청되었습니다.
                    </p>
                    <div className="mt-1 text-xs text-gray-500">
                      <p>요청자: {notification.requester_name}</p>
                      <p>분량: {formatNumber(notification.volume)}</p>
                      <p>작업비: {formatNumber(notification.total, notification.currency)}</p>
                    </div>
                    <div className="flex justify-end mt-2 space-x-2">
                      <button
                        onClick={async () => {
                          await handleNotification(notification._id, 'reject', { type: 'po', po_id: notification.po_id, requested_date: notification.requested_date });
                          await refetchNotifications();
                        }}
                        className="px-2 py-1 text-xs text-white transition-colors bg-red-500 rounded hover:bg-red-600"
                      >
                        거부
                      </button>
                      <button
                        onClick={async () => {
                          await handleNotification(notification._id, 'approve', { type: 'po', po_id: notification.po_id, requested_date: notification.requested_date });
                          await refetchNotifications();
                        }}
                        className="px-2 py-1 text-xs text-white transition-colors bg-green-500 rounded hover:bg-green-600"
                      >
                        승인
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-sm text-gray-500">새로운 알림이 없습니다.</p>
        )
      )}
    </div>
  );
}

export default Sidebar2;