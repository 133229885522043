export const formatNumber = (number, currency) => {
  if (number == null) return 'TBD';
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) return 'TBD';
  const formattedNumber = currency === 'KRW'
    ? parsedNumber.toFixed(0)
    : parsedNumber.toFixed(3);
  const currencySymbol = {
    'KRW': '₩',
    'USD': '$',
    'EUR': '€',
    'JPY': '¥',
    'CNY': '¥',
    'GBP': '£',
  }[currency] || '';
  return `${currencySymbol} ${formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
};

export const formatDate = (dateString) => {
  if (!dateString) return 'TBD';
  const date = new Date(dateString);
  if (isNaN(date)) return 'TBD';
  return date.toISOString().split('T')[0];
};