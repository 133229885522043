import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import '../App.css';
import BoardTitle from '../area/BoardTitle';
import axios from '../axiosConfig';
import ReportBoard from '../boards/ReportBoard';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext'; // Import SidebarContext

const fetchProjects = async (page, projectsPerPage) => {
  const response = await axios.get(`/projectboard?page=${page}&limit=${projectsPerPage}`);
  return response.data;
};

const Report = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;
  const queryClient = useQueryClient();
  const [allProjects, setAllProjects] = useState([]);
  const { isCollapsed } = useContext(SidebarContext); // Use SidebarContext

  const { data, isLoading, error } = useQuery(['projects', currentPage], () => fetchProjects(currentPage, projectsPerPage), {
    keepPreviousData: true,
  });

  useEffect(() => {
    const fetchAllProjects = async () => {
      try {
        let allProjects = [];
        let currentPage = 1;
        let totalPages = 1;

        while (currentPage <= totalPages) {
          const response = await axios.get(`/projectboard?page=${currentPage}&limit=${projectsPerPage}`);
          if (response.status === 200) {
            allProjects = [...allProjects, ...response.data.projects];
            totalPages = Math.ceil(response.data.total / projectsPerPage);
            currentPage++;
          } else {
            break;
          }
        }

        setAllProjects(allProjects);
      } catch (error) {
        console.error('Error fetching all projects:', error);
      }
    };

    fetchAllProjects();
  }, [projectsPerPage]);

  const handleAddProjectSuccess = () => {
    queryClient.invalidateQueries(['projects', currentPage]);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching projects: {error.message}</div>;

  const { projects = [], total = 0 } = data || {};
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(total / projectsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-white custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title="Report" />
        <ReportBoard projects={allProjects} />
      </main>
      <AsideContainer />
    </div>
  );
};

export default Report;
