import { faChevronLeft, faChevronRight, faExpand, faMinus, faSearch, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { io } from 'socket.io-client';
import axios from '../axiosConfig';
import CustomScrollbars from '../components/commons/CustomScrollbars';
import ChatSidebar from './ChatSidebar'; // ChatSidebar 추가

const fetchMessages = async (projectId, userName) => {
  const response = await axios.get(`/projects/${projectId}/chats`, { params: { user_name: userName } });
  return response.data;
};

const fetchUserProfile = async () => {
  const response = await axios.get('/session-user');
  const apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
  const imagePath = response.data.user.image ? `${apiUrl}/upload/profile_pics/${response.data.user.image}` : "https://via.placeholder.com/40";
  return {
    name: response.data.user.name,
    image: imagePath,
  };
};

const fetchAllUsers = async () => {
  const employeesResponse = await axios.get('/employees');
  const workersResponse = await axios.get('/workers');
  const users = [...employeesResponse.data, ...workersResponse.data];
  return users;
};

const CommentModal = ({ isOpen, onClose, projectName, projectId, onInput, userName }) => {
  const [inputMessage, setInputMessage] = useState('');
  const [isMaximized, setIsMaximized] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [modalSize, setModalSize] = useState({ width: 800, height: window.innerHeight * 2 / 3 });
  const [showRedDot, setShowRedDot] = useState(false);
  const [userProfile, setUserProfile] = useState({ name: '', image: '' });
  const [allUsers, setAllUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(0);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const modalRef = useRef(null);
  const scrollbarsRef = useRef(null);
  const inputRef = useRef(null);
  const dragStartPosition = useRef({ x: 0, y: 0 });
  const dragOffset = useRef({ x: 0, y: 0 });

  const minimizedSize = { width: 500, height: 40 };
  const queryClient = useQueryClient();

  const { data: messagesData = { current_project_chats: [], other_project_chats: [] }, refetch } = useQuery(
    ['messages', projectId, userName],
    () => fetchMessages(projectId, userName),
    {
      enabled: isOpen,
    }
  );

  const messages = messagesData.current_project_chats || [];

  const mutation = useMutation(
    (newMessage) => axios.post(`/projects/${projectId}/chats`, newMessage),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['messages', projectId, userName]);
      },
    }
  );

  useEffect(() => {
    if (modalRef.current) {
      if (isMaximized) {
        setModalSize({ width: window.innerWidth, height: window.innerHeight });
        modalRef.current.style.left = '0px';
        modalRef.current.style.top = '0px';
      } else if (isMinimized) {
        setModalSize(minimizedSize);
      } else {
        const maxWidth = window.innerWidth * 2 / 3;
        setModalSize({ width: Math.min(800, maxWidth), height: window.innerHeight * 2 / 3 });
      }
    }
  }, [isMaximized, isMinimized]);

  useEffect(() => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = await fetchUserProfile();
        setUserProfile(userProfile);
        const allUsers = await fetchAllUsers();
        setAllUsers(allUsers);
      } catch (error) {
        console.error('데이터를 불러오는 중 오류 발생:', error);
      }
    };

    fetchData();
  }, [projectId]);

  useEffect(() => {
    const uniqueUsers = [...new Set(messages.map(msg => msg.user))];
    setActiveUsers(uniqueUsers);
  }, [messages]);

  useEffect(() => {
    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const socket = io(socketUrl, {
      withCredentials: true,
      transports: ['websocket'],
    });
    socket.emit('join', projectId);

    socket.on('new_message', (message) => {
      if (document.hidden) {
        setShowRedDot(true);
      }
      queryClient.invalidateQueries(['messages', projectId, userName]);
    });

    socket.on('message_read', ({ project_id, user }) => {
      if (project_id === projectId) {
        queryClient.invalidateQueries(['messages', projectId, userName]);
      }
    });

    return () => {
      socket.emit('leave', projectId);
      socket.disconnect();
    };
  }, [projectId, queryClient, userName]);

  useEffect(() => {
    if (isOpen) {
      const readAllMessages = async () => {
        try {
          await axios.post(`/projects/${projectId}/chats/read`, { user: userProfile.name });
          queryClient.invalidateQueries(['messages', projectId]);
          setShowRedDot(false); // 레드닷 제거
        } catch (error) {
          console.error('메시지 읽음 처리 중 오류 발생:', error);
        }
      };

      readAllMessages();
    }
  }, [isOpen, projectId, userProfile.name, queryClient]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputMessage(value);
    if (onInput) {
      onInput(value);
    }

    if (value.includes('@')) {
      const lastWord = value.split(' ').pop();
      if (lastWord.startsWith('@')) {
        const searchQuery = lastWord.slice(1).toLowerCase();
        const filtered = allUsers.filter(user => user.name.toLowerCase().includes(searchQuery));
        setFilteredUsers(filtered);
        setShowAutocomplete(true);
      } else {
        setShowAutocomplete(false);
      }
    } else {
      setShowAutocomplete(false);
    }
  };

  const handleKeyPress = (e) => {
    if (showAutocomplete) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedUserIndex((prevIndex) => (prevIndex + 1) % filteredUsers.length);
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedUserIndex((prevIndex) => (prevIndex - 1 + filteredUsers.length) % filteredUsers.length);
      } else if (e.key === 'Tab') {
        e.preventDefault();
        handleUserSelect(filteredUsers[selectedUserIndex]);
      } else if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage(e);
      }
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const handleUserSelect = (user) => {
    const words = inputMessage.split(' ');
    words.pop();
    const newMessage = `${words.join(' ')} @${user.name} `;
    setInputMessage(newMessage);
    setShowAutocomplete(false);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === '') return;

    const newMessage = {
      '_id': Date.now().toString(),
      'user': userProfile.name,
      'content': inputMessage,
      'time': new Date().toISOString(),
      'readBy': [],
      'isRead': false,
      'annotations': [], // 어노테이션된 사용자 목록
    };

    try {
      await mutation.mutateAsync(newMessage);
      setInputMessage('');
      if (onInput) {
        onInput(); // 메시지 전송 후에도 부모 컴포넌트에 알림
      }
    } catch (error) {
      console.error('메시지 전송 중 오류 발생:', error);
    }
  };

  const handleMaximize = () => {
    setIsMaximized(!isMaximized);
    setIsMinimized(false);
  };

  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
    setIsMaximized(false);
  };

  const handleMouseDown = (e) => {
    dragStartPosition.current = {
      x: e.clientX,
      y: e.clientY,
    };

    const rect = modalRef.current.getBoundingClientRect();
    dragOffset.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (!modalRef.current) return; // modalRef가 null인지 확인

    const newLeft = Math.max(0, Math.min(e.clientX - dragOffset.current.x, window.innerWidth - modalRef.current.offsetWidth));
    const newTop = Math.max(0, Math.min(e.clientY - dragOffset.current.y, window.innerHeight - modalRef.current.offsetHeight));
  
    modalRef.current.style.left = `${newLeft}px`;
    modalRef.current.style.top = `${newTop}px`;
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleDoubleClick = () => {
    setIsMaximized(!isMaximized);
    setIsMinimized(false);
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
  };

  const handleDateClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const handleDateChange = (date) => {
    console.log('Selected date:', date);
  };

  const renderMessageContent = (content) => {
    const parts = (content || '').split(/(@[\wÀ-ÿ가-힣]+)/g); 
    return parts.map((part, index) => {
      if (part.startsWith('@')) {
        return (
          <span key={index} className="px-1 text-white bg-blue-500 rounded">
            {part}
          </span>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' };
    return new Date(date).toLocaleDateString('ko-KR', options);
  };

  const formatTime = (date) => {
    const options = { hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(date).toLocaleTimeString('ko-KR', options);
  };

  const handleInputClick = async () => {
    try {
      await axios.post(`/projects/${projectId}/chats/read`, { user: userProfile.name });
      queryClient.invalidateQueries(['messages', projectId]);
      setShowRedDot(false); // 레드닷 제거
    } catch (error) {
      console.error('메시지 읽음 처리 중 오류 발생:', error);
    }
  };

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  if (!isOpen) return null;

  return (
    <div onClick={handleModalClick}>
      <div className="fixed inset-0 z-50 pointer-events-none">
        <div
          ref={modalRef}
          className="flex bg-white rounded-lg shadow-lg pointer-events-auto"
          style={{
            position: 'absolute',
            width: `${modalSize.width}px`,
            height: `${modalSize.height}px`,
            left: `calc(50% - ${modalSize.width / 2}px)`,
            top: `calc(50% - ${modalSize.height / 2}px)`,
            resize: isMinimized ? 'none' : 'both',
            overflow: 'hidden',
            minWidth: '450px', // 최소 너비 설정
            minHeight: '40px', // 최소 높이 설정
          }}
        >
          <div className={`transition-width duration-300 ${isSidebarCollapsed ? 'w-0' : 'w-64'}`}>
            {!isSidebarCollapsed && <ChatSidebar userName={userProfile.name} />}
          </div>
          <div className="flex flex-col flex-grow">
            <div
              className="flex items-center justify-between p-2 bg-gray-100 border-b cursor-move select-none"
              onMouseDown={handleMouseDown}
              onDoubleClick={handleDoubleClick}
            >
              <div className="flex items-center">
                <button onClick={toggleSidebar} className="p-1 text-gray-500 hover:text-gray-900">
                  <FontAwesomeIcon icon={isSidebarCollapsed ? faChevronRight : faChevronLeft} />
                </button>
                <h2 className="ml-2 text-lg font-semibold">{projectName}</h2>
                {showRedDot && <div className="w-3 h-3 ml-2 bg-red-500 rounded-full animate-pulse"></div>}
              </div>
              <div className="flex space-x-2">
                <button onClick={handleMinimize} className="p-1 text-gray-500 hover:text-gray-900">
                  <FontAwesomeIcon icon={faMinus} />
                  <span className="sr-only">Minimize</span>
                </button>
                <button onClick={handleMaximize} className="p-1 text-gray-500 hover:text-gray-900">
                  <FontAwesomeIcon icon={faExpand} />
                  <span className="sr-only">Maximize</span>
                </button>
                <button onClick={onClose} className="p-1 text-gray-500 hover:text-gray-900">
                  <FontAwesomeIcon icon={faTimes} />
                  <span className="sr-only">Close</span>
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between p-2 bg-gray-100 border-b">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faUser} className="text-gray-500 cursor-pointer" onClick={toggleDrawer} />
                <span className="ml-2 text-sm font-medium">{activeUsers.length}</span>
              </div>
              <button className="p-1 text-gray-500 hover:text-gray-900">
                <FontAwesomeIcon icon={faSearch} />
                <span className="sr-only">Search</span>
              </button>
            </div>

            {showDrawer && (
              <div className="p-2 bg-gray-100 border-b">
                <h3 className="text-sm font-medium">참여인원</h3>
                <ul>
                  {activeUsers.map((user, index) => {
                    const userObj = allUsers.find(u => u.name === user);
                    const userImage = userObj ? `${process.env.REACT_APP_API_URL.replace('/api', '')}/upload/profile_pics/${userObj.image}` : "https://via.placeholder.com/40";
                    return (
                      <li key={index} className="flex items-center p-2 cursor-pointer hover:bg-gray-200">
                        <img className="w-8 h-8 mr-2 rounded-full" src={userImage} alt="User profile" />
                        <span className="text-sm font-medium">{user}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {!isMinimized && (
              <>
                <CustomScrollbars ref={scrollbarsRef}>
                  <div className="flex-1 p-2 overflow-y-auto">
                    <div className="my-2 text-center text-gray-500 cursor-pointer" onClick={handleDateClick}>
                      {formatDate(new Date())}
                    </div>
                    {showDatePicker && (
                      <DatePicker
                        selected={new Date()}
                        onChange={handleDateChange}
                        inline
                      />
                    )}
                    {messages.map((msg) => {
                      const userObj = allUsers.find(u => u.name === msg.user);
                      const userImage = userObj ? `${process.env.REACT_APP_API_URL.replace('/api', '')}/upload/profile_pics/${userObj.image}` : "https://via.placeholder.com/40";
                      return (
                        <div
                          key={msg._id}
                          className={`flex items-start gap-2 mb-2 ${msg.user === userProfile.name ? 'justify-end' : ''}`}
                        >
                          <img className="w-12 h-12 rounded-full" src={msg.user === userProfile.name ? userProfile.image : userImage} alt="User image" />
                          <div className="flex flex-col w-full max-w-[180px] leading-1.5">
                            <div className="flex items-center space-x-2 rtl:space-x-reverse">
                              <span className="text-sm font-semibold text-gray-900 dark:text-white">{msg.user}</span>
                              <span className="text-xs font-normal text-gray-500 dark:text-gray-400">{formatTime(msg.time)}</span>
                            </div>
                            <p className="py-1 text-sm font-normal text-gray-900 dark:text-white">{renderMessageContent(msg.content)}</p>
                            <span className="text-xs font-normal text-gray-500 dark:text-gray-400">
                              {msg.readBy && msg.readBy.length > 0 ? `읽음(${msg.readBy.length})` : '안읽음'}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </CustomScrollbars>

                <form onSubmit={handleSendMessage} className="relative flex items-center px-3 py-2 bg-gray-50 dark:bg-gray-700">
                  {showAutocomplete && (
                    <div
                      className="absolute z-10 bg-white border rounded-lg shadow-lg"
                      style={{
                        bottom: '100%', // 메시지 입력창 위에 자동완성 리스트를 표시합니다.
                        left: 0,
                        width: '100%',
                        maxHeight: '150px',
                        overflowY: 'auto',
                      }}
                    >
                      {filteredUsers.map((user, index) => (
                        <div
                          key={user.id}
                          className={`flex items-center px-3 py-2 cursor-pointer ${index === selectedUserIndex ? 'bg-gray-200' : ''}`}
                          onMouseDown={() => handleUserSelect(user)}
                        >
                          <span className="text-sm font-medium">{user.name}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  <textarea
                    id="chat"
                    ref={inputRef}
                    rows="1"
                    className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Your message..."
                    value={inputMessage}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    onClick={handleInputClick} // 입력창 클릭 시 읽음 처리
                    style={{ minHeight: 'calc(1.5em)', maxHeight: 'calc(5 * 1.5em)' }}
                  ></textarea>
                  <button
                    type="submit"
                    className={`inline-flex justify-center p-2 rounded-full cursor-pointer ${inputMessage.trim() ? 'text-blue-600 hover:bg-blue-100' : 'text-gray-500'}`}
                  >
                    <svg className="w-5 h-5 rotate-90 rtl:-rotate-90" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                      <path d="m17.914 18.594-8-18a1 1 0 0 0-1.828 0l-8 18a1 1 0 0 0 1.157 1.376L8 18.281V9a1 1 0 0 1 2 0v9.281l6.758 1.689a1 1 0 0 0 1.156-1.376Z"/>
                    </svg>
                    <span className="sr-only">Send message</span>
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommentModal;