import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import { OneButtonModal } from '../components/commons/Modal';
import { formatDate, formatNumber } from '../components/commons/Utils';


const InvoiceBoard = () => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [modalDate, setModalDate] = useState(null);
  const [modalInvoiceId, setModalInvoiceId] = useState('');
  const [modalField, setModalField] = useState('');
  const [username, setUsername] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const [expandedInvoiceIds, setExpandedInvoiceIds] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [taskModalField, setTaskModalField] = useState('');
  const [taskModalDate, setTaskModalDate] = useState(null);
  const [taskModalId, setTaskModalId] = useState('');
  const [workerStatusOptions, setWorkerStatusOptions] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState({});

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get('/invoices');
        setInvoices(response.data);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    const fetchStatusOptions = async () => {
      try {
        const response = await axios.get('/dropdown/taskStatus');
        setStatusOptions(response.data);
      } catch (error) {
        console.error('Error fetching status options:', error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get('/session-user');
        setUsername(response.data.user.name);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    const fetchWorkerStatusOptions = async () => {
      try {
        const response = await axios.get('/dropdown/workerStatus');
        setWorkerStatusOptions(response.data);
      } catch (error) {
        console.error('Error fetching worker status options:', error);
      }
    };

    fetchInvoices();
    fetchStatusOptions();
    fetchUser();
    fetchWorkerStatusOptions();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchParam = params.get('search');
    if (searchParam) {
      setSearchTerm(searchParam);
    }
  }, [location.search]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedInvoices(filteredInvoices.map(invoice => invoice._id));
      const allTasks = {};
      filteredInvoices.forEach(invoice => {
        allTasks[invoice._id] = invoice.tasks.map(task => task.id);
      });
      setSelectedTasks(allTasks);
    } else {
      setSelectedInvoices([]);
      setSelectedTasks({});
    }
  };

  const handleSelectInvoice = (e, invoiceId) => {
    if (e.target.checked) {
      setSelectedInvoices(prev => [...prev, invoiceId]);
      const invoice = invoices.find(invoice => invoice._id === invoiceId);
      setSelectedTasks(prev => ({
        ...prev,
        [invoiceId]: invoice.tasks.map(task => task.id)
      }));
    } else {
      setSelectedInvoices(prev => prev.filter(id => id !== invoiceId));
      setSelectedTasks(prev => {
        const newSelectedTasks = { ...prev };
        delete newSelectedTasks[invoiceId];
        return newSelectedTasks;
      });
    }
  };

  const handleSelectAllTasks = (e, invoiceId) => {
    if (e.target.checked) {
      const allTaskIds = invoices.find(invoice => invoice._id === invoiceId).tasks.map(task => task.id);
      setSelectedTasks(prev => ({ ...prev, [invoiceId]: allTaskIds }));
    } else {
      setSelectedTasks(prev => ({ ...prev, [invoiceId]: [] }));
    }
  };

  const handleSelectTask = (e, invoiceId, taskId) => {
    if (e.target.checked) {
      setSelectedTasks(prev => ({
        ...prev,
        [invoiceId]: [...(prev[invoiceId] || []), taskId]
      }));
      if (!selectedInvoices.includes(invoiceId)) {
        setSelectedInvoices(prev => [...prev, invoiceId]);
      }
    } else {
      setSelectedTasks(prev => ({
        ...prev,
        [invoiceId]: (prev[invoiceId] || []).filter(id => id !== taskId)
      }));
      if (selectedTasks[invoiceId]?.length === 1) {
        setSelectedInvoices(prev => prev.filter(id => id !== invoiceId));
      }
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleTaskOpenModal = (field, date, taskId, projectId) => {
    setTaskModalField(field);
    setTaskModalDate(date ? new Date(date) : new Date());
    setTaskModalId(taskId || ''); // taskId가 없으면 빈 문자열로 설정
    setModalInvoiceId(projectId);
    setIsDateModalOpen(true);
  };

  const handleTaskStatusChange = async (projectId, taskId, value) => {
    try {
      const requestData = { project_id: projectId, task_id: taskId, status: value };
      console.log('Sending request with:', requestData);
      const response = await axios.put('/updateInvoiceTaskStatus', requestData);
      console.log('Response:', response.data);
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice._id === projectId
            ? {
                ...invoice,
                tasks: invoice.tasks.map((task) =>
                  task.id === taskId ? { ...task, Status: value } : task
                ),
              }
            : invoice
        )
      );
    } catch (error) {
      console.error('Error updating task status:', error.response ? error.response.data : error.message);
    }
  };

  const handleTaskSaveDate = async () => {
    try {
      const formattedDate = taskModalDate.toISOString().split('T')[0];
      const requestData = {
        project_id: modalInvoiceId,
        task_id: taskModalId,
        field: taskModalField,
        date: formattedDate
      };
      console.log('Sending request with:', requestData);
      const response = await axios.put('/updateTaskDate', requestData);
      console.log('Response:', response.data);
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice._id === modalInvoiceId
            ? {
                ...invoice,
                tasks: invoice.tasks.map((task) =>
                  task.id === taskModalId ? { ...task, [taskModalField]: formattedDate } : task
                ),
              }
            : invoice
        )
      );
      setIsDateModalOpen(false);
    } catch (error) {
      console.error('Error updating task date:', error.response ? error.response.data : error.message);
    }
  };

  const handleProjectSaveDate = async () => {
    try {
      const formattedDate = taskModalDate.toISOString().split('T')[0];
      const requestData = {
        invoice_id: modalInvoiceId,
        date: formattedDate
      };
      const endpoint = taskModalField === 'payment_requested_at' ? '/updateInvoicePaymentRequestedDate' : '/updateInvoicePaymentReceivedDate';
      console.log('Sending request with:', requestData);
      const response = await axios.put(endpoint, requestData);
      console.log('Response:', response.data);
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice._id === modalInvoiceId
            ? { ...invoice, [taskModalField]: formattedDate }
            : invoice
        )
      );
      setIsDateModalOpen(false);
    } catch (error) {
      console.error('Error updating project date:', error.response ? error.response.data : error.message);
    }
  };

  const handleStatusChange = async (id, value) => {
    try {
      await axios.put('/updateInvoiceStatus', {
        invoice_id: id,
        status: value,
      });
      setInvoices((prevInvoices) =>
        prevInvoices.map((invoice) =>
          invoice._id === id ? { ...invoice, Status: value } : invoice
        )
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleExport = async () => {
    const invoiceIds = selectedInvoices;
    const taskIds = Object.values(selectedTasks).flat();
  
    console.log('Exporting Invoices:', invoiceIds);
    console.log('Exporting Tasks:', taskIds);
    console.log('Username:', username);
  
    try {
      const response = await axios.post('/exportInvoices', {
        invoiceIds: invoiceIds,
        taskIds: taskIds,
        username: username,
      }, { responseType: 'blob' });
  
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `Invoices_${username}_${new Date().toISOString().split('T')[0]}.xlsx`);
      setIsExportModalOpen(false);
    } catch (error) {
      console.error("Error exporting invoices:", error);
    }
  };

  const toggleExpand = (invoiceId) => {
    setExpandedInvoiceIds(prev => 
      prev.includes(invoiceId) ? prev.filter(id => id !== invoiceId) : [...prev, invoiceId]
    );
  };

  const toggleDropdown = (invoiceId) => {
    setDropdownOpen(dropdownOpen === invoiceId ? null : invoiceId);
  };

  const getStatusBadge = (status) => {};

  const filteredInvoices = invoices.filter((invoice) => {
    if (selectedStatus && invoice.Status !== selectedStatus) return false;
  
    // 세부 작업 기준으로 시작일과 종료일 필터링
    const tasksWithinDateRange = invoice.tasks.some(task => {
      const taskStartDate = new Date(task.SDate);
      const taskEndDate = new Date(task.DDate);
      if (!task.SDate || !task.DDate) return false; // 시작일 또는 종료일이 없는 항목 필터링
      if (startDate && taskStartDate < startDate) return false;
      if (endDate && taskEndDate > endDate) return false;
      return true;
    });
  
    if (!tasksWithinDateRange) return false;
  
    if (searchTerm && !(
      (invoice.Invoice && invoice.Invoice.includes(searchTerm)) ||
      (invoice.ProjectName && invoice.ProjectName.includes(searchTerm)) ||
      (invoice.ClientName && invoice.ClientName.includes(searchTerm)) ||
      (invoice.MainPM && invoice.MainPM.includes(searchTerm))
    )) return false;
  
    return true;
  });

  const resetDateFilters = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const totalPages = Math.ceil(filteredInvoices.length / itemsPerPage);
  const paginatedInvoices = filteredInvoices.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className="relative m-4 overflow-x-auto shadow-md sm:rounded-lg">
      <div className="flex flex-wrap items-center justify-between p-4 py-2 space-y-2 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            id="table-search-invoices"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for invoices"
          />
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            placeholderText="시작일"
            className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            placeholderText="종료일"
            className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <button
            onClick={resetDateFilters}
            className="px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-lg hover:bg-gray-700"
          >
            Reset
          </button>
          <button
            onClick={() => setIsExportModalOpen(true)}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700"
          >
            Export
          </button>
        </div>
      </div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="p-4">
            <div className="flex items-center">
              <input
                id="checkbox-all"
                type="checkbox"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                onChange={handleSelectAll}
                checked={selectedInvoices.length === filteredInvoices.length && filteredInvoices.length > 0}
              />
              <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
            </div>
          </th>
          <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">견적번호</th>
          <th scope="col" className="px-4 py-2">담당자</th>
          <th scope="col" className="px-4 py-2">프로젝트</th>
          <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">클라이언트</th>
          <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">클라이언트 PM</th>
          <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">총금액</th>
          <th scope="col" className="px-4 py-2">작업상태</th>
          <th scope="col" className="px-4 py-2">시작일</th>
          <th scope="col" className="px-4 py-2">종료일</th>
          <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">결제요청</th>
          <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">결제완료</th>
          <th scope="col" className="px-4 py-2">Action</th>
        </tr>
      </thead>
        <tbody>
        {paginatedInvoices.map((invoice) => (
          <React.Fragment key={invoice._id}>
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="p-4">
                <div className="flex items-center">
                  <input
                    id={`checkbox-invoice-${invoice._id}`}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    onChange={(e) => handleSelectInvoice(e, invoice._id)}
                    checked={selectedInvoices.includes(invoice._id)}
                  />
                  <label htmlFor={`checkbox-invoice-${invoice._id}`} className="sr-only">checkbox</label>
                </div>
              </td>
              <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{invoice.Invoice}</td>
              <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{invoice.MainPM}</td>
              <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">
                <Link to={`/projects/${invoice._id}`} className="hover:underline">
                  {invoice.ProjectName}
                </Link>
              </td>
              <td className="px-4 py-2">{invoice.ClientName}</td>
              <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{invoice.ClientPM}</td>
              <td className="px-4 py-2 font-bold min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(invoice.Total, invoice.Currency)}</td>
              <td className="px-4 py-2">
                <select
                  className="p-1 text-xs border border-gray-300 rounded-lg"
                  value={invoice.Status || statusOptions[0]}
                  onChange={(e) => handleStatusChange(invoice._id, e.target.value)}
                >
                  <option value="">Select Status</option>
                  {statusOptions.map((status, idx) => (
                    <option key={idx} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </td>
              <td className="px-4 py-2">{formatDate(invoice.SDate)}</td>
              <td className="px-4 py-2">{formatDate(invoice.DDate)}</td>
              <td className="px-4 py-2">
                {invoice.payment_requested_at ? (
                  formatDate(invoice.payment_requested_at)
                ) : (
                  <button
                    className={`px-2 py-1 text-xs text-white ${invoice.DDate ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'} rounded`}
                    onClick={() => invoice.DDate && handleTaskOpenModal('payment_requested_at', invoice.payment_requested_at, '', invoice._id)}
                    disabled={!invoice.DDate}
                  >
                    Edit
                  </button>
                )}
              </td>
              <td className="px-4 py-2">
                {invoice.payment_received_at ? (
                  formatDate(invoice.payment_received_at)
                ) : (
                  <button
                    className={`px-2 py-1 text-xs text-white ${invoice.payment_requested_at ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'} rounded`}
                    onClick={() => invoice.payment_requested_at && handleTaskOpenModal('payment_received_at', invoice.payment_received_at, '', invoice._id)}
                    disabled={!invoice.payment_requested_at}
                  >
                    Edit
                  </button>
                )}
              </td>
              <td className="p-4">
                <button onClick={() => toggleExpand(invoice._id)} className={`font-medium hover:underline ${expandedInvoiceIds.includes(invoice._id) ? 'text-red-600' : 'text-blue-600'}`}>
                  {expandedInvoiceIds.includes(invoice._id) ? '접기' : '펼치기'}
                </button>
              </td>
            </tr>
                    {expandedInvoiceIds.includes(invoice._id) && (
                      <tr className="bg-gray-50 dark:bg-gray-700">
                        <td colSpan="13" className="px-6 py-4">
                          <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <h4 className="mb-2 text-lg font-semibold">세부 작업</h4>
                            {invoice.tasks && invoice.tasks.length > 0 ? (
                              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                  <tr>
                                    <th scope="col" className="p-4">
                                      <div className="flex items-center">
                                        <input
                                          id={`checkbox-all-tasks-${invoice._id}`}
                                          type="checkbox"
                                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                          onChange={(e) => handleSelectAllTasks(e, invoice._id)}
                                          checked={selectedTasks[invoice._id]?.length === invoice.tasks.length}
                                        />
                                        <label htmlFor={`checkbox-all-tasks-${invoice._id}`} className="sr-only">checkbox</label>
                                      </div>
                                    </th>
                                    <th scope="col" className="px-4 py-2">견적번호</th>
                                    <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">유형</th>
                                    <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">PM</th>
                                    <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">출발어</th>
                                    <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">도착어</th>
                                    <th scope="col" className="px-4 py-2">분량</th>
                                    <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">단위</th>
                                    <th scope="col" className="px-4 py-2">단가</th>
                                    <th scope="col" className="px-4 py-2">총금액</th>
                                    <th scope="col" className="px-4 py-2">작업상태</th>
                                    <th scope="col" className="px-4 py-2">시작일</th>
                                    <th scope="col" className="px-4 py-2">종료일</th>
                                    <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">결제요청</th>
                                    <th scope="col" className="px-4 py-2 min-w-[4em] whitespace-nowrap">결제완료</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {invoice.tasks.map((task) => (
                                    <tr key={task.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                      <td className="p-4">
                                        <div className="flex items-center">
                                          <input
                                            id={`checkbox-task-${task.id}`}
                                            type="checkbox"
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                            onChange={(e) => handleSelectTask(e, invoice._id, task.id)}
                                            checked={selectedTasks[invoice._id]?.includes(task.id)}
                                          />
                                          <label htmlFor={`checkbox-task-${task.id}`} className="sr-only">checkbox</label>
                                        </div>
                                      </td>
                                      <td className="px-4 py-2">{task.Invoice}</td>
                                      <td className="px-4 py-2">{task.ProjectType}</td>
                                      <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{task.PMName}</td>
                                      <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{task.SLang}</td>
                                      <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{task.TLang}</td>
                                      <td className="px-4 py-2">{Number(task.ProjectVolume).toLocaleString()}</td>
                                      <td className="px-4 py-2">{task.Unit}</td>
                                      <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">{formatNumber(task.Rate, invoice.Currency)}</td>
                                      <td className="px-4 py-2 font-bold min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(task.Total, invoice.Currency)}</td>
                                      <td className="px-4 py-2">
                                        <select
                                          className="p-1 text-xs border border-gray-300 rounded-lg"
                                          value={task.Status || workerStatusOptions[0]}
                                          onChange={(e) => handleTaskStatusChange(invoice._id, task.id, e.target.value)}
                                        >
                                          <option value="">Select Status</option>
                                          {workerStatusOptions.map((status, idx) => (
                                            <option key={idx} value={status}>
                                              {status}
                                            </option>
                                          ))}
                                        </select>
                                      </td>
                                      <td className="px-4 py-2">
                                        {task.SDate ? formatDate(task.SDate) : (
                                          <button
                                            className="px-2 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-700"
                                            onClick={() => handleTaskOpenModal('SDate', task.SDate, task.id, invoice._id)}
                                          >
                                            Edit
                                          </button>
                                        )}
                                      </td>
                                      <td className="px-4 py-2">
                                        {task.DDate ? formatDate(task.DDate) : (
                                          <button
                                            className="px-2 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-700"
                                            onClick={() => handleTaskOpenModal('DDate', task.DDate, task.id, invoice._id)}
                                          >
                                            Edit
                                          </button>
                                        )}
                                      </td>
                                      <td className="px-4 py-2">
                                        {task.payment_requested_at ? formatDate(task.payment_requested_at) : (
                                          <button
                                            className="px-2 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-700"
                                            onClick={() => handleTaskOpenModal('payment_requested_at', task.payment_requested_at, task.id, invoice._id)}
                                          >
                                            Edit
                                          </button>
                                        )}
                                      </td>
                                      <td className="px-4 py-2">
                                        {task.payment_received_at ? formatDate(task.payment_received_at) : (
                                          <button
                                            className="px-2 py-1 text-xs text-white bg-blue-500 rounded hover:bg-blue-700"
                                            onClick={() => handleTaskOpenModal('payment_received_at', task.payment_received_at, task.id, invoice._id)}
                                          >
                                            Edit
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <p>No tasks available.</p>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
          </React.Fragment>
        ))}
      </tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={handlePageChange}
        pageNumbers={[...Array(totalPages).keys()].map(i => i + 1)}
      />
      {isDateModalOpen && (
        <OneButtonModal
          id="date-modal"
          title="날짜 입력"
          body={
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-400">날짜 선택</label>
              <input
                type="date"
                value={taskModalDate ? taskModalDate.toISOString().split('T')[0] : ''}
                onChange={(e) => setTaskModalDate(new Date(e.target.value))}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          }
          onSave={taskModalId ? handleTaskSaveDate : handleProjectSaveDate}
          onClose={() => setIsDateModalOpen(false)}
        />
      )}
      {isExportModalOpen && (
        <OneButtonModal
          id="export-modal"
          title="Export Invoices"
          body={<p>Are you sure you want to export the selected invoices?</p>}
          onSave={handleExport}
          onClose={() => setIsExportModalOpen(false)}
        />
      )}
    </div>
  );
};

export default InvoiceBoard;
