const projects = [
  { id: 2451, totalamount: '$872,323,232', projectmanager: 'Bonhak Ku', client: 'Client A', projectstatus: '취소', paymentstatus: '완료', deliverydate: '2024/02/14' },
  { id: 2452, totalamount: '$7264', projectmanager: 'Bonhak Ku', client: 'Client B', projectstatus: '납품대기', paymentstatus: '취소', deliverydate: '2024/02/14' },
  { id: 2451, totalamount: '$375', projectmanager: 'Bonhak Ku', client: 'Client C', projectstatus: '완료', paymentstatus: '대기중', deliverydate: '2024/02/14' },
  { id: 2450, totalamount: '$657', projectmanager: 'Bonhak Ku', client: 'Client D', projectstatus: '완료 ', paymentstatus: '취소', deliverydate: '2024/03/06' },
  { id: 2449, totalamount: '$9562', projectmanager: 'Igor Borvibson', client: 'Client E', projectstatus: '완료 ', paymentstatus: '취소', deliverydate: '2024/02/14' },
  { id: 2448, totalamount: '$46', projectmanager: 'Katerina Karenin', client: 'Client F', projectstatus: '대기', paymentstatus: '완료', deliverydate: '2024/03/06' },
  { id: 2447, totalamount: '$953', projectmanager: 'Roy Anderson', client: 'Client G', projectstatus: '대기', paymentstatus: '대기중', deliverydate: '2024/02/14' },
  { id: 2446, totalamount: '$12', projectmanager: 'Martina Scorcese', client: 'Client H', projectstatus: '완료', paymentstatus: '대기중', deliverydate: '2024/03/06' },
  { id: 2445, totalamount: '$3927', projectmanager: 'Luis Bunuel', client: 'Client I', projectstatus: '취소', paymentstatus: '완료', deliverydate: '2024/02/14' },
  { id: 2444, totalamount: '$5937', projectmanager: 'Jean Renoir', client: 'Client J', projectstatus: '납품대기', paymentstatus: '완료', deliverydate: '2024/03/06' },
];

export default projects;

