import React from 'react';
import { useQuery } from 'react-query';
import axios from '../axiosConfig';

const fetchProjects = async () => {
  const { data } = await axios.get(`/projects`, { withCredentials: true });
  return data.projects;
};

const ActiveProjectsCard = () => {
  const { data: projects, isLoading, error } = useQuery('projects', fetchProjects);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching projects: {error.message}</div>;

  // Check if projects is an array
  if (!Array.isArray(projects)) {
    return <div>Error: Unexpected data format</div>;
  }

  const totalProjects = projects.length;
  const completedProjectsCount = projects.filter(project => project.Status === '완료').length;
  const completionRate = totalProjects > 0 ? (completedProjectsCount / totalProjects * 100).toFixed(2) : 0;

  return (
    <div className="w-full h-48 p-6 text-white bg-gray-800 rounded-lg shadow-md">
      <div className="text-4xl font-bold">{totalProjects}</div>
      <div className="text-lg">전체 프로젝트</div>
      <div className="flex items-center justify-between mt-4">
        <div>{completedProjectsCount} 건의 프로젝트 완료</div>
        <div>{completionRate}%</div>
      </div>
      <div className="w-full bg-white rounded-full h-2.5 mt-2">
        <div className="bg-blue-700 h-2.5 rounded-full" style={{ width: `${completionRate}%` }}></div>
      </div>
    </div>
  );
};

export default ActiveProjectsCard;
