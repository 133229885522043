import React, { useState } from 'react';
import { Radar } from 'react-chartjs-2';
import workerImage from '../../assets/pepe_sleep.jpg';

const TranslatorCard = ({ worker }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  if (!worker) {
    return <div>Loading...</div>; // worker 객체가 null일 때 로딩 메시지 표시
  }

  const radarData = {
    labels: ['의사소통', '전문성', '일관성', '프로의식', '규칙준수', '문장력'],
    datasets: [
      {
        label: '항목별 점수',
        data: [
          Math.round(worker?.overall_rating?.communication) || 0,
          Math.round(worker?.overall_rating?.expertise) || 0,
          Math.round(worker?.overall_rating?.consistency) || 0,
          Math.round(worker?.overall_rating?.professionalism) || 0,
          Math.round(worker?.overall_rating?.compliance) || 0,
          Math.round(worker?.overall_rating?.flow) || 0,
        ],
        backgroundColor: 'rgba(34, 202, 236, 0.2)',
        borderColor: 'rgba(34, 202, 236, 1)',
        borderWidth: 2,
      },
      {
        label: 'QA 항목별 점수',
        data: [
          Math.round(worker?.overall_qa_rating?.communication) || 0,
          Math.round(worker?.overall_qa_rating?.expertise) || 0,
          Math.round(worker?.overall_qa_rating?.consistency) || 0,
          Math.round(worker?.overall_qa_rating?.professionalism) || 0,
          Math.round(worker?.overall_qa_rating?.compliance) || 0,
          Math.round(worker?.overall_qa_rating?.flow) || 0,
        ],
        backgroundColor: 'rgba(128, 0, 128, 0.2)',
        borderColor: 'rgba(128, 0, 128, 1)',
        borderWidth: 2,
      },
    ],
  };

  const radarOptions = {
    scales: {
      r: {
        min: 50, // 최소값 설정
        max: 100, // 최대값 설정
        ticks: {
          beginAtZero: true,
          stepSize: 10 // 눈금 간격 설정
        }
      }
    }
  };

  const getCardClass = (rating) => {
    if (rating >= 90) return 'bg-gold';
    if (rating >= 75) return 'bg-silver';
    return 'bg-bronze';
  };

  const getGrade = (rating) => {
    if (rating >= 96) return 'S';
    if (rating >= 91) return 'A+';
    if (rating >= 86) return 'A';
    if (rating >= 81) return 'B+';
    if (rating >= 76) return 'B';
    if (rating >= 71) return 'C+';
    return 'C';
  };

  const overallRating = worker?.overall_rating ? Math.round(Object.values(worker.overall_rating).reduce((a, b) => a + b, 0) / 6) : 0;
  const overallQARating = worker?.overall_qa_rating ? Math.round(Object.values(worker.overall_qa_rating).reduce((a, b) => a + b, 0) / 6) : 0;
  const cardClass = getCardClass(overallRating);
  const grade = getGrade(overallRating);
  const qaGrade = getGrade(overallQARating);

  const skillDescriptions = {
    communication: "의사소통 능력은 PM, 클라이언트, 팀원, 그리고 다른 관계자들과 얼마나 효과적으로 소통할 수 있는지를 나타냅니다.",
    expertise: "전문성은 특정 분야에 대한 지식과 경험의 깊이를 의미합니다.",
    consistency: "일관성은 번역물 전체에 걸쳐 용어, 스타일, 톤을 일관되게 유지하는 능력을 나타냅니다.",
    professionalism: "프로의식은 마감일 준수, 피드백 수용, 그리고 전반적인 책임감을 포함합니다.",
    compliance: "규칙준수는 프로젝트 지침, 스타일 가이드 그리고 PM의 요청사항을 얼마나 잘 따르는지를 나타냅니다.",
    flow: "문장력은 번역된 텍스트가 얼마나 자연스럽고 읽기 쉬운지를 평가합니다."
  };

  const handleQuestionClick = (skill) => {
    setModalContent(skillDescriptions[skill]);
    setModalOpen(true);
  };

  return (
    <>            
    <h2 className="mt-4 mb-8 text-xl font-semibold">
    <span className="pb-1 border-b-2 border-blue-500">작업자 정보</span>
  </h2>
    <div className={`flex flex-col items-center bg-white border dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 p-4 rounded-lg shadow-md mb-4 ${cardClass}`} style={{ width: '100%', maxWidth: '28rem'}}>
      <div className="flex-shrink-0 w-24 h-20 bg-gray-200 rounded-full">
        <img src={workerImage} alt="Worker" className="w-full h-full rounded-full" />
      </div>
      <div className="mt-4 text-center">
        <h2 className="text-2xl font-semibold">{worker.name}</h2>
        <p className="text-gray-600 dark:text-gray-400">{worker.email}</p>
        <p className="text-gray-600 dark:text-gray-400">{worker.mobile}</p>
      </div>
      <div className="mt-4 text-center">
        <div className="text-4xl font-bold text-green-500">{overallRating} / {overallQARating}</div>
        <div className="text-sm text-gray-600 dark:text-gray-400">PM 점수 / QA 점수</div>
        <div className="text-xl font-semibold text-blue-500">{grade} / {qaGrade}</div> 
      </div>
      <div className="w-full mt-4" style={{ height: '400px' }}>
        <Radar data={radarData} options={radarOptions} />
      </div>
      <div className="w-full mt-4 text-center">
        <ul className="text-sm text-gray-600 dark:text-gray-400">
          {Object.entries(worker.overall_rating || {}).map(([key, value]) => (
            <li key={key} className="flex items-center justify-between mb-2">
              <span>{key === 'flow' ? '문장력' : key === 'communication' ? '의사소통' : key === 'expertise' ? '전문성' : key === 'consistency' ? '일관성' : key === 'professionalism' ? '프로의식' : '규칙준수'}:</span>
              <div className="flex items-center">
                <span>{Math.round(value) || 0} / {Math.round(worker?.overall_qa_rating?.[key]) || 0}</span>
                <button 
                  className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                  onClick={() => handleQuestionClick(key)}
                >
                  ?
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {modalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="max-w-sm p-6 bg-white rounded-lg">
            <p>{modalContent}</p>
            <button 
              className="px-4 py-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-700"
              onClick={() => setModalOpen(false)}
            >
              닫기
            </button>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default TranslatorCard;