import React from 'react';
import Chart from 'react-apexcharts';
import { formatNumber } from '../components/commons/Utils';

const ClientRevenueTreemap = ({ clientRevenueData }) => {
  const data = Object.entries(clientRevenueData)
    .map(([client, revenue]) => ({
      x: client,
      y: revenue,
      label: formatNumber(revenue, 'KRW')
    }))
    .sort((a, b) => b.y - a.y); // 수익이 높은 순으로 정렬

  const options = {
    series: [{ data }],
    legend: { show: false },
    chart: {
      height: 350,
      type: 'treemap'
    },
    colors: [
      '#3B93A5', '#F7B844', '#ADD8C7', '#EC3C65', '#CDD7B6',
      '#C1F666', '#D43F97', '#1E5D8C', '#421243', '#7F94B0',
      '#EF6537', '#C0ADDB'
    ],
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false
      }
    },
    tooltip: {
      y: {
        formatter: (value) => formatNumber(value, 'KRW')
      }
    }
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="treemap"
      height={350}
    />
  );
};

export default ClientRevenueTreemap;