import React from 'react';

const Pagination = ({ currentPage, setCurrentPage, pageNumbers }) => {
  if (pageNumbers.length === 0) return null;

  const maxPageNumbers = 10;
  const startPage = Math.floor((currentPage - 1) / maxPageNumbers) * maxPageNumbers;
  const endPage = Math.min(startPage + maxPageNumbers, pageNumbers.length);

  return (
    <nav aria-label="Page navigation" className="flex justify-center my-0 p-4 bg-white">
      <ul className="inline-flex justify-center -space-x-px text-sm">
        <li>
          <button
            onClick={() => setCurrentPage(Math.max(currentPage - 10, 1))}
            disabled={currentPage === 1}
            className="flex items-center justify-center h-10 w-10 text-lg leading-tight text-gray-500 bg-white border border-gray-300 ms-0 border-e-0 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            &laquo;
          </button>
        </li>
        {pageNumbers.slice(startPage, endPage).map(number => (
          <li key={number}>
            <button
              onClick={() => setCurrentPage(number)}
              className={`flex items-center justify-center h-10 w-10 text-lg leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${
                currentPage === number ? 'border-2 border-blue-500 text-blue-600 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : ''
              }`}
            >
              {number}
            </button>
          </li>
        ))}
        <li>
          <button
            onClick={() => setCurrentPage(Math.min(currentPage + 10, pageNumbers.length))}
            disabled={currentPage === pageNumbers.length}
            className="flex items-center justify-center h-10 w-10 text-lg leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            &raquo;
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;