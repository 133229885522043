import React, { useContext, useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import BoardTitle from '../area/BoardTitle';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import { OneButtonModal } from '../components/commons/Modal';
import { formatDate, formatNumber } from '../components/commons/Utils';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';

const ClientDetails = () => {
  const { isCollapsed } = useContext(SidebarContext); 
  const { clientId } = useParams();
  const [client, setClient] = useState(null);
  const [projects, setProjects] = useState([]);
  const [filter, setFilter] = useState('');
  const [filterField, setFilterField] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDate, setModalDate] = useState('');
  const [errorModal, setErrorModal] = useState({ show: false, message: '' });
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await axios.get(`/resource/client/${clientId}`);
        const { client, projects } = response.data;
        setClient(client);
        setProjects(projects);
      } catch (error) {
        console.error('Error fetching client details:', error);
      }
    };

    fetchClientDetails();
  }, [clientId]);

  const filteredProjects = useMemo(() => {
    return projects.filter(project =>
      (project.ProjectName && project.ProjectName.toLowerCase().includes(filter.toLowerCase())) ||
      (project.MainPM && project.MainPM.toLowerCase().includes(filter.toLowerCase())) ||
      (project.Status && project.Status.toLowerCase().includes(filter.toLowerCase()))
    ).filter(project => {
      if (!filterDate) return true;
      const date = new Date(filterDate).toISOString().substr(0, 10);
      return project[filterField] && new Date(project[filterField]).toISOString().substr(0, 10) === date;
    });
  }, [projects, filter, filterField, filterDate]);

  const currentProjects = filteredProjects.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredProjects.length / itemsPerPage);

  const handleResetFilters = () => {
    setFilter('');
    setFilterField('');
    setFilterDate(null);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedProjects(projects.map(project => project._id));
    } else {
      setSelectedProjects([]);
    }
  };

  const handleSelectProject = (e, id) => {
    if (e.target.checked) {
      setSelectedProjects([...selectedProjects, id]);
    } else {
      setSelectedProjects(selectedProjects.filter(projectId => projectId !== id));
    }
  };

  const handleExport = async () => {
    try {
      const response = await axios.post('/exportProjects', {
        projectIds: selectedProjects,
        clientName: client.clientName,
      }, { responseType: 'blob' });

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `Projects_${client.clientName}_${new Date().toISOString().split('T')[0]}.xlsx`);

      setIsExportModalOpen(false);
    } catch (error) {
      console.error("Error exporting projects:", error);
    }
  };

  // 상태별 TotalInKRW와 expectedRevenue 데이터 생성
  const statusData = projects.reduce((acc, project) => {
    const status = project.Status || 'Unknown';
    if (!acc[status]) {
      acc[status] = { TotalInKRW: 0, expectedRevenue: 0, currency: project.Currency };
    }
    acc[status].TotalInKRW += project.TotalInKRW;
    acc[status].expectedRevenue += project.expectedRevenue;
    return acc;
  }, {});

  const statusCategories = Object.keys(statusData);
  const totalInKRWSeries = statusCategories.map(status => statusData[status].TotalInKRW);
  const expectedRevenueSeries = statusCategories.map(status => statusData[status].expectedRevenue);
  const currency = statusCategories.length > 0 ? statusData[statusCategories[0]].currency : '';

  const getStatusBadge = (status) => {
    switch (status) {
      case '협상':
        return <span className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">협상</span>;
      case '진행':
        return <span className="bg-green-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">진행</span>;
      case '완료':
        return <span className="bg-purple-200 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">완료</span>;
      case '실주':
        return <span className="bg-orange-200 text-orange-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">실주</span>;
      case '중단':
        return <span className="bg-red-200 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">중단</span>;
      default:
        return <span className="bg-blue-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{status}</span>;
    }
  };

  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-white custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <BoardTitle title={`클라이언트 상세 정보`} />
        <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-3">
          <div className="col-span-1 p-4 bg-gray-100 rounded-lg">
            <h3 className="text-md font-semibold">클라이언트 정보</h3>
            <p className="mt-2"><strong>Client Name:</strong> {client?.clientName}</p>
            <p><strong>Client Type:</strong> {client?.clientType}</p>
            <p><strong>Email:</strong> {client?.clientEmail}</p>
            <p><strong>Mobile:</strong> {client?.clientMobile}</p>
            <p><strong>Registration Number:</strong> {client?.clientRegistrationNumber}</p>
            <p><strong>Memo:</strong> {client?.memo}</p>
            <p><strong>Domains:</strong> {client?.clientDomains.join(', ')}</p>
          </div>
          <div className="col-span-2 p-4 bg-white rounded-lg shadow-md">
            <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">상태별 금액</h2>
            <Chart
              options={{
                chart: {
                  type: 'bar',
                  height: 350,
                  stacked: false
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                  },
                },
                dataLabels: {
                  enabled: false
                },
                xaxis: {
                  categories: statusCategories,
                },
                yaxis: {
                  title: {
                    text: 'Amount'
                  },
                  labels: {
                    formatter: function (val) {
                      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    }
                  }
                },
                fill: {
                  opacity: 1
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return formatNumber(val, currency);
                    }
                  }
                },
                legend: {
                  position: 'top'
                },
                colors: ['#008FFB', '#00E396']
              }}
              series={[
                {
                  name: 'TotalInKRW',
                  data: totalInKRWSeries
                },
                {
                  name: 'ExpectedRevenue',
                  data: expectedRevenueSeries
                }
              ]}
              type="bar"
              height={250}
            />
          </div>
        </div>
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">Projects</h2>
            {selectedProjects.length > 0 && (
              <button
                onClick={() => setIsExportModalOpen(true)}
                className="px-3 py-1.5 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 transition duration-200 ml-auto"
              >
                Export
              </button>
            )}
          </div>
          <table className="w-full mt-2 text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all"
                      type="checkbox"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      onChange={handleSelectAll}
                      checked={selectedProjects.length === projects.length}
                    />
                    <label htmlFor="checkbox-all" className="sr-only">checkbox</label>
                  </div>
                </th>
                <th className="px-4 py-3 border-gray-300">Project Name</th>
                <th className="px-4 py-3 border-gray-300">Main PM</th>
                <th className="px-6 py-3 font-bold min-w-[4em] whitespace-nowrap border-gray-300">Status</th>
                <th className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap border-gray-300">Client Name</th>
                <th className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap border-gray-300">Client PM</th>
                <th className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap border-gray-300">Total</th>
                <th className="px-4 py-3 border-gray-300">Start Date</th>
                <th className="px-4 py-3 border-gray-300">End Date</th>
                <th className="px-4 py-3 border-gray-300">Payment Requested</th>
                <th className="px-4 py-3 border-gray-300">Payment Received</th>
              </tr>
            </thead>
            <tbody>
              {currentProjects.length > 0 ? (
                currentProjects.map((project, index) => (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="p-4">
                      <div className="flex items-center">
                        <input
                          id={`checkbox-${project._id}`}
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          checked={selectedProjects.includes(project._id)}
                          onChange={(e) => handleSelectProject(e, project._id)}
                        />
                        <label htmlFor={`checkbox-${project._id}`} className="sr-only">checkbox</label>
                      </div>
                    </td>
                    <td className="px-4 py-4">{project.ProjectName}</td>
                    <td className="px-4 py-4">{project.MainPM}</td>
                    <td className="px-6 py-4 font-bold min-w-[4em] whitespace-nowrap">{getStatusBadge(project.Status)}</td>
                    <td className="px-6 py-2 min-w-[4em] whitespace-nowrap">{project.ClientName}</td>
                    <td className="px-6 py-2 min-w-[4em] whitespace-nowrap">{project.ClientPM}</td>
                    <td className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(project.Total, project.Currency)}</td>
                    <td className="px-4 py-4">{formatDate(project.SDate)}</td>
                    <td className="px-4 py-4">{formatDate(project.DDate)}</td>
                    <td className="px-4 py-4">{project.payment_requested_at ? formatDate(project.payment_requested_at) : 'N/A'}</td>
                    <td className="px-4 py-4">{project.payment_received_at ? formatDate(project.payment_received_at) : 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" className="px-4 py-4 text-center">No Project records found</td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageNumbers={[...Array(totalPages).keys()].map(i => i + 1)}
          />
        </div>
        {errorModal.show && (
          <OneButtonModal
            id="error-modal"
            title="Error"
            body={errorModal.message}
            onSave={() => setErrorModal({ show: false, message: '' })}
            onClose={() => setErrorModal({ show: false, message: '' })}
          />
        )}
      </main>
      <AsideContainer />
      {isExportModalOpen && (
        <OneButtonModal
          id="export-modal"
          title="Export Projects"
          body={<p>Are you sure you want to export the selected projects?</p>}
          onSave={handleExport}
          onClose={() => setIsExportModalOpen(false)}
        />
      )}
    </div>
  );
};

export default ClientDetails;