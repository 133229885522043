import React, { useContext, useEffect, useState } from 'react';
import '../../App.css';
import BoardTitle from '../../area/BoardTitle'; // BoardTitle import 추가
import axios from '../../axiosConfig';
import CompanyBoard from '../../boards/settings/CompanySettingsBoard'; // 게시판 컴포넌트 추가
import AsideContainer from '../../components/containers/AsideContainer';
import { SidebarContext } from '../../contexts/SidebarContext'; // Import SidebarContext

const Company = () => {
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const { isCollapsed } = useContext(SidebarContext); // Use SidebarContext

  useEffect(() => {
    fetchTotalItems();
  }, []);

  const fetchTotalItems = async () => {
    try {
      const response = await axios.get('/getDropdown');
      const total = response.data.flatMap(item => item.values).length;
      setTotalItems(total);
    } catch (error) {
      console.error('총 항목 수 가져오기 실패:', error);
    }
  };

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-gray-150 custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title="회사 설정" /> 
        <CompanyBoard itemsPerPage={itemsPerPage} totalItems={totalItems} /> 
      </main>
      <AsideContainer />
    </div>
  );
};

export default Company;
