import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaDownload, FaFileAlt, FaFileExcel, FaFileImage, FaFilePdf, FaFileWord, FaTimesCircle } from 'react-icons/fa';
import axios from '../../axiosConfig';
import CustomScrollbars from './CustomScrollbars';
import { TwoButtonModal } from './Modal';

const WorkerFiles = ({ workerId }) => {
  const [files, setFiles] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axios.get(`/workerFiles/${workerId}`);
        const filesWithPreview = response.data.files.map(file => ({
          ...file,
          preview: file.name.match(/\.(jpeg|jpg|gif|png)$/) ? `${apiUrl}/upload/worker/${workerId}/${file.name}` : null,
          type: file.name.match(/\.(jpeg|jpg|gif|png)$/) ? 'image' : file.name.match(/\.pdf$/) ? 'application/pdf' : file.name.match(/\.xlsx$/) ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/octet-stream' // 파일 타입 설정
        }));
        setFiles(filesWithPreview);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, [workerId, apiUrl]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    },
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('worker_id', workerId);

        axios.post('/uploadWorkerFile', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          setFiles(prevFiles => [...prevFiles, {
            name: file.name,
            size: file.size,
            preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
            path: response.data.file_path,
            type: file.type
          }]);
        })
        .catch(error => {
          console.error('Error uploading file:', error);
        });
      });
    }
  });

  const getFileIcon = (type) => {
    switch (type) {
      case 'application/pdf':
        return <FaFilePdf className="w-10 h-10 text-red-500" />;
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return <FaFileWord className="w-10 h-10 text-blue-500" />;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return <FaFileExcel className="w-10 h-10 text-green-500" />;
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return <FaFileImage className="w-10 h-10 text-green-500" />;
      default:
        return <FaFileAlt className="w-10 h-10 text-gray-500" />;
    }
  };

  const handleDownload = (filePath) => {
    axios.get(`${apiUrl}/upload/worker/${workerId}/${filePath}`, {
      responseType: 'blob',
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filePath);
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => {
      console.error('Error downloading file:', error);
    });
  };

  const handleDelete = (file) => {
    setFileToDelete(file);
    setIsDeleteModalOpen(true);
  };

  const confirmDelete = () => {
    axios.delete(`/deleteWorkerFile/${workerId}/${fileToDelete.name}`)
      .then(() => {
        setFiles(files.filter(f => f.name !== fileToDelete.name));
        setIsDeleteModalOpen(false);
        setFileToDelete(null);
      })
      .catch(error => {
        console.error('Error deleting file:', error);
      });
  };

  return (
    <>
      <h2 className="mt-4 mb-8 text-xl font-semibold">
        <span className="pb-1 border-b-2 border-blue-500">작업자 파일</span>
      </h2>
      <div className="w-full max-w-md p-4 bg-white border border-gray-200 rounded-lg shadow-md">
        <div {...getRootProps({ className: 'dropzone' })} className="flex flex-col items-center justify-center w-full h-20 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
          <input {...getInputProps()} className="hidden" />
          <div className="flex flex-col items-center justify-center pt-2 pb-2">
            <svg className="w-8 h-8 mb-2 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"/>
            </svg>
            <p className="mb-1 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
          </div>
        </div>
        <CustomScrollbars style={{ height: 300 }}>
          <div className="mt-4">
            {files.map(file => (
              <div key={file.name} className="relative flex items-center justify-between p-2 mb-2 border rounded-lg">
                <button className="absolute top-0 left-0 mt-1 ml-1 text-gray-500 hover:text-gray-700" onClick={() => handleDelete(file)}>
                  <FaTimesCircle className="w-4 h-4" />
                </button>
                <div className="flex items-center">
                  {file.preview ? (
                    <img src={file.preview} alt={file.name} className="w-10 h-10 mr-2" />
                  ) : (
                    getFileIcon(file.type)
                  )}
                  <div>
                    <p className="text-sm font-medium text-gray-900">{file.name}</p>
                    <p className="text-xs text-gray-500">{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                  </div>
                </div>
                <button className="text-blue-500 hover:text-blue-700" onClick={() => handleDownload(file.path)}>
                  <FaDownload className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
        </CustomScrollbars>
      </div>

      {isDeleteModalOpen && (
        <TwoButtonModal
          id="delete-modal"
          title="파일 삭제"
          body={`정말로 ${fileToDelete?.name} 파일을 삭제하시겠습니까?`}
          onSave={confirmDelete}
          onClose={() => setIsDeleteModalOpen(false)}
          saveButtonText="삭제"
          closeButtonText="취소"
        />
      )}
    </>
  );
};

export default WorkerFiles;