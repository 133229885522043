import { faSearch, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Button = ({ children, className, onClick, type = 'button' }) => {
  return (
    <button type={type} onClick={onClick} className={`flex items-center justify-center h-8 px-3 text-sm rounded hover:bg-opacity-75 ${className}`}>
      {children}
    </button>
  );
};

export const RightAlignedButton = ({ children, className, onClick, type = 'button' }) => {
  return (
    <div className="flex justify-end">
      <Button type={type} onClick={onClick} className={className}>
        {children}
      </Button>
    </div>
  );
};

export const ListButton = () => {
  return (
    <Button className="text-white bg-gray-500">
      <svg className="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
        <path d="M2 12.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5z"/>
      </svg>
      목록으로
    </Button>
  );
};

export const WriteButton = ({ onClick }) => {
  return (
    <Button className="text-white bg-gray-800" onClick={onClick}>
      <svg className="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
        <path d="M12.146 0.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zM10.5 3.207L2 11.707V14h2.293l8.5-8.5-2.293-2.293z"/>
      </svg>
      글쓰기
    </Button>
  );
};

export const FindCredentialsButton = () => {
  return (
    <Button className="p-2 bg-gray-200 hover:bg-gray-300">
      아이디/비번 찾기
    </Button>
  );
};

export const SignupButton = ({ onClick }) => {
  return (
    <Button className="p-2 bg-gray-200 hover:bg-gray-300" onClick={onClick}>
      회원가입
    </Button>
  );
};

export const LoginButton = ({ onClick }) => {
  return (
    <Button type="submit" className="justify-center w-full p-2 text-white bg-blue-500 hover:bg-blue-700" onClick={onClick}>
      <FontAwesomeIcon icon={faSignInAlt} className="mr-2" />
      로그인
    </Button>
  );
};

export const LogoutButton = ({ onClick }) => {
  return (
    <Button className="w-full p-2 text-white bg-red-500 hover:bg-red-700" onClick={onClick}>
      <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
      로그아웃
    </Button>
  );
};

export const SearchButton = ({ onClick }) => {
  return (
    <Button className="p-2 bg-gray-200 hover:bg-gray-300" onClick={onClick}>
      <FontAwesomeIcon icon={faSearch} className="mr-2" />
      검색
    </Button>
  );
};