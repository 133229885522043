import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../session/AuthContext';
import { FindCredentialsButton, LoginButton, SignupButton } from '../commons/Buttons';
import { OneButtonModal } from '../commons/Modal';
import LoggedInView from './LoggedSidebar';

const LoginSidebar = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [modalInfo, setModalInfo] = useState({ show: false, title: '', body: '', onSave: null });
  const { user, login, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login({ name, password });
      setModalInfo({
        show: true,
        title: '로그인 성공',
        body: '로그인에 성공하였습니다.',
        onSave: () => {
          setModalInfo({ ...modalInfo, show: false });
          navigate('/home', { replace: true });
        },
        onClose: () => setModalInfo({ ...modalInfo, show: false })
      });
    } catch (error) {
      console.error('로그인 실패:', error);
      setModalInfo({
        show: true,
        title: '로그인 실패',
        body: '아이디 또는 비밀번호가 잘못되었습니다.',
        onSave: () => setModalInfo({ ...modalInfo, show: false }),
        onClose: () => setModalInfo({ ...modalInfo, show: false })
      });
    }
  };

  const handleLogout = async () => {
    await logout();
    setModalInfo({
      show: true,
      title: '로그아웃 성공',
      body: '로그아웃에 성공하였습니다.',
      onSave: () => {
        setModalInfo({ ...modalInfo, show: false });
        navigate('/home', { replace: true });
      },
      onClose: () => setModalInfo({ ...modalInfo, show: false })
    });
  };

  const handleSignup = () => {
    navigate('/signup');
  };

  return (
    <div className="w-full sidebar">
      {user ? (
        <LoggedInView handleLogout={handleLogout} profile={user} />
      ) : (
        <form onSubmit={handleLogin} className="flex flex-col">
          <div className="mb-1">
            <label htmlFor="name" className="block mb-2">아이디</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-2">
            <label htmlFor="password" className="block mb-2">비밀번호</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="flex items-center mb-2">
            <input type="checkbox" id="rememberMe" className="mr-2" />
            <label htmlFor="rememberMe">자동로그인</label>
          </div>
          <LoginButton type="submit" />
        </form>
      )}
      {!user && (
        <div className="flex justify-between mt-2">
          <FindCredentialsButton />
          <SignupButton onClick={handleSignup} />
        </div>
      )}
      {modalInfo.show && (
        <OneButtonModal
          id="loginModal"
          title={modalInfo.title}
          body={modalInfo.body}
          onSave={modalInfo.onSave}
          onClose={modalInfo.onClose}
        />
      )}
    </div>
  );
};

export default LoginSidebar;

