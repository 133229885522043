import React, { useEffect, useState } from 'react';
import { LiaFlagUsaSolid } from "react-icons/lia";
import { useNavigate } from 'react-router-dom';
import axios from '../../axiosConfig';
import { LogoutButton } from '../commons/Buttons';
import { OneButtonModal } from '../commons/Modal';

const LoggedSidebar = ({ handleLogout, profile = {} }) => {
  const [modalInfo, setModalInfo] = useState({ show: false, title: '', body: '', onSave: null });
  const [projectCount, setProjectCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [poCount, setPoCount] = useState(0);
  const [userName, setUserName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filteredPOs, setFilteredPOs] = useState([]);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const response = await axios.get(`/session-user`);
        const name = response.data.user.name;
        setUserName(name);

        // Fetch counts after setting userName
        fetchCounts(name);
      } catch (error) {
        console.error('Error fetching user name:', error);
      }
    };

    fetchUserName();
  }, []);

  const fetchCounts = async (name) => {
    try {
      const projectResponse = await axios.get(`/session-projects`);
      const invoiceResponse = await axios.get(`/session-invoices`);
      const poResponse = await axios.get(`/session-pos`);

      const userProjects = projectResponse.data.projects.filter(project => 
        project.MainPM === name || (project.tasks && project.tasks.some(task => task.PMName === name))
      );

      const userInvoices = invoiceResponse.data.invoices.filter(invoice => 
        invoice.MainPM === name || (invoice.tasks && invoice.tasks.some(task => task.PMName === name))
      );

      const userPOs = poResponse.data.pos.filter(po => 
        po.pm_name === name && po.payment_status === "정산대기"
      );

      setProjectCount(userProjects.length);
      setInvoiceCount(userInvoices.length);
      setPoCount(userPOs.length);

      // 필터링된 데이터를 정렬
      setFilteredProjects(userProjects.sort((a, b) => new Date(b.SDate) - new Date(a.SDate)));
      setFilteredInvoices(userInvoices.sort((a, b) => new Date(b.payment_requested_at) - new Date(a.payment_requested_at)));
      setFilteredPOs(userPOs.sort((a, b) => new Date(b.payment_requested_at) - new Date(a.payment_requested_at)));

      // 페이지네이션 상태 초기화
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching counts:', error);
    }
  };

  const handleLogoutClick = () => {
    setModalInfo({
      show: true,
      title: '로그아웃 확인',
      body: '정말 로그아웃 하시겠습니까?',
      onSave: () => {
        setModalInfo({ ...modalInfo, show: false });
        handleLogout();
      },
      onClose: () => setModalInfo({ ...modalInfo, show: false })
    });
  };

  const apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
  const imagePath = profile.image ? `${apiUrl}/upload/profile_pics/${profile.image}` : "https://via.placeholder.com/40";

  const handleMyProjectsClick = () => {
    navigate(`/project?search=${userName}`);
  };

  const handleMyInvoicesClick = () => {
    navigate(`/financial/invoice?search=${userName}`);
  };

  const handleMyPOsClick = () => {
    navigate(`/financial/po?search=${userName}`);
  };

  // 현재 페이지에 표시할 데이터 계산
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProjects = filteredProjects.slice(indexOfFirstItem, indexOfLastItem);
  const currentInvoices = filteredInvoices.slice(indexOfFirstItem, indexOfLastItem);
  const currentPOs = filteredPOs.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="">
      <div className="flex items-center mb-4">
        <img
          src={imagePath}
          alt="User Avatar"
          className="w-10 h-10 mr-3 rounded-full"
        />
        <div>
          <h3 className="text-lg font-semibold">{profile.name || 'Unknown User'}</h3>
          <p className="text-sm text-gray-500">{profile.email || 'unknown@example.com'}</p>
        </div>
      </div>
      <ul className="space-y-2">
        <li className="flex items-center justify-between">
          <span>내 프로필</span>
        </li>
        <li className="flex items-center justify-between cursor-pointer" onClick={handleMyProjectsClick}>
          <span>내 프로젝트</span>
          <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">{projectCount}</span>
        </li>
        <li className="flex items-center justify-between cursor-pointer" onClick={handleMyInvoicesClick}>
          <span>내 견적 관리</span>
          <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">{invoiceCount}</span>
        </li>
        <li className="flex items-center justify-between cursor-pointer" onClick={handleMyPOsClick}>
          <span>내 PO 관리</span>
          <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">{poCount}</span>
        </li>
        <li className="flex items-center justify-between">
          <span>설정언어</span>
          <span className="flex items-center">
            <span>English</span>
            <LiaFlagUsaSolid className="ml-2" />
          </span>
        </li>
        <li className="flex items-center justify-between">
          <LogoutButton onClick={handleLogoutClick} />
        </li>
      </ul>
      {modalInfo.show && (
        <OneButtonModal
          id="logoutModal"
          title={modalInfo.title}
          body={modalInfo.body}
          onSave={modalInfo.onSave}
          onClose={modalInfo.onClose}
        />
      )}
    </div>
  );
};

export default LoggedSidebar;