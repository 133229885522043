import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import '../../App.css';
import BoardTitle from '../../area/BoardTitle';
import POApprovalBoard from '../../boards/POApprovalBoard';
import Adsbar from '../../components/bars/Adsbar';
import { SidebarContext } from '../../contexts/SidebarContext';

const Confirmation = () => {
  const { isCollapsed } = useContext(SidebarContext); 
  const { poNumber } = useParams(); 
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const workerId = queryParams.get('worker_id'); 

  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-white custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <BoardTitle title="Confirmation" /> 
        <POApprovalBoard poNumber={poNumber} workerId={workerId} /> 
      </main>
      <aside className="flex flex-col w-[300px] p-1 bg-[#EEEEEE] custom:hidden">
        <div className="sticky top-0 rounded-lg">
          <Adsbar />
        </div>
      </aside>
    </div>
  );
};

export default Confirmation;