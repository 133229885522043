import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../axiosConfig';

const DownloadPage = () => {
    const { projectId } = useParams();

    useEffect(() => {
        const downloadFiles = async () => {
            try {
                // 토큰 생성 요청
                const tokenResponse = await axios.post('/generateDownloadToken', { project_id: projectId });
                console.log('Token Response:', tokenResponse);  // 토큰 응답 출력
                const token = tokenResponse.data.token;

                // 파일 다운로드 요청
                const response = await axios.get(`/downloadFiles?token=${token}`, {
                    responseType: 'blob',  // 파일을 blob 형태로 받아오기 위해 설정
                });
                console.log('Download Response:', response);  // 다운로드 응답 출력

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `project_files_${projectId}.zip`);  // 다운로드할 파일 이름 설정
                document.body.appendChild(link);
                link.click();
                link.remove();
            } catch (error) {
                console.error('Error downloading files:', error);
                alert('파일 다운로드 중 오류가 발생했습니다.');
            }
        };

        downloadFiles();
    }, [projectId]);

    return (
        <div className="download-page">
            <h1>파일 다운로드 중...</h1>
            <p>잠시만 기다려 주세요. 파일 다운로드가 자동으로 시작됩니다.</p>
        </div>
    );
};

export default DownloadPage;