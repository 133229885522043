import React, { useEffect, useState } from "react";

const Alert = ({ message, duration = 3000 }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (message) {
      setVisible(true);
      const timer = setTimeout(() => setVisible(false), duration);
      return () => clearTimeout(timer);
    }
  }, [message, duration]);

  if (!visible) return null;

  return (
    <div className="fixed flex items-center px-4 py-3 text-green-700 bg-green-100 border border-green-400 rounded bottom-4 right-4">
      <span className="mr-2">{message}</span>
      <button onClick={() => setVisible(false)} className="ml-2 text-green-700">
        닫기
      </button>
    </div>
  );
};

export default Alert;