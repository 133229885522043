import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({ onImageUpload, initialImage }) => {
  const [imagePreview, setImagePreview] = useState(initialImage);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
      onImageUpload(reader.result);
    };
    reader.readAsDataURL(file);
  }, [onImageUpload]);

  useEffect(() => {
    setImagePreview(initialImage);
  }, [initialImage]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

  return (
    <div {...getRootProps({ className: 'dropzone' })} className="flex items-center justify-center w-32 h-32 p-4 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
      <input {...getInputProps()} />
      {imagePreview ? (
        <img src={imagePreview} alt="Preview" className="object-cover w-full h-full" />
      ) : (
        <p className="text-gray-500">프로필 사진</p>
      )}
    </div>
  );
};

export default Dropzone;
