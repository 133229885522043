import React from 'react';
import { useParams } from 'react-router-dom';
import projects from '../../../src/data/Projects';

const PMDetails = () => {
  const { pm } = useParams();
  const pmProjects = projects.filter((p) => p.projectmanager === pm);

  if (pmProjects.length === 0) {
    return <div>PM not found</div>;
  }

  return (
    <div>
      <h1>PM Details: {pm}</h1>
      <ul>
        {pmProjects.map((project) => (
          <li key={project.id}>
            <p>견적번호: {project.id}</p>
            <p>견적금액: {project.totalamount}</p>
            <p>클라이언트: {project.client}</p>
            <p>번역상황: {project.projectstatus}</p>
            <p>정산상황: {project.paymentstatus}</p>
            <p>납품일: {project.deliverydate}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PMDetails;