import React from 'react';
import '../App.css';
import Board from '../boards/Board';
import AsideContainer from '../components/containers/AsideContainer';

const Financial = () => {
  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]" >
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-white custom:w-full custom:mr-0 sm-custom:mx-2">
        <Board /> {/* 게시판 컴포넌트 추가 */}
      </main>
      <AsideContainer />
    </div>
  );
};

export default Financial;

