import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactStars from 'react-rating-stars-component';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import { LargeModal, SaveConfirmationModal } from '../components/commons/Modal';
import { formatNumber } from '../components/commons/Utils';
import AddWorkerModal from '../components/commons/modal/AddWorkerModal';
import CustomSelect from '../components/commons/modal/CustomSelect';
import EditWorkerModal from '../components/commons/modal/EditWorkerModal';
import { SidebarContext } from '../contexts/SidebarContext';

const fetchWorkers = async () => {
  const { data } = await axios.get('/workers');
  return data;
};

const WorkerBoard = ({ onAddWorkerSuccess }) => {
  const { isCollapsed } = useContext(SidebarContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [saveMessage, setSaveMessage] = useState('');
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [importedData, setImportedData] = useState([]);
  const [saveStatus, setSaveStatus] = useState({});
  const fileInputRef = React.createRef();
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    taskType: '',
    workerType: '',
    sLang: '',
    tLang: '',
    currency: '',
    domains: []
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    taskType: [],
    currency: [],
    language: [],
    workerType: [],
    domain: []
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    taskType: null,
    workerType: null,
    sLang: null,
    tLang: null,
    currency: null,
    domains: []
  });
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [expandedWorkerTypes, setExpandedWorkerTypes] = useState({});
  const [expandedTaskTypes, setExpandedTaskTypes] = useState({});
  const [expandedDomains, setExpandedDomains] = useState({});
  const [filteredWorkers, setFilteredWorkers] = useState([]);

  const workersPerPage = 10;

  const { data: workers, isLoading, refetch } = useQuery('workers', fetchWorkers);

  const filteredWorkersMemo = useMemo(() => {
    if (!workers) return [];
    return workers.filter(worker =>
      (!filters.taskType || worker.taskType.includes(filters.taskType)) &&
      (!filters.workerType || worker.workerType.includes(filters.workerType)) &&
      (!filters.sLang || worker.sLang === filters.sLang) &&
      (!filters.tLang || worker.tLang === filters.tLang) &&
      (!filters.currency || worker.currency === filters.currency) &&
      (filters.domains.length === 0 || (worker.domains && filters.domains.some(domain => worker.domains.includes(domain))))
    );
  }, [workers, filters]);

  useEffect(() => {
    setFilteredWorkers(filteredWorkersMemo);
  }, [filteredWorkersMemo]);

  const currentWorkers = useMemo(() => {
    const indexOfLastWorker = currentPage * workersPerPage;
    const indexOfFirstWorker = indexOfLastWorker - workersPerPage;
    return filteredWorkers.slice(indexOfFirstWorker, indexOfLastWorker);
  }, [filteredWorkers, currentPage]);

  const pageNumbers = useMemo(() => {
    return [...Array(Math.ceil(filteredWorkers.length / workersPerPage)).keys()].map(i => i + 1);
  }, [filteredWorkers]);

  const addWorkerMutation = useMutation(
    (newWorker) => axios.post('/addWorker', newWorker),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workers');
        setIsModalOpen(false);
        onAddWorkerSuccess();
      },
    }
  );

  const editWorkerMutation = useMutation(
    (updatedWorker) => axios.put(`/editWorker/${updatedWorker._id}`, updatedWorker),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workers');
        setIsEditModalOpen(false);
        onAddWorkerSuccess();
      },
    }
  );

  const deleteWorkerMutation = useMutation(
    (workerIds) => axios.post('/deleteWorkers', { ids: workerIds }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workers');
        setSelectedWorkers([]);
      },
    }
  );

  const handleSelectWorker = (workerId) => {
    setSelectedWorkers(prevSelected =>
      prevSelected.includes(workerId)
        ? prevSelected.filter(id => id !== workerId)
        : [...prevSelected, workerId]
    );
  };

  const handleSelectAllWorkers = (e) => {
    if (e.target.checked) {
      const allWorkerIds = workers.map(worker => worker._id);
      setSelectedWorkers(allWorkerIds);
    } else {
      setSelectedWorkers([]);
    }
  };

  const handleDeleteWorkers = () => {
    setModalAction('delete');
    setIsConfirmModalOpen(true);
  };

  const handleConfirmAction = () => {
    if (modalAction === 'delete') {
      deleteWorkerMutation.mutate(selectedWorkers);
    }
    setIsConfirmModalOpen(false);
    setModalAction('');
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterWorkers(term, filters);
  };

  const handleFilterChange = (name, option) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: option ? option.value : ''
    }));
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [name]: option
    }));
  };

  const handleDomainFilterChange = (options) => {
    const domains = options ? options.map(option => option.value) : [];
    setFilters(prevFilters => ({
      ...prevFilters,
      domains
    }));
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      domains: options
    }));
  };

  const resetFilters = () => {
    setFilters({
      taskType: '',
      workerType: '',
      sLang: '',
      tLang: '',
      currency: '',
      domains: []
    });
    setSelectedOptions({
      taskType: null,
      workerType: null,
      sLang: null,
      tLang: null,
      currency: null,
      domains: []
    });
    setSearchTerm('');
    setFilteredWorkers(filteredWorkersMemo);
  };

  const handleWorkerClick = (workerId) => {
    navigate(`/resource/workers/${workerId}`);
  };

  const handleEditWorker = (worker) => {
    setSelectedWorker(worker);
    setIsEditModalOpen(true);
  };

  const filterWorkers = (term, filter) => {
    setFilteredWorkers(
      workers.filter((worker) =>
        (
          (worker.name && worker.name.toLowerCase().includes(term.toLowerCase())) ||
          (worker.sLang && worker.sLang.toLowerCase().includes(term.toLowerCase())) ||
          (worker.tLang && worker.tLang.toLowerCase().includes(term.toLowerCase())) ||
          (worker.email && worker.email.toLowerCase().includes(term.toLowerCase())) ||
          (worker.mobile && worker.mobile.toLowerCase().includes(term.toLowerCase())) ||
          (worker.overall_rating && Object.values(worker.overall_rating).some(rating => rating.toString().includes(term)))
        ) &&
        (filter.sLang ? worker.sLang === filter.sLang : true) &&
        (filter.tLang ? worker.tLang === filter.tLang : true) &&
        (filter.domains.length === 0 || (worker.domains && worker.domains.some(domain => filter.domains.includes(domain)))) &&
        (filter.taskType ? worker.taskType.includes(filter.taskType) : true) &&
        (filter.workerType ? worker.workerType.includes(filter.workerType) : true)
      )
    );
  };
  

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    sortWorkers(key, direction);
  };

  const sortWorkers = (key, direction) => {
    const sortedWorkers = [...filteredWorkers].sort((a, b) => {
      if (key === 'overall_rating') {
        const aRating = calculateAverage(a.overall_rating);
        const bRating = calculateAverage(b.overall_rating);
        if (aRating < bRating) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (aRating > bRating) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      } else {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });
    setFilteredWorkers(sortedWorkers);
  };

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const types = ['taskType', 'currency', 'language', 'workerType', 'domain'];
        const options = await Promise.all(types.map(type => axios.get(`/dropdown/${type}`)));
        const newOptions = {};
        types.forEach((type, index) => {
          newOptions[type] = options[index].data.map(option => ({ value: option, label: option }));
        });
        setDropdownOptions(newOptions);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  if (isLoading) return <div>Loading...</div>;

  const toggleExpand = (id, type) => {
    if (type === 'workerType') {
      setExpandedWorkerTypes((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (type === 'taskType') {
      setExpandedTaskTypes((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (type === 'domains') {
      setExpandedDomains((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const handleExport = () => {
    const selectedWorkerData = workers.filter(worker => selectedWorkers.includes(worker._id));
    
    const exportData = selectedWorkerData.map(worker => ({
      _id: worker._id.$oid || worker._id,
      Name: worker.name,
      'Worker Type': Array.isArray(worker.workerType) ? worker.workerType.join(',') : worker.workerType,
      'Task Type': Array.isArray(worker.taskType) ? worker.taskType.join(',') : worker.taskType,
      Domain: Array.isArray(worker.domains) ? worker.domains.join(',') : worker.domains,
      'Source Language': worker.sLang,
      'Target Language': worker.tLang,
      Rate: worker.rate,
      Currency: worker.currency,
      Email: worker.email,
      Mobile: worker.mobile,
      'Payment Method': worker.paymentMethod,
      'Payment Country': worker.paymentCountry,
      'Payment Bank': worker.paymentBank,
      'Payment Account': worker.paymentAccount,
      Memo: worker.memo,
      'Exchange Rate': worker.exchangeRate,
      Unit: worker.unit,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Workers');
    XLSX.writeFile(workbook, 'workers.xlsx');
  };

  const saveImportedData = async () => {
    const emailSet = new Set();
    const localSaveStatus = {};
    let successCount = 0;
    let representativeName = '';

    const formattedData = importedData.map(worker => {
      if (!worker.Email) {
        console.error('Worker without email found:', worker);
        localSaveStatus[worker.Name] = 'error';
        return null;
      }

      if (emailSet.has(worker.Email)) {
        console.error(`Duplicate email found in the sheet: ${worker.Email}`);
        localSaveStatus[worker.Name] = 'error';
        return null;
      }

      emailSet.add(worker.Email);

      return {
        _id: worker._id ? { $oid: worker._id } : null,
        name: worker.Name,
        workerType: worker['Worker Type'] ? worker['Worker Type'].split(',') : [],
        taskType: worker['Task Type'] ? worker['Task Type'].split(',') : [],
        domains: worker.Domain ? worker.Domain.split(',') : [],
        sLang: worker['Source Language'],
        tLang: worker['Target Language'],
        rate: worker.Rate,
        unit: worker.Unit,
        currency: worker.Currency,
        exchangeRate: worker['Exchange Rate'],
        email: worker.Email,
        mobile: worker.Mobile,
        paymentMethod: worker['Payment Method'],
        paymentCountry: worker['Payment Country'],
        paymentBank: worker['Payment Bank'],
        paymentAccount: worker['Payment Account'],
        memo: worker.Memo,
      };
    }).filter(worker => worker !== null);

    try {
      const response = await axios.post('/importWorkers', formattedData);
      
      formattedData.forEach(worker => {
        if (worker) {
          localSaveStatus[worker.name] = 'success';
          successCount++;
          if (!representativeName) {
            representativeName = worker.name;
          }
        }
      });
      setSaveMessage(`${representativeName} 외 ${successCount - 1}명이 저장되었습니다.`);
      setIsSaveModalOpen(true);
    } catch (error) {
      console.error('Error importing workers:', error);
      formattedData.forEach(worker => {
        if (worker) {
          localSaveStatus[worker.name] = 'error';
        }
      });
    }

    setSaveStatus(localSaveStatus);
    setImportedData([...importedData]);
    onAddWorkerSuccess(); // Import 완료 후 다시 데이터 패치
  };

  const handleCloseModal = () => {
    setIsConfirmModalOpen(false);
    setImportedData([]);
    setSaveStatus({});
    onAddWorkerSuccess(); // 모달이 닫힐 때 데이터 패치
  };

  const handleImport = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsConfirmModalOpen(false); // 모달을 열기 전에 초기화
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const importedData = XLSX.utils.sheet_to_json(worksheet);

      setImportedData(importedData);
      setIsConfirmModalOpen(true);
    };
    reader.readAsArrayBuffer(file);

    // 파일 입력 요소의 값을 초기화
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (!isConfirmModalOpen) {
      setImportedData([]);
      setSaveStatus({});
    }
  }, [isConfirmModalOpen]);

  const handleOpenAddWorkerModal = () => {
    setIsModalOpen(true); // 모달 열기
  };

  return (
    <>
    <div>
        <h3 className="mb-4 text-lg font-semibold mt-4">
        <span className=" m-4 pb-1 border-b-2 border-blue-500">작업자 리스트</span>
      </h3>
    </div>
    <div className="relative p-6 overflow-x-auto bg-white shadow-md sm:rounded-lg dark:bg-gray-900 m-4">
      <div className="flex flex-wrap items-center justify-between py-4 space-y-4 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
        <div className="relative">
          <input
            type="text"
            id="table-search-workers"
            className="block pt-2 text-sm text-gray-900 border border-gray-300 rounded-lg ps-10 w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search for workers"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
            <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
          </div>
        </div>
        <div className="relative inline-block text-left">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="mr-2 text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            type="button"
          >
            필터
            <svg
              className="w-2.5 h-2.5 ms-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </button>
          {isDropdownOpen && (
            <div
              className="absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
            >
              <ul
                className="py-2 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownHoverButton"
              >
                <li>
                  <CustomSelect
                    name="taskType"
                    className={"mb-0.5"}
                    value={selectedOptions.taskType}
                    onChange={(option) => handleFilterChange('taskType', option)}
                    options={dropdownOptions.taskType}
                    placeholder="작업 유형"
                  />
                </li>
                <li>
                  <CustomSelect
                    name="workerType"
                    className={"mb-0.5"}
                    value={selectedOptions.workerType}
                    onChange={(option) => handleFilterChange('workerType', option)}
                    options={dropdownOptions.workerType}
                    placeholder="작업자 유형"
                  />
                </li>
                <li>
                  <CustomSelect
                    name="sLang"
                    className={"mb-0.5"}
                    value={selectedOptions.sLang}
                    onChange={(option) => handleFilterChange('sLang', option)}
                    options={dropdownOptions.language}
                    placeholder="출발어"
                  />
                </li>
                <li>
                  <CustomSelect
                    name="tLang"
                    className={"mb-0.5"}
                    value={selectedOptions.tLang}
                    onChange={(option) => handleFilterChange('tLang', option)}
                    options={dropdownOptions.language}
                    placeholder="도착어"
                  />
                </li>
                <li>
                  <CustomSelect
                    name="domains"
                    className={"mb-0.5"}
                    value={selectedOptions.domains}
                    onChange={handleDomainFilterChange}
                    options={dropdownOptions.domain}
                    isMulti
                    placeholder="분야"
                  />
                </li>
                <li>
                  <button
                    onClick={resetFilters}
                    className="w-full flex justify-start px-4 py-2 text-left text-md text-red-500 hover:text-gray-500"
                  >
                    Reset
                  </button>
                </li>
              </ul>
            </div>
          )}
           {selectedWorkers.length > 0 ? (
          <>
            <button
              onClick={handleExport}
              className="inline-flex items-center text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-500 dark:hover:bg-green-600 focus:outline-none dark:focus:ring-green-800"
            >
              내보내기
            </button>
            <label className="inline-flex items-center text-white bg-yellow-500 hover:bg-yellow-700 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2 ml-2 dark:bg-yellow-500 dark:hover:bg-yellow-600 focus:outline-none dark:focus:ring-yellow-800 cursor-pointer">
              가져오기
              <input
                type="file"
                accept=".xlsx"
                onChange={handleImport}
                className="hidden"
                ref={fileInputRef}
              />
            </label>
          </>
        ) : (
          <>
          <button
            onClick={handleOpenAddWorkerModal} 
            className="inline-flex items-center text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-500 dark:hover:bg-blue-600 focus:outline-none dark:focus:ring-blue-800">
            작업자 등록
          </button>
            <label className="inline-flex items-center text-white bg-yellow-500 hover:bg-yellow-700 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-4 py-2 ml-2 dark:bg-yellow-500 dark:hover:bg-yellow-600 focus:outline-none dark:focus:ring-yellow-800 cursor-pointer">
              가져오기
              <input
                type="file"
                accept=".xlsx"
                onChange={handleImport}
                className="hidden"
                ref={fileInputRef}
              />
            </label>
          </>
        )}
        </div>
      </div>

      <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="p-4">
              <div className="flex items-center">
                <input
                  id="checkbox-all-search"
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  onChange={handleSelectAllWorkers}
                  checked={selectedWorkers.length === workers.length}
                />
                <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
              </div>
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => requestSort('name')}>
              이름
              {sortConfig.key === 'name' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3">작업자 유형</th>
            <th scope="col" className="px-6 py-3">작업 유형</th>
            <th scope="col" className="px-6 py-3">분야</th>
            <th scope="col" className="px-6 py-3">출발어</th>
            <th scope="col" className="px-6 py-3">도착어</th>
            <th scope="col" className="px-6 py-3">단위</th>
            <th scope="col" className="px-6 py-3">단가</th>
            <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => requestSort('overall_rating')}>
              평점
              {sortConfig.key === 'overall_rating' && (
                <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
              )}
            </th>
            <th scope="col" className="px-6 py-3">MEMO</th>
            <th scope="col" className="px-6 py-3">ACTION</th>
          </tr>
        </thead>
        <tbody>
          {currentWorkers.map((worker) => (
            <tr key={worker._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="w-4 p-4">
                <div className="flex items-center">
                  <input
                    id={`checkbox-table-search-${worker._id}`}
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    checked={selectedWorkers.includes(worker._id)}
                    onChange={() => handleSelectWorker(worker._id)}
                  />
                  <label htmlFor={`checkbox-table-search-${worker._id}`} className="sr-only">checkbox</label>
                </div>
              </td>
              <td className="px-6 py-4 font-bold underline cursor-pointer text-md min-w-[4em] whitespace-nowrap" onClick={() => handleWorkerClick(worker._id)}>{worker.name}</td>
              <td className="px-6 py-2 min-w-[4em] whitespace-nowrap">
                {Array.isArray(worker.workerType) && worker.workerType.length > 0 && (
                  <div className="flex items-center">
                    <span className="px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                      {worker.workerType[0]}
                    </span>
                    {worker.workerType.length > 1 && (
                      <button onClick={() => toggleExpand(worker._id, 'workerType')} className="ml-2 text-xs text-blue-500">
                        {expandedWorkerTypes[worker._id] ? '▲' : '▼'}
                      </button>
                    )}
                  </div>
                )}
                {expandedWorkerTypes[worker._id] && worker.workerType.slice(1).map((type, index) => (
                  <span key={index} className="block px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full mt-1">
                    {type}
                  </span>
                ))}
              </td>
              <td className="px-6 py-2 min-w-[4em] whitespace-nowrap">
              {Array.isArray(worker.taskType) && worker.taskType.length > 0 && (
                  <div className="flex items-center">
                    <span className="px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                      {worker.taskType[0]}
                    </span>
                    {worker.taskType.length > 1 && (
                      <button onClick={() => toggleExpand(worker._id, 'taskType')} className="ml-2 text-xs text-blue-500">
                        {expandedTaskTypes[worker._id] ? '▲' : '▼'}
                      </button>
                    )}
                  </div>
                )}
                {expandedTaskTypes[worker._id] && worker.taskType.slice(1).map((type, index) => (
                  <span key={index} className="block px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full mt-1">
                    {type}
                  </span>
                ))}
              </td>
              <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">
                {worker.domains && worker.domains.length > 0 && (
                  <div className="flex items-center">
                    <span className="px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                      {worker.domains[0]}
                    </span>
                    {worker.domains.length > 1 && (
                      <button onClick={() => toggleExpand(worker._id, 'domains')} className="ml-2 text-xs text-blue-500">
                        {expandedDomains[worker._id] ? '▲' : '▼'}
                      </button>
                    )}
                  </div>
                )}
                {expandedDomains[worker._id] && worker.domains.slice(1).map((domain, index) => (
                  <span key={index} className="block px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full mt-1">
                    {domain}
                  </span>
                ))}
              </td>
              <td className="px-6 py-2 min-w-[4em] whitespace-nowrap">{worker.sLang}</td>
              <td className="px-6 py-2 min-w-[4em] whitespace-nowrap">{worker.tLang}</td>
              <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.unit}</td>
              <td className="px-6 py-3 min-w-[4em] whitespace-nowrap font-bold">{formatNumber(worker.rate, worker.currency)}</td>
              <td className="px-6 py-3 min-w-[8em] whitespace-nowrap">
                <ReactStars
                  count={5}
                  value={worker.overall_rating ? (Object.values(worker.overall_rating).reduce((acc, val) => acc + val, 0) / 6) / 20 : 0}
                  edit={false}
                  size={12}
                  isHalf={true}
                  activeColor="#ffd700"
                />
              </td>
              <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{worker.memo}</td>
              <td className="px-6 py-4">
                <button onClick={() => handleEditWorker(worker)} className="font-medium text-blue-600 hover:underline">Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isConfirmModalOpen && (
        <LargeModal
          title="작업자 데이터 불러오기"
          body={
            <div>
              <div className="overflow-auto" style={{ maxHeight: '70vh', maxWidth: '80vw' }}>
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th>Status</th>
                      {Object.keys(importedData[0] || {}).map((key) => (
                        <th key={key} className="px-6 py-3">{key}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {importedData.map((worker, index) => (
                      <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td className="px-6 py-4 whitespace-nowrap">
                          {saveStatus[worker.Name] === 'success' ? (
                            <span className="text-green-600">✔</span>
                          ) : saveStatus[worker.Name] === 'error' ? (
                            <span className="text-red-600">✘</span>
                          ) : (
                            <div className="flex items-center justify-center w-8 h-8">
                              <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                              </svg>
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                        </td>
                        {Object.values(worker).map((value, i) => (
                          <td key={i} className="px-6 py-4 whitespace-nowrap">{value}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          }
          onSave={saveImportedData}
          onClose={handleCloseModal}
          isOpen={isConfirmModalOpen}
        />
      )}

      <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />

      <AddWorkerModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onOpen={() => {
          // Resetting the form data when the modal opens
          setFormData(initialFormData); 
          setPresetData(initialPresetData);
        }}
        onSave={(newWorker) => addWorkerMutation.mutate(newWorker)}
      />
      <EditWorkerModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        worker={selectedWorker}
        onSave={(updatedWorker) => editWorkerMutation.mutate(updatedWorker)}
      />

      <SaveConfirmationModal
        id="saveModal"
        title="알림"
        body={saveMessage}
        onSave={() => setIsSaveModalOpen(false)}
        onClose={() => setIsSaveModalOpen(false)}
        isOpen={isSaveModalOpen}
      />
    </div>
    </>
  );
};

const calculateAverage = (ratings) => {
  if (!ratings) return 0;
  const values = Object.values(ratings);
  const sum = values.reduce((acc, val) => acc + val, 0);
  const average = sum / values.length;
  return average / 20;
};

export default WorkerBoard;
