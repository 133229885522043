import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import * as XLSX from 'xlsx';
import CustomSelect from '../components/commons/modal/CustomSelect';

const PepeContent = ({ projects = [] }) => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  // Sort projects by start date (SDate)
  const sortedProjects = projects.slice().sort((a, b) => new Date(a.SDate) - new Date(b.SDate));

  // Filter projects for the selected year
  const filteredProjects = sortedProjects.filter((project) => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);
    return startDate.getFullYear() <= selectedYear && endDate.getFullYear() >= selectedYear;
  });

  // Generate monthly project data
  const monthlyData = Array.from({ length: 12 }, () => ({
    total: 0,
    진행: 0,
  }));

  filteredProjects.forEach((project) => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);
    const status = project.Status;

    for (let month = 0; month < 12; month++) {
      const monthStart = new Date(selectedYear, month, 1);
      const monthEnd = new Date(selectedYear, month + 1, 0);

      if (startDate <= monthEnd && endDate >= monthStart) {
        monthlyData[month].total += 1;
        if (status === '진행') {
          monthlyData[month].진행 += 1;
        }
      }
    }
  });

  const monthlySeries = monthlyData.map((data, month) => ({
    x: new Date(selectedYear, month, 1).toLocaleString('default', { month: 'short' }),
    y: data.total,
    goals: [
      {
        name: '진행',
        value: data.진행,
        strokeColor: '#00E396',
      },
    ],
  }));

  // Generate daily revenue data
  const dailyRevenueData = sortedProjects.reduce((acc, project) => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);
    const workDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const dailyRevenue = project.expectedRevenue / workDays;

    const month = startDate.getMonth();
    if (!acc[month]) {
      acc[month] = 0;
    }
    acc[month] += dailyRevenue;
    return acc;
  }, {});

  const dailyRevenueSeries = Array.from({ length: 12 }, (_, month) => ({
    x: new Date(selectedYear, month, 1).toLocaleString('default', { month: 'short' }),
    y: dailyRevenueData[month] || 0,
  }));

  // Generate data for task types per month
  const monthlyTaskTypeData = Array.from({ length: 12 }, () => ({
    TEP: 0,
    LQA: 0,
    MTPE: 0,
  }));

  filteredProjects.forEach((project) => {
    const startDate = new Date(project.SDate);
    const endDate = new Date(project.DDate);

    project.tasks.forEach((task) => {
      const taskType = task.ProjectType.toUpperCase();

      for (let month = 0; month < 12; month++) {
        const monthStart = new Date(selectedYear, month, 1);
        const monthEnd = new Date(selectedYear, month + 1, 0);

        if (startDate <= monthEnd && endDate >= monthStart) {
          if (['TEP', 'LQA', 'MTPE'].includes(taskType)) {
            monthlyTaskTypeData[month][taskType]++;
          }
        }
      }
    });
  });

  const taskTypeSeries = ['TEP', 'LQA', 'MTPE'].map((type) => ({
    name: type,
    data: monthlyTaskTypeData.map((monthData) => monthData[type]),
  }));

  // Available years for the select dropdown
  const years = Array.from(new Set(projects.map((project) => new Date(project.SDate).getFullYear())));

  // Export data to Excel
  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Add monthly projects sheet
    const monthlySheetData = [['Month', 'Total Projects', 'In Progress']];
    monthlySeries.forEach((data) => {
      monthlySheetData.push([data.x, data.y, data.goals[0].value]);
    });
    const monthlySheet = XLSX.utils.aoa_to_sheet(monthlySheetData);
    XLSX.utils.book_append_sheet(wb, monthlySheet, 'Monthly Projects');

    // Add other sheets based on group and PM data...

    // Save the Excel file
    XLSX.writeFile(wb, `Projects_${selectedYear}.xlsx`);
  };

  return (
    <div className="w-full p-2 mb-2 text-center bg-gray-150" style={{ height: 'auto' }}>
      <div className="grid grid-cols-1 gap-4 mt-0 md:grid-cols-2 lg:grid-cols-3">
        {/* Ensure the UI remains even if no projects are available */}
        <div className="col-span-1 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
          <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">월간 프로젝트</h2>
          <div className="mb-2">
            <CustomSelect
              value={{ value: selectedYear, label: selectedYear }}
              onChange={(option) => setSelectedYear(option.value)}
              options={years.map((year) => ({ value: year, label: year }))}
            />
          </div>
          <Chart
            options={{
              chart: {
                height: 350,
                type: 'line',
                stacked: false,
              },
              stroke: {
                width: [0, 4],
              },
              dataLabels: {
                enabled: true,
                enabledOnSeries: [1],
              },
              xaxis: {
                categories: Array.from({ length: 12 }, (_, i) =>
                  new Date(selectedYear, i, 1).toLocaleString('default', { month: 'short' })
                ),
                labels: {
                  rotate: -45, // Rotate labels
                },
              },
              yaxis: [
                {
                  title: {
                    text: 'Projects',
                    style: {
                      color: '#008FFB',
                    },
                  },
                  labels: {
                    style: {
                      colors: '#008FFB',
                    },
                  },
                },
              ],
              tooltip: {
                shared: true,
                intersect: false,
                x: {
                  formatter: (val) => `Month: ${val}`,
                },
              },
              legend: {
                horizontalAlign: 'left',
                offsetX: 40,
              },
            }}
            series={[
              {
                name: '전체 프로젝트',
                type: 'column',
                data: monthlySeries.map((item) => item.y),
              },
              {
                name: '진행 중인 프로젝트',
                type: 'line',
                data: monthlySeries.map((item) => item.goals[0].value),
              },
            ]}
            type="line"
            height={250}
          />
        </div>
        <div className="col-span-2 p-4 text-left bg-white rounded-lg shadow-md" style={{ height: '100%' }}>
          <h2 className="mb-2 text-lg font-semibold text-blue-500 border-b border-blue-500">월간 작업 유형</h2>
          <div className="mb-2">
            <CustomSelect
              value={{ value: selectedYear, label: selectedYear }}
              onChange={(option) => setSelectedYear(option.value)}
              options={years.map((year) => ({ value: year, label: year }))}
            />
          </div>
          <Chart
            options={{
              chart: {
                type: 'bar',
                height: 350,
                stacked: false,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded',
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
              },
              xaxis: {
                categories: Array.from({ length: 12 }, (_, i) =>
                  new Date(selectedYear, i, 1).toLocaleString('default', { month: 'short' })
                ),
              },
              yaxis: {
                title: {
                  text: 'Tasks',
                },
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                y: {
                  formatter: (val) => `${val} tasks`,
                },
              },
              legend: {
                position: 'top',
              },
              colors: ['#008FFB', '#00E396', '#FEB019'], // Colors for TEP, LQA, MTPE
            }}
            series={taskTypeSeries}
            type="bar"
            height={250}
          />
        </div>
      </div>
    </div>
  );
};

export default PepeContent;
