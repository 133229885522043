import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import { formatNumber } from '../components/commons/Utils';

const formatDate = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  const yy = String(date.getFullYear()).slice(-2);
  const mm = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  return `${yy}/${mm}/${dd}`;
};

const fetchPOList = async (projectId) => {
  const response = await axios.get('/getPOList', { params: { project_id: projectId } });
  return response.data;
};

const ITEMS_PER_PAGE = 19;

const POTable = ({ projectId, projectName, onPOSuccess }) => {
  const [poList, setPoList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { data, error, isLoading, refetch } = useQuery(['poList', projectId], () => fetchPOList(projectId), {
    refetchOnWindowFocus: false,
    onSuccess: (data) => setPoList(data.po_list),
  });

  const deletePOMutation = useMutation(
    async ({ poId, taskId }) => {
      await axios.delete(`/deletePO/${poId}`, {
        data: { project_id: projectId, task_id: taskId }
      });
    },
    {
      onSuccess: (_, { poId }) => {
        setPoList((prevPoList) => prevPoList.filter((po) => po._id !== poId));
        refetch(); 
      },
    }
  );

  const handleDeletePO = (poId, taskId) => {
    if (!poId || !taskId) {
      return;
    }
    deletePOMutation.mutate({ poId, taskId });
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= Math.ceil(poList.length / ITEMS_PER_PAGE)) {
      setCurrentPage(newPage);
    }
  };

  const handlePOSuccess = () => {
    refetch();
    if (onPOSuccess) onPOSuccess();
  };

  const currentPageData = poList.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);
  const totalPages = Math.ceil(poList.length / ITEMS_PER_PAGE);

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching PO list: {error.message}</div>;
  }

  return (
    <div className="mt-4">
      <h2 className="text-xl font-semibold">
        <span className="pb-1 border-b-2 border-blue-500">{projectName} PO 목록</span>
      </h2>
      <table className="w-full mt-2 text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th className="px-4 py-3">PO 번호</th>
            <th className="px-4 py-3">이름</th>
            <th className="px-4 py-3">이메일</th>
            <th className="px-4 py-3">연락처</th>
            <th className="px-4 py-3">분량</th>
            <th className="px-4 py-3">단가</th>
            <th className="px-6 py-3">작업비</th>
            <th className="px-4 py-3 font-bold">시작일</th>
            <th className="px-4 py-3 font-bold">종료일</th>
            <th className="px-4 py-3 font-bold">결제 요청일</th>
            <th className="px-4 py-3 font-bold">결제 완료일</th>
            <th className="px-4 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPageData.map((po, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-4 py-4">{po.po_number}</td>
              <td className="px-4 py-4">{po.worker_name}</td>
              <td className="px-4 py-4">{po.worker_email}</td>
              <td className="px-4 py-4">{po.worker_mobile}</td>
              <td className="px-4 py-4">{po.volume}</td>
              <td className="px-4 py-4 min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(po.rate, po.currency)}</td>
              <td className="px-6 py-2 font-bold min-w-[4em] whitespace-nowrap text-blue-600">{formatNumber(po.total, po.currency)}</td>
              <td className="px-4 py-4 font-bold">{formatDate(po.work_started_at)}</td>
              <td className="px-4 py-4 font-bold">{formatDate(po.work_ended_at)}</td>
              <td className="px-4 py-4 font-bold">{formatDate(po.payment_requested_at)}</td>
              <td className="px-4 py-4 font-bold">{formatDate(po.payment_received_at)}</td>
              <td className="px-4 py-4">
                <button
                  className="px-2 py-1 text-xs text-white bg-red-500 rounded"
                  onClick={() => handleDeletePO(po._id, po.task_id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex items-center justify-center mt-4">
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
      </div>
    </div>
  );
};

export default POTable;