import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import io from 'socket.io-client';
import axios from '../axiosConfig';

const NotificationContext = createContext();

export const useNotifications = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [isSidebarExpanded, setSidebarExpanded] = useState(true);
  const [isDisplayExpanded, setDisplayExpanded] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // 로그인 상태 확인
  const queryClient = useQueryClient();

  // 세션 사용자 정보 확인하여 로그인 상태 설정
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const response = await axios.get('/session-user');
        if (response.status === 200) {
          setIsLoggedIn(true);
        }
      } catch (error) {
        setIsLoggedIn(false);
      }
    };

    checkLoginStatus();
  }, []);

  // 로그인된 상태에서만 WebSocket 연결
  useEffect(() => {
    if (!isLoggedIn) return; // 로그인된 경우에만 실행

    const socketUrl = process.env.REACT_APP_SOCKET_URL;
    const socket = io(socketUrl, {
      withCredentials: true,
      transports: ['websocket'],
    });

    socket.on('notification_update', (updatedNotifications) => {
      setNotifications(updatedNotifications);
      queryClient.invalidateQueries('notifications');
    });

    return () => socket.disconnect();
  }, [isLoggedIn, queryClient]);

  // 로그인 상태에 따른 알림 데이터 페칭
  const fetchNotifications = async () => {
    const response = await axios.get('/notifications');
    return response.data.notifications;
  };

  const { refetch } = useQuery('notifications', fetchNotifications, {
    enabled: isLoggedIn, // 로그인된 경우에만 쿼리 실행
    onSuccess: (data) => {
      setNotifications(data);
    },
  });

  // 알림 삭제 함수
  const clearNotification = async (notificationId) => {
    try {
      await axios.delete(`/notifications/${notificationId}`);
      setNotifications(prevNotifications =>
        prevNotifications.filter(notification => notification._id !== notificationId)
      );
      queryClient.invalidateQueries('notifications');
    } catch (error) {
      console.error('알림 삭제 중 오류 발생:', error);
    }
  };

  // 알림 읽음 처리 함수
  const readNotification = async (notificationId) => {
    try {
      await axios.put(`/notifications/${notificationId}/read`);
      setNotifications(prevNotifications =>
        prevNotifications.map(notification =>
          notification._id === notificationId ? { ...notification, is_read: true } : notification
        )
      );
      queryClient.invalidateQueries('notifications');
    } catch (error) {
      console.error('알림 읽음 처리 중 오류 발생:', error);
    }
  };

  // 알림 처리 함수
  const handleNotification = async (notificationId, action, additionalData) => {
    try {
      let response;
      if (additionalData.type === 'signup') {
        response = await axios.put(`/approve_signup`, {
          notification_id: notificationId,
          action: action,
          signup_data: additionalData,
        });
      } else if (additionalData.type === 'po') {
        response = await axios.put(`/approvePOPaymentRequest`, {
          notification_id: notificationId,
          action: action,
          po_id: additionalData.po_id,
          requested_date: additionalData.requested_date,
        });
      }

      if (response && response.status === 200) {
        setNotifications(prevNotifications =>
          prevNotifications.map(notification =>
            notification._id === notificationId ? { ...notification, is_read: true } : notification
          )
        );
        queryClient.invalidateQueries('notifications');
      } else {
        console.error('알림 처리 중 오류 발생: 응답이 없습니다.');
      }
    } catch (error) {
      console.error('알림 처리 중 오류 발생:', error);
    }
  };

  const toggleSidebar = () => setSidebarExpanded(prev => !prev);
  const toggleDisplay = () => setDisplayExpanded(prev => !prev);

  return (
    <NotificationContext.Provider value={{ 
      notifications, 
      setNotifications,  
      clearNotification, 
      readNotification,
      refetchNotifications: refetch,
      handleNotification,
      isSidebarExpanded,
      isDisplayExpanded,
      toggleSidebar,
      toggleDisplay,
    }}>
      {children}
    </NotificationContext.Provider>
  );
};
