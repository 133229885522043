import React, { useEffect, useState } from 'react';
import axios from '../../axiosConfig';
import Pagination from '../../components/bars/Pagination';
import { LargeModal } from '../../components/commons/Modal';
import CustomSelect from '../../components/commons/modal/CustomSelect';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

function ProjectSettings() {
    const [newType, setNewType] = useState('projectType');
    const [newValue, setNewValue] = useState('');
    const [dropdownData, setDropdownData] = useState([]);
    const [itemsPerPage] = useState(10);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('');
    const [formData, setFormData] = useState({
        projectType: '',
        language: '',
        rate: '',
        clientType: '',
        workerType: '',
        taskStatus: '',
        SLang: '',
        TLang: '',
        currency: '',
        unit: '',
        rateValue: ''
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [currentLanguagePage, setCurrentLanguagePage] = useState(1);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        fetchDropdownData();
    }, [currentPage, currentLanguagePage]);

    const fetchDropdownData = async () => {
        try {
            const response = await axios.get(`/getDropdown`, {
                params: {
                    page: currentPage,
                    limit: itemsPerPage
                }
            });
            console.log('Dropdown Data:', response.data);
            setDropdownData(response.data);
        } catch (error) {
            console.error('드롭다운 데이터 가져오기 실패:', error);
        }
    };

    const handleAddItem = async () => {
        try {
            await axios.post(`/saveDropdown`, { type: newType, value: newValue });
            alert(`${newType}가 추가되었습니다.`);
            setNewValue('');
            fetchDropdownData();
        } catch (error) {
            console.error(`${newType} 추가 실패:`, error);
            alert(`${newType} 추가에 실패했습니다.`);
        }
    };

    const handleAddLanguagePair = async () => {
        try {
            const { projectType, SLang, TLang, currency, unit, rateValue } = formData;
            await axios.post(`/saveDropdown`, {
                type: 'languagePair',
                value: { projectType, SLang, TLang, currency, unit, rate: rateValue }
            });
            alert('언어 쌍이 추가되었습니다.');
            setFormData({
                projectType: '',
                language: '',
                rate: '',
                clientType: '',
                workerType: '',
                taskStatus: '',
                SLang: '',
                TLang: '',
                currency: '',
                unit: '',
                rateValue: ''
            });
            fetchDropdownData();
        } catch (error) {
            console.error('언어 쌍 추가 실패:', error);
            alert('언어 쌍 추가에 실패했습니다.');
        }
    };

    const handleDeleteItem = async (type, value) => {
        try {
            await axios.post(`/deleteDropdown`, { type, value });
            alert(`${type}가 삭제되었습니다.`);
            fetchDropdownData();
        } catch (error) {
            console.error(`${type} 삭제 실패:`, error);
            alert(`${type} 삭제에 실패했습니다.`);
        }
    };

    const handleOpenModal = (type) => {
        setModalType(type);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSaveModal = () => {
        if (modalType === 'addDropdown') {
            handleAddItem();
        } else if (modalType === 'addLanguagePair') {
            handleAddLanguagePair();
        }
        setIsModalOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFilterChange = (e) => {
        const filterValue = e.target.value.toLowerCase();
        setFilter(filterValue);
    };

    const allItems = dropdownData.flatMap(item => item.values.map(value => ({ type: item.type, value })));
    const filteredItems = allItems.filter(item => item.type.toLowerCase().includes(filter));
    const languagePairs = filteredItems.filter(item => item.type === 'languagePair');
    const otherItems = filteredItems.filter(item => item.type !== 'languagePair' && item.type !== 'employees' && item.type !== 'clients' && item.type !== 'group' && item.type !== 'division' && item.type !== 'position' && item.type !== 'status');

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const indexOfLastLanguageItem = currentLanguagePage * itemsPerPage;
    const indexOfFirstLanguageItem = indexOfLastLanguageItem - itemsPerPage;

    const currentItems = otherItems.slice(indexOfFirstItem, indexOfLastItem);
    const currentLanguageItems = currentLanguagePage === 1 ? languagePairs.slice(0, itemsPerPage) : languagePairs.slice((currentLanguagePage - 1) * itemsPerPage, currentLanguagePage * itemsPerPage);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(otherItems.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const languagePageNumbers = [];
    for (let i = 1; i <= Math.ceil(languagePairs.length / itemsPerPage); i++) {
        languagePageNumbers.push(i);
    }

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="grid grid-cols-2 gap-4">
                <div className="p-4 bg-white rounded-md shadow">
                    <h1 className="mb-4 text-2xl font-bold">드롭다운 항목</h1>
                    <button onClick={() => handleOpenModal('addDropdown')} className="px-4 py-2 mb-4 text-white bg-blue-500 rounded-md">드롭다운 항목 추가</button>
                    <input
                        type="text"
                        placeholder="항목 필터링..."
                        value={filter}
                        onChange={handleFilterChange}
                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 dark:border-gray-600" />
                                        <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">항목</th>
                                <th scope="col" className="px-6 py-3">값</th>
                                <th className="px-6 py-3">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((item, index) => (
                                <tr key={`${index}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input id={`checkbox-table-search-${index}`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor={`checkbox-table-search-${index}`} className="sr-only">checkbox</label>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 border-b border-gray-300">{item.type}</td>
                                    <td className="px-6 py-4 border-b border-gray-300">
                                        {typeof item.value === 'object' ? `${item.value.SLang} - ${item.value.TLang} : ${item.value.rate}` : item.value}
                                    </td>
                                    <td className="px-6 py-4 border-b border-gray-300">
                                        <button onClick={() => handleDeleteItem(item.type, item.value)} className="px-2 py-1 text-white bg-red-500 rounded">삭제</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        pageNumbers={pageNumbers}
                    />
                </div>
                <div className="p-4 bg-white rounded-md shadow">
                    <h2 className="mb-4 text-xl font-bold">언어 쌍 항목</h2>
                    <button onClick={() => handleOpenModal('addLanguagePair')} className="px-4 py-2 mb-4 text-white bg-blue-500 rounded-md">언어 쌍 추가</button>
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="p-4">
                                    <div className="flex items-center">
                                        <input id="checkbox-all-search-lang" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label htmlFor="checkbox-all-search-lang" className="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th scope="col" className="px-6 py-3">유형</th>
                                <th scope="col" className="px-6 py-3">소스 언어</th>
                                <th scope="col" className="px-6 py-3">타겟 언어</th>
                                <th scope="col" className="px-6 py-3">화폐 단위</th>
                                <th scope="col" className="px-6 py-3">유닛</th>
                                <th scope="col" className="px-6 py-3">단가</th>
                                <th className="px-6 py-3">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentLanguageItems.map((item, index) => (
                                <tr key={`${index}`} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td className="w-4 p-4">
                                        <div className="flex items-center">
                                            <input id={`checkbox-table-search-lang-${index}`} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label htmlFor={`checkbox-table-search-lang-${index}`} className="sr-only">checkbox</label>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 border-b border-gray-300">{item.value.projectType}</td>
                                    <td className="px-6 py-4 border-b border-gray-300">{item.value.SLang}</td>
                                    <td className="px-6 py-4 border-b border-gray-300">{item.value.TLang}</td>
                                    <td className="px-6 py-4 border-b border-gray-300">{item.value.currency}</td>
                                    <td className="px-6 py-4 border-b border-gray-300">{item.value.unit}</td>
                                    <td className="px-6 py-4 border-b border-gray-300">{item.value.rate}</td>
                                    <td className="px-6 py-4 border-b border-gray-300">
                                        <button onClick={() => handleDeleteItem(item.type, item.value)} className="px-2 py-1 text-white bg-red-500 rounded">삭제</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentLanguagePage}
                        setCurrentPage={setCurrentLanguagePage}
                        pageNumbers={languagePageNumbers}
                    />
                </div>
            </div>
            {isModalOpen && (
                <LargeModal
                    id="modal"
                    title={modalType === 'addDropdown' ? '드롭다운 항목 추가' : '언어 쌍 추가'}
                    body={
                        modalType === 'addDropdown' ? (
                            <div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">드롭다운 항목</label>
                                    <select
                                        name="newType"
                                        value={newType}
                                        onChange={(e) => setNewType(e.target.value)}
                                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        <option value="projectType">프로젝트 유형</option>
                                        <option value="language">언어</option>
                                        <option value="rate">단가</option>
                                        <option value="clientType">클라이언트 유형</option>
                                        <option value="workerType">작업자 유형</option>
                                        <option value="taskType">작업 유형</option>
                                        <option value="domain">도메인</option>
                                        <option value="taskStatus">작업 상태</option>
                                        <option value="currency">화폐</option>
                                        <option value="unit">단위</option>
                                        <option value="paymentStatus">결제 상태</option>
                                        <option value="workerStatus">작업 상태</option>
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">값</label>
                                    <input
                                        type="text"
                                        placeholder="값을 입력하세요"
                                        name="newValue"
                                        value={newValue}
                                        onChange={(e) => setNewValue(e.target.value)}
                                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">프로젝트 유형</label>
                                    <CustomSelect
                                        name="projectType"
                                        value={{ label: formData.projectType, value: formData.projectType }}
                                        onChange={(option) => handleChange({ target: { name: 'projectType', value: option.value } })}
                                        options={dropdownData.find(item => item.type === 'projectType')?.values.map((option) => ({ label: option, value: option }))}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">소스 언어</label>
                                    <select
                                        name="SLang"
                                        value={formData.SLang}
                                        onChange={handleChange}
                                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        <option value="">Select Source Language</option>
                                        {dropdownData.find(item => item.type === 'language')?.values.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">타겟 언어</label>
                                    <select
                                        name="TLang"
                                        value={formData.TLang}
                                        onChange={handleChange}
                                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        <option value="">Select Target Language</option>
                                        {dropdownData.find(item => item.type === 'language')?.values.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">화폐</label>
                                    <select
                                        name="currency"
                                        value={formData.currency}
                                        onChange={handleChange}
                                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        <option value="">Select Currency</option>
                                        {dropdownData.find(item => item.type === 'currency')?.values.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">유닛</label>
                                    <select
                                        name="unit"
                                        value={formData.unit}
                                        onChange={handleChange}
                                        className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    >
                                        <option value="">Select Unit</option>
                                        {dropdownData.find(item => item.type === 'unit')?.values.map((option) => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium text-gray-700">단가</label>
                                    <input
                                        type="text"
                                        placeholder="단가를 입력하세요"
                                        name="rateValue"
                                        value={formData.rateValue}
                                        onChange={handleChange}
                                        className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                        )
                    }
                    onSave={handleSaveModal}
                    onClose={handleCloseModal}
                />
            )}
        </div>
    );
}

export default ProjectSettings;