import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';
import AppRoutes from './Routes';
import Footer from './area/Footer';
import CommentModal from './boards/CommentModal';
import Navbar from './components/bars/Navbar';
import { CommentModalProvider, useCommentModal } from './contexts/CommentModalContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { SidebarProvider } from './contexts/SidebarContext';
import { AuthProvider } from './session/AuthContext';

function App() {
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();

  return (
    <AuthProvider>
      <NotificationProvider>
        <SidebarProvider>
          <CommentModalProvider>
            <Navbar />
            <div className="flex min-h-screen">
              <div className="flex flex-col flex-grow">
                <main className="flex-grow">
                  <AppRoutes />
                </main>
                <footer>
                  <Footer />
                </footer>
              </div>
            </div>
            <CommentModalWrapper userName={userName} /> 
          </CommentModalProvider>
        </SidebarProvider>
      </NotificationProvider>
    </AuthProvider>
  );
}

const CommentModalWrapper = ({ userName }) => {
  const { isCommentModalOpen, projectId, projectName, closeCommentModal, comments, handleAddComment, handleInput } = useCommentModal();

  // projectId와 projectName이 있을 때만 모달 렌더링
  if (!projectId || !projectName) {
    return null;
  }

  return (
    <CommentModal
      isOpen={isCommentModalOpen}
      onClose={closeCommentModal}
      projectId={projectId}
      projectName={projectName}
      comments={comments}
      onAddComment={handleAddComment}
      onInput={handleInput}
      userName={userName}
    />
  );
};

export default App;