import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation, useQueryClient } from 'react-query';
import ReactStars from 'react-rating-stars-component';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination'; // Pagination 컴포넌트 임포트
import QARatingModal from '../components/commons/QARatingModal';
import RatingModal from '../components/commons/RatingModal';
import { formatNumber } from '../components/commons/Utils';

const WorkerDetailsTable = ({ currentPOs, selectedPOs, handleSelectPO, handleSelectAll, formatDateCustom, handleOpenModal }) => {
  const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
  const [isQARatingModalOpen, setIsQARatingModalOpen] = useState(false);
  const [selectedPO, setSelectedPO] = useState(null);
  const [filter, setFilter] = useState('');
  const [filterField, setFilterField] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태 추가
  const itemsPerPage = 10; // 페이지당 항목 수
  const queryClient = useQueryClient();

  const handleRatingClick = (po) => {
    setSelectedPO(po);
    setIsRatingModalOpen(true);
  };

  const handleQARatingClick = (po) => {
    setSelectedPO(po);
    setIsQARatingModalOpen(true);
  };

  const ratePoMutation = useMutation(
    (poData) => axios.put('/po_rating', poData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workers');
        setIsRatingModalOpen(false);
      },
    }
  );

  const rateQAPoMutation = useMutation(
    (poData) => axios.put('/po_qarating', poData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('workers');
        setIsQARatingModalOpen(false);
      },
    }
  );

  const handleRatingSave = (ratings) => {
    const poData = {
      _id: selectedPO._id,
      ...ratings
    };
    ratePoMutation.mutate(poData);
  };

  const handleQARatingSave = (ratings) => {
    const poData = {
      _id: selectedPO._id,
      ...ratings
    };
    rateQAPoMutation.mutate(poData);
  };

  const handleResetFilters = () => {
    setFilter('');
    setFilterField('');
    setFilterDate(null);
  };

  // 페이지네이션을 위한 현재 페이지의 POs 계산
  const indexOfLastPO = currentPage * itemsPerPage;
  const indexOfFirstPO = indexOfLastPO - itemsPerPage;
  const currentPOsPage = currentPOs.slice(indexOfFirstPO, indexOfLastPO);

  return (
    <>
      <div>
        <h2 className="mt-4 mb-8 text-xl font-semibold">
          <span className="pb-1 border-b-2 border-blue-500">작업이력</span>
        </h2>
      </div>
      <div className="flex-auto overflow-y-auto shadow-md max-h-auto">
        <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-md">
          <div className="flex flex-wrap items-center justify-between py-3 space-y-2 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
            <div className="relative">
              <input
                type="text"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Search for POs"
              />
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-5 text-gray-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
            <div className="flex space-x-2">
              <select
                value={filterField}
                onChange={(e) => setFilterField(e.target.value)}
                className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Select Date Field</option>
                <option value="work_started_at">작업 시작일</option>
                <option value="work_ended_at">작업 납품일</option>
                <option value="payment_requested_at">결제 요청일</option>
                <option value="payment_received_at">결제 완료일</option>
              </select>
              <DatePicker
                selected={filterDate}
                onChange={(date) => setFilterDate(date)}
                placeholderText="Select Date"
                className="block p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                onClick={handleResetFilters}
                className="px-2 py-1 text-sm font-medium text-white bg-gray-500 rounded-lg hover:bg-gray-600"
              >
                Reset
              </button>
            </div>
          </div>
          <table className="w-full mt-2 text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-4 py-3">
                  <input
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="checkbox"
                    checked={selectedPOs.length === currentPOs.length}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="px-4 py-3 border-gray-300">PO 번호</th>
                <th className="px-4 py-3 border-gray-300" style={{ minWidth: '120px' }}>프로젝트</th>
                <th className="px-4 py-3 border-gray-300">분량</th>
                <th className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">단가</th>
                <th className="px-6 py-3 border-gray-300">비용</th>
                <th className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">시작일</th>
                <th className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300" style={{ minWidth: '100px' }}>납품일</th>
                <th className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">결제 요청일</th>
                <th className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">결제 완료일</th>
                <th className="px-4 py-3 border-gray-300">작업평가</th>
                <th className="px-4 py-3 border-gray-300">QA 작업평가</th> 
              </tr>
            </thead>
            <tbody>
              {currentPOsPage.length > 0 ? (
                currentPOsPage.map((po, index) => (
                  <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-4 py-4">
                      <input
                        type="checkbox"
                        checked={selectedPOs.includes(po._id)}
                        onChange={() => handleSelectPO(po._id)}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                    </td>
                    <td className="px-4 py-3 border-gray-300">{po.po_number}</td>
                    <td className="px-4 py-3 border-gray-300" style={{ minWidth: '120px' }}>{po.project_name}</td>
                    <td className="px-4 py-3 border-gray-300">{po.volume}</td>
                    <td className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">{formatNumber(po.rate, po.currency)}</td>
                    <td className="px-6 py-3 min-w-[4em] whitespace-nowrap border-gray-300 text-red-500">{formatNumber(po.total, po.currency)}</td>
                    <td className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">{formatDateCustom(po.work_started_at)}</td>
                    <td className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300" style={{ minWidth: '100px' }}>{formatDateCustom(po.work_ended_at)}</td>
                    <td className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">{formatDateCustom(po.payment_requested_at)}</td>
                    <td className="px-4 py-3 min-w-[4em] whitespace-nowrap border-gray-300">
                      {po.payment_received_at ? formatDateCustom(po.payment_received_at) : 'TBD'}
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center">
                        <ReactStars
                          count={5}
                          value={
                            po.ratings ? (
                              (po.ratings.communication + po.ratings.expertise + po.ratings.consistency + po.ratings.professionalism + po.ratings.compliance + po.ratings.flow) / 6 / 20
                            ) : 0 // 100점 만점을 5점 만점으로 환산
                          }
                          edit={false}
                          size={12} // 별표 크기 조정
                          isHalf={true}
                          activeColor="#ffd700"
                        />
                        <button
                          onClick={() => handleRatingClick(po)}
                          className={`ml-2 text-yellow-500 hover:text-yellow-700 ${po.ratings ? 'text-gray-500 cursor-not-allowed disabled:opacity-50' : ''}`}
                          disabled={!!po.ratings}
                        >
                          ⭐ Rate
                        </button>
                      </div>
                      <div className="w-48 overflow-hidden whitespace-nowrap">
                        <div className="inline-block animate-marquee">
                          {po.ratings && po.ratings.comment ? po.ratings.comment : 'No comment'}
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex items-center">
                        <ReactStars
                          count={5}
                          value={
                            po.qa_ratings ? (
                              (po.qa_ratings.communication + po.qa_ratings.expertise + po.qa_ratings.consistency + po.qa_ratings.professionalism + po.qa_ratings.compliance + po.qa_ratings.flow) / 6 / 20
                            ) : 0 // 100점 만점을 5점 만점으로 환산
                          }
                          edit={false}
                          size={12} // 별표 크기 조정
                          isHalf={true}
                          activeColor="#800080" // 보라색
                        />
                        <button
                          onClick={() => handleQARatingClick(po)}
                          className={`ml-2 text-purple-500 hover:text-purple-700 ${po.qa_ratings ? 'text-gray-500 cursor-not-allowed disabled:opacity-50' : ''}`}
                          disabled={!!po.qa_ratings}
                        >
                          ⭐ Rate
                        </button>
                      </div>
                      <div className="w-48 overflow-hidden whitespace-nowrap">
                        <div className="inline-block animate-marquee">
                          {po.qa_ratings && po.qa_ratings.comment ? po.qa_ratings.comment : 'No comment'}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="15" className="px-4 py-4 text-center">No PO records found</td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageNumbers={Array.from({ length: Math.ceil(currentPOs.length / itemsPerPage) }, (_, i) => i + 1)}
          />
          {isRatingModalOpen && (
            <RatingModal
              isOpen={isRatingModalOpen}
              onRequestClose={() => setIsRatingModalOpen(false)}
              po={selectedPO}
              onSave={handleRatingSave}
            />
          )}
          {isQARatingModalOpen && (
            <QARatingModal
              isOpen={isQARatingModalOpen}
              onRequestClose={() => setIsQARatingModalOpen(false)}
              po={selectedPO}
              onSave={handleQARatingSave}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WorkerDetailsTable;