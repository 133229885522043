import React, { useState } from 'react';
import { WriteButton } from './Buttons';

export default function ReplyWindow() {
  const [isWriting, setIsWriting] = useState(false);

  return (
    <div className="p-4 mt-4 rounded" style={{ maxWidth: '1440px', margin: '0 auto' }}>
      <div className="relative flex flex-col sm:flex-row">
        <div className="relative flex-1">
          <textarea
            className="w-full p-2 border border-gray-600 rounded-l resize-none"
            placeholder="댓글은 로그인이 필요한 서비스 입니다."
            rows="4"
            style={{ height: isWriting ? '100px' : '60px' }}
            onClick={() => setIsWriting(true)}
          />
          {isWriting && (
            <div className="absolute bottom-2 right-2 sm:hidden">
              <WriteButton />
            </div>
          )}
        </div>
        <button
          className="hidden px-4 py-2 text-xs text-white bg-gray-800 rounded-r sm:block hover:bg-gray-700"
          style={{ minWidth: '100px', fontSize: '12px', height: isWriting ? '100px' : '60px' }}
        >
          댓글쓰기
        </button>
      </div>
    </div>
  );
}

