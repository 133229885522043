import gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import React, { useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import BoardTitle from '../area/BoardTitle';
import axios from '../axiosConfig';
import { OneButtonModal } from '../components/commons/Modal';
import { formatDate } from '../components/commons/Utils';

const DivisionSchedule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [projects, setProjects] = useState([]);
  const [user, setUser] = useState(location.state?.user || null);
  const [filter, setFilter] = useState('');
  const [filterField, setFilterField] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [expandedWorkerIds, setExpandedWorkerIds] = useState([]); // 추가된 상태
  const itemsPerPage = 10;
  const ganttContainer = useRef(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('/projects');
        if (response.status === 200) {
          setProjects(response.data.projects);
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    const fetchUser = async () => {
      try {
        const response = await axios.get('/session-user');
        if (response.status === 200) {
          setUser(response.data.user);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchProjects();
    if (!user) {
      fetchUser();
    }
  }, [user]);

  useEffect(() => {
    if (projects.length > 0) {
      gantt.config.date_format = "%Y-%m-%d %H:%i";
      gantt.config.scale_unit = "day";
      gantt.config.date_scale = "%d %M";
      gantt.config.scale_height = 50;
      gantt.config.subscales = [
        { unit: "week", step: 1, date: "Week #%W" }
      ];
      gantt.config.work_time = true;
      gantt.config.correct_work_time = true;
      gantt.config.show_tasks_outside_timescale = true;
      gantt.config.min_column_width = 30; // 날짜 가로 크기 줄이기

      gantt.config.columns = [
        { name: "text", label: "Task name", width: "*", tree: true, resize: true, add: true },
        { name: "owner", label: "Owner", align: "center", resize: true },
        { name: "duration", label: "Duration", align: "center", template: (obj) => Math.round(obj.duration), resize: true }
      ];

      gantt.templates.task_class = function (start, end, task) {
        if (task.type === gantt.config.types.project) {
          return "gantt_project";
        } else if (task.type === gantt.config.types.task) {
          return "gantt_task";
        } else {
          return "gantt_po";
        }
      };

      gantt.init(ganttContainer.current);

      const data = projects.flatMap(project => [
        {
          id: `project_${project._id}`,
          text: project.ProjectName,
          start_date: new Date(project.SDate),
          duration: Math.round((new Date(project.DDate) - new Date(project.SDate)) / (1000 * 60 * 60 * 24)), // 소수점 제거
          progress: 0,
          open: true,
          owner: project.MainPM, // owner 항목 추가
          type: gantt.config.types.project // 프로젝트 타입 지정
        },
        ...(project.tasks ? project.tasks.flatMap(task => [
          {
            id: `task_${task.id}`,
            text: `${task.SLang} > ${task.TLang}`,
            start_date: new Date(project.SDate),
            duration: Math.round((new Date(project.DDate) - new Date(project.SDate)) / (1000 * 60 * 60 * 24)), // 소수점 제거
            parent: `project_${project._id}`,
            progress: 0,
            open: true,
            owner: task.PMName, // owner 항목 추가
            type: gantt.config.types.task // 작업 타입 지정
          },
          ...(task.po ? task.po.map((po, idx) => ({
            id: `po_${task.id}_${idx}`,
            text: `${po.worker_name} (${formatDate(po.work_started_at || project.SDate)} - ${formatDate(po.work_ended_at || project.DDate)})`,
            start_date: new Date(po.work_started_at || project.SDate),
            duration: Math.round((new Date(po.work_ended_at || project.DDate) - new Date(po.work_started_at || project.SDate)) / (1000 * 60 * 60 * 24)), // 소수점 제거
            parent: `task_${task.id}`,
            progress: 0,
            owner: po.worker_name, // owner 항목 추가
            type: "po" // PO 타입 지정
          })) : [])
        ]) : [])
      ]);

      gantt.parse({ data, links: [] });

      // Zoom 기능 추가
      const zoomConfig = {
        levels: [
          {
            name: "day",
            scale_height: 27,
            min_column_width: 80,
            scales: [
              { unit: "day", step: 1, format: "%d %M" },
              { unit: "hour", step: 1, format: "%H" }
            ]
          },
          {
            name: "week",
            scale_height: 50,
            min_column_width: 50,
            scales: [
              { unit: "week", step: 1, format: "Week #%W" },
              { unit: "day", step: 1, format: "%d %M" }
            ]
          },
          {
            name: "month",
            scale_height: 50,
            min_column_width: 120,
            scales: [
              { unit: "month", step: 1, format: "%F, %Y" },
              { unit: "week", step: 1, format: "Week #%W" }
            ]
          },
          {
            name: "year",
            scale_height: 50,
            min_column_width: 30,
            scales: [
              { unit: "year", step: 1, format: "%Y" },
              { unit: "month", step: 1, format: "%M" }
            ]
          }
        ],
        useKey: "ctrlKey",
        trigger: "wheel",
        element: ganttContainer.current
      };

      gantt.ext.zoom.init(zoomConfig);

      // 마우스 스크롤 기능 추가
      gantt.config.scroll_on_click = true;
      gantt.config.autoscroll = true;

      // 이벤트 리스너 추가
      gantt.attachEvent("onAfterTaskAdd", function(id, item) {
        axios.post('/api/tasks', item)
          .then(response => {
            console.log("Task added:", response.data);
          })
          .catch(error => {
            console.error("Error adding task:", error);
          });
      });

      gantt.attachEvent("onAfterTaskUpdate", function(id, item) {
        axios.put(`/api/tasks/${id}`, item)
          .then(response => {
            console.log("Task updated:", response.data);
          })
          .catch(error => {
            console.error("Error updating task:", error);
          });
      });

      gantt.attachEvent("onAfterTaskDelete", function(id) {
        axios.delete(`/api/tasks/${id}`)
          .then(response => {
            console.log("Task deleted:", response.data);
          })
          .catch(error => {
            console.error("Error deleting task:", error);
          });
      });

      gantt.attachEvent("onAfterLinkAdd", function(id, link) {
        axios.post('/api/links', link)
          .then(response => {
            console.log("Link added:", response.data);
          })
          .catch(error => {
            console.error("Error adding link:", error);
          });
      });

      gantt.attachEvent("onAfterLinkUpdate", function(id, link) {
        axios.put(`/api/links/${id}`, link)
          .then(response => {
            console.log("Link updated:", response.data);
          })
          .catch(error => {
            console.error("Error updating link:", error);
          });
      });

      gantt.attachEvent("onAfterLinkDelete", function(id) {
        axios.delete(`/api/links/${id}`)
          .then(response => {
            console.log("Link deleted:", response.data);
          })
          .catch(error => {
            console.error("Error deleting link:", error);
          });
      });
    }
  }, [projects]);

  if (!projects.length || !user) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-row p-1 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main className="flex-grow p-4 mt-0 mb-0 ml-0 mr-4 bg-white custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title={`프로젝트 스케줄`} />

        {isExportModalOpen && (
          <OneButtonModal
            id="export-modal"
            title="Export Projects"
            body={<p>Are you sure you want to export the selected projects?</p>}
            onSave={handleExport}
            onClose={() => setIsExportModalOpen(false)}
          />
        )}
        {/* DHTMLX Gantt component below the table */}
        <div className="mt-6" ref={ganttContainer} style={{ width: '100%', height: 'calc(100vh - 200px)' }}></div> {/* 페이지 가득차게 수정 */}
      </main>
    </div>
  );
};

export default DivisionSchedule;