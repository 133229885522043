import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import Pagination from '../components/bars/Pagination';
import FileUploadModal from '../components/commons/FileUploadModal';
import { ErrorModal, OneButtonModal } from '../components/commons/Modal';
import { formatNumber as formatNumberWithCurrency } from '../components/commons/Utils';
import { useNotifications } from '../contexts/NotificationContext';
import ActivityLog from './ActivityLog';

const POApprovalBoard = ({ poNumber, workerId, userName }) => {
  const { socket } = useNotifications();
  const [poDetails, setPoDetails] = useState(null);
  const [workerPOs, setWorkerPOs] = useState([]);
  const [selectedPOs, setSelectedPOs] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDate, setModalDate] = useState('');
  const [saving, setSaving] = useState(false);
  const [activityLogs, setActivityLogs] = useState([]);
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [uploadingPoId, setUploadingPoId] = useState(null);
  const [uploadingTaskId, setUploadingTaskId] = useState(null);
  const [uploadingProjectId, setUploadingProjectId] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadingPoNumber, setUploadingPoNumber] = useState(null);
  const [pmName, setPmName] = useState('');
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [errorModal, setErrorModal] = useState({ show: false, message: '' });
  const [confirmModalInfo, setConfirmModalInfo] = useState({ show: false, poId: null, date: null });
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchPoDetails = async () => {
      try {
        const response = await axios.get(`/getPOWorkDetails?po_number=${poNumber}`);
        if (response.data && response.data.tasks && response.data.tasks.length > 0) {
          const task = response.data.tasks.find(task => task.po.some(po => po.po_number === poNumber));
          if (task) {
            const po = task.po.find(po => po.po_number === poNumber);
            setPoDetails({
              ...po,
              po_id: po._id 
            });
            setUploadingTaskId(task.id);
            setUploadingProjectId(po.project_id);
            setPmName(task.PMName);
          }
        }
      } catch (error) {
        console.error('Error fetching PO details:', error);
      }
    };

    const fetchWorkerPOs = async () => {
      try {
        const response = await axios.get(`/getWorkerPOs?worker_id=${workerId}`);
        setWorkerPOs(response.data.po_list);
      } catch (error) {
        console.error('Error fetching worker POs:', error);
      }
    };

    const fetchActivityLogs = async () => {
      try {
        const response = await axios.get(`/getActivityLogs?worker_id=${workerId}`);
        setActivityLogs(response.data.logs);
      } catch (error) {
        console.error('Error fetching activity logs:', error);
      }
    };

    if (workerId) {
      fetchPoDetails();
      fetchWorkerPOs();
      fetchActivityLogs();
    }
  }, [poNumber, workerId]);

  useEffect(() => {
    if (socket) {
      socket.emit('join', { username: userName });
    }
  }, [socket, userName]);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const handleOpenModal = (field, initialDate, poId) => {
    setSelectedField(field);
    setModalDate(initialDate ? new Date(initialDate).toISOString().substr(0, 10) : '');
    setSelectedPOs([poId]);
    setIsModalOpen(true);
  };

  const handleSaveDate = async () => {
    if (selectedField === 'work_ended_at') {
      const selectedPO = workerPOs.find(po => po._id === selectedPOs[0]);
      if (selectedPO && new Date(modalDate) < new Date(selectedPO.work_started_at)) {
        setErrorModal({
          show: true,
          message: 'End date는 Start date보다 빠를 수 없습니다.'
        });
        return;
      }
    }
    
    if (selectedField === 'payment_requested_at') {
      setConfirmModalInfo({
        show: true,
        poId: selectedPOs[0],
        date: modalDate
      });
      setIsModalOpen(false);
    } else {
      setSaving(true);
      try {
        for (let poId of selectedPOs) {
          const response = await axios.put('/updatePOEndDate', {
            po_id: poId,
            field: selectedField,
            date: modalDate
          });

          if (response.status !== 200) {
            throw new Error(`Error updating PO date: ${response.data.error}`);
          }
        }

        const updatedPOs = workerPOs.map(po =>
          selectedPOs.includes(po._id) 
            ? { ...po, [selectedField]: modalDate }
            : po
        );
        setWorkerPOs(updatedPOs);
        setIsModalOpen(false);
      } catch (error) {
        console.error('Error saving date:', error);
        setErrorModal({
          show: true,
          message: '날짜 저장 중 오류가 발생했습니다.'
        });
      } finally {
        setSaving(false);
      }
    }
  };

  const handleConfirmPaymentRequest = async () => {
    try {
      await axios.put('/updatePOPaymentRequestedDate', {
        po_id: confirmModalInfo.poId,
        date: confirmModalInfo.date,
        pm_name: pmName
      });

      const updatedPOs = workerPOs.map(po =>
        po._id === confirmModalInfo.poId
          ? { ...po, payment_requested_at: confirmModalInfo.date, payment_status: '승인 대기 중' }
          : po
      );
      setWorkerPOs(updatedPOs);

      setConfirmModalInfo({ show: false, poId: null, date: null });
    } catch (error) {
      console.error('Error updating payment requested date:', error);
      setErrorModal({ show: true, message: '날짜 업데이트 중 오류가 발생했습니다.' });
    }
  };

  const handleSelectPO = (poId) => {
    setSelectedPOs(prevSelected =>
      prevSelected.includes(poId) ? prevSelected.filter(id => id !== poId) : [...prevSelected, poId]
    );
  };

  const handleSelectAll = () => {
    if (selectedPOs.length === workerPOs.length) {
      setSelectedPOs([]);
    } else {
      setSelectedPOs(workerPOs.map(po => po._id));
    }
  };

  const handleFileUploadModalOpen = (poId, poNumber) => {
    setUploadingPoId(poId);
    setUploadingPoNumber(poNumber);
    setIsFileUploadModalOpen(true);
  };

  const handleFileUploadModalClose = () => {
    setIsFileUploadModalOpen(false);
    setUploadingPoId(null);
    setUploadingPoNumber(null);
  };

  const handleFileUploadSave = async (filePaths, poNumber) => {
    setUploadedFiles(prevFiles => ({
      ...prevFiles,
      [uploadingPoId]: filePaths
    }));

    const response = await axios.get(`/getActivityLogs?worker_id=${workerId}`);
    setActivityLogs(response.data.logs);
    console.log("Updated activity logs: ", response.data.logs);  
  };

  const filteredPOs = workerPOs.filter(po =>
    (po.worker_name && po.worker_name.toLowerCase().includes(filter.toLowerCase())) ||
    (po.po_number && po.po_number.toLowerCase().includes(filter.toLowerCase())) ||
    (po.worker_email && po.worker_email.toLowerCase().includes(filter.toLowerCase()))
  );

  const currentPOs = filteredPOs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  const totalPages = Math.ceil(filteredPOs.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  if (!poDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="w-full mt-2 text-sm text-left text-gray-500 dark:text-gray-400">
        <div className="p-4 bg-white border-b rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <strong>PO Number:</strong> {poDetails.po_number}
            </div>
            <div>
              <strong>Worker Name:</strong> {poDetails.worker_name}
            </div>
            <div>
              <strong>Worker Email:</strong> {poDetails.worker_email}
            </div>
            <div>
              <strong>Worker Mobile:</strong> {poDetails.worker_mobile}
            </div>
            <div>
              <strong>Volume:</strong> <span className="font-bold text-blue-600">{formatNumber(poDetails.volume)}</span>
            </div>
            <div>
              <strong>Rate:</strong> {formatNumber(poDetails.rate)}
            </div>
            <div>
              <strong>Currency:</strong> {poDetails.currency}
            </div>
            <div>
              <strong>Total:</strong> <span className="font-bold text-red-600">{formatNumberWithCurrency(poDetails.total, poDetails.currency)}</span>
            </div>
            <div>
              <strong>Work Started:</strong> {formatDate(poDetails.work_started_at)}
            </div>
            <div>
              <strong>Work Ended:</strong> {formatDate(poDetails.work_ended_at)}
            </div>
            <div>
              <strong>Payment Requested:</strong> {formatDate(poDetails.payment_requested_at)}
            </div>
            <div>
              <strong>Payment Received:</strong> {poDetails.payment_received_at ? formatDate(poDetails.payment_received_at) : 'N/A'}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">
            <span className="pb-1 border-b-2 border-blue-500">All POs for {poDetails.worker_name}</span>
          </h2>
          <div className="flex items-center space-x-2">
            <input
              type="text"
              placeholder="Search..."
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg"
            />
            {selectedPOs.length > 0 && (
              <div className="flex space-x-2">
                <button
                  className="px-2 py-1 text-xs text-white bg-blue-600 rounded"
                  onClick={() => handleOpenModal('work_started_at', '')}
                >
                  Bulk Work Started
                </button>
                <button
                  className="px-2 py-1 text-xs text-white bg-blue-600 rounded"
                  onClick={() => handleOpenModal('work_ended_at', '')}
                >
                  Bulk Work Ended
                </button>
                <button
                  className="px-2 py-1 text-xs text-white bg-blue-600 rounded"
                  onClick={() => handleOpenModal('payment_requested_at', '')}
                >
                  Bulk Payment Requested
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-md">
          <table className="w-full mt-2 text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-4 py-3">
                  <input
                    type="checkbox"
                    checked={selectedPOs.length === workerPOs.length}
                    onChange={handleSelectAll}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                </th>
                <th className="px-4 py-3 border-gray-300">PO Number</th>
                <th className="px-4 py-3 border-gray-300" style={{ minWidth: '120px' }}>Project Name</th>
                <th className="px-4 py-3 border-gray-300">Worker Email</th>
                <th className="px-4 py-3 border-gray-300">Worker Mobile</th>
                <th className="px-4 py-3 border-gray-300">Volume</th>
                <th className="px-4 py-3 border-gray-300">Rate</th>
                <th className="px-4 py-3 border-gray-300">Total</th>
                <th className="px-4 py-3 border-gray-300">WORK STARTED</th>
                <th className="px-4 py-3 border-gray-300" style={{ minWidth: '100px' }}>WORK ENDED</th>
                <th className="px-4 py-3 border-gray-300">PAYMENT REQUESTED</th>
                <th className="px-4 py-3 border-gray-300">PAYMENT RECEIVED</th>
                <th className="px-4 py-3 border-gray-300">File</th>
              </tr>
            </thead>
            <tbody>
              {currentPOs.map((po, index) => (
                <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-4 py-4">
                    <input
                      type="checkbox"
                      checked={selectedPOs.includes(po._id)}
                      onChange={() => handleSelectPO(po._id)}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                  </td>
                  <td className="px-4 py-4" style={{ minWidth: '120px' }}>{po.po_number}</td>
                  <td className="px-4 py-4">{po.project_name}</td>
                  <td className="px-4 py-4">{po.worker_email}</td>
                  <td className="px-4 py-4">{po.worker_mobile}</td>
                  <td className="px-4 py-4 font-bold text-blue-600">{formatNumber(po.volume)}</td>
                  <td className="px-4 py-4">{formatNumber(po.rate)}</td>
                  <td className="px-6 py-4 font-bold min-w-[4em] whitespace-nowrap text-blue-600">{formatNumberWithCurrency(po.total, po.currency)}</td>
                  <td className="px-4 py-4">
                    {po.work_started_at ? (
                      formatDate(po.work_started_at)
                    ) : (
                      <button
                        className="px-2 py-1 text-xs text-white bg-blue-500 rounded"
                        onClick={() => handleOpenModal('work_started_at', po.work_started_at, po._id)}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-4" style={{ minWidth: '100px' }}>
                    {po.work_ended_at ? (
                      formatDate(po.work_ended_at)
                    ) : (
                      <button
                        className={`px-2 py-1 text-xs text-white ${po.work_started_at ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'} rounded`}
                        onClick={() => po.work_started_at && handleOpenModal('work_ended_at', po.work_ended_at, po._id)}
                        disabled={!po.work_started_at}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-4">
                    {po.payment_requested_at ? (
                      po.payment_status === '승인 대기 중' ? (
                        <span className="text-yellow-500">Pending</span>
                      ) : (
                        formatDate(po.payment_requested_at)
                      )
                    ) : (
                      <button
                        className={`px-2 py-1 text-xs text-white ${po.work_ended_at ? 'bg-blue-500 hover:bg-blue-700' : 'bg-gray-500 cursor-not-allowed'} rounded`}
                        onClick={() => po.work_ended_at && handleOpenModal('payment_requested_at', po.payment_requested_at, po._id)}
                        disabled={!po.work_ended_at}
                      >
                        Edit
                      </button>
                    )}
                  </td>
                  <td className="px-4 py-4">
                    {po.payment_received_at ? (
                      formatDate(po.payment_received_at)
                    ) : (
                      'N/A'
                    )}
                  </td>
                  <td className="px-4 py-4">
                    <button
                      className="px-2 py-1 text-xs text-white bg-blue-500 rounded"
                      onClick={() => handleFileUploadModalOpen(po._id, po.po_number)}
                    >
                      Upload
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageNumbers={Array.from({ length: totalPages }, (_, i) => i + 1)}
          />
        </div>
      </div>

      {isModalOpen && (
        <OneButtonModal
          id="date-modal"
          title="날짜 입력"
          body={
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-400">Choose Date</label>
              <input
                type="date"
                value={modalDate}
                onChange={(e) => setModalDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          }
          onSave={handleSaveDate}
          onClose={() => setIsModalOpen(false)}
        />
      )}

      {errorModal.show && (
        <ErrorModal
          id="error-modal"
          title="Error"
          body={errorModal.message}
          onClose={() => setErrorModal({ show: false, message: '' })}
        />
      )}

      <FileUploadModal
        isOpen={isFileUploadModalOpen}
        onClose={handleFileUploadModalClose}
        onSave={handleFileUploadSave}
        poId={uploadingPoId}
        taskId={uploadingTaskId}
        projectId={uploadingProjectId}
        poNumber={uploadingPoNumber}
      />

      <ActivityLog logs={activityLogs.filter(log => log.worker_id === workerId)} poId={poDetails._id} />

      {confirmModalInfo.show && (
        <OneButtonModal
          id="confirm-payment-request-modal"
          title="Payment Request Confirmation"
          body={`Are you sure you want to set the Payment Request to ${formatDate(confirmModalInfo.date)}?`}
          onSave={handleConfirmPaymentRequest}
          onClose={() => setConfirmModalInfo({ show: false, poId: null, date: null })}
        />
      )}
    </div>
  );
};

export default POApprovalBoard;
