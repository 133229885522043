import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BoardTitle from '../../area/BoardTitle'; // 제목 컴포넌트
import axios from '../../axiosConfig';
import AsideContainer from '../../components/containers/AsideContainer'; // 사이드바 컴포넌트
import { SidebarContext } from '../../contexts/SidebarContext'; // 사이드바 상태

const EmployeeDetail = () => {
  const { employeeName } = useParams();  // URL에서 직원의 이름을 가져옵니다
  const [projects, setProjects] = useState([]);
  const { isCollapsed } = useContext(SidebarContext);  // 사이드바 상태 사용

  useEffect(() => {
    const fetchEmployeeProjects = async () => {
      try {
        // Fetch projects where the MainPM is this employee's name
        const projectResponse = await axios.get(`/projects/by-mainpm-name?main_pm_name=${employeeName}`);
        console.log("Fetched Projects:", projectResponse.data.projects);  // 디버깅용
        setProjects(projectResponse.data.projects);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    fetchEmployeeProjects();
  }, [employeeName]);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('ko-KR', options);
  };

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      {/* 메인 콘텐츠 영역 */}
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-gray-150 custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title={`${employeeName}의 프로젝트 관리`} /> {/* 제목 컴포넌트 */}
        
        {projects.length > 0 ? (
          <div className="relative m-4 overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th className="px-4 py-2 min-w-[4em] whitespace-nowrap">프로젝트명</th>
                  <th className="px-4 py-2">PM</th>
                  <th className="px-4 py-2 min-w-[4em] whitespace-nowrap">클라이언트</th>
                  <th className="px-4 py-2 min-w-[4em] whitespace-nowrap">시작일</th>
                  <th className="px-4 py-2 min-w-[4em] whitespace-nowrap">종료일</th>
                  <th className="px-4 py-2">상태</th>
                  <th className="px-4 py-2">액션</th>
                </tr>
              </thead>
              <tbody>
                {projects.map((project) => (
                  <tr key={project._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-4 py-2 min-w-[4em] whitespace-nowrap">
                      <Link to={`/projects/${project._id}`} className="hover:underline">
                        {project.ProjectName}
                      </Link>
                    </td>
                    <td className="px-4 py-2">{project.MainPM}</td>
                    <td className="px-4 py-2">{project.ClientName}</td>
                    <td className="px-4 py-2">{formatDate(project.SDate)}</td>
                    <td className="px-4 py-2">{formatDate(project.DDate)}</td>
                    <td className="px-4 py-2">{project.Status}</td>
                    <td className="px-4 py-2">
                      <Link to={`/projects/${project._id}/edit`} className="text-blue-600 hover:underline">수정</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No current projects found.</p>
        )}
      </main>

      {/* 사이드바 영역 */}
      <AsideContainer />
    </div>
  );
};

export default EmployeeDetail;
