import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BoardTitle from '../../area/BoardTitle';
import axios from '../../axiosConfig';
import { getWeeksInMonth } from '../../components/commons/DateUtils'; // 주간 필터링 유틸리티 함수
import { formatDate, formatNumber } from '../../components/commons/Utils';
import AsideContainer from '../../components/containers/AsideContainer';
import { SidebarContext } from '../../contexts/SidebarContext';

const DetailPage = () => {
  const { type, name } = useParams(); // URL에서 type(그룹 또는 디비전)과 name(이름)을 가져옵니다
  const { isCollapsed } = useContext(SidebarContext);
  const [details, setDetails] = useState(null);
  const [projects, setProjects] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(20);
  const [expandedProjectIds, setExpandedProjectIds] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear()); // 년 필터링 상태
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // 월 필터링 상태
  const [selectedWeek, setSelectedWeek] = useState(null); // 주간 필터링 상태
  const [loading, setLoading] = useState(true); // 로딩 상태
  const [error, setError] = useState(null); // 에러 상태
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async (retry = 5) => {
      try {
        setError(null); // 에러 초기화
        // URL에서 특수문자를 인코딩하여 API 호출
        const encodedName = encodeURIComponent(name);
        const response = type === 'group' 
          ? await axios.get(`/groups/${encodedName}`) 
          : await axios.get(`/divisions/${encodedName}`);
        setDetails(response.data);
      } catch (error) {
        console.error(`Error fetching ${type} details:`, error);
        if (retry > 0) {
          setTimeout(() => fetchDetails(retry - 1), 1000); // 1초 후 재시도
        }
      } finally {
        setLoading(false); // 로딩 종료
      }
    };

    const fetchProjects = async (retry = 5) => {
      try {
        setError(null); // 에러 초기화
        // URL에서 특수문자를 인코딩하여 API 호출
        const encodedName = encodeURIComponent(name);
        const projectResponse = type === 'group'
          ? await axios.get(`/projects/by-group-name?group_name=${encodedName}`)
          : await axios.get(`/projects/by-division-name?division_name=${encodedName}`);
        // created_at 기준으로 정렬
        const sortedProjects = projectResponse.data.projects.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setProjects(sortedProjects);
      } catch (error) {
        console.error(`Error fetching projects for ${type}:`, error);
        if (retry > 0) {
          setTimeout(() => fetchProjects(retry - 1), 1000); // 1초 후 재시도
        }
      } finally {
        setLoading(false); // 로딩 종료
      }
    };

    setLoading(true); // 로딩 시작
    fetchDetails();
    fetchProjects();
  }, [type, name]);

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const toggleExpand = (projectId) => {
    setExpandedProjectIds((prevExpandedProjectIds) =>
      prevExpandedProjectIds.includes(projectId)
        ? prevExpandedProjectIds.filter(id => id !== projectId)
        : [...prevExpandedProjectIds, projectId]
    );
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedProjects = React.useMemo(() => {
    let sortableProjects = [...projects];
    if (sortConfig.key !== null) {
      sortableProjects.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProjects;
  }, [projects, sortConfig]);

  const getStatusBadge = (status) => {
    switch (status) {
      case '협상':
        return <span className="bg-gray-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">협상</span>;
      case '진행':
        return <span className="bg-green-200 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">진행</span>;
      case '완료':
        return <span className="bg-purple-200 text-purple-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">완료</span>;
      case '실주':
        return <span className="bg-orange-200 text-orange-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">실주</span>;
      case '중단':
        return <span className="bg-red-200 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">중단</span>;
      default:
        return <span className="bg-blue-200 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded">{status}</span>;
    }
  };

  const displayName = details && details.name ? details.name : '';

  const filteredProjects = sortedProjects.filter(project =>
    project.ProjectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.ClientName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    project.MainPM.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const weekFilteredProjects = selectedWeek
    ? filteredProjects.filter(project => {
        return project.tasks.some(task => {
          const taskStartDate = task.SDate ? new Date(task.SDate) : null;
          const taskEndDate = task.DDate ? new Date(task.DDate) : null;
          return (
            (!taskStartDate && !taskEndDate) ||
            (taskStartDate <= selectedWeek.endDate && taskEndDate >= selectedWeek.startDate)
          );
        });
      })
    : filteredProjects;

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = weekFilteredProjects.slice(indexOfFirstProject, indexOfLastProject);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleYearMonthChange = (event) => {
    const [year, month] = event.target.value.split('-');
    setSelectedYear(parseInt(year));
    setSelectedMonth(parseInt(month));
    setSelectedWeek(null); // 년월이 변경되면 주간 선택 초기화
  };

  const handleWeekChange = (event) => {
    const selectedWeekIndex = event.target.value;
    const weeks = getWeeksInMonth(selectedYear, selectedMonth);
    setSelectedWeek(weeks[selectedWeekIndex]);
  };

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-gray-150 custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title={`${type === 'group' ? '그룹' : '본부'} ${displayName} 상세 정보`} />
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <div className="flex flex-wrap items-center justify-between py-4 space-y-4 bg-white flex-column md:flex-row md:space-y-0 dark:bg-gray-900">
            <div className="relative ml-4">
              <div className="absolute inset-y-0 flex items-center pointer-events-none rtl:inset-r-0 start-0 ps-3">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                </svg>
              </div>
              <input
                type="text"
                id="table-search-users"
                className="block pt-2 text-sm text-gray-900 border border-gray-300 rounded-lg ps-10 w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="프로젝트명, 클라이언트, 담당자 검색"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            {/* Year and week filters */}
            <div className="relative flex items-center ml-4">
              <select
                className="block pt-2 text-sm text-gray-900 border border-gray-300 rounded-lg ps-5 w-60 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={handleYearMonthChange}
                value={`${selectedYear}-${selectedMonth}`}
              >
                {Array.from({ length: 5 }, (_, i) => {
                  const year = new Date().getFullYear() - i;
                  return Array.from({ length: 12 }, (_, j) => (
                    <option key={`${year}-${j}`} value={`${year}-${j}`}>
                      {`${year}년 ${j + 1}월`}
                    </option>
                  ));
                })}
              </select>
              <select
                className="block pt-2 ml-4 text-sm text-gray-900 border border-gray-300 rounded-lg ps-5 w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={handleWeekChange}
                value={selectedWeek ? selectedWeek.index : ''}
              >
                <option value="">전체 항목</option>
                {getWeeksInMonth(selectedYear, selectedMonth).map((week, index) => (
                  <option key={index} value={index}>
                    {`${week.startDate.toLocaleDateString()} ~ ${week.endDate.toLocaleDateString()}`}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="relative">
            {loading && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                <div role="status">
                  <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <table className="w-full text-sm text-left text-gray-500 rtl:text-right dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                <th className="px-4 py-2 min-w-[4em] whitespace-nowrap cursor-pointer hover:bg-gray-200" onClick={() => requestSort('ProjectName')}>
                  프로젝트명
                    {sortConfig.key === 'ProjectName' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('ClientName')}>
                  클라이언트
                    {sortConfig.key === 'ClientName' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('EndClient')}>
                    최종 클라이언트
                    {sortConfig.key === 'EndClient' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('Domain')}>
                    분야
                    {sortConfig.key === 'Domain' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>

                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('MainPM')}>
                    담당자
                    {sortConfig.key === 'MainPM' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('SDate')}>
                    시작일
                    {sortConfig.key === 'SDate' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('DDate')}>
                    마감일
                    {sortConfig.key === 'DDate' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('Total')}>
                    총액
                    {sortConfig.key === 'Total' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2 cursor-pointer" onClick={() => requestSort('Status')}>
                    상태
                    {sortConfig.key === 'Status' && (
                      <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                <th className="px-4 py-2">액션</th>
                </tr>
              </thead>
              <tbody>
                {currentProjects.map((project) => (
                  <React.Fragment key={project._id}>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td className="px-4 py-2 min-w-[4em] whitespace-nowrap cursor-pointer hover:underline" onClick={() => handleProjectClick(project._id)}>{truncateText(project.ProjectName, 15)}</td>
                      <td className="px-4 py-2">{project.ClientName}</td>
                      <td className="px-4 py-2">{project.EndClient}</td>
                      <td className="px-4 py-2">{project.Domain}</td>
                      <td className="px-4 py-2">{project.MainPM}</td>
                      <td className="px-4 py-2">{formatDate(project.SDate)}</td>
                      <td className="px-4 py-2">{formatDate(project.DDate)}</td>
                      <td className="px-4 py-2 font-bold text-blue-600">{formatNumber(project.Total, project.Currency)}</td>
                      <td className="px-4 py-2">{getStatusBadge(project.Status)}</td>
                      <td className="px-4 py-2">
                        <button onClick={() => toggleExpand(project._id)} className={`font-medium hover:underline ${expandedProjectIds.includes(project._id) ? 'text-red-600' : 'text-blue-600'}`}>
                          {expandedProjectIds.includes(project._id) ? '접기' : '펼치기'}
                        </button>
                      </td>
                    </tr>
                    {expandedProjectIds.includes(project._id) && (
                      <tr className="bg-gray-50 dark:bg-gray-700">
                        <td colSpan="12" className="px-6 py-4">
                          <div className="p-4 bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <h4 className="mb-2 text-lg font-semibold">세부 작업</h4>
                            {project.tasks.length > 0 ? (
                              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                  <tr>
                                    <th scope="col" className="px-6 py-3 font-bold min-w-[4em] whitespace-nowrap">프로젝트 유형</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">PM</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">출발어</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">도착어</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">시작일</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">마감일</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">분량</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단위</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">단가</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">총액</th>
                                    <th scope="col" className="px-6 py-3 min-w-[4em] whitespace-nowrap">상태</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {project.tasks.map((task, index) => (
                                    <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                      <td className="px-6 py-4 font-bold min-w-[4em] whitespace-nowrap">{task.ProjectType}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.PMName}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.SLang}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.TLang}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.SDate}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.DDate}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{Number(task.ProjectVolume).toLocaleString()}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{task.Unit}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{formatNumber(task.Rate, project.Currency)}</td>
                                      <td className="px-6 py-4 font-bold text-blue-600 min-w-[4em] whitespace-nowrap">{formatNumber(task.Total, project.Currency)}</td>
                                      <td className="px-6 py-4 min-w-[4em] whitespace-nowrap">{getStatusBadge(task.Status)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            ) : (
                              <p>No tasks available.</p>
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            projectsPerPage={projectsPerPage}
            totalProjects={weekFilteredProjects.length}
            paginate={paginate}
            currentPage={currentPage}
          />
        </div>
      </main>

      {/* 사이드바 영역 */}
      <AsideContainer />
    </div>
  );
};

const Pagination = ({ projectsPerPage, totalProjects, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalProjects / projectsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav aria-label="Page navigation" className="flex justify-center py-2 bg-white">
      <ul className="flex items-center h-8 -space-x-px text-sm">
        <li>
          <a
            href="#"
            onClick={() => paginate(currentPage > 1 ? currentPage - 1 : 1)}
            className="flex items-center justify-center px-3 leading-tight text-gray-500 bg-white border border-gray-300 h-9 ms-0 border-e-0 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Previous</span>
            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
            </svg>
          </a>
        </li>
        {pageNumbers.map(number => (
          <li key={number} className={`px-3 py-2 leading-tight ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-white text-blue-500'} border border-gray-300 hover:bg-gray-100 hover:text-blue-700`}>
            <a onClick={() => paginate(number)} href="#" className="page-link">
              {number}
            </a>
          </li>
        ))}
        <li>
          <a
            href="#"
            onClick={() => paginate(currentPage < pageNumbers.length ? currentPage + 1 : pageNumbers.length)}
            className="flex items-center justify-center px-3 leading-tight text-gray-500 bg-white border border-gray-300 h-9 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          >
            <span className="sr-only">Next</span>
            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
            </svg>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default DetailPage;