import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import axios from '../../axiosConfig';
import Pagination from '../bars/Pagination';
import { formatNumber } from '../commons/Utils';
import { LargeModal } from './Modal';
import CustomSelect from './modal/CustomSelect';
import RecentTasksModal from './RecentTasksModal';

const WorkerAssignModal = ({ isOpen, onClose, workers, onAssign, dropdownmenus = { language: [], domain: [], currency: [] } }) => {
  const [favorites, setFavorites] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredWorkers, setFilteredWorkers] = useState(workers);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [filter, setFilter] = useState({ sLang: '', tLang: '', domain: '', taskType: [], workerType: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [recentTasksModalOpen, setRecentTasksModalOpen] = useState(false);
  const [currentWorker, setCurrentWorker] = useState(null);
  const [step, setStep] = useState(1);
  const [selectedWorkerType, setSelectedWorkerType] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [selectedDomains, setSelectedDomains] = useState(null);
  const [expandedWorkerTypes, setExpandedWorkerTypes] = useState({});
  const [expandedTaskTypes, setExpandedTaskTypes] = useState({});
  const [expandedDomains, setExpandedDomains] = useState({});
  const [presets, setPresets] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const workersPerPage = 10;

  useEffect(() => {
    setFilteredWorkers(workers);
  }, [workers]);

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const response = await axios.get('/favorites');
        setFavorites(response.data.favorites || []);
      } catch (error) {
        console.error("Error fetching favorites:", error);
      }
    };

    fetchFavorites();
  }, []);

  const toggleFavorite = async (workerId) => {
    try {
      const response = await axios.post('/favorites/toggle', { workerId });
      setFavorites(response.data.favorites || []);
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterWorkers(term, filter);
  };

  const handleFilterChange = (selectedOption, { name }) => {
    const value = selectedOption ? selectedOption.value : '';
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }));
    filterWorkers(searchTerm, { ...filter, [name]: value });
  };

  const handleResetFilters = () => {
    setSearchTerm('');
    setFilter({ sLang: '', tLang: '', domain: '', taskType: [], workerType: [] });
    setFilteredWorkers(workers);
  };

  const filterWorkers = (term, filter) => {
    setFilteredWorkers(
      workers.filter((worker) =>
        (worker.name?.toLowerCase().includes(term.toLowerCase()) ||
          worker.sLang?.toLowerCase().includes(term.toLowerCase()) ||
          worker.tLang?.toLowerCase().includes(term.toLowerCase()) ||
          worker.email?.toLowerCase().includes(term.toLowerCase()) ||
          worker.mobile?.toLowerCase().includes(term.toLowerCase()) ||
          (worker.overall_rating && Object.values(worker.overall_rating).some(rating => rating.toString().includes(term)))) &&
        (filter.sLang ? worker.sLang === filter.sLang : true) &&
        (filter.tLang ? worker.tLang === filter.tLang : true) &&
        (filter.domain ? worker.domains.includes(filter.domain) : true) &&
        (filter.taskType.length ? filter.taskType.includes(worker.taskType) : true) &&
        (filter.workerType.length ? filter.workerType.includes(worker.workerType) : true)
      )
    );
  };

  const handleWorkerSelect = (worker) => {
    setSelectedWorker(worker);
  };

  const handleNext = () => {
    if (step === 1) {
      setStep(2);
    } else if (step === 2) {
      handleAssign();
    }
  };

  const handleAssign = async () => {
    if (selectedWorker && selectedWorkerType && selectedTaskType && selectedDomains) {
      setIsAssigning(true); // Disable the save button during assignment
      try {
        await onAssign({
          ...selectedWorker,
          workerType: selectedWorkerType,
          taskType: selectedTaskType,
          domains: selectedDomains,
          sLang: filter.sLang,
          tLang: filter.tLang,
        }); // Assign the worker with the selected options
        setIsAssigning(false); // Enable the save button again
        onClose(); // Close the modal after assignment
      } catch (error) {
        setIsAssigning(false); // Enable the save button if there's an error
        console.error("Error assigning worker:", error);
      }
    }
  };

  const handleSavePreset = () => {
    if (presets.length < 10) {
      const newPreset = {
        worker: selectedWorker,
        workerType: selectedWorkerType,
        taskType: selectedTaskType,
        domains: selectedDomains,
        sLang: filter.sLang,
        tLang: filter.tLang,
        rate: selectedWorker?.rate,
        currency: selectedWorker?.currency,
        unit: selectedWorker?.unit,
      };
      setPresets([...presets, newPreset]);
      alert(`프리셋 ${presets.length + 1}로 저장되었습니다.`);
    } else {
      alert('최대 10개의 프리셋만 저장할 수 있습니다.');
    }
  };

  const handlePresetSelect = (preset) => {
    setSelectedWorker(preset.worker);
    setSelectedWorkerType(preset.workerType);
    setSelectedTaskType(preset.taskType);
    setSelectedDomains(preset.domains);
    setFilter({
      sLang: preset.sLang,
      tLang: preset.tLang,
      domain: preset.domains,
      taskType: [preset.taskType],
      workerType: [preset.workerType],
    });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    sortWorkers(key, direction);
  };

  const sortWorkers = (key, direction) => {
    const sortedWorkers = [...filteredWorkers].sort((a, b) => {
      if (key === 'overall_rating') {
        const aRating = calculateAverage(a.overall_rating);
        const bRating = calculateAverage(b.overall_rating);
        if (aRating < bRating) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (aRating > bRating) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      } else {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      }
    });
    setFilteredWorkers(sortedWorkers);
  };

  const toggleExpand = (id, type) => {
    if (type === 'workerType') {
      setExpandedWorkerTypes((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (type === 'taskType') {
      setExpandedTaskTypes((prev) => ({ ...prev, [id]: !prev[id] }));
    } else if (type === 'domains') {
      setExpandedDomains((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  const indexOfLastWorker = currentPage * workersPerPage;
  const indexOfFirstWorker = indexOfLastWorker - workersPerPage;

  // 즐겨찾기 항목을 최우선으로 정렬
  const sortedWorkers = [
    ...filteredWorkers.filter(worker => favorites.includes(worker._id)),
    ...filteredWorkers.filter(worker => !favorites.includes(worker._id))
  ];

  const currentWorkers = sortedWorkers.slice(indexOfFirstWorker, indexOfLastWorker);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredWorkers.length / workersPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    if (selectedWorker) {
      const fetchPresets = async () => {
        try {
          const response = await axios.get(`/loadPresets/${selectedWorker._id}`);
          setPresets(response.data || []);
        } catch (error) {
          console.error("Error fetching presets:", error);
        }
      };

      fetchPresets();
    }
  }, [selectedWorker]);

  return (
    <>
      <LargeModal
        id="worker-assign-modal"
        title="작업자 할당"
        style={{ width: step === 2 ? '30%' : 'auto' }} // Adjust modal width based on step
        body={
          <div>
            {step === 1 && (
              <>
                <div className="flex justify-between mb-4">
                  <div className="relative">
                    <input
                      type="text"
                      placeholder="Search..."
                      value={searchTerm}
                      onChange={handleSearchChange}
                      className="block pt-2 text-sm text-gray-900 border border-gray-300 rounded-lg ps-10 w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <div className="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3">
                      <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                        <path stroke="currentColor" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                      </svg>
                    </div>
                  </div>
                  <div className="flex space-x-2">
                  <CustomSelect
                      name="sLang"
                      value={filter.sLang ? { value: filter.sLang, label: filter.sLang } : null}
                      onChange={handleFilterChange}
                      options={Array.isArray(dropdownmenus.language) ? dropdownmenus.language.map(lang => ({ value: lang, label: lang })) : []}
                      placeholder="출발어"
                    />
                    <CustomSelect
                      name="tLang"
                      value={filter.tLang ? { value: filter.tLang, label: filter.tLang } : null}
                      onChange={handleFilterChange}
                      options={Array.isArray(dropdownmenus.language) ? dropdownmenus.language.map(lang => ({ value: lang, label: lang })) : []}
                      placeholder="도착어"
                    />  
                    <CustomSelect
                      name="domain"
                      value={filter.domain ? { value: filter.domain, label: filter.domain } : null}
                      onChange={handleFilterChange}
                      options={Array.isArray(dropdownmenus.domain) ? dropdownmenus.domain.map(domain => ({ value: domain, label: domain })) : []}
                      placeholder="분야"
                    />
                    <button
                      onClick={handleResetFilters}
                      className="px-2 py-1 text-sm font-medium text-white bg-gray-500 rounded-lg hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full text-sm bg-white border border-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">즐겨찾기</th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('name')}>
                          이름
                          {sortConfig.key === 'name' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('sLang')}>
                          출발어
                          {sortConfig.key === 'sLang' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('tLang')}>
                          도착어
                          {sortConfig.key === 'tLang' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('email')}>
                          이메일
                          {sortConfig.key === 'email' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('taskType')}>
                          작업 유형
                          {sortConfig.key === 'taskType' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('workerType')}>
                          작업자 유형
                          {sortConfig.key === 'workerType' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('domain')}>
                          분야
                          {sortConfig.key === 'domain' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('rate')}>
                          단가
                          {sortConfig.key === 'rate' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 cursor-pointer" onClick={() => requestSort('overall_rating')}>
                          평점
                          {sortConfig.key === 'overall_rating' && (
                            <span>{sortConfig.direction === 'ascending' ? ' ▲' : ' ▼'}</span>
                          )}
                        </th>
                        <th className="px-4 py-2 text-xs font-medium tracking-wider text-left text-gray-500 uppercase border-b border-gray-200">
                          최근 작업
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {currentWorkers.map((worker) => {
                        const taskTypes = Array.isArray(worker.taskType) ? worker.taskType : [worker.taskType];
                        const workerTypes = Array.isArray(worker.workerType) ? worker.workerType : [worker.workerType];
                        const domains = Array.isArray(worker.domains) ? worker.domains : [];
                        return (
                          <tr
                            key={worker._id}
                            className={`cursor-pointer ${selectedWorker && selectedWorker._id === worker._id ? 'bg-gray-100' : ''}`}
                            onClick={() => handleWorkerSelect(worker)}
                          >
                            <td className="px-4 py-2 text-sm text-center text-gray-900 whitespace-nowrap">
                              <button
                                className={`mr-2 ${favorites.includes(worker._id) ? 'text-emerald-400' : 'text-gray-400'}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleFavorite(worker._id);
                                }}
                              >
                                <FontAwesomeIcon icon={faStar} size="lg" />
                              </button>
                            </td>
                            <td className="px-4 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                              {worker.name}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">{worker.sLang}</td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">{worker.tLang}</td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">{worker.email}</td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                              {taskTypes.length > 0 && (
                                <div className="flex items-center">
                                         <span className="px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                                    {taskTypes[0]}
                                  </span>
                                  {taskTypes.length > 1 && (
                                    <button onClick={() => toggleExpand(worker._id, 'taskType')} className="ml-2 text-xs text-blue-500">
                                      {expandedTaskTypes[worker._id] ? '▲' : '▼'}
                                    </button>
                                  )}
                                </div>
                              )}
                              {expandedTaskTypes[worker._id] && taskTypes.slice(1).map((type, index) => (
                                <span key={index} className="block px-2 py-1 mt-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                                  {type}
                                </span>
                              ))}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                              {workerTypes.length > 0 && (
                                <div className="flex items-center">
                                  <span className="px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                                    {workerTypes[0]}
                                  </span>
                                  {workerTypes.length > 1 && (
                                    <button onClick={() => toggleExpand(worker._id, 'workerType')} className="ml-2 text-xs text-blue-500">
                                      {expandedWorkerTypes[worker._id] ? '▲' : '▼'}
                                    </button>
                                  )}
                                </div>
                              )}
                              {expandedWorkerTypes[worker._id] && workerTypes.slice(1).map((type, index) => (
                                <span key={index} className="block px-2 py-1 mt-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                                  {type}
                                </span>
                              ))}
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                              {domains.length > 0 && (
                                <div className="flex items-center">
                                  <span className="px-2 py-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                                    {domains[0]}
                                  </span>
                                  {domains.length > 1 && (
                                    <button onClick={() => toggleExpand(worker._id, 'domains')} className="ml-2 text-xs text-blue-500">
                                      {expandedDomains[worker._id] ? '▲' : '▼'}
                                    </button>
                                  )}
                                </div>
                              )}
                              {expandedDomains[worker._id] && domains.slice(1).map((domain, index) => (
                                <span key={index} className="block px-2 py-1 mt-1 text-xs font-semibold text-blue-800 bg-blue-100 rounded-full">
                                  {domain}
                                </span>
                              ))}
                            </td>
                            <td className="px-4 py-2 text-sm text-blue-500 whitespace-nowrap">{formatNumber(worker.rate, worker.currency)}</td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                              <ReactStars
                                count={5}
                                value={Math.round(calculateAverage(worker.overall_rating) * 2) / 2}
                                edit={false}
                                size={12}
                                isHalf={true}
                                activeColor="#ffd700"
                              />
                            </td>
                            <td className="px-4 py-2 text-sm text-gray-500 whitespace-nowrap">
                              <button 
                                className="text-blue-500 hover:text-blue-700 focus:outline-none"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentWorker(worker);
                                  setRecentTasksModalOpen(true);
                                }}
                              >
                                최근 작업
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
              </>
            )}
            {step === 2 && (
              <div className="flex flex-wrap">
                <div className="w-1/2 pr-2">
                  <h3>프리셋 선택</h3>
                  <CustomSelect
                    name="preset"
                    value={selectedPreset ? { value: selectedPreset, label: `프리셋 ${selectedPreset}` } : null}
                  onChange={(selectedOption) => {
                    setSelectedPreset(selectedOption.value);
                    const preset = presets[selectedOption.value - 1];
                    setSelectedTaskType(preset.taskType);
                    setSelectedDomains(preset.domains);
                    setFilter({
                      sLang: preset.sLang,
                      tLang: preset.tLang,
                      domain: preset.domains,
                      taskType: [preset.taskType],
                      workerType: [preset.workerType],
                    });
                    setSelectedWorker((prevWorker) => ({
                      ...prevWorker,
                      rate: preset.rate,
                      currency: preset.currency,
                      unit: preset.unit,
                    }));
                  }}
                    options={Array.isArray(presets) ? presets.map((preset, index) => ({ value: index + 1, label: `프리셋 ${index + 1}` })) : []}
                    placeholder="프리셋 선택"
                />
              </div>
              <div className="w-1/2 pl-2">
                <h3>작업자 유형 선택</h3>
                <CustomSelect
                  name="workType"
                  value={selectedWorkerType ? { value: selectedWorkerType, label: selectedWorkerType } : null}
                  onChange={(selectedOption) => {
                    setSelectedWorkerType(selectedOption.value);
                    setSelectedWorker((prevWorker) => ({
                      ...prevWorker,
                      workerType: selectedOption.value,
                    }));
                  }}
                  options={selectedWorker && Array.isArray(selectedWorker.workerType) ? selectedWorker.workerType.map(type => ({ value: type, label: type })) : []}
                  placeholder="작업자 유형"
                />
              </div>
              <div className="w-1/2 pr-2 mt-4">
                  <h3>작업 유형</h3>
                  <CustomSelect
                    name="taskType"
                    value={selectedTaskType ? { value: selectedTaskType, label: selectedTaskType } : null}
                    onChange={(selectedOption) => setSelectedTaskType(selectedOption.value)}
                    options={selectedWorker && Array.isArray(selectedWorker.taskType) ? selectedWorker.taskType.map(type => ({ value: type, label: type })) : []}
                    placeholder="작업 유형"
                  />
                </div>
                <div className="w-1/2 pl-2 mt-4">
                  <h3>분야</h3>
                  <CustomSelect
                    name="domain"
                    value={selectedDomains ? { value: selectedDomains, label: selectedDomains } : null}
                    onChange={(selectedOption) => setSelectedDomains(selectedOption.value)}
                    options={selectedWorker && Array.isArray(selectedWorker.domains) ? selectedWorker.domains.map(domain => ({ value: domain, label: domain })) : []}
                    placeholder="분야"
                  />
                </div>
                <div className="w-1/2 pr-2 mt-4">
                <h3>출발어</h3>
                <CustomSelect
                  name="sLang"
                  value={filter.sLang ? { value: filter.sLang, label: filter.sLang } : null}
                  onChange={(selectedOption) => setFilter((prevFilter) => ({ ...prevFilter, sLang: selectedOption.value }))}
                  options={Array.isArray(dropdownmenus.language) ? dropdownmenus.language.map(lang => ({ value: lang, label: lang })) : []}
                  placeholder="출발어"
                />
              </div>
              <div className="w-1/2 pl-2 mt-4">
                <h3>도착어</h3>
                <CustomSelect
                  name="tLang"
                  value={filter.tLang ? { value: filter.tLang, label: filter.tLang } : null}
                  onChange={(selectedOption) => setFilter((prevFilter) => ({ ...prevFilter, tLang: selectedOption.value }))}
                  options={Array.isArray(dropdownmenus.language) ? dropdownmenus.language.map(lang => ({ value: lang, label: lang })) : []}
                  placeholder="도착어"
                />
              </div>
              <div className="w-1/2 pr-2 mt-4">
                <h3>통화</h3>
                <CustomSelect
                  name="currency"
                  value={selectedWorker?.currency ? { value: selectedWorker.currency, label: selectedWorker.currency } : null}
                  onChange={(selectedOption) => setSelectedWorker((prevWorker) => ({
                    ...prevWorker,
                    currency: selectedOption.value,
                  }))}
                  options={dropdownmenus.currency ? dropdownmenus.currency.map(curr => ({ value: curr, label: curr })) : []}
                  placeholder="통화"
                  isDisabled={true}
                />
              </div>
              <div className="w-1/2 pl-2 mt-3">
                  <h3>단가</h3>
                  <input
                    type="number"
                    value={selectedWorker?.rate || ''}
                    onChange={(e) => setSelectedWorker((prevWorker) => ({
                      ...prevWorker,
                      rate: e.target.value,
                    }))}
                    className="block w-full px-3 py-2 mt-1 text-sm border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>

              </div>
            )}
          </div>
        }
        onSave={handleNext} // Save button moves to the next step
        onClose={onClose}
        disableSave={isAssigning} // Disable Save button during assignment
      />
      {recentTasksModalOpen && (
        <RecentTasksModal
          isOpen={recentTasksModalOpen}
          onClose={() => setRecentTasksModalOpen(false)}
          worker={currentWorker}
        />
      )}
    </>
  );
};

const calculateAverage = (ratings) => {
  if (!ratings) return 0;
  const values = Object.values(ratings);
  const sum = values.reduce((acc, val) => acc + val, 0);
  const average = sum / values.length;
  return average / 20; // 100점 만점을 5점 만점으로 변환
};

export default WorkerAssignModal;