import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faPhone, faSearch, faSignInAlt, faSignOutAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';

// 필요한 아이콘을 라이브러리에 추가
library.add(faSignInAlt, faSignOutAlt, faSearch, faPhone, faVideo, faBars);

const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);