import React, { useContext } from 'react';
import '../../App.css';
import { SidebarContext } from '../../contexts/SidebarContext';
import BoardTitle from '../BoardTitle';
import SignupForm from './SignupForm';

const Signup = () => {
  const { isCollapsed } = useContext(SidebarContext);

  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-gray-150 custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <BoardTitle title="회원가입" /> {/* BoardTitle component used */}
        <SignupForm />
      </main>
    </div>
  );
}

export default Signup;
