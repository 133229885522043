import { ObjectId } from 'bson';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import axios from '../../../axiosConfig';
import CustomScrollbars from '../CustomScrollbars';
import { OneButtonModal, TwoButtonModal } from '../Modal';
import CustomSelect from './CustomSelect';

const AddWorkerModal = ({ isOpen, onClose, userId, onSave, onOpen }) => {
  const queryClient = useQueryClient();

  const initialFormData = {
    name: '',
    taskType: [],
    workerType: [],
    sLang: '',
    tLang: '',
    rate: '',
    exchangeRate: '1',
    currency: '',
    email: '',
    mobile: '',
    attachments: '',
    paymentMethod: '',
    paymentCountry: '',
    paymentBank: '',
    paymentAccount: '',
    memo: '',
    domains: [],
    unit: '',
    presets: [],
    ssn: ''
  };

  const initialPresetData = {
    presetSLang: '',
    presetTLang: '',
    presetUnit: '',
    presetTaskType: '',
    presetDomains: '',
    presetRate: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [presetData, setPresetData] = useState(initialPresetData);
  const [presets, setPresets] = useState([]);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [isPresetOpen, setIsPresetOpen] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState(null);
  const [step, setStep] = useState(1);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDuplicateEmailModalOpen, setIsDuplicateEmailModalOpen] = useState(false);
  const [isNextStepModalOpen, setIsNextStepModalOpen] = useState(false);
  const [isPresetSaveModalOpen, setIsPresetSaveModalOpen] = useState(false);
  const [isPresetLoadModalOpen, setIsPresetLoadModalOpen] = useState(false);
  const [presetIndexToSave, setPresetIndexToSave] = useState(null);

  useEffect(() => {
    if (isOpen && userId) {
      setFormData(initialFormData);
      setStep(1);
      loadPresets(userId); // Load presets when modal opens
    } else {
      // Reset presets when modal closes
      setPresets([]);
      setSelectedPreset(null);
      setPresetData(initialPresetData);
    }
  }, [isOpen, userId]);

  const loadPresets = async (userId) => {
    try {
      const response = await axios.get(`/loadPresets/${userId}`);
      setPresets(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('프리셋 로드 중 오류 발생:', error);
      setPresets([]); // 오류 발생 시 빈 배열로 초기화
    }
  };

  const deletePreset = async (userId, index) => {
    try {
      await axios.delete(`/deletePreset/${userId}/${index}`);
      loadPresets(userId);
    } catch (error) {
      console.error('프리셋 삭제 중 오류 발생:', error);
    }
  };

  const handleDeletePreset = (index) => {
    setPresetToDelete(index);
    setIsDeleteModalOpen(true);
  };
  
  const confirmDeletePreset = async () => {
    if (presetToDelete !== null) {
      try {
        await deletePreset(userId, presetToDelete);
        alert(`프리셋 ${presetToDelete + 1}가 삭제되었습니다.`);
      } catch (error) {
        console.error('프리셋 삭제 중 오류 발생:', error);
        alert('프리셋 삭제 중 오류가 발생했습니다.');
      } finally {
        setIsDeleteModalOpen(false);
        setPresetToDelete(null);
      }
    }
  };

  const [dropdownOptions, setDropdownOptions] = useState({
    taskType: [],
    currency: [],
    unit: [],
    language: [],
    workerType: [],
    domain: []
  });

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const types = ['taskType', 'currency', 'unit', 'language', 'workerType', 'domain'];
        const options = await Promise.all(types.map(type => axios.get(`/dropdown/${type}`)));
        const newOptions = {};
        types.forEach((type, index) => {
          newOptions[type] = options[index].data.map(option => ({ value: option, label: option }));
        });
        setDropdownOptions(newOptions);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePresetChange = (name, value) => {
    setPresetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };

  const handlePresetSelectChange = (name, selectedOption) => {
    setPresetData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : '',
    }));
  };

  const handleSave = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      const response = await axios.post('/addWorker', { ...formData, presets });
      if (response.status === 201) {
        alert('번역사가 성공적으로 추가되었습니다.');
        
        // Invalidate 'workers' query to refetch updated worker list
        queryClient.invalidateQueries('workers');
        
        onClose();
        setFormData(initialFormData); // 폼 데이터 초기화
        setPresetData(initialPresetData); // 프리셋 데이터 초기화
        setSelectedPreset(null); // 선택된 프리셋 초기화
        setStep(1); // 스텝 초기화
      }
    } catch (error) {
      console.error('번역사 추가 중 오류 발생:', error);
      alert('번역사 추가 중 오류가 발생했습니다.');
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  const handleNext = async () => {
    if (!formData.name) {
      setIsNextStepModalOpen(true);
    } else {
      try {
        const response = await axios.post(`/checkEmail`, { email: formData.email });
        if (response.data.exists) {
          setIsDuplicateEmailModalOpen(true);
        } else {
          setStep(step + 1);
        }
      } catch (error) {
        console.error('이메일 확인 중 오류 발생:', error);
      }
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleNextStepConfirm = () => {
    setIsNextStepModalOpen(false);
  };

  const handleDuplicateEmailConfirm = () => {
    setIsDuplicateEmailModalOpen(false);
  };

  const triggerPresetLoad = (index) => {
    const preset = presets[index];
    setPresetData({
      presetSLang: preset.sLang,
      presetTLang: preset.tLang,
      presetUnit: preset.unit,
      presetTaskType: preset.taskType,
      presetDomains: preset.domains,
      presetRate: preset.rate,
    });
    setSelectedPreset(index + 1);
  };

  const handleSavePreset = async () => {
    if (presets.length < 10) {
      const newPreset = {
        taskType: presetData.presetTaskType,
        domains: presetData.presetDomains,
        sLang: presetData.presetSLang,
        tLang: presetData.presetTLang,
        unit: presetData.presetUnit,
        rate: presetData.presetRate,
        currency: formData.currency,
        memo: formData.memo,
        exchangeRate: formData.exchangeRate,
      };

      try {
        const response = await axios.post('/savePreset', { user_id: userId || new ObjectId().toString(), ...newPreset });
        setPresets([...presets, newPreset]);
        alert(`프리셋 ${presets.length + 1}로 저장되었습니다.`);
      } catch (error) {
        console.error('프리셋 저장 중 오류 발생:', error);
        alert('프리셋 저장 중 오류가 발생했습니다.');
      }
    } else {
      alert('최대 10개의 프리셋만 저장할 수 있습니다.');
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${isOpen ? '' : 'hidden'}`}>
        <div className="relative w-full max-w-4xl p-8 bg-white rounded shadow-lg">
          <button onClick={onClose} className="absolute text-gray-500 top-4 right-4 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="mb-4 text-xl font-bold">작업자 추가</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            {step === 1 && (
              <CustomScrollbars style={{ height: 'calc(80vh - 100px)' }}>
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div className="mb-4">
                    <label className="block text-gray-700">작업자 이름</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">작업자 유형</label>
                    <CustomSelect
                      name="workerType"
                      value={dropdownOptions.workerType?.filter(option => formData.workerType?.includes(option.value))}
                      onChange={(selectedOptions) => handleSelectChange('workerType', selectedOptions)}
                      options={dropdownOptions.workerType}
                      isMulti
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">작업 유형</label>
                    <CustomSelect
                      name="taskType"
                      value={dropdownOptions.taskType?.filter(option => formData.taskType?.includes(option.value))}
                      onChange={(selectedOptions) => handleSelectChange('taskType', selectedOptions)}
                      options={dropdownOptions.taskType}
                      isMulti
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">분야</label>
                    <CustomSelect
                      name="domains"
                      value={dropdownOptions.domain?.filter(option => formData.domains?.includes(option.value))}
                      onChange={(selectedOptions) => handleSelectChange('domains', selectedOptions)}
                      options={dropdownOptions.domain}
                      isMulti
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">출발어</label>
                    <CustomSelect
                      name="sLang"
                      value={dropdownOptions.language?.find(option => option.value === formData.sLang)}
                      onChange={(option) => setFormData({ ...formData, sLang: option.value })}
                      options={dropdownOptions.language}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">도착어</label>
                    <CustomSelect
                      name="tLang"
                      value={dropdownOptions.language?.find(option => option.value === formData.tLang)}
                      onChange={(option) => setFormData({ ...formData, tLang: option.value })}
                      options={dropdownOptions.language}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">단위</label>
                    <CustomSelect
                      name="unit"
                      value={dropdownOptions.unit?.find(option => option.value === formData.unit)}
                      onChange={(option) => setFormData({ ...formData, unit: option.value })}
                      options={dropdownOptions.unit}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">통화</label>
                    <CustomSelect
                      name="currency"
                      value={dropdownOptions.currency?.find(option => option.value === formData.currency)}
                      onChange={(option) => setFormData({ ...formData, currency: option.value })}
                      options={dropdownOptions.currency}
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">단가</label>
                    <input type="text" name="rate" value={formData.rate} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">이메일</label>
                    <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">연락처</label>
                    <input type="text" name="mobile" value={formData.mobile} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">Memo</label>
                    <input type="text" name="memo" value={formData.memo} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">환율(기본설정, KRW 일경우는 1)</label>
                    <input type="text" name="exchangeRate" value={formData.exchangeRate} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                  </div>
                  <div className="col-span-2 my-2 border-t border-gray-300"></div>
                  <div className="flex items-center col-span-2 mb-2">
                    {selectedPreset && <div className="ml-1 text-gray-700 text-md text-bold">{`프리셋 ${selectedPreset}`}</div>}
                    <button type="button" onClick={() => setIsPresetOpen(!isPresetOpen)} className="ml-2 text-sm text-blue-500">
                      {isPresetOpen ? '접기 ▲' : '펼치기 ▼'}
                    </button>
                  </div>
                  <div className="flex col-span-2 mb-2 space-x-2">
                    {presets.map((preset, index) => (
                      <div key={index} className="relative flex items-center">
                      <button type="button" onClick={() => triggerPresetLoad(index)} className="px-1 py-1 text-xs text-white bg-blue-500 rounded h-8">
                        프리셋 {index + 1}
                      </button>
                          </div>
                        ))}
                        <button
                          onClick={handleSavePreset}
                          className="px-1 py-1 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 h-8"
                        >
                          프리셋 저장
                        </button>
                      </div>
                  {isPresetOpen && (
                    <>
                      <div className="mb-4">
                        <label className="block text-gray-700">출발어 (프리셋)</label>
                        <CustomSelect
                          name="presetSLang"
                          value={dropdownOptions.language?.find(option => option.value === presetData.presetSLang)}
                          onChange={(option) => handlePresetChange('presetSLang', option.value)}
                          options={dropdownOptions.language}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">도착어 (프리셋)</label>
                        <CustomSelect
                          name="presetTLang"
                          value={dropdownOptions.language?.find(option => option.value === presetData.presetTLang)}
                          onChange={(option) => handlePresetChange('presetTLang', option.value)}
                          options={dropdownOptions.language}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">단위 (프리셋)</label>
                        <CustomSelect
                          name="presetUnit"
                          value={dropdownOptions.unit?.find(option => option.value === presetData.presetUnit)}
                          onChange={(option) => handlePresetChange('presetUnit', option.value)}
                          options={dropdownOptions.unit}
                        />
                      </div>
                      <div className="mb-4">
                      <label className="block text-gray-700">작업 유형 (프리셋)</label>
                      <CustomSelect
                        name="presetTaskType"
                        value={dropdownOptions.taskType?.find(option => option.value === presetData.presetTaskType)}
                        onChange={(option) => handlePresetChange('presetTaskType', option.value)}
                        options={dropdownOptions.taskType}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">분야 (프리셋)</label>
                      <CustomSelect
                        name="presetDomains"
                        value={dropdownOptions.domain?.find(option => option.value === presetData.presetDomains)}
                        onChange={(option) => handlePresetChange('presetDomains', option.value)}
                        options={dropdownOptions.domain}
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block text-gray-700">단가 (프리셋)</label>
                      <input type="text" name="presetRate" value={presetData.presetRate} onChange={(e) => handlePresetChange('presetRate', e.target.value)} className="w-full p-2 border border-gray-300 rounded" />
                    </div>
                  </>
                )}
              </div>
            </CustomScrollbars>
            )}
            {step === 2 && (
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                <div className="mb-4">
                  <label className="block text-gray-700">주민번호</label>
                  <input type="text" name="ssn" value={formData.ssn} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">결제 방법</label>
                  <input type="text" name="paymentMethod" value={formData.paymentMethod} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">결제 국가</label>
                  <input type="text" name="paymentCountry" value={formData.paymentCountry} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">결제 은행</label>
                  <input type="text" name="paymentBank" value={formData.paymentBank} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">결제 계좌</label>
                  <input type="text" name="paymentAccount" value={formData.paymentAccount} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">첨부파일</label>
                  <input type="text" name="attachments" value={formData.attachments} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                </div>
              </div>
            )}
            <div className="flex justify-end">
              {step > 1 && <button type="button" onClick={handlePrev} className="px-4 py-2 mr-2 bg-gray-300 rounded">Previous</button>}
              {step < 2 && <button type="button" onClick={handleNext} className="px-4 py-2 mr-2 text-white bg-blue-500 rounded">Next</button>}
              {step === 2 && <button type="button" onClick={handleConfirmSave} className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>}
            </div>
          </form>

          </div>
          </div>

          {/* Delete Confirmation Modal */}
          {isDeleteModalOpen && (
            <TwoButtonModal
              title="프리셋 삭제"
              body={`프리셋 ${presetToDelete + 1}을(를) 삭제하시겠습니까?`}
              onSave={confirmDeletePreset}
              onClose={() => setIsDeleteModalOpen(false)}
            />
          )}

          {/* Preset Load Confirmation Modal */}
          {isPresetLoadModalOpen && (
            <TwoButtonModal
              title="프리셋 불러오기"
              body={`프리셋 ${presetIndexToSave + 1}을(를) 불러오시겠습니까?`}
              onSave={handlePresetLoadConfirm}
              onClose={() => setIsPresetLoadModalOpen(false)}
            />
          )}

          /* Preset Save Confirmation Modal */
          {isPresetSaveModalOpen && (
            <TwoButtonModal
              title="프리셋 저장"
              body={`프리셋 ${presetIndexToSave + 1}을(를) 저장하시겠습니까?`}
              onSave={handlePresetSaveConfirm}
              onClose={() => setIsPresetSaveModalOpen(false)}
            />
          )}

          /* Confirm Save Modal */
          {isConfirmModalOpen && (
            <TwoButtonModal
              title="저장하시겠습니까?"
              body="저장하시겠습니까?"
              onSave={handleConfirmSave}
              onClose={() => setIsConfirmModalOpen(false)}
            />
          )}

          /* Duplicate Email Modal */
          {isDuplicateEmailModalOpen && (
            <OneButtonModal
              title="중복된 이메일"
              body="이미 사용중인 이메일입니다."
              onSave={handleDuplicateEmailConfirm}
              onClose={() => setIsDuplicateEmailModalOpen(false)}
            />
          )}

          /* Missing Worker Name Modal */
          {isNextStepModalOpen && (
            <OneButtonModal
              title="작업자 정보 불충분"
              body="작업자 이름을 입력해주세요."
              onSave={handleNextStepConfirm}
              onClose={() => setIsNextStepModalOpen(false)}
            />
          )}
          </>
          );
          };

export default AddWorkerModal;