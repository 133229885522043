import React, { useContext } from 'react';
import '../App.css';
import BoardTitle from '../area/BoardTitle';
import Board from '../boards/Board';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';

const Freeboard = () => {
  const { isCollapsed } = useContext(SidebarContext); 

  return (
    <div className={`flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px] ${isCollapsed ? 'pl-20' : 'pl-64'}`}>
      <main className="flex-grow p-5 mt-0 mb-0 ml-0 mr-4 bg-white custom:w-full custom:mr-0 sm-custom:mx-2">
        <BoardTitle title="자유게시판" />
        <Board />
      </main>
      <AsideContainer />
    </div>
  );
};

export default Freeboard;
