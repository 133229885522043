"use client";

import { Breadcrumb } from "flowbite-react";
import { useState } from "react";
import { HiHome, HiOutlineShare } from "react-icons/hi";
import Alert from "../commons/Alert";

export function BreadScrum() {
  const [alertMessage, setAlertMessage] = useState("");

  const copyLink = () => {
    navigator.clipboard.writeText("https://recolor.co.kr/study/10140123");
    setAlertMessage("링크가 복사되었습니다: https://recolor.co.kr/study/10140123");
  };

  return (
    <div className="relative">
      <div className="flex items-center justify-between py-4 border-t border-gray-300">
        <Breadcrumb aria-label="Default breadcrumb example">
          <Breadcrumb.Item href="#" icon={HiHome}>
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item href="#">Study</Breadcrumb.Item>
          <Breadcrumb.Item>1140123</Breadcrumb.Item>
        </Breadcrumb>
        <div className="flex items-center">
          <a href="https://recolor.co.kr/study/10140123" className="mr-1">
            https://recolor.co.kr/study/10140123
          </a>
          <button onClick={copyLink} className="flex items-center">
            <HiOutlineShare className="ml-1 text-xl" />
          </button>
        </div>
      </div>
      <Alert message={alertMessage} />
    </div>
  );
}

export default BreadScrum;

