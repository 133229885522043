import axios from 'axios';
import { io } from 'socket.io-client';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

const socket = io(process.env.REACT_APP_SOCKET_URL, {
  withCredentials: true,
  transports: ['websocket'],
});

export const setupInterceptors = (onSessionInvalid) => {
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        if (!error.config.url.includes('/login') && !error.config.url.includes('/logout')) {
          onSessionInvalid();
        }
      }
      return Promise.reject(error);
    }
  );

  socket.on('session_invalid', () => {
    onSessionInvalid();
  });
};

export default instance;