import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import axios from '../../../axiosConfig';
import CustomScrollbars from '../CustomScrollbars';
import { OneButtonModal, TwoButtonModal } from '../Modal';
import CustomSelect from './CustomSelect';

const EditWorkerModal = ({ isOpen, onClose, worker }) => {
  const queryClient = useQueryClient();

  const initialFormData = {
    _id: worker?._id || '',
    name: worker?.name || '',
    taskType: worker?.taskType || [], // Changed to array
    workerType: worker?.workerType || [], // Changed to array
    sLang: worker?.sLang || '',
    tLang: worker?.tLang || '',
    rate: worker?.rate || '',
    exchangeRate: worker?.exchangeRate || '1',
    currency: worker?.currency || '',
    email: worker?.email || '',
    mobile: worker?.mobile || '',
    attachments: worker?.attachments || '',
    paymentMethod: worker?.paymentMethod || '',
    paymentCountry: worker?.paymentCountry || '',
    paymentBank: worker?.paymentBank || '',
    paymentAccount: worker?.paymentAccount || '',
    memo: worker?.memo || '',
    domains: worker?.domains || [], // Initialized as an array
    unit: worker?.unit || '',
    presets: worker?.presets || [], // Added presets
    ssn: worker?.ssn || '', // 주민번호 필드 추가
  };

  const initialPresetData = {
    presetSLang: '',
    presetTLang: '',
    presetUnit: '',
    presetTaskType: '',
    presetDomains: '',
    presetRate: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [presetData, setPresetData] = useState(initialPresetData);
  const [presets, setPresets] = useState(worker?.presets || []);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [isPresetOpen, setIsPresetOpen] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [presetToDelete, setPresetToDelete] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDuplicateEmailModalOpen, setIsDuplicateEmailModalOpen] = useState(false);
  const [isNextStepModalOpen, setIsNextStepModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [isPresetChanged, setIsPresetChanged] = useState(false);
  const [isLoadComplete, setIsLoadComplete] = useState(false); // 로드 완료 상태 추가
  const [isLoadModalOpen, setIsLoadModalOpen] = useState(false); // 로드 완료 모달 상태 추가
  const [isNoPresetsModalOpen, setIsNoPresetsModalOpen] = useState(false); // 프리셋 없음 모달 상태 추가
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  useEffect(() => {
    if (isOpen) {
      setFormData({
        _id: worker?._id || '',
        name: worker?.name || '',
        taskType: worker?.taskType || [],
        workerType: worker?.workerType || [],
        sLang: worker?.sLang || '',
        tLang: worker?.tLang || '',
        rate: worker?.rate || '',
        exchangeRate: worker?.exchangeRate || '1',
        currency: worker?.currency || '',
        email: worker?.email || '',
        mobile: worker?.mobile || '',
        attachments: worker?.attachments || '',
        paymentMethod: worker?.paymentMethod || '',
        paymentCountry: worker?.paymentCountry || '',
        paymentBank: worker?.paymentBank || '',
        paymentAccount: worker?.paymentAccount || '',
        memo: worker?.memo || '',
        domains: worker?.domains || [],
        unit: worker?.unit || '',
        presets: worker?.presets || [],
        ssn: worker?.ssn || '', // 주민번호 필드 초기화
      });
      setStep(1);
      setIsLoadComplete(false); // 로드 완료 상태 초기화
      setIsLoading(true); // 로딩 상태 설정
      loadPresets(worker._id, 0); // Load presets when modal opens
    } else {
      // Reset presets when modal closes
      setPresets([]);
      setSelectedPreset(null);
      setPresetData(initialPresetData);
    }
  }, [isOpen, worker]);

  const loadPresets = async (userId, attempt) => {
    try {
      console.log(`Loading presets for user: ${userId}, attempt: ${attempt}`); // 디버깅 로그 추가
      const response = await axios.get(`/loadPresets/${userId}`);
      console.log('Presets loaded:', response.data); // 디버깅 로그 추가
      if (Array.isArray(response.data)) {
        setPresets(response.data);
        setIsLoadComplete(true); // 로드 완료 상태 설정
        setIsLoadModalOpen(true); // 로드 완료 모달 열기
      } else {
        throw new Error('Invalid presets data');
      }
    } catch (error) {
      console.error('프리셋 로드 중 오류 발생:', error);
      if (attempt < 2) {
        setTimeout(() => loadPresets(userId, attempt + 1), 1000); // 1초 후에 다시 시도
      } else {
        setIsNoPresetsModalOpen(true); // 프리셋 없음 모달 열기
      }
    } finally {
      setIsLoading(false); // 로딩 상태 해제
    }
  };

  const deletePreset = async (userId, index) => {
    try {
      await axios.delete(`/deletePreset/${userId}/${index}`);
      loadPresets(userId, 0);
    } catch (error) {
      console.error('프리셋 삭제 중 오류 발생:', error);
    }
  };

  const handleDeletePreset = (index) => {
    setPresetToDelete(index);
    setIsDeleteModalOpen(true);
  };

  const confirmDeletePreset = async () => {
    if (presetToDelete !== null) {
      try {
        await deletePreset(worker._id, presetToDelete);
        alert(`프리셋 ${presetToDelete + 1}가 삭제되었습니다.`);
      } catch (error) {
        console.error('프리셋 삭제 중 오류 발생:', error);
        alert('프리셋 삭제 중 오류가 발생했습니다.');
      } finally {
        setIsDeleteModalOpen(false);
        setPresetToDelete(null);
      }
    }
  };

  const updatePreset = async (userId, index, updatedPreset) => {
    try {
      await axios.put(`/updatePreset/${userId}/${index}`, updatedPreset);
      loadPresets(userId, 0);
    } catch (error) {
      console.error('프리셋 수정 중 오류 발생:', error);
    }
  };

  const handleUpdatePreset = async () => {
    if (selectedPreset !== null) {
      const updatedPreset = {
        sLang: presetData.presetSLang,
        tLang: presetData.presetTLang,
        unit: presetData.presetUnit,
        taskType: presetData.presetTaskType,
        domains: presetData.presetDomains,
        rate: presetData.presetRate,
      };

      try {
        await updatePreset(worker._id, selectedPreset - 1, updatedPreset);
        alert(`프리셋 ${selectedPreset}가 수정되었습니다.`);
        setIsPresetChanged(false);
      } catch (error) {
        console.error('프리셋 수정 중 오류 발생:', error);
        alert('프리셋 수정 중 오류가 발생했습니다.');
      }
    }
  };

  const [dropdownOptions, setDropdownOptions] = useState({
    taskType: [],
    currency: [],
    unit: [],
    language: [],
    workerType: [],
    domain: []
  });

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const types = ['taskType', 'currency', 'unit', 'language', 'workerType', 'domain'];
        const options = await Promise.all(types.map(type => axios.get(`/dropdown/${type}`)));
        const newOptions = {};
        types.forEach((type, index) => {
          newOptions[type] = Array.isArray(options[index].data) ? options[index].data.map(option => ({ value: option, label: option })) : [];
        });
        setDropdownOptions(newOptions);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePresetChange = (name, value) => {
    setPresetData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsPresetChanged(true);
  };

  const handleSelectChange = (name, selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };

  const handlePresetSelectChange = (name, selectedOption) => {
    setPresetData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : '',
    }));
    setIsPresetChanged(true);
  };

  const handleSave = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      await axios.post(`/updateWorker`, { ...formData, presets });
      queryClient.invalidateQueries('workers'); // workers 쿼리 무효화
      onClose();
    } catch (error) {
      if (error.response && error.response.status === 400 && error.response.data.error === "이미 같은 이메일을 가진 번역사가 존재합니다.") {
        setIsDuplicateEmailModalOpen(true);
      } else {
        console.error('번역사 정보 처리 중 오류 발생:', error);
      }
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  const handleNext = async () => {
    if (!formData.name) {
      setIsNextStepModalOpen(true);
    } else {
      try {
        const response = await axios.post(`/checkEmail`, { email: formData.email, worker_id: worker._id });
        if (response.data.exists) {
          setIsDuplicateEmailModalOpen(true);
        } else {
          setStep(step + 1);
        }
      } catch (error) {
        console.error('이메일 확인 중 오류 발생:', error);
      }
    }
  };

  const handlePrev = () => {
    setStep(step - 1);
  };

  const handleNextStepConfirm = () => {
    setIsNextStepModalOpen(false);
  };

  const handleDuplicateEmailConfirm = () => {
    setIsDuplicateEmailModalOpen(false);
  };

  const triggerPresetLoad = (index) => {
    const preset = presets[index];
    setPresetData({
      presetSLang: preset.sLang,
      presetTLang: preset.tLang,
      presetUnit: preset.unit,
      presetTaskType: preset.taskType,
      presetDomains: preset.domains,
      presetRate: preset.rate,
    });
    setSelectedPreset(index + 1);
    setIsPresetChanged(false);
  };

  const handleSavePreset = async () => {
    if (presets.length < 10) {
      const newPreset = {
        taskType: presetData.presetTaskType,
        domains: presetData.presetDomains,
        sLang: presetData.presetSLang,
        tLang: presetData.presetTLang,
        unit: presetData.presetUnit,
        rate: presetData.presetRate,
        currency: formData.currency,
        memo: formData.memo,
        exchangeRate: formData.exchangeRate,
      };

      try {
        await axios.post('/savePreset', { user_id: worker._id, ...newPreset });
        setPresets([...presets, newPreset]);
        alert(`프리셋 ${presets.length + 1}로 저장되었습니다.`);
      } catch (error) {
        console.error('프리셋 저장 중 오류 발생:', error);
        alert('프리셋 저장 중 오류가 발생했습니다.');
      }
    } else {
      alert('최대 10개의 프리셋만 저장할 수 있습니다.');
    }
  };

  const handleClose = () => {
    setPresets([]);
    setSelectedPreset(null);
    setPresetData(initialPresetData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <div className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${isLoading ? 'pointer-events-none' : ''}`} onClick={handleClose}>
        <div className="relative w-4/5 max-w-2xl p-4 bg-white rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
          {isLoading && (
            <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div role="status">
                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <CustomScrollbars style={{ height: '80vh' }}>
            <form onSubmit={(e) => e.preventDefault()}>
              {step === 1 && (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                  <div className="mb-4">
                    <label className="block text-gray-700">작업자 이름</label>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">작업자 유형</label>
                    <CustomSelect
                      name="workerType"
                      value={Array.isArray(dropdownOptions.workerType) ? dropdownOptions.workerType.filter(option => formData.workerType?.includes(option.value)) : []}
                      onChange={(selectedOptions) => handleSelectChange('workerType', selectedOptions)}
                      options={dropdownOptions.workerType}
                      isMulti
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">작업 유형</label>
                    <CustomSelect
                      name="taskType"
                      value={Array.isArray(dropdownOptions.taskType) ? dropdownOptions.taskType.filter(option => formData.taskType?.includes(option.value)) : []}
                      onChange={(selectedOptions) => handleSelectChange('taskType', selectedOptions)}
                      options={Array.isArray(dropdownOptions.taskType) ? dropdownOptions.taskType : []}
                      isMulti
                    />
                  </div>
                  <div className="mb-4">
                    <label className="block text-gray-700">분야</label>
                    <CustomSelect
                      name="domains"
                      value={Array.isArray(dropdownOptions.domain) ? dropdownOptions.domain.filter(option => formData.domains?.includes(option.value)) : []}
                      onChange={(selectedOptions) => handleSelectChange('domains', selectedOptions)}
                      options={Array.isArray(dropdownOptions.domain) ? dropdownOptions.domain : []}
                      isMulti
                      />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">출발어</label>
                        <CustomSelect
                          name="sLang"
                          value={Array.isArray(dropdownOptions.language) ? dropdownOptions.language.find(option => option.value === formData.sLang) : null}
                          onChange={(option) => setFormData({ ...formData, sLang: option.value })}
                          options={Array.isArray(dropdownOptions.language) ? dropdownOptions.language : []}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">도착어</label>
                        <CustomSelect
                          name="tLang"
                          value={Array.isArray(dropdownOptions.language) ? dropdownOptions.language.find(option => option.value === formData.tLang) : null}
                          onChange={(option) => setFormData({ ...formData, tLang: option.value })}
                          options={Array.isArray(dropdownOptions.language) ? dropdownOptions.language : []}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">단위</label>
                        <CustomSelect
                          name="unit"
                          value={Array.isArray(dropdownOptions.unit) ? dropdownOptions.unit.find(option => option.value === formData.unit) : null}
                          onChange={(option) => setFormData({ ...formData, unit: option.value })}
                          options={Array.isArray(dropdownOptions.unit) ? dropdownOptions.unit : []}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">통화</label>
                        <CustomSelect
                          name="currency"
                          value={Array.isArray(dropdownOptions.currency) ? dropdownOptions.currency.find(option => option.value === formData.currency) : null}
                          onChange={(option) => setFormData({ ...formData, currency: option.value })}
                          options={Array.isArray(dropdownOptions.currency) ? dropdownOptions.currency : []}
                        />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">단가</label>
                        <input type="text" name="rate" value={formData.rate} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">이메일</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">연락처</label>
                        <input type="text" name="mobile" value={formData.mobile} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">Memo</label>
                        <input type="text" name="memo" value={formData.memo} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">환율(기본설정, KRW 일경우는 1)</label>
                        <input type="text" name="exchangeRate" value={formData.exchangeRate} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="col-span-2 my-2 border-t border-gray-300"></div>
                      <div className="flex items-center col-span-2 mb-2">
                        {selectedPreset && <div className="ml-1 text-gray-700 text-md text-bold">{`프리셋 ${selectedPreset}`}</div>}
                        <button type="button" onClick={() => setIsPresetOpen(!isPresetOpen)} className="ml-2 text-sm text-blue-500">
                          {isPresetOpen ? '접기 ▲' : '펼치기 ▼'}
                        </button>
                      </div>
                      <div className="flex col-span-2 mb-2 space-x-2">
                        {presets.map((preset, index) => (
                          <div key={index} className="relative flex items-center">
                            <button type="button" onClick={() => triggerPresetLoad(index)} className="px-2 py-1 text-xs text-white bg-blue-500 rounded h-8">
                              프리셋 {index + 1}
                            </button>
                            <button type="button" onClick={() => handleDeletePreset(index)} className="px-1 py-1 text-xs text-black bg-white rounded-r h-8">
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        ))}
                        {isPresetChanged && selectedPreset && (
                          <button
                            onClick={handleUpdatePreset}
                            className="px-2 py-1 text-sm text-white bg-green-500 rounded hover:bg-green-600 h-8"
                          >
                            프리셋 수정
                          </button>
                        )}
                        <button
                          onClick={handleSavePreset}
                          className="px-2 py-1 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 h-8"
                        >
                          프리셋 저장
                        </button>
                      </div>
                      {isPresetOpen && (
                        <>
                          <div className="mb-4">
                            <label className="block text-gray-700">출발어 (프리셋)</label>
                            <CustomSelect
                              name="presetSLang"
                              value={Array.isArray(dropdownOptions.language) ? dropdownOptions.language.find(option => option.value === presetData.presetSLang) : null}
                              onChange={(option) => handlePresetChange('presetSLang', option.value)}
                              options={Array.isArray(dropdownOptions.language) ? dropdownOptions.language : []}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700">도착어 (프리셋)</label>
                            <CustomSelect
                              name="presetTLang"
                              value={Array.isArray(dropdownOptions.language) ? dropdownOptions.language.find(option => option.value === presetData.presetTLang) : null}
                              onChange={(option) => handlePresetChange('presetTLang', option.value)}
                              options={Array.isArray(dropdownOptions.language) ? dropdownOptions.language : []}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700">단위 (프리셋)</label>
                            <CustomSelect
                              name="presetUnit"
                              value={Array.isArray(dropdownOptions.unit) ? dropdownOptions.unit.find(option => option.value === presetData.presetUnit) : null}
                              onChange={(option) => handlePresetChange('presetUnit', option.value)}
                              options={Array.isArray(dropdownOptions.unit) ? dropdownOptions.unit : []}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700">작업 유형 (프리셋)</label>
                            <CustomSelect
                              name="presetTaskType"
                              value={Array.isArray(dropdownOptions.taskType) ? dropdownOptions.taskType.find(option => option.value === presetData.presetTaskType) : null}
                              onChange={(option) => handlePresetChange('presetTaskType', option.value)}
                              options={Array.isArray(dropdownOptions.taskType) ? dropdownOptions.taskType : []}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700">분야 (프리셋)</label>
                            <CustomSelect
                              name="presetDomains"
                              value={Array.isArray(dropdownOptions.domain) ? dropdownOptions.domain.find(option => option.value === presetData.presetDomains) : null}
                              onChange={(option) => handlePresetChange('presetDomains', option.value)}
                              options={Array.isArray(dropdownOptions.domain) ? dropdownOptions.domain : []}
                            />
                          </div>
                          <div className="mb-4">
                            <label className="block text-gray-700">단가 (프리셋)</label>
                            <input type="text" name="presetRate" value={presetData.presetRate} onChange={(e) => handlePresetChange('presetRate', e.target.value)} className="w-full p-2 border border-gray-300 rounded" />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {step === 2 && (
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                      <div className="mb-4">
                        <label className="block text-gray-700">주민번호</label>
                        <input type="text" name="ssn" value={formData.ssn} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">결제 방법</label>
                        <input type="text" name="paymentMethod" value={formData.paymentMethod} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">결제 국가</label>
                        <input type="text" name="paymentCountry" value={formData.paymentCountry} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">결제 은행</label>
                        <input type="text" name="paymentBank" value={formData.paymentBank} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">결제 계좌</label>
                        <input type="text" name="paymentAccount" value={formData.paymentAccount} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                      <div className="mb-4">
                        <label className="block text-gray-700">첨부파일</label>
                        <input type="text" name="attachments" value={formData.attachments} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
                      </div>
                    </div>
                  )}
                  <div className="flex justify-end">
                    {step > 1 && <button type="button" onClick={handlePrev} className="px-4 py-2 mr-2 bg-gray-300 rounded">Previous</button>}
                    {step < 2 && <button type="button" onClick={handleNext} className="px-4 py-2 mr-2 text-white bg-blue-500 rounded">Next</button>}
                    {step === 2 && <button type="button" onClick={handleConfirmSave} className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>}
                  </div>
                </form>
              </CustomScrollbars>
              <button type="button" onClick={handleClose} className="absolute top-0 right-0 mt-2 mr-2 text-gray-500 hover:text-gray-700">
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          </div>
    
          {/* Preset Delete Confirmation Modal */}
          {isDeleteModalOpen && (
            <TwoButtonModal
              title="프리셋 삭제"
              body={`프리셋 ${presetToDelete + 1}을(를) 삭제하시겠습니까?`}
              onSave={confirmDeletePreset}
              onClose={() => setIsDeleteModalOpen(false)}
            />
          )}
    
          {/* Confirm Save Modal */}
          {isConfirmModalOpen && (
            <TwoButtonModal
              title="저장하시겠습니까?"
              body="저장하시겠습니까?"
              onSave={handleConfirmSave}
              onClose={() => setIsConfirmModalOpen(false)}
            />
          )}
    
          {/* Duplicate Email Modal */}
          {isDuplicateEmailModalOpen && (
            <OneButtonModal
              title="중복된 이메일"
              body="이미 사용중인 이메일입니다."
              onSave={handleDuplicateEmailConfirm}
              onClose={() => setIsDuplicateEmailModalOpen(false)}
            />
          )}
    
          {/* Missing Worker Name Modal */}
          {isNextStepModalOpen && (
            <OneButtonModal
              title="작업자 정보 불충분"
              body="작업자 이름을 입력해주세요."
              onSave={handleNextStepConfirm}
              onClose={() => setIsNextStepModalOpen(false)}
            />
          )}
    
          {/* Load Complete Modal */}
          {isLoadModalOpen && (
            <OneButtonModal
              title="프리셋 로드 완료"
              body="프리셋이 정상적으로 로드되었습니다."
              onSave={() => setIsLoadModalOpen(false)}
              onClose={() => setIsLoadModalOpen(false)}
            />
          )}
    
          {/* No Presets Modal */}
          {isNoPresetsModalOpen && (
            <OneButtonModal
              title="프리셋 없음"
              body="프리셋을 찾을 수 없습니다."
              onSave={() => setIsNoPresetsModalOpen(false)}
              onClose={() => setIsNoPresetsModalOpen(false)}
            />
          )}
        </>
      );
    };
    
    export default EditWorkerModal;