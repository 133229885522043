import React, { useContext } from 'react';
import '../App.css';
import AsideContainer from '../components/containers/AsideContainer';
import { SidebarContext } from '../contexts/SidebarContext';
import { AuthContext } from '../session/AuthContext';
import Dashboard from './Dashboard';

const Home = () => {
  const { user } = useContext(AuthContext);
  const { isCollapsed } = useContext(SidebarContext);

  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-gray-150 custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <Dashboard user={user} />
      </main>
      <AsideContainer />
    </div>
  );
};

export default Home;