import React, { createContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import axios from '../axiosConfig';

axios.defaults.withCredentials = true;

export const AuthContext = createContext();

const fetchUser = async () => {
  const response = await axios.get('/session-user', { withCredentials: true });
  return response.data.user;
};

const loginUser = async (userData) => {
  const response = await axios.post('/login', userData, { withCredentials: true });
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response.data;
};

const logoutUser = async () => {
  await axios.post('/logout', {}, { withCredentials: true });
};

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: user, isLoading, refetch } = useQuery('user', fetchUser, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const loginMutation = useMutation(loginUser, {
    onSuccess: (data) => {
      queryClient.setQueryData('user', data.user);
    },
    onError: (error) => {
      console.error('로그인 실패:', error.message);
    },
  });

  const logoutMutation = useMutation(logoutUser, {
    onSuccess: () => {
      queryClient.removeQueries('user');
      navigate('/guest');
    },
  });

  const login = async (userData) => {
    try {
      const response = await loginMutation.mutateAsync(userData);
      return { success: true, user: response.user };
    } catch (error) {
      console.error('Error logging in:', error);
      return { success: false, error: error.response?.data?.error || '아이디 또는 비밀번호가 잘못되었습니다.' };
    }
  };

  const logout = async () => {
    try {
      await logoutMutation.mutateAsync();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading: isLoading, refetchUser: refetch }}>
      {children}
    </AuthContext.Provider>
  );
};