import React, { useEffect, useState } from 'react';
import axios from '../../../axiosConfig';
import { TwoButtonModal } from '../Modal';
import CustomSelect from './CustomSelect';

const EditClientModal = ({ isOpen, onClose, client, fetchClients }) => {
  const initialFormData = {
    clientName: '',
    clientType: '',
    clientDomains: [], // clientDomains 추가
    clientEmail: '',
    clientMobile: '',
    clientRegistrationNumber: '',
    attachments: '',
    memo: '',
    endClient: [], // endClient 추가
  };

  const [formData, setFormData] = useState(initialFormData);
  const [dropdownOptions, setDropdownOptions] = useState({
    clientType: [],
    domain: [],
    endClient: [], // endClient 추가
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setFormData(client || initialFormData);
    }
  }, [isOpen, client]);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const types = ['clientType', 'domain', 'endClient'];
        const options = await Promise.all(types.map(type => axios.get(`/dropdown/${type}`)));
        const newOptions = {};
        types.forEach((type, index) => {
          newOptions[type] = options[index].data.map(option => ({ value: option, label: option }));
        });
        setDropdownOptions(newOptions);
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (name, selectedOptions) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOptions ? selectedOptions.map(option => option.value) : [],
    }));
  };

  const handleSave = () => {
    setIsConfirmModalOpen(true);
  };

  const handleConfirmSave = async () => {
    try {
      await axios.put(`/clients/${client._id}`, formData);
      console.log('클라이언트 정보가 성공적으로 업데이트되었습니다.');
      onClose();
      fetchClients(); // fetch the updated list of clients
    } catch (error) {
      console.error('클라이언트 정보 처리 중 오류 발생:', error);
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={onClose}>
        <div className="relative w-full max-w-2xl p-4 bg-white rounded-lg shadow-lg" onClick={(e) => e.stopPropagation()}>
          <button onClick={onClose} className="absolute text-gray-500 top-4 right-4 hover:text-gray-700">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <h2 className="mb-4 text-xl font-bold">클라이언트 수정</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div className="mb-4">
                <label className="block text-gray-700">클라이언트 이름</label>
                <input type="text" name="clientName" value={formData.clientName} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">클라이언트 유형</label>
                <CustomSelect
                  name="clientType"
                  value={dropdownOptions.clientType?.find(option => option.value === formData.clientType)}
                  onChange={(option) => setFormData({ ...formData, clientType: option.value })}
                  options={dropdownOptions.clientType}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">클라이언트 분야</label>
                <CustomSelect
                  name="clientDomains"
                  value={dropdownOptions.domain?.filter(option => formData.clientDomains?.includes(option.value))}
                  onChange={(selectedOptions) => handleSelectChange('clientDomains', selectedOptions)}
                  options={dropdownOptions.domain}
                  isMulti
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">이메일</label>
                <input type="email" name="clientEmail" value={formData.clientEmail} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">연락처</label>
                <input type="text" name="clientMobile" value={formData.clientMobile} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">사업자 등록번호</label>
                <input type="text" name="clientRegistrationNumber" value={formData.clientRegistrationNumber} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Memo</label>
                <input type="text" name="memo" value={formData.memo} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
            
            </div>
            <div className="flex justify-end">
              <button type="button" onClick={onClose} className="px-4 py-2 mr-2 bg-gray-300 rounded">Cancel</button>
              <button type="button" onClick={handleSave} className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>
            </div>
          </form>
        </div>
      </div>
      {isConfirmModalOpen && (
        <TwoButtonModal
          title="Confirm Save"
          body="Are you sure you want to save the changes?"
          onSave={handleConfirmSave}
          onClose={() => setIsConfirmModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditClientModal;