import { useEffect, useState } from 'react';
import axios from '../../../axiosConfig';
import { TwoButtonModal } from '../Modal';
import Dropzone from '../ProfileDropzone';

const EditUserModal = ({ isOpen, onClose, user }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    division: '',
    group: '',
    position: '',
    status: '',
    skypeId: '',
    image: '',
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    division: [],
    group: [],
    position: [],
    status: []
  });

  const [isTwoButtonModalOpen, setIsTwoButtonModalOpen] = useState(false);

  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await axios.get('/getDropdown');
        const dropdownData = response.data.reduce((acc, item) => {
          acc[item.type] = item.values;
          return acc;
        }, {});
        setDropdownOptions((prevOptions) => ({ ...prevOptions, ...dropdownData }));
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  useEffect(() => {
    if (user) {
      const apiUrl = process.env.REACT_APP_API_URL.replace('/api', '');
      const imagePath = user.image ? `${apiUrl}/upload/profile_pics/${user.image}` : 'https://via.placeholder.com/40';
      setFormData({
        name: user.name || '',
        email: user.email || '',
        phone: user.phone || '',
        division: user.division || '',
        group: user.group || '',
        position: user.position || '',
        status: user.status || '',
        skypeId: user.skypeId || '',
        image: imagePath,
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (imageData) => {
    setFormData((prevData) => ({
      ...prevData,
      image: imageData,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsTwoButtonModalOpen(true);
  };

  const handleSave = async () => {
    try {
      let imagePath = formData.image;
  
      // 이미지가 base64 형식이면 서버에 업로드
      if (formData.image.startsWith('data:image')) {
        const imageData = new FormData();
        imageData.append('file', formData.image);
        imageData.append('name', formData.name); // 사용자 이름 추가
        const uploadResponse = await axios.post('/uploadImage', imageData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        imagePath = uploadResponse.data.path;
      }
  
      await axios.put('/updateEmployee', { ...formData, image: imagePath, _id: user._id });
      setIsTwoButtonModalOpen(false);
      onClose();
    } catch (error) {
      console.error('유저 정보 업데이트 중 오류 발생:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="max-w-md px-6 py-12 mx-auto bg-white border-0 shadow-lg sm:rounded-3xl">
          <h2 className="mb-8 text-2xl font-bold">Edit User</h2>
          <form onSubmit={handleSubmit} className="flex flex-wrap">
            <div className="relative z-0 flex items-center w-full mb-5">
              <Dropzone onImageUpload={handleImageUpload} initialImage={formData.image} />
            </div>
            {[
              { name: 'name', type: 'text', label: 'Name' },
              { name: 'email', type: 'email', label: 'Email' },
              { name: 'phone', type: 'text', label: 'Phone' },
              { name: 'skypeId', type: 'text', label: 'Skype ID' }
            ].map(({ name, type, label }) => (
              <div key={name} className="relative z-0 w-full mb-5">
                <input
                  type={type}
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                  placeholder=" "
                  required
                  className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black"
                />
                <label
                  htmlFor={name}
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 uppercase"
                >
                  {label}
                </label>
              </div>
            ))}
            {[
              { name: 'division', label: 'Division' },
              { name: 'group', label: 'Group' },
              { name: 'position', label: 'Position' },
              { name: 'status', label: 'Status' }
            ].map(({ name, label }) => (
              <div key={name} className="relative z-0 w-full mb-5">
                <select
                  name={name}
                  value={formData[name]}
                  onChange={handleChange}
                  required
                  className="block w-full px-0 pt-3 pb-2 mt-0 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-black"
                >
                  {dropdownOptions[name].map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                <label
                  htmlFor={name}
                  className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 uppercase"
                >
                  {label}
                </label>
              </div>
            ))}
            <div className="flex justify-end w-full p-2">
              <button type="button" onClick={onClose} className="px-4 py-2 mr-2 bg-gray-300 rounded">Cancel</button>
              <button type="submit" className="px-4 py-2 text-white bg-blue-500 rounded">Save</button>
            </div>
          </form>
        </div>
      </div>
      {isTwoButtonModalOpen && (
        <TwoButtonModal
          title="Confirm Save"
          body="Are you sure you want to save the changes?"
          onSave={handleSave}
          onClose={() => setIsTwoButtonModalOpen(false)}
        />
      )}
    </>
  );
};

export default EditUserModal;