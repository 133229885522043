import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import SrtParser from 'srt-parser-2';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from '../axiosConfig';
import CustomScrollbars from "../components/commons/CustomScrollbars";

const SubtitleEditor = ({ selectedVideoId, setSelectedVideoId }) => {
  const [subtitles, setSubtitles] = useState([]);
  const [videoSrc, setVideoSrc] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0); // 전체 영상 길이
  const [progress, setProgress] = useState(0); // 현재 작업 진척도
  const [confirmedSubtitles, setConfirmedSubtitles] = useState(new Set()); // 컨펌된 타임코드
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const timelineRef = useRef(null);
  const [videoHeight, setVideoHeight] = useState(0);  // To dynamically adjust video height

  // Fetch subtitles if a video is selected
  useEffect(() => {
    if (selectedVideoId) {
      fetchSubtitles(selectedVideoId).then(setSubtitles);
    }
  }, [selectedVideoId]);

  // Initialize video.js and sync the player with the current time and subtitles
  useEffect(() => {
    if (videoRef.current) {
      playerRef.current = videojs(videoRef.current, {
        controls: true,
        fluid: true,
      });

      playerRef.current.on('timeupdate', () => {
        const time = playerRef.current.currentTime();
        setCurrentTime(time);
        updatePlayheadPosition(time);
        setProgress((confirmedSubtitles.size / subtitles.length) * 100); // Update progress
      });

      playerRef.current.on('loadedmetadata', () => {
        // Adjust video height dynamically based on aspect ratio
        const videoElement = videoRef.current;
        const videoWidth = videoElement.offsetWidth;
        const aspectRatio = playerRef.current.videoHeight() / playerRef.current.videoWidth();
        setVideoHeight(videoWidth * aspectRatio);
        setVideoDuration(playerRef.current.duration()); // Set video duration

        if (subtitles.length > 0) {
          playerRef.current.addRemoteTextTrack({
            kind: 'subtitles',
            src: URL.createObjectURL(new Blob([generateVtt(subtitles)], { type: 'text/vtt' })),
            srclang: 'en',
            label: 'English'
          }, false);
        }
      });
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [subtitles, videoSrc]);

  // Fetch subtitles from the API
  const fetchSubtitles = async (videoId) => {
    try {
      const response = await axios.get(`/subtitles/${videoId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching subtitles:', error);
      return [];
    }
  };

  // Generate VTT format for subtitles
  const generateVtt = (subtitles) => {
    return subtitles.map(sub => {
      return `\n${formatTime(sub.start)} --> ${formatTime(sub.end)}\n${sub.text}\n`;
    }).join('');
  };

  const handleSubtitleChange = (index, key, value) => {
    const updatedSubtitles = [...subtitles];
    updatedSubtitles[index][key] = value;
    setSubtitles(updatedSubtitles);
  };

  const handleConfirmSubtitle = (index) => {
    setConfirmedSubtitles(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      setProgress((newSet.size / subtitles.length) * 100); // Update progress
      return newSet;
    });
  };

  const updatePlayheadPosition = (time) => {
    const timeline = timelineRef.current;
    const totalDuration = playerRef.current.duration();
    const playheadPosition = (time / totalDuration) * timeline.clientWidth; // Adjust to clientWidth
    const playhead = document.getElementById('playhead');
    playhead.style.left = `${playheadPosition}px`;
  
    // Scroll the timeline to keep the playhead in view
    timeline.scrollLeft = playheadPosition - timeline.clientWidth / 2;
  };

  // Handle video file upload
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const videoURL = URL.createObjectURL(file);
      setVideoSrc(videoURL);  // Set video source without resetting subtitles
    }
  };

  // Handle SRT file upload
  const handleSrtUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const parser = new SrtParser();
        const srtSubtitles = parser.fromSrt(e.target.result);
        setSubtitles(srtSubtitles.map(sub => ({
          start: parseTime(sub.startTime),
          end: parseTime(sub.endTime),
          text: sub.text
        })));
      };
      reader.readAsText(file);
    }
  };

  // Helper function to parse time
  const parseTime = (timeString) => {
    const parts = timeString.split(":");
    const hours = parseInt(parts[0], 10) || 0;
    const minutes = parseInt(parts[1], 10) || 0;
    const seconds = parseFloat(parts[2]) || 0;
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Jump to the start time of a subtitle when clicked
  const handleSubtitleClick = (startTime) => {
    if (playerRef.current) {
      playerRef.current.currentTime(startTime);
    }
  };

  // 타임라인의 카드 클릭 시 해당 영상 위치로 이동
  const handleTimelineClick = (time) => {
    if (playerRef.current) {
      playerRef.current.currentTime(time);
    }
  };

  // Format time as HH:MM:SS.mmm
  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);
    const ms = Math.floor((seconds % 1) * 1000);
    return `${h > 0 ? `${h}:` : ''}${m < 10 ? `0${m}` : m}:${s < 10 ? `0${s}` : s}.${ms < 100 ? ms < 10 ? `00${ms}` : `0${ms}` : ms}`;
  };

  // Render timecodes for the timeline
  const renderTimecodes = () => {
    const timecodes = [];
    const totalDuration = playerRef.current ? playerRef.current.duration() : 1;
    const numIntervals = 10;
    const interval = totalDuration / numIntervals;

    for (let i = 0; i <= numIntervals; i++) {
      const time = i * interval;
      timecodes.push(
        <div
          key={i}
          style={{ left: `${(time / totalDuration) * 100}%` }}
          className="absolute text-xs text-gray-600"
        >
          {formatTime(time)}
        </div>
      );
    }
    return timecodes;
  };

  const calculateCPS = (text, duration) => {
    const characters = text.length;
    return (characters / duration).toFixed(2);
  };

  const calculateCPL = (text) => {
    const lines = text.split('\n');
    const totalCharacters = lines.reduce((acc, line) => acc + line.length, 0);
    return (totalCharacters / lines.length).toFixed(2);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left - Subtitles Editor */}
      <div className="w-1/3 p-6 overflow-auto bg-white border-r" style={{ overflow: 'hidden' }}>
        <h2 className="mb-4 text-2xl font-semibold">판타스틱 듀오 EP.21</h2>
        <div className="flex items-center mb-4">
          <span className="text-sm text-gray-600">영상 길이: {formatTime(videoDuration)}</span>
        </div>
        <div className="flex items-center mb-4">
          <span className="text-sm text-gray-600">진척도: {progress.toFixed(2)}%</span>
          <div className="w-full h-2 ml-2 bg-gray-200 rounded">
            <div className="h-full bg-blue-600 rounded" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
        <div className="flex items-center mb-4">
          <button className="px-4 py-2 mr-2 text-sm text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-200 hover:text-gray-700">Ai.PE</button>
          <button className="px-4 py-2 mr-2 text-sm text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-200 hover:text-gray-700">작업자 할당</button>
          <button className="px-4 py-2 mr-2 text-sm text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-200 hover:text-gray-700">영상 변경</button>
          <button
            onClick={() => document.getElementById('srtUpload').click()}
            className="px-4 py-2 text-sm text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-200 hover:text-gray-700"
          >
            자막 업로드
          </button>
        </div>
        <input
          type="file"
          id="srtUpload"
          accept=".srt"
          onChange={handleSrtUpload}
          className="hidden"
        />
        <CustomScrollbars style={{ height: 'calc(100vh - 200px)' }}>
          {subtitles.map((subtitle, index) => (
            <div
              key={index}
              onClick={() => handleSubtitleClick(subtitle.start)}
              className={`border p-4 mb-4 rounded-lg shadow cursor-pointer ${
                currentTime >= subtitle.start && currentTime <= subtitle.end ? 'border-blue-500' : 'border-gray-300'
              }`}
            >
              <div className="flex justify-between mb-2">
                <div className="font-bold">#{index + 1}</div>
                <div>
                  <span className="text-sm text-gray-600">{formatTime(subtitle.start)} - {formatTime(subtitle.end)}</span>
                  <span className="ml-2 text-sm text-gray-600">CPS: {calculateCPS(subtitle.text, subtitle.end - subtitle.start)}</span>
                  <span className="ml-2 text-sm text-gray-600">CPL: {calculateCPL(subtitle.text)}</span>
                </div>
                <button
                  onClick={() => handleConfirmSubtitle(index)}
                  className={`ml-2 px-2 py-1 text-xs rounded ${confirmedSubtitles.has(index) ? 'bg-green-500 text-white' : 'bg-gray-300 text-gray-700'}`}
                >
                  {confirmedSubtitles.has(index) ? 'Confirmed' : 'Confirm'}
                </button>
              </div>
              <textarea
                value={subtitle.text}
                onChange={(e) => handleSubtitleChange(index, 'text', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                rows="3"
              />
            </div>
          ))}
        </CustomScrollbars>
      </div>

      {/* Right - Video and Timeline */}
      <div className="flex flex-col w-2/3 p-6 bg-white">
        {/* Video Player */}
        <div className="relative flex items-center justify-center p-4 mb-4 bg-white border rounded-lg" style={{ height: videoHeight || 'auto' }}>
          {!videoSrc && (
            <div className="flex flex-col items-center">
              <label
                htmlFor="videoUpload"
                className="px-4 py-2 text-sm font-medium text-black bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-200"
              >
                Select video file
              </label>
              <input
                type="file"
                id="videoUpload"
                accept="video/*"
                onChange={handleVideoUpload}
                className="hidden"
              />
            </div>
          )}
          {videoSrc && (
            <div className="relative w-full h-full">
              <video ref={videoRef} className="w-full h-full video-js vjs-default-skin" controls preload="auto">
                <source src={videoSrc} type="video/mp4" />
              </video>
              {subtitles.map((subtitle, index) => (
                <div
                  key={index}
                  className={`absolute bottom-0 w-full text-center text-white bg-black bg-opacity-50 ${
                    currentTime >= subtitle.start && currentTime <= subtitle.end ? 'block' : 'hidden'
                  }`}
                  style={{ bottom: '50px', fontSize: '1.5rem', padding: '10px 0' }}
                >
                  {subtitle.text}
                </div>
              ))}
            </div>
          )}
        </div>
        <button className="absolute px-4 py-2 text-sm text-white bg-blue-600 rounded-lg top-4 right-12 hover:bg-blue-700">
          <FontAwesomeIcon icon={faFileExport} className="mr-2" />
          Export
        </button>

        <CustomScrollbars style={{ height: '100px' }}>
          <div className="relative w-full h-32 mt-8 bg-white rounded-lg" ref={timelineRef}>
            <div id="playhead" className="absolute w-1 h-full bg-blue-500" style={{ top: 0, zIndex: 10 }}></div>
            <div className="relative flex items-center h-full" style={{ minWidth: '100%' }}>
              {subtitles.map((subtitle, index) => {
                const totalDuration = playerRef.current ? playerRef.current.duration() : 1;
                const startPercent = (subtitle.start / totalDuration) * 100;
                const endPercent = (subtitle.end / totalDuration) * 100;
                const widthPercent = endPercent - startPercent;

                return (
                  <div
                    key={index}
                    onClick={() => handleTimelineClick(subtitle.start)}
                    className="absolute px-2 py-1 text-sm text-center text-white bg-gray-400 rounded-lg cursor-pointer"
                    style={{ left: `${startPercent}%`, width: `${widthPercent}%`, height: '85%' }}
                  >
                    <span>{subtitle.text}</span>
                  </div>
                );
              })}
            </div>
            <div className="absolute flex items-center w-full h-full">
              {renderTimecodes()}
            </div>
          </div>
        </CustomScrollbars>
      </div>
    </div>
  );
};

export default SubtitleEditor;