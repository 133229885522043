import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa'; // Font Awesome 아이콘 가져오기

const QuicklinkCard = () => {
  const handleHomePageClick = () => {
    window.location.href = 'https://www.enkoline.com';
  };

  const handleHiworksClick = () => {
    window.location.href = 'https://login.office.hiworks.com/enkoline.com';
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <h3 className="mb-4 text-lg font-semibold">
        <span className="pb-1 border-b-2 border-blue-500">외부 링크</span>
      </h3>
      <ul className="space-y-2">
        <li className="flex items-center justify-between pb-2 border-b border-gray-200">
          <button onClick={handleHomePageClick} className="text-blue-500 hover:underline">홈페이지</button>
          <FaExternalLinkAlt onClick={handleHomePageClick} className="w-4 h-4 text-gray-500 cursor-pointer" />
        </li>
        <li className="flex items-center justify-between pb-2 border-b border-gray-200">
          <button onClick={handleHiworksClick} className="text-blue-500 hover:underline">하이웍스 이메일</button>
          <FaExternalLinkAlt onClick={handleHiworksClick} className="w-4 h-4 text-gray-500 cursor-pointer" />
        </li>
        <li className="flex items-center justify-between pb-2 border-b border-gray-200">
          <button onClick={handleHiworksClick} className="text-blue-500 hover:underline">구글 드라이브</button>
          <FaExternalLinkAlt onClick={handleHiworksClick} className="w-4 h-4 text-gray-500 cursor-pointer" />
        </li>
        
      </ul>
    </div>
  );
};

export default QuicklinkCard;