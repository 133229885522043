import React, { useContext, useState } from 'react';
import Pagination from '../components/bars/Pagination';
import { WriteButton } from '../components/commons/Buttons'; // WriteButton imported
import { SidebarContext } from '../contexts/SidebarContext'; // Import SidebarContext

const Board = () => {
  const { isCollapsed } = useContext(SidebarContext); // Use SidebarContext
  const posts = [
    { id: 1, category: '공지', title: '모바일 앱 이용 안내(iOS/Android)', date: '20.08.18', views: 28306, likes: 0, author: '관리자', content: '여기에 내용을 입력하세요.', link: '/posts/1' },
    { id: 2, category: '공지', title: '유머 채널 규정 안내', date: '22.12.31', views: 158062, likes: 0, author: '관리자', content: '여기에 내용을 입력하세요.', link: '/posts/2' },
    { id: 3, category: '공지', title: '혐오 관련 글/댓글 제한 안내', date: '22.10.23', views: 244261, likes: 0, author: '관리자관리자관리자', content: '여기에 내용을 입력하세요.', link: '/posts/3' },
    { id: 4, category: '이슈', title: '여성판 N번방에 화들짝 주한미군, 법적대응 검토', date: '25분전', views: 857, likes: 46, author: '유저1', content: '여기에 내용을 입력하세요.', link: '/posts/4' },
    { id: 5, category: '유머', title: '??? : "간나새끼들 또 일 터졌다고?"', date: '17분전', views: 1071, likes: 27, author: '유저2', content: '여기에 내용을 입력하세요.', link: '/posts/5' },
    { id: 6, category: '유머', title: '너희 자꾸 선생님 말 ��들으면', date: '38분전', views: 2143, likes: 35, author: '유저3', content: '여기에 내용을 입력하세요.', link: '/posts/6' },
    { id: 7, category: '유머', title: '오줌에 면세한도 풀린 품목', date: '42분', views: 2482, likes: 56, author: '유저4', content: '여기에 내용을 입력하세요.', link: '/posts/7' },
    { id: 8, category: '유머', title: '국에서 흑인 빈민가가 생긴 원인.jpg', date: '3시간전', views: 2756, likes: 44, author: '유저5', content: '여기에 내용을 입력하세요.', link: '/posts/8' },
    { id: 9, category: '유머', title: '원피스 고 명장면', date: '1시간전', views: 2324, likes: 53, author: '유저6', content: '여기에 내용을 입력하세요.', link: '/posts/9' },
    { id: 10, category: '유머', title: '되게 하찮게 생긴 새', date: '1시간전', views: 2476, likes: 29, author: '유저7', content: '여기에 내용을 입력하세요.', link: '/posts/10' },
    { id: 11, category: '유머', title: '나라에 큰일이 났는지 확인 가능한 간단한 방법', date: '1시간전', views: 2694, likes: 56, author: '유저8', content: '여기에 내용을 입력하세요.', link: '/posts/11' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(posts.length / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex flex-row p-0 px-2.5 mx-auto min-h-[100vh] sm-custom:px-2 sm-custom:max-w-[430px] min-custom:max-w-[344px]">
      <main
        className={`flex-grow p-5 mt-0 mb-0 ml-0 bg-white custom:w-full transition-all duration-300 ${
          isCollapsed ? 'ml-[70px] mr-[10px]' : 'ml-[240px] mr-[10px]'
        }`}
      >
        <div className="w-full mt-5">
          <div className="min-w-full">
            <div className="hidden md:block">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left bg-gray-100 border-b border-gray-300 truncate max-w-[8em]">주제</th>
                    <th className="px-4 py-2 text-left bg-gray-100 border-b border-gray-300">제목</th>
                    <th className="px-4 py-2 text-center bg-gray-100 border-b border-gray-300 truncate max-w-[6em]">작성자</th>
                    <th className="px-4 py-2 text-center bg-gray-100 border-b border-gray-300">조회수</th>
                    <th className="px-4 py-2 text-center bg-gray-100 border-b border-gray-300 truncate max-w-[4em]">추천</th>
                    <th className="px-4 py-2 text-center bg-gray-100 border-b border-gray-300">작성일</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((post) => (
                    <tr key={post.id} className="hover:bg-gray-100">
                      <td className="px-4 py-2 border-b border-gray-300 truncate max-w-[8em]">{post.category}</td>
                      <td className="max-w-xs px-4 py-2 truncate border-b border-gray-300">
                        <a href={post.link} className="text-black hover:underline">{post.title}</a>
                      </td>
                      <td className="px-4 py-2 text-sm text-center border-b border-gray-300 truncate max-w-[6em]">{post.author}</td>
                      <td className="px-4 py-2 text-sm text-center border-b border-gray-300">{post.views}</td>
                      <td className="px-4 py-2 text-sm text-center border-b border-gray-300 truncate max-w-[4em]">{post.likes}</td>
                      <td className="px-4 py-2 text-sm text-center border-b border-gray-300">{post.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="block md:hidden">
              {currentPosts.map((post) => (
                <div key={post.id} className="p-4 bg-white border-b border-gray-300 hover:bg-gray-100">
                  <a href={post.link} className="block max-w-full font-bold text-black truncate hover:underline">{post.title}</a>
                  <div className="flex justify-between mt-2 text-xs">
                    <div className="text-left truncate">{post.author}</div>
                    <div className="space-x-2 text-right">
                      <span>{post.date}</span>
                      <span>| 조회 {post.views}</span>
                      <span>| 추천 {post.likes}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between mt-4">
            <div className="flex-grow"></div>
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} pageNumbers={pageNumbers} />
            <div className="flex-grow"></div>
            <WriteButton />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Board;