import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import axios from '../axiosConfig';

const HashTagModal = ({ isOpen, onClose, onSave }) => {
  const [newTag, setNewTag] = useState('');
  const queryClient = useQueryClient();

  const handleAddTag = async () => {
    if (!newTag.trim()) {
      // newTag가 비어 있으면 함수 종료
      return;
    }

    try {
      await axios.post('/saveDropdown', { type: 'tags', value: newTag });
      onSave(newTag);
      setNewTag('');
      onClose();
      queryClient.invalidateQueries('tags');
    } catch (error) {
      console.error('Error adding tag:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-11/12 bg-white rounded-lg shadow-lg md:w-1/2 lg:w-1/3">
        <div className="flex items-center justify-between p-4 border-b">
          <h2 className="text-xl font-semibold">태그 추가</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
        <div className="p-4">
          <input
            type="text"
            className="w-full p-2 mb-4 border border-gray-300 rounded"
            placeholder="새 태그 입력"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
          />
          <div className="flex justify-end">
            <button onClick={handleAddTag} className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600">추가</button>
            <button onClick={onClose} className="px-4 py-2 ml-2 text-gray-700 bg-gray-200 rounded hover:bg-gray-300">취소</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HashTagModal;